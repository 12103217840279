import { FC, memo } from 'react';
import { css, styled } from 'styled-components';

interface ErrorMessageProps {
  error?: string;
  className?: string;

  isAbsolute?: boolean;
  marginBottom?: number;
  isCenterAlign?: boolean;
}

const ErrorText = styled.p<Omit<ErrorMessageProps, 'className' | 'error'>>`
  width: 100%;

  color: ${({ theme }) => theme.colors.primary};

  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 'initial')};
  text-align: ${({ isCenterAlign }) => (isCenterAlign ? 'center' : 'initial')};

  // WARNING ----- applies only to one line error ----- WARNING
  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 73px;
    `}
`;

const ErrorMessage: FC<ErrorMessageProps> = ({ error, className, ...props }) => {
  return (
    <ErrorText className={className} {...props}>
      {error}
    </ErrorText>
  );
};

export default memo(ErrorMessage);

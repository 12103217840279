import { darken } from 'polished';
import { styled } from 'styled-components';

export const Label = styled.label`
  display: flex;
  line-height: 23px;
`;

export const CheckboxInput = styled.input`
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  border: 1px solid ${({ theme }) => theme.colors.card.background};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary)};

    &:before {
      background-color: ${({ theme }) => darken(0.05, theme.colors.controls.background)};
    }
  }

  display: grid;
  place-content: center;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 5px;
    transition: 0.3s all ease;
    background-color: ${({ theme }) => theme.colors.controls.background};
    clip-path: none;
  }

  &:checked:before {
    // to avoid strange error with invalid property value for basic clip-path
    -webkit-clip-path: path(
      'M14.3382 6.30096C14.6999 5.91846 15.3074 5.89779 15.695 6.25479C16.0826 6.61179 16.1036 7.21128 15.7418 7.59378L9.02182 14.699C8.67084 15.0701 8.08581 15.1023 7.69525 14.7719L4.33526 11.9298C3.9327 11.5893 3.88609 10.9912 4.23113 10.594C4.57618 10.1967 5.18222 10.1507 5.58477 10.4912L8.24608 12.7423L14.3382 6.30096Z'
    );
  }
`;

import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AccessStatus } from 'enums/application';
import { TypographyComponent } from 'enums/ui';
import { getLoanOfficerFormattedArray } from 'utils/formatting';

import { selectIsRealtorLoanOfficer } from 'store/selectors/auth';
import { selectTasksByApplicationIds } from 'store/selectors/tasks';
import { selectIsDesktopView } from 'store/selectors/ui';
import { LoanApplication } from 'types/application';

import Tabs from 'components/Tabs';

import FileProgress, { FileProgressSkeleton } from '../FileProgress';
import KeyPeople, { KeyPeopleSkeleton } from '../KeyPeople';

import { useGrouppedTasks, useTaskTabs } from './Tasks.hooks';
import { TasksContainer, TaskSidebar, TasksSkeleton, TasksWrapper, Title } from './Tasks.styles';

interface TasksProps {
  application?: LoanApplication;
  isLoading: boolean;
  isError: boolean;
  isTasksError: boolean;
  isSuccess: boolean;
  isLoadingTasks: boolean;
}

const Tasks: FC<TasksProps> = ({
  application,
  isLoading,
  isLoadingTasks,
  isSuccess,
  isError,
  isTasksError,
}) => {
  const applicationId = application?.applicationId as string;

  const IsDesktop = useSelector(selectIsDesktopView);
  const isRealtorLO = useSelector(selectIsRealtorLoanOfficer);
  const tasks = useSelector(selectTasksByApplicationIds([applicationId]));

  const grouppedTasks = useGrouppedTasks(tasks?.[applicationId], isRealtorLO);
  const tabs = useTaskTabs(grouppedTasks, isRealtorLO);

  const isNoLoan = isSuccess && !application;
  const withAccess = application?.accessStatus === AccessStatus.Approved;

  const loanContactsData = useMemo(
    () => getLoanOfficerFormattedArray(application?.loanOfficer),
    [application?.loanOfficer],
  );

  if (isLoading || isLoadingTasks || !application) {
    const isPlug = isError || isTasksError || isNoLoan;
    return (
      <TasksWrapper>
        <TasksSkeleton isPlug={isPlug} />
        {IsDesktop && (
          <TaskSidebar>
            <FileProgressSkeleton isPlug={isPlug} />
            <KeyPeopleSkeleton isPlug={isPlug} />
          </TaskSidebar>
        )}
      </TasksWrapper>
    );
  }

  return (
    <TasksWrapper>
      <TasksContainer>
        <Title component={TypographyComponent.H6}>Tasks</Title>

        <Tabs $isFewLinesEnabled={isRealtorLO} minTabWidth={140} tabs={tabs} />
      </TasksContainer>

      {IsDesktop && withAccess && (
        <TaskSidebar>
          <FileProgress
            isTbd={application.isTbd}
            processingStopReason={application.loanProcessingStopReason}
            progress={application.progress}
            status={application.loanStatus}
          />
          <KeyPeople loanContacts={loanContactsData} />
        </TaskSidebar>
      )}
    </TasksWrapper>
  );
};

export default memo(Tasks);

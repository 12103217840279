import { Form as FormikForm } from 'formik';
import { transparentize } from 'polished';
import { styled } from 'styled-components';

import Button from 'components/Button';
import Card from 'components/Card';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const NotesContainer = styled(Card)`
  display: flex;
  width: 340px;
  padding: 20px 0;
  height: 650px;
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;
  padding: 0 25px;
`;

export const Form = styled(FormikForm)`
  width: 100%;
  padding: 0 25px;

  & > :first-child {
    margin-bottom: 20px;
  }
`;

export const NotesList = styled.div<{ $isOverflow: boolean }>`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow-y: auto;
  // consider the width of scrollbar
  padding: 0 ${({ $isOverflow }) => ($isOverflow ? 13 : 25)}px 0 25px;

  & > :not(:last-child) {
    margin-bottom: 15px;
    border-bottom: ${({ theme }) =>
      `1px solid ${transparentize(0.5, theme.colors.controls.placeholder)}`};
  }
`;

export const EmptyMessage = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.controls.placeholder};
`;

export const LeaveNoteButton = styled(Button)`
  min-width: 130px;
`;

export const APIErrorMessage = styled(ErrorMessage)`
  margin-bottom: 15px;
  text-align: center;
`;

export const NotesSkeleton = styled(Skeleton)`
  width: 340px;
  height: 650px;
  border-radius: 20px;
`;

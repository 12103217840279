import { memo, useCallback, useState } from 'react';
import get from 'lodash/get';

import { Cell } from 'components/Table/components/Cell';
import { StyledRow } from 'components/Table/components/Row/Row.styles';
import { Column } from 'components/Table/Table.types';

interface RowProps<T> {
  onRowClick?: (rowData: T) => void;
  rowData: T;
  columns: Column<T>[];
  rowHeight?: number;
  activeId?: string;
  activeIdPath?: string;
  selectedId?: string;
  selectedIdPath?: string;
  getIsHighlighted?: ({ rowData }: { rowData: T }) => boolean;
  getIsWithSubGrid?: ({ rowData }: { rowData: T }) => boolean;
  subGridComponent?: React.ComponentType<{ rowData: T }>;
  blockTestName?: string;
}

const Row = <T extends unknown>({
  onRowClick,
  rowData,
  columns,
  rowHeight,
  activeId,
  activeIdPath,
  selectedId,
  selectedIdPath,
  getIsHighlighted,
  getIsWithSubGrid,
  subGridComponent: SubGridComponent,
  blockTestName,
}: RowProps<T>) => {
  const [isSubGridShown, setIsSubGridShown] = useState(false);

  const isActiveRow = activeId && activeIdPath ? get(rowData, activeIdPath) === activeId : false;
  const isSelectedRow =
    selectedId && selectedIdPath ? get(rowData, selectedIdPath) === selectedId : false;
  const isHighlighted = getIsHighlighted ? getIsHighlighted({ rowData }) : false;

  const isWithSubGrid = getIsWithSubGrid?.({ rowData });

  const onClick = useCallback(() => {
    if (isWithSubGrid) {
      setIsSubGridShown(val => !val);
    }

    if (isActiveRow) {
      return;
    }

    onRowClick?.(rowData);
  }, [isActiveRow, isWithSubGrid, onRowClick, rowData]);

  return (
    <>
      <StyledRow
        $isClickable={!!onRowClick || isWithSubGrid}
        data-testid={`${blockTestName}__row`}
        isActive={isActiveRow}
        isHighlighted={isHighlighted}
        isSelected={isSelectedRow}
        isSubGridShown={isSubGridShown}
        isWithSubGrid={isWithSubGrid}
        rowHeight={rowHeight}
        onClick={onClick}
      >
        {columns.map(columnConfig => {
          const key = columnConfig.key;
          return (
            <Cell<T>
              {...columnConfig}
              cellData={get(rowData, key)}
              data-testid={`${blockTestName}__cell__${key}`}
              key={key}
              rowData={rowData}
            />
          );
        })}
      </StyledRow>
      {isSubGridShown && (
        //@ts-ignore
        <SubGridComponent rowData={rowData} />
      )}
    </>
  );
};

export default memo(Row) as typeof Row;

import { FC } from 'react';
import isEmpty from 'lodash/isEmpty';

import { NO_APPLICATIONS_MSG } from 'constants/application';
import { TypographyComponent } from 'enums/ui';
import usePagedApplications from 'hooks/usePagedApplications';
import { getEmptyArray } from 'utils/array';

import CustomerCard, { ApplicationCardSkeleton } from 'components/ApplicationCard';
import Pagination, { PaginationSkeleton } from 'components/Pagination';
import { Typography } from 'components/Typography';

import { CardsContainer, EmptyStateContainer } from './ApplicationCards.styles';

const PAGE_SIZE = 6;

interface ApplicationCardsProps {
  status?: string | null;
}

const ApplicationCards: FC<ApplicationCardsProps> = ({ status }) => {
  const {
    applications,
    applicationIdsOnPage,
    isError,
    isFetchingNewQuery,
    currentPage,
    maxPage,
    setPage,
  } = usePagedApplications({ pageSize: PAGE_SIZE, status });

  if (isFetchingNewQuery || !applications || isError) {
    return (
      <>
        <CardsContainer>
          {getEmptyArray(PAGE_SIZE).map((_, index) => (
            <ApplicationCardSkeleton isError={isError} key={index} />
          ))}
        </CardsContainer>
        {maxPage ? (
          <Pagination currentPage={currentPage} maxPage={maxPage} setPage={setPage} />
        ) : (
          <PaginationSkeleton isError={isError} />
        )}
      </>
    );
  }

  if (isEmpty(applications)) {
    return (
      <EmptyStateContainer>
        <Typography component={TypographyComponent.H5}>{NO_APPLICATIONS_MSG}</Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <>
      <CardsContainer>
        {applications.map(app => (
          <CustomerCard key={app.id} {...app} applicationIdsOnPage={applicationIdsOnPage} />
        ))}
      </CardsContainer>
      <Pagination currentPage={currentPage} maxPage={maxPage} setPage={setPage} />
    </>
  );
};

export default ApplicationCards;

import { FC, ReactNode } from 'react';
import { Field } from 'formik';
import { styled } from 'styled-components';

import { TypographyComponent } from 'enums/ui';

import Modal from 'components/Modal';
import { Typography } from 'components/Typography';

export const DevTogglesModalContaner = styled(Modal)`
  padding: 25px;
`;

export const FieldSet = styled.fieldset`
  max-height: 250px; // 7 flags visible in each category
  overflow-x: hidden;
  overflow-y: auto;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: baseline;
  width: min-content;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
export const Checkbox = styled(Field)`
  width: 14px;
  height: 14px;
  margin-left: 5px;
  appearance: auto;
  cursor: pointer;
`;

const StyledTitle = styled(Typography)`
  padding: 0 20px 5px 0;
`;

export const Title: FC<{ children?: ReactNode }> = ({ children }) => (
  <StyledTitle component={TypographyComponent.H5}>{children}</StyledTitle>
);

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Warning = styled.p`
  margin: 10px 0 -10px;
  font-size: 14px;
  color: orangered;
`;

import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, FormikHelpers } from 'formik';
import { useTheme } from 'styled-components';

import { ButtonVariant, TypographyComponent, TypographyWeight } from 'enums/ui';
import { getApiError } from 'utils/api';
import { copyToClipboard } from 'utils/clipboard';
import { formatPhoneForRequest } from 'utils/formatting';

import { useGetReusableLinkMutation, useSendInviteMutation } from 'services/baseAPI';
import { selectCompanyId } from 'store/selectors/auth';

import EmailField from 'components/@fields/EmailField';
import NameField from 'components/@fields/NameField';
import PhoneField from 'components/@fields/PhoneField';
import CircleSpinner from 'components/CircleSpinner';
import SeparatorLine from 'components/SeparatorLine';
import { Typography } from 'components/Typography';

import {
  ButtonsContainer,
  ButtonWithIcon,
  ChainIcon,
  Form,
  InviteBuyerCardContainer,
  InviteCardError,
  LinkButtons,
  SendInviteButton,
} from './InviteBuyerCard.styles';
import schema from './InviteBuyerCard.validationSchema';

const initialValue = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

const showToast = () =>
  toast('Copied to clipboard', {
    // toastId prevent duplicate toasts
    toastId: 'clipboardToastId',
    position: 'bottom-center',
    pauseOnHover: false,
    pauseOnFocusLoss: false,
  });

const InviteBuyerCard: FC = () => {
  const theme = useTheme();

  const companyId = useSelector(selectCompanyId);

  const [sendInvite, metaSendInvite] = useSendInviteMutation();
  const [getReusableLink, metaGetReusableLink] = useGetReusableLinkMutation();

  const isLoading = metaSendInvite.isLoading || metaGetReusableLink.isLoading;
  const APIError = getApiError(metaSendInvite.error || metaGetReusableLink.error);

  const onSubmit = useCallback(
    async (
      { email, phoneNumber, firstName, lastName }: typeof initialValue,
      { resetForm }: FormikHelpers<typeof initialValue>,
    ) => {
      const leadLink = await sendInvite({
        emailAddress: (email && email.trim()) || null,
        phoneNumber: formatPhoneForRequest(phoneNumber) || null,
        companyId,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      }).unwrap();

      if (leadLink) {
        toast('Invite was sent');
        resetForm();
      }
    },
    [companyId, sendInvite],
  );

  const onGetUniqueLink = useCallback(async () => {
    const isWithoutError = await copyToClipboard({ request: sendInvite({ companyId }) });

    if (isWithoutError) {
      showToast();
    }
  }, [companyId, sendInvite]);

  const onGetReusableLink = useCallback(async () => {
    const reusableLink = await getReusableLink().unwrap();
    await copyToClipboard({ text: reusableLink });

    showToast();
  }, [getReusableLink]);

  return (
    <InviteBuyerCardContainer>
      <Formik initialValues={initialValue} validationSchema={schema} onSubmit={onSubmit}>
        {({ handleChange, isSubmitting }) => (
          <Form>
            <Typography component={TypographyComponent.H5} weight={TypographyWeight.SemiBold}>
              Invite Buyer
            </Typography>

            <NameField
              isAbsoluteError
              required
              disabled={isSubmitting}
              label="First Name"
              name="firstName"
              onChange={handleChange}
            />
            <NameField
              isAbsoluteError
              required
              disabled={isSubmitting}
              label="Last Name"
              name="lastName"
              onChange={handleChange}
            />
            <EmailField
              isAbsoluteError
              required
              disabled={isSubmitting}
              label="Email"
              name="email"
              onChange={handleChange}
            />

            <SeparatorLine>Or</SeparatorLine>

            <PhoneField isAbsoluteError required disabled={isSubmitting} onChange={handleChange} />

            {APIError && <InviteCardError error={APIError} />}

            <ButtonsContainer>
              <SendInviteButton disabled={isSubmitting} type="submit">
                {isSubmitting ? <CircleSpinner /> : ' Send Invite'}
              </SendInviteButton>

              <LinkButtons>
                <ButtonWithIcon
                  disabled={isLoading}
                  type="button"
                  variant={ButtonVariant.Transparent}
                  onClick={onGetUniqueLink}
                >
                  {/*only if click on get link button*/}
                  {!isSubmitting && isLoading ? (
                    <CircleSpinner color={theme.colors.primary} size={15} />
                  ) : (
                    <ChainIcon />
                  )}
                  <span>Get Unique Link</span>
                </ButtonWithIcon>

                <ButtonWithIcon
                  disabled={isLoading}
                  type="button"
                  variant={ButtonVariant.Transparent}
                  onClick={onGetReusableLink}
                >
                  <ChainIcon />
                  <span>Get Reusable Link</span>
                </ButtonWithIcon>
              </LinkButtons>
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
    </InviteBuyerCardContainer>
  );
};

export default InviteBuyerCard;

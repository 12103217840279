import { styled } from 'styled-components';

import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const FileProgressContainer = styled(Card)`
  width: 340px;
  padding: 0 0 25px 0;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 87px;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Title = styled(Typography)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Path = styled.div`
  margin: 10px 20px 0;
`;

export const FileProgressSkeleton = styled(Skeleton)`
  border-radius: 20px;
  width: 340px;
  height: 700px;
`;

export const Error = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
`;

import { KeyboardEvent } from 'react';

import { KeyCode } from 'enums/keyboard';

export const inputKeyHandler = (e: KeyboardEvent<HTMLInputElement>) => {
  switch (e.code) {
    case KeyCode.Enter:
      // we should ignore enter key down for avoiding unexpected form submitting
      e.preventDefault();
      e.stopPropagation();
      break;
  }
};

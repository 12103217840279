import { FC, memo, useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { TypographyWeight } from 'enums/ui';
import { getApiError } from 'utils/api';

import { useSendFeedbackMutation } from 'services/baseAPI';

import TextField from 'components/@fields/TextField';
import CircleSpinner from 'components/CircleSpinner';

import {
  APIErrorMessage,
  FeedbackContainer,
  Form,
  SendFeedbackButton,
  Title,
} from './Feedback.styles';
import schema from './Feedback.validationSchema';

const initialValues = {
  feedback: '',
};

interface FeedbackProps {
  applicationId: string;
}

const Feedback: FC<FeedbackProps> = ({ applicationId }) => {
  const [sendFeedback, { error }] = useSendFeedbackMutation();
  const APIError = getApiError(error);

  const onSubmit = useCallback(
    async (
      { feedback }: typeof initialValues,
      { resetForm }: FormikHelpers<typeof initialValues>,
    ) => {
      await sendFeedback({ applicationId, feedback: feedback.trim() }).unwrap();
      resetForm();
    },
    [applicationId, sendFeedback],
  );

  return (
    <FeedbackContainer>
      <Title weight={TypographyWeight.SemiBold}>Feedback to Interfirst</Title>

      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ handleChange, dirty, isSubmitting }) => (
          <Form>
            <TextField
              $isNarrowScrollbar
              isTextArea
              autoComplete="off"
              label="Leave Your Feedback"
              maxLength={1000}
              name="feedback"
              placeholder="Your feedback here"
              rows={5}
              onChange={handleChange}
            />

            {APIError && <APIErrorMessage error={APIError} />}
            <SendFeedbackButton disabled={!dirty || isSubmitting} type="submit">
              {isSubmitting ? <CircleSpinner size={14} /> : 'Send Feedback'}
            </SendFeedbackButton>
          </Form>
        )}
      </Formik>
    </FeedbackContainer>
  );
};

export default memo(Feedback);

import { FC, memo, useCallback } from 'react';
import { format } from 'date-fns';

import { useDownloadDocumentMutation } from 'services/documentsAPI';
import { FormBundleModel } from 'types/documents';

import { DocumentContainer, Icon, Label } from './Documents.styles';

interface DocumentItemProps {
  document: FormBundleModel;
  fullName: string;
  blockTestName: string;
}

const DocumentItem: FC<DocumentItemProps> = ({ document, fullName, blockTestName }) => {
  const [downloadDocument, { isLoading: isLoading }] = useDownloadDocumentMutation();

  const { completedDate, createdDate, documentId, name: documentName } = document;

  const onDownload = useCallback(() => {
    if (documentId) {
      downloadDocument({
        documentId,
        fullName,
        createdDate: completedDate || createdDate,
        ...(documentName && { name: documentName }),
      });
    }
  }, [documentId, downloadDocument, fullName, completedDate, createdDate, documentName]);

  const label = `${documentName} - ${
    completedDate || createdDate ? format(new Date(completedDate || createdDate), 'MM.dd.u') : 'TBD'
  }`;

  return (
    <DocumentContainer data-testid={`${blockTestName}__item`} onClick={onDownload}>
      <Icon
        $isDownloadable={!isLoading && !!documentId}
        data-testid={`${blockTestName}__itemIcon`}
        title="Download"
      />
      <Label>{label}</Label>
    </DocumentContainer>
  );
};

export default memo(DocumentItem);

import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import Select, { OnChangeValue, Props as ReactSelectProps, StylesConfig } from 'react-select';
import { useField } from 'formik';
import { DefaultTheme, ThemeConsumer } from 'styled-components';

import useUniqueId from 'hooks/useUniqueId';
import { inputKeyHandler } from 'utils/ui';

import { FieldErrorMessage } from 'components/@fields/TextField';
import FieldLabel from 'components/FieldLabel';

import { getCustomStyles, SelectContainer } from './SelectField.styles';

export type OptionType = { label: string | number; value: string | number };

interface SelectFieldProps extends Omit<ReactSelectProps, 'onChange'> {
  name: string;
  label?: string | ReactNode;
  disabled?: boolean;
  required?: boolean;
  options?: OptionType[];
  isAbsoluteError?: boolean;
  onChange?: (option: OnChangeValue<OptionType, false>) => void;
}

const SelectField: FC<SelectFieldProps> = ({
  options,
  name,
  disabled,
  label,
  placeholder,
  isSearchable = true,
  styles = {},
  isAbsoluteError,
  required,
  className,
  onChange,
}) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(name);
  const id = useUniqueId(field.name);

  const isInvalid = !!(!disabled && touched && error);
  const formattedPlaceholder = required && placeholder ? `${placeholder}*` : placeholder;
  const formattedLabel = required ? `${label}*` : label;
  const validPlaceholder = formattedPlaceholder || formattedLabel;

  const getSelectStyles = useCallback(
    // eslint-disable-next-line
    (theme?: DefaultTheme): StylesConfig<any, false> => ({
      ...(theme ? getCustomStyles(theme, isInvalid) : {}),
      ...styles,
    }),
    [styles, isInvalid],
  );

  const currentValue = useMemo(
    (): OptionType | undefined =>
      options?.find((option: OptionType) => option.value === field.value),
    [field.value, options],
  );

  const handleSelectChange = useCallback(
    (option: OnChangeValue<OptionType, false>): void => {
      const value = (option as OptionType).value;

      if (value === currentValue?.value) {
        return;
      }

      setValue(value, true);
      onChange?.(option);
    },
    [setValue, currentValue, onChange],
  );

  const onBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  return (
    <SelectContainer
      className={className}
      data-testid={`${String(validPlaceholder).replace(/[^a-zA-Z]/g, '')}_dropdown`}
    >
      <FieldLabel htmlFor={id}>{label}</FieldLabel>
      <ThemeConsumer>
        {theme => (
          <Select
            {...field}
            inputId={id}
            isDisabled={disabled}
            isSearchable={isSearchable}
            menuPortalTarget={document.body}
            options={options}
            placeholder={validPlaceholder}
            styles={getSelectStyles(theme)}
            value={currentValue || null}
            onBlur={onBlur}
            onChange={handleSelectChange}
            onKeyDown={inputKeyHandler}
          />
        )}
      </ThemeConsumer>
      {isInvalid ? <FieldErrorMessage error={error} isAbsolute={isAbsoluteError} /> : null}
    </SelectContainer>
  );
};

export default memo(SelectField);

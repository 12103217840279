import { ReactNode } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { darken } from 'polished';
import { css, styled } from 'styled-components';

import { commonInput } from 'styles/common';

interface AutocompleteContainerProps {
  isNotValid?: boolean;
  children?: ReactNode;
}

interface InputProps {
  name: string;
  disabled?: boolean;
  placeholder?: string;
}

const validationHighlight = css`
  padding-right: 38px;
  border-color: ${({ theme }) => theme.colors.primary};
`;

export const LeftIconWrapper = styled.div<{
  isFocused: boolean;
  isNotValid: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 46px;

  border-right: 1px solid;
  border-color: #dadae8;

  color: #dadae8;

  transition:
    color 0.2s,
    border-color 0.2s;

  ${({ isNotValid, theme }) =>
    isNotValid &&
    css`
      border-color: ${theme.colors.primary} !important;
      color: ${theme.colors.status.error} !important;
    `}

  ${({ isFocused, theme }) =>
    isFocused &&
    css`
      border-color: ${darken(
        theme.coefficients.primaryHovered,
        theme.colors.status.info,
      )} !important;

      color: ${darken(theme.coefficients.primaryHovered, theme.colors.status.info)} !important;
    `}
`;

export const RelativeWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Wrapper = styled.div<{ disabled?: boolean | undefined }>`
  width: 100%;

  &:not(:disabled) {
    &:hover ${LeftIconWrapper} {
      border-color: ${({ theme }) =>
        darken(theme.coefficients.controlsHovered, theme.colors.status.info)};

      color: ${({ theme }) => darken(theme.coefficients.controlsHovered, theme.colors.status.info)};
    }
  }
`;

export const AutocompleteContainer = styled(
  ({ isNotValid, ...restProps }: AutocompleteContainerProps) => <div {...restProps} />,
)`
  position: relative;
  width: 100%;

  .pac-target-input {
    ${({ isNotValid }) => isNotValid && validationHighlight}
`;

export const PlacesInput = styled(Autocomplete)<InputProps>`
  ${commonInput};
`;

import { useMemo } from 'react';

import { TaskDataType, TaskGroupType, TaskStatus } from 'enums/task';

import { Task } from 'types/task';

import TasksList from './components/TasksList';

interface GrouppedTasks {
  generalTasks: Task[]; // Can be complited by realtor. TaskStatus.New
  borrowerTasks: Task[]; // can'te be complited by realtor (borrower actions required). TaskStatus.New
  doneTasks: Task[]; // all other tasks except TaskStatus.Cancelled. Excluded for advanced realtor!

  // exclusive categories for advanced realtor
  opsToReviewTasks: Task[]; // TaskStatus.Submitted
  completedTasks: Task[]; // TaskStatus.Approved
  rejectedTasks: Task[]; // TaskStatus.Rejected
  canceledTasks: Task[]; // TaskStatus.Canceled
}

export const useGrouppedTasks = (tasks?: Task[], isRealtorLO?: boolean): GrouppedTasks =>
  useMemo(() => {
    const initialState: GrouppedTasks = {
      generalTasks: [],
      borrowerTasks: [],
      doneTasks: [],
      opsToReviewTasks: [],
      completedTasks: [],
      rejectedTasks: [],
      canceledTasks: [],
    };

    if (!tasks) {
      return initialState;
    }

    if (isRealtorLO) {
      return tasks.reduce((acc, task) => {
        if (task.status === TaskStatus.Approved) {
          acc.completedTasks.push(task);
        } else if (task.status === TaskStatus.Rejected) {
          acc.rejectedTasks.push(task);
        } else if (task.status === TaskStatus.Canceled) {
          acc.canceledTasks.push(task);
        } else if (task.status === TaskStatus.Submitted) {
          acc.opsToReviewTasks.push(task);
        } else if (task.status === TaskStatus.New) {
          const isBorrowerTask = task.dataType === TaskDataType.Forms && task.isSignatureRequired;
          isBorrowerTask ? acc.borrowerTasks.push(task) : acc.generalTasks.push(task);
        }

        return acc;
      }, initialState);
    }

    return tasks.reduce((acc, task) => {
      if (task.status !== TaskStatus.New && task.status !== TaskStatus.Canceled) {
        acc.doneTasks.push(task);
        return acc;
      }

      if (task.status === TaskStatus.New) {
        if (task.dataType === TaskDataType.Forms && task.isSignatureRequired) {
          acc.borrowerTasks.push(task);
        } else {
          acc.generalTasks.push(task);
        }
      }

      return acc;
    }, initialState);
  }, [isRealtorLO, tasks]);

type TaskTab = {
  title: string;
  component: JSX.Element;
};

export const useTaskTabs = (grouppedTasks: GrouppedTasks, isRealtorLO?: boolean): TaskTab[] =>
  useMemo(() => {
    const commonTabs = [
      {
        title: `General (${grouppedTasks.generalTasks.length})`,
        component: (
          <TasksList groupType={TaskGroupType.General} tasks={grouppedTasks.generalTasks} />
        ),
      },
      {
        title: `Borrower (${grouppedTasks.borrowerTasks.length})`,
        component: (
          <TasksList groupType={TaskGroupType.Borrower} tasks={grouppedTasks.borrowerTasks} />
        ),
      },
    ];

    if (isRealtorLO) {
      return commonTabs.concat([
        {
          title: `Ops to Review (${grouppedTasks.opsToReviewTasks.length})`,
          component: (
            <TasksList groupType={TaskGroupType.Done} tasks={grouppedTasks.opsToReviewTasks} />
          ),
        },
        {
          title: `Completed (${grouppedTasks.completedTasks.length})`,
          component: (
            <TasksList groupType={TaskGroupType.Done} tasks={grouppedTasks.completedTasks} />
          ),
        },
        {
          title: `Rejected (${grouppedTasks.rejectedTasks.length})`,
          component: (
            <TasksList groupType={TaskGroupType.Done} tasks={grouppedTasks.rejectedTasks} />
          ),
        },
        {
          title: `Canceled (${grouppedTasks.canceledTasks.length})`,
          component: (
            <TasksList groupType={TaskGroupType.Done} tasks={grouppedTasks.canceledTasks} />
          ),
        },
      ]);
    }

    return commonTabs.concat({
      title: `Done (${grouppedTasks.doneTasks.length})`,
      component: <TasksList groupType={TaskGroupType.Done} tasks={grouppedTasks.doneTasks} />,
    });
  }, [grouppedTasks, isRealtorLO]);

import { string, StringSchema, TestFunction } from 'yup';

import { BOTH_ALPHANUMERIC_REGEXP, PHONE_NUMBER_REGEXP } from 'constants/regExps';
import { PURCHASE_AGREEMENT_STREET_ERROR_TEXT } from 'constants/tasks';
import { ZIP_CODE_LENGTH } from 'constants/ui';
import { ValidationError } from 'enums/validation';
import { formatCurrency } from 'utils/formatting';

import { checkForTbdValue } from './common';

export const getMinLengthError = (length: number): string =>
  `${ValidationError.MinLength} ${length}`;

export const getMaxLengthError = (length: number): string =>
  `${ValidationError.MaxLength} ${length}`;

interface GetTextFieldProps {
  isRequired?: boolean;
  errorText?: string;
}
export const getTextField = ({
  isRequired,
  errorText = ValidationError.RequiredField,
}: GetTextFieldProps = {}): StringSchema<string | undefined> => {
  const fieldSchema = string().trim();

  if (isRequired) {
    return fieldSchema.required(errorText);
  }

  return fieldSchema.notRequired();
};

interface GetNameFieldProps {
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
}

export const getNameField = ({
  isRequired = true,
  minLength = 0,
  maxLength = 100,
}: GetNameFieldProps = {}): StringSchema<string | undefined> => {
  const fieldSchema = getTextField({ isRequired }).max(maxLength, getMaxLengthError(maxLength));

  if (minLength === 1) {
    return fieldSchema;
  }

  return fieldSchema.min(minLength, getMinLengthError(minLength));
};

interface GetZipFieldProps {
  isRequired?: boolean;
  customValidator?: TestFunction;
}
export const getZipField = ({
  isRequired = true,
  customValidator,
}: GetZipFieldProps = {}): StringSchema<string | undefined> => {
  const fieldSchema = getTextField({ isRequired })
    .max(ZIP_CODE_LENGTH, getMaxLengthError(ZIP_CODE_LENGTH))
    .min(ZIP_CODE_LENGTH, getMinLengthError(ZIP_CODE_LENGTH));

  if (customValidator) {
    return fieldSchema.test('isInvalidZip', ValidationError.Zip, customValidator);
  }

  return fieldSchema;
};

interface GetCellPhoneNumberFieldProps {
  isRequired?: boolean;
  excludeEmptyString?: boolean;
  message?: string;
}

export const getCellPhoneNumberField = ({
  isRequired = true,
  excludeEmptyString = false,
  message = ValidationError.PhoneNumber,
}: GetCellPhoneNumberFieldProps = {}): StringSchema<string | undefined> => {
  return getTextField({ isRequired }).matches(PHONE_NUMBER_REGEXP, {
    excludeEmptyString,
    message,
  });
};

interface GetCurrencyFieldProps {
  isRequired?: boolean;
  currency?: RegExp;
  exception?: RegExp;
  exceptionErrorText?: string;
  emptyErrorText?: string;
  minValue?: number;
}

export const getCurrencyField = (
  {
    isRequired = true,
    currency = /.*/,
    exception = /.*/,
    exceptionErrorText = '',
    emptyErrorText,
    minValue = 0,
  }: GetCurrencyFieldProps = {
    isRequired: true,
    exceptionErrorText: '',
    currency: /.*/,
    exception: /.*/,
    minValue: 0,
  },
): StringSchema<string | undefined> => {
  return getTextField({ isRequired, errorText: emptyErrorText })
    .test('match', `${ValidationError.MinValue} ${formatCurrency(minValue)}`, value => {
      if (!isRequired && !value) {
        return true;
      }
      return +(value || '') >= minValue;
    })
    .matches(currency, { message: ValidationError.CurrencyAmount, excludeEmptyString: !isRequired })
    .matches(exception, exceptionErrorText);
};

export const getAddressFields = () => ({
  street: getTextField({})
    .test(
      'is-tbd',
      PURCHASE_AGREEMENT_STREET_ERROR_TEXT,
      value => !!value && !checkForTbdValue(value),
    )
    .matches(BOTH_ALPHANUMERIC_REGEXP, ValidationError.Address),
  unit: getTextField({ isRequired: false }),
  country: getTextField({ isRequired: false }),
  state: getNameField({ maxLength: 100, minLength: 0 }),
  city: getTextField({}),
  zip: getZipField({}),
  county: getTextField({ isRequired: false }),
});

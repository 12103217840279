import { memo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { TypographyComponent } from 'enums/ui';
import { getEmptyArray } from 'utils/array';

import { Row, RowSkeleton } from './components/Row';
import { Typography } from 'components/Typography';

import { EmptyStateContainer } from './Table.styles';
import { TableBodyProps } from './Table.types';

const TableBody = <T extends unknown>({
  isLoading,
  isError,
  data,
  onRowClick,
  columns,
  rowHeight,
  emptyStateText,
  pageSize,
  activeId,
  activeIdPath,
  selectedId,
  selectedIdPath,
  getIsHighlighted,
  getIsWithSubGrid,
  subGridComponent,
  isSubGrid,
  blockTestName,
}: TableBodyProps<T>) => {
  if (isLoading || !data || isError) {
    return (
      <>
        {getEmptyArray(pageSize).map((_, index) => (
          <RowSkeleton isError={isError} key={index} />
        ))}
      </>
    );
  }

  if (isEmpty(data)) {
    return (
      <EmptyStateContainer data-testid={`${blockTestName}__empty-state`}>
        <Typography component={TypographyComponent.H5}>{emptyStateText}</Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <>
      {data.map((rowData, index) => (
        <Row<T>
          activeId={activeId}
          activeIdPath={activeIdPath}
          blockTestName={blockTestName}
          columns={columns}
          getIsHighlighted={getIsHighlighted}
          getIsWithSubGrid={getIsWithSubGrid}
          key={get(rowData, 'id', index) as string} // TODO: Temp solution. pass custom key
          rowData={rowData}
          rowHeight={rowHeight}
          selectedId={selectedId}
          selectedIdPath={selectedIdPath}
          subGridComponent={subGridComponent}
          onRowClick={onRowClick}
        />
      ))}
    </>
  );
};

export default memo(TableBody) as typeof TableBody;

import {
  FC,
  FocusEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useField } from 'formik';

import { ONLY_LATIN_LETTERS_NUMBERS_PUNCTUATION_MARKS_REGEXP } from 'constants/regExps';
import useUniqueId from 'hooks/useUniqueId';
import { inputKeyHandler } from 'utils/ui';

import { FieldErrorMessage } from 'components/@fields/TextField';
import FieldLabel from 'components/FieldLabel';

import {
  AutocompleteContainer,
  PlacesInput,
  RelativeWrapper,
  Wrapper,
} from './PlacesAutocomplete.styles';
import { PlacesAutocompleteProps } from './PlacesAutocomplete.types';
import { getSuggestion } from './PlacesAutocomplete.utils';

const defaultOptions: google.maps.places.AutocompleteOptions = {
  types: ['address'],
  componentRestrictions: { country: 'us' },
};

const regexp = new RegExp(ONLY_LATIN_LETTERS_NUMBERS_PUNCTUATION_MARKS_REGEXP);

// TODO: move the react-google-autocomplete library
const PlacesAutocomplete: FC<PlacesAutocompleteProps> = ({
  name,
  label,
  placeholder,
  disabled,
  onPlaceClick,
  onBlur,
  required,
  options,
  isAbsoluteError,
  ...props
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  const isNotValid = !!(touched && error);
  const formattedPlaceholder = required && placeholder ? `${placeholder}*` : placeholder;
  const formattedLabel = required ? `${label}*` : label;
  const validPlaceholder = formattedPlaceholder || formattedLabel;

  const id = useUniqueId(name);

  const autocompleteOptions: google.maps.places.AutocompleteOptions = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);

  const onBlurAutoComplete = useCallback(
    (e: FocusEvent<HTMLInputElement>): void => {
      if (!touched) {
        setValue(e.target.value || '', true);
        setTouched(true);
      }

      onBlur?.(e);
    },
    [touched, setValue, onBlur, setTouched],
  );

  const onPlaceSelected = useCallback(
    ({ address_components: addressComponents }: google.maps.places.PlaceResult) => {
      if (!addressComponents) {
        return;
      }

      const suggestion = getSuggestion(addressComponents);

      if (inputRef.current) {
        inputRef.current.value = suggestion?.street || '';
      }

      onPlaceClick(suggestion);
    },
    [onPlaceClick],
  );

  const onKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    inputKeyHandler(e);

    if (!regexp.test(String.fromCharCode(e.keyCode || e.which))) {
      e.preventDefault();
    }
  }, []);

  return (
    <Wrapper>
      <RelativeWrapper>
        <AutocompleteContainer isNotValid={isNotValid}>
          <FieldLabel htmlFor={id}>{label}</FieldLabel>
          <PlacesInput
            {...props}
            defaultValue={value}
            disabled={disabled}
            id={id}
            inputAutocompleteValue="off"
            language="en"
            name={name}
            options={autocompleteOptions}
            placeholder={validPlaceholder}
            ref={inputRef}
            onBlur={onBlurAutoComplete}
            onKeyPress={onKeyPress}
            onPlaceSelected={onPlaceSelected}
          />
        </AutocompleteContainer>
        {isNotValid ? <FieldErrorMessage error={error} isAbsolute={isAbsoluteError} /> : null}
      </RelativeWrapper>
    </Wrapper>
  );
};

export default memo(PlacesAutocomplete);

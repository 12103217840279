import { FC, SVGProps } from 'react';
import { styled } from 'styled-components';

import { ReactComponent as SpinnerIcon } from 'assets/animations/ring-resize.svg';

// const strokeAnimation = keyframes`
//   0% {
//     stroke-dashoffset: 1900px;
//     stroke-dasharray: 950px;
//   }

//   50% {
//     stroke-dashoffset: 950px;
//     stroke-dasharray: 950px;
//   }

//   100% {
//     stroke-dashoffset: 1900px;
//     stroke-dasharray: 950px;
//   }
// `;

// const Path = styled.path`
//   animation: ${strokeAnimation} 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
// `;

const StyledSpinnerIcon = styled(SpinnerIcon)`
  stroke: ${({ theme }) => theme.colors.loader};
`;

interface Props extends SVGProps<SVGSVGElement> {
  height?: number;
}

const AnimatedLogoSVG: FC<Props> = ({ className, height = 200 }) => {
  const width = height * 1.25843;

  return (
    // <svg
    //   className={className}
    //   fill="none"
    //   height={height}
    //   viewBox="0 0 448 356"
    //   width={width}
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <Path
    //     d="M35 45H290.902L76.7973 295.404C151.009 321.043 307.962 325.316 418 227.034"
    //     stroke="#F54A90"
    //     strokeWidth="89"
    //   />
    // </svg>
    <StyledSpinnerIcon className={className} height={height} width={width} />
  );
};

export default AnimatedLogoSVG;

import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: min-content; // fixes iOS 14 invalid height and overlaps
`;

export const FirstBlockContainer = styled.div`
  display: flex;

  @media (max-width: ${MediaQueries.Desktop}) {
    flex-direction: column;
  }
`;

export const PositioningContainer = styled.div``;

import { memo, useCallback, useMemo, useState } from 'react';
import get from 'lodash/get';
import sortByKey from 'lodash/sortBy';

import { SortDirection } from 'enums/common';
import { usePagination } from 'hooks/usePagination';

import Pagination, { PaginationSkeleton } from 'components/Pagination';
import TableHeader from 'components/Table/components/TableHeader/TableHeader';
import TableBody from 'components/Table/TableBody';

import { TableContainer } from './Table.styles';
import { TableProps } from './Table.types';

const PAGE_SIZE = 10;

const Table = <T extends unknown>({
  data,
  columns,
  className,
  onRowClick,
  defaultSortBy,
  defaultSortDirection = SortDirection.DESC,
  pageSize = PAGE_SIZE,
  rowHeight,
  isLoading,
  isError,
  emptyStateText,
  activeId,
  activeIdPath,
  selectedId,
  selectedIdPath,
  isSkipSort,
  getIsHighlighted,
  getIsWithSubGrid,
  subGridComponent,
  isSubGrid,
  blockTestName,
}: TableProps<T>) => {
  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [sortDirection, setSortDirection] = useState<SortDirection>(defaultSortDirection);

  const sortedData = useMemo(() => {
    if (isSkipSort) {
      return data;
    }

    if (data) {
      const element = get(data[0], sortBy);
      const isDate = typeof element === 'string' && !!Date.parse(element);
      let ASCData;

      if (isDate) {
        // '-' is added because 'lodash/sortBy' sorts the numbers in reverse order
        ASCData = sortByKey(data, item => Date.parse(get(item, sortBy)));
      } else {
        ASCData = sortByKey(data, sortBy);
      }

      if (sortDirection === SortDirection.ASC) {
        return ASCData;
      } else {
        return ASCData.reverse();
      }
    }
  }, [data, isSkipSort, sortBy, sortDirection]);

  const { currentPage, setPage, shownData, maxPage } = usePagination<T>({
    pageSize,
    data: sortedData,
  });

  const onSort = useCallback(
    (key: string) => {
      if (key === sortBy) {
        setSortDirection(
          sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        );
        return;
      }

      setSortBy(key);
      setSortDirection(SortDirection.ASC);
    },
    [sortBy, sortDirection],
  );

  const hidePagination = isLoading || isError || maxPage < 2;

  return (
    <>
      <TableContainer className={className} data-testid={blockTestName} isSubGrid={isSubGrid}>
        <TableHeader<T>
          blockTestName={blockTestName}
          columns={columns}
          isSubGrid={isSubGrid}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <TableBody<T>
          activeId={activeId}
          activeIdPath={activeIdPath}
          blockTestName={blockTestName}
          columns={columns}
          data={shownData}
          emptyStateText={emptyStateText}
          getIsHighlighted={getIsHighlighted}
          getIsWithSubGrid={getIsWithSubGrid}
          isError={isError}
          isLoading={isLoading}
          isSubGrid={isSubGrid}
          pageSize={pageSize}
          rowHeight={rowHeight}
          selectedId={selectedId}
          selectedIdPath={selectedIdPath}
          subGridComponent={subGridComponent}
          onRowClick={onRowClick}
        />
      </TableContainer>
      {(isLoading || !data || isError) && <PaginationSkeleton isError={isError} />}
      {!hidePagination && (
        <Pagination currentPage={currentPage} maxPage={maxPage} setPage={setPage} />
      )}
    </>
  );
};

export default memo(Table) as typeof Table;

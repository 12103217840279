import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';

import { DETAILS_INCLUDES } from 'constants/application';

import loansAPI from 'services/loansAPI';
import { RootState } from 'store/index';
import { LoanApplication, LoanApplicationsData } from 'types/application';

export const selectCurrentApplicationByApplicationId =
  (applicationId: string) => (state: RootState) => {
    return loansAPI.endpoints.getLoanApplications.select({
      applicationIds: [applicationId],
      includes: DETAILS_INCLUDES,
    })(state).data?.items?.[0];
  };

export const selectCachedApplicationsById = createSelector(
  [(state: RootState) => state.loansAPI.queries, (_, applicationId) => applicationId],
  (queries, applicationId) => {
    let application;

    Object.values(queries).find(
      query =>
        query?.status === QueryStatus.fulfilled &&
        (query?.data as LoanApplicationsData)?.items?.find(app => {
          if (app.applicationId === applicationId) {
            application = app;
            return true;
          }
        }),
    );

    return application as LoanApplication | undefined;
  },
);

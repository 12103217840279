import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { Typography } from 'components/Typography';

export const RateSectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 5% 20px 20%;

  @media (max-width: ${MediaQueries.Mobile}) {
    margin: 0 5% 20px 10%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  > :fisrt-child {
    white-space: nowrap;
  }

  > svg {
    margin-left: 3px;
    outline: none;
  }
`;

export const UnderlinedText = styled(Typography)`
  display: flex;
  width: fit-content;
  text-decoration: underline;
`;

export const YearsCost = styled.div`
  font-size: 14px;
`;

import { Form } from 'formik';
import { darken, lighten } from 'polished';
import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import { Typography } from 'components/Typography';

import { ReactComponent as UploadCloud } from 'assets/icons/uploadCloud.svg';

const containerStyles = css`
  margin-top: 20px;
  cursor: default;
`;

export const Container = styled.div`
  ${containerStyles};
`;

export const FormContainer = styled(Form)<{ $isReadonly?: boolean }>`
  ${containerStyles};

  ${({ $isReadonly }) =>
    $isReadonly &&
    css`
      margin-top: 10px;
    `}
`;

const buttonStylesOnMobile = css`
  @media (max-width: ${MediaQueries.Mobile}) {
    width: 100%;
  }
`;

export const SubmitButton = styled(Button)<{ isSubmitting: boolean }>`
  width: 130px;
  height: 36px;
  margin-top: 20px;

  ${({ isSubmitting }) =>
    isSubmitting &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${buttonStylesOnMobile};
`;

export const DownloadButton = styled(SubmitButton)`
  width: 196px;

  ${buttonStylesOnMobile};
`;

export const Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  min-width: 220px;
  margin-top: 9px;

  color: ${({ theme }) => theme.colors.text.primary};
  border: 1px dashed #c5c5c5;
  border-radius: 20px;

  font-size: 15px;
  font-weight: 400;
`;

export const Warning = styled.p`
  margin-bottom: 30px;
  font-size: 13px;
  text-align: center;
`;

export const SelectFileButton = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;

  &:hover,
  &:focus {
    color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
  }

  &:disabled {
    color: ${({ theme }) => lighten(theme.coefficients.primaryDisabled, theme.colors.primary)};
  }
`;

const disabledFileStyles = css`
  opacity: 0.75;
  pointer-events: none;
`;

export const FileList = styled.ul<{ isLoading?: boolean }>`
  ${({ isLoading }) => isLoading && disabledFileStyles}
`;

export const File = styled.li<{ isViewing?: boolean; isDisabled?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 13px;
  font-weight: 400;
  line-height: 1;

  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  ${({ isDisabled }) => isDisabled && disabledFileStyles}

  ${({ isViewing }) =>
    !isViewing &&
    css`
      height: 25px;
      grid-template-columns: 4fr 1fr;
    `}
`;

export const UploadIcon = styled(UploadCloud)`
  margin-right: 8px;
`;

export const FileName = styled.span`
  padding-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FileExtension = styled.span`
  padding-top: 2px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 3px;
  }
`;

export const FileSize = styled.span`
  margin-right: 13px;
`;

export const FileNameContainer = styled.span`
  display: flex;
  align-items: center;
  max-width: 80%;
  overflow: hidden;

  color: ${({ theme }) => theme.colors.text.primary};
  text-align: initial;
  text-overflow: ellipsis;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const FileControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Errors = styled.div`
  margin-bottom: 20px;
`;

export const APIErrorMessage = styled(ErrorMessage)`
  margin-top: 10px;
  margin-bottom: -10px;
`;

export const DocumentsTitle = styled(Typography)`
  margin-bottom: 10px;
`;

import { FC, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { APP_CONTAINER_ID } from 'constants/common';
import useScrollToTop from 'hooks/useScrollToTop';

import { selectIsTabletAndHigher } from 'store/selectors/ui';

import BackLink from 'components/BackLink';
import Header from 'components/Header';
import Navbar from 'components/Navbar';

import { AppContainer, AppContent } from './ApplicationLayout.styles';

const ApplicationLayout: FC = () => {
  const [isOpenSidebar, setIsOpenSideBar] = useState(false);
  const openSideBar = useCallback(() => setIsOpenSideBar(true), []);
  const closeSideBar = useCallback(() => setIsOpenSideBar(false), []);

  const isDesktopOrTablet = useSelector(selectIsTabletAndHigher);
  const isMobileLandscapeAndLower = !isDesktopOrTablet;

  useScrollToTop();

  return (
    <>
      <Header openSideBar={openSideBar} />
      {isMobileLandscapeAndLower && (
        <Navbar closeSideBar={closeSideBar} isOpenSidebar={isOpenSidebar} />
      )}
      <AppContainer id={APP_CONTAINER_ID}>
        <AppContent data-testid="app-content">
          {isMobileLandscapeAndLower && <BackLink />}
          {isDesktopOrTablet && (
            <Navbar closeSideBar={closeSideBar} isOpenSidebar={isOpenSidebar} />
          )}
          <Outlet />
        </AppContent>
      </AppContainer>
    </>
  );
};

export default memo(ApplicationLayout);

import { TypographyComponent } from 'enums/ui';
import { getTime } from 'utils/date';
import { getFormattedDate } from 'utils/formatting';

import { ExpirationDaysText } from './ExpirationRateDate.styles';

interface ExpirationRateDateProps {
  lockExpirationDate?: string;
}

const ExpirationRateDate = ({ lockExpirationDate }: ExpirationRateDateProps) => {
  if (lockExpirationDate) {
    const expDaysLeft = getTime({ date: lockExpirationDate, time: 'days' });

    if (expDaysLeft < 0) {
      return <>Expired</>;
    }

    return (
      <>
        {getFormattedDate(lockExpirationDate)} -&nbsp;
        <ExpirationDaysText
          component={TypographyComponent.H7}
          forwardedAs={TypographyComponent.SPAN}
        >
          {expDaysLeft === 1 ? `1 day` : `${expDaysLeft} days`}
        </ExpirationDaysText>
      </>
    );
  }

  return <>Not Locked</>;
};

export default ExpirationRateDate;

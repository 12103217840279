import { REALTOR_ID } from 'constants/common';

const isSupportRandomUUID = typeof crypto !== 'undefined' && !!crypto.randomUUID;

const randomUUIDPolyfill = () => {
  const url = URL.createObjectURL(new Blob());
  const uuid = url.toString();
  URL.revokeObjectURL(url); // releases ObjetURL so it can be harvested by GC
  return uuid.substring(uuid.lastIndexOf('/') + 1);
};

export const getRandomUUID = () =>
  isSupportRandomUUID ? crypto.randomUUID() : randomUUIDPolyfill();

export const getTooltipId = (value: string): string => `tid_${value.toLowerCase()}`;

export const getRealtorId = () => {
  const realtorId = localStorage.getItem(REALTOR_ID);

  if (!realtorId) {
    throw Error('realtorId not found');
  }

  return realtorId;
};

import { FC } from 'react';

import { Wrapper } from './CircleSpinner.styled';

export interface CircleSpinnerProps {
  size: number;
  color?: string;
  className?: string;
}

const CircleSpinner: FC<Partial<CircleSpinnerProps>> = ({ className, size = 20, color }) => {
  return <Wrapper className={className} color={color} size={size} />;
};

export default CircleSpinner;

import { emailRegExp, getEmailField } from '@interfirst/utils';
import { lazy, object, string } from 'yup';

import { getCellPhoneNumberField, getNameField } from 'utils/validation';

const schema = object().shape({
  firstName: getNameField(),
  lastName: getNameField(),
  email: lazy(() =>
    string().when('phoneNumber', (phoneNumber: string) =>
      getEmailField({ isRequired: !phoneNumber, emailValidationRegExp: emailRegExp }),
    ),
  ),
  phoneNumber: lazy(() =>
    string().when('email', (email: string) => getCellPhoneNumberField({ isRequired: !email })),
  ),
});

export default schema;

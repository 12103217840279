import { darken } from 'polished';
import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Button from 'components/Button';
import { Separator } from 'components/SeparatorLine';
import { Typography } from 'components/Typography';

const mobileFontStyles = css`
  @media (max-width: ${MediaQueries.MobileLandscape}) {
    font-size: 15px;
  }
`;

export const Wrapper = styled.section`
  padding: 10px 0;
`;

export const Block = styled.div`
  margin-bottom: 15px;
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;

  ${mobileFontStyles};
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Row = styled.div<{ isTotal?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-height: 42px;
  overflow: hidden;

  > ${Text} {
    ${({ isTotal }) => isTotal && mobileFontStyles};

    :first-child {
      margin-right: 10px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    :last-child {
      white-space: nowrap;
    }
  }
`;

export const UpfrontCostSeparator = styled(Separator)<{ isTotal?: boolean }>`
  margin: ${({ isTotal }) => (isTotal ? 0 : '2px 0 5px')};

  border-bottom-width: ${({ isTotal }) => (isTotal ? '2px' : '1px')};
  border-color: ${({ theme, isTotal }) =>
    isTotal ? darken(0.4, theme.colors.separateLine) : theme.colors.separateLine};
`;

export const FeesBlock = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

export const ShowMore = styled(Button)`
  width: 100%;
  margin-bottom: 15px;
`;

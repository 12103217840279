import { FC, MouseEvent } from 'react';
import { DropzoneInputProps, DropzoneRootProps, FileWithPath } from 'react-dropzone';

import File from './File';
import { Dropzone, FileList, SelectFileButton, UploadIcon, Warning } from './TaskForm.styles';

interface FileZoneProps {
  files: FileWithPath[];
  onSelectFile: () => void;
  onRemoveFile: (file: FileWithPath) => (e: MouseEvent<HTMLButtonElement>) => void;
  inputProps: DropzoneInputProps;
  dropzoneProps: DropzoneRootProps;
  isLoading?: boolean;
}

const FileZone: FC<FileZoneProps> = ({
  files,
  onSelectFile,
  inputProps,
  dropzoneProps,
  onRemoveFile,
  isLoading,
}) => {
  const isFilesExist = !!files.length;

  return (
    <div>
      <Dropzone {...dropzoneProps}>
        <input {...inputProps} />
        <UploadIcon />
        Drop your file or&nbsp;
        <SelectFileButton disabled={isFilesExist} onClick={onSelectFile}>
          Select file
        </SelectFileButton>
      </Dropzone>
      <Warning>You can add 1 file only</Warning>
      {isFilesExist && (
        <FileList isLoading={isLoading}>
          {files.map((file, index) => (
            <File file={file} key={index} onRemoveFile={onRemoveFile} />
          ))}
        </FileList>
      )}
    </div>
  );
};

export default FileZone;

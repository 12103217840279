import { FC, memo } from 'react';
import { styled } from 'styled-components';

import { TypographyComponent, TypographyType } from 'enums/ui';

import { typographyStyles } from 'styles/typography';

import { StyledTypographyProps, TypographyProps } from './Typography.types';

// by default:
// component - 'p'
// size - 16px (depends on component)
// weight - h1 - 700, (h2-h5) - 600, other 400

// H1-72px
// H2-60px
// H3-40px
// H4-26px
// H5-20px
// H6-16px
// H7-14px
// H8-12px
export const StyledTypography = styled.p<StyledTypographyProps>`
  ${typographyStyles}
`;

export const Typography: FC<TypographyProps> = memo(
  ({
    children,
    size,
    component = TypographyComponent.P,
    type = TypographyType.Default,
    weight,
    dataTestId,
    ...props
  }) => {
    const as =
      component === TypographyComponent.H7 || component === TypographyComponent.H8
        ? undefined
        : component;

    return (
      <StyledTypography
        $component={component}
        $size={size}
        $type={type}
        $weight={weight}
        as={as}
        data-testid={dataTestId}
        {...props}
      >
        {children}
      </StyledTypography>
    );
  },
);

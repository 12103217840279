import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  requestId: string;
  redirectUrl: string | null;
  confirmationCode: string | null;
  expirationDate: string;
  isAuthenticated: boolean;
}

export const initialState: AuthState = {
  requestId: '',
  confirmationCode: null,
  redirectUrl: null,
  isAuthenticated: false,
  expirationDate: '',
};

const AuthSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCreateRequestAuthData(state, action: PayloadAction<Partial<AuthState>>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key as keyof AuthState] = value as never;
      });
    },
    setRedirectUrl(state, action: PayloadAction<string | null>) {
      state.redirectUrl = action.payload;
    },
    signIn(state) {
      state.isAuthenticated = true;
    },
    signOut(state) {
      state.isAuthenticated = false;
    },
  },
});

export const { setCreateRequestAuthData, signIn, signOut, setRedirectUrl } = AuthSlice.actions;

export const AuthReducer = AuthSlice.reducer;

import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { selectRealtorsFavicon } from 'store/selectors/ui';

const HeadConnector: FC = () => {
  const favicon = useSelector(selectRealtorsFavicon);

  //* in Helmet we can not use React.Fragment
  return (
    <Helmet>
      <link href={favicon} rel="apple-touch-icon" />
      <link color="#ffffff" href={favicon} rel="mask-icon" />
      <link href={favicon} rel="icon" />
    </Helmet>
  );
};

export default HeadConnector;

import { Ref, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { FeatureFlags } from 'constants/featureToggles';
import {
  DESKTOP_LONGEST_COLUMN_HEIGHT,
  LONGEST_COLUMN_HEIGHT,
  LONGEST_COLUMN_HEIGHT_FOR_LO,
} from 'constants/ui';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { selectIsRealtorLoanOfficer } from 'store/selectors/auth';
import { selectIsDesktopView, selectIsMobileView } from 'store/selectors/ui';

const REGULAR_PROGRESS_HEIGHT = 606;

interface UseDynamicProgressHeightResult {
  progressRef: Ref<HTMLDivElement>;
  containerRef: Ref<HTMLDivElement>;
}

const useDynamicProgressHeight = (isLoading: boolean): UseDynamicProgressHeightResult => {
  const isDynamicProgress = useFeatureFlag(FeatureFlags.UseDynamicLoanProgress);

  const isRealtorLO = useSelector(selectIsRealtorLoanOfficer);
  const IsDesktop = useSelector(selectIsDesktopView);
  const isMobile = useSelector(selectIsMobileView);

  const progressRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isDynamicProgress && !isLoading && progressRef.current && containerRef.current) {
      if (isMobile) {
        containerRef.current.style.height = '';
        return;
      }

      const columnHeight = isRealtorLO ? LONGEST_COLUMN_HEIGHT_FOR_LO : LONGEST_COLUMN_HEIGHT;
      const initialColumnHeight = IsDesktop ? DESKTOP_LONGEST_COLUMN_HEIGHT : columnHeight;
      // additional space to fit the longest column
      const delta = IsDesktop ? 132 : 105 - (isRealtorLO ? 20 : 0);

      const { height } = progressRef.current.getBoundingClientRect();
      const calculatedHeight = Math.max(
        initialColumnHeight - REGULAR_PROGRESS_HEIGHT - delta + height,
        initialColumnHeight,
      );

      containerRef.current.style.height = `${calculatedHeight}px`;
    }
  }, [IsDesktop, isDynamicProgress, isMobile, isRealtorLO, isLoading]);

  return {
    progressRef,
    containerRef,
  };
};

export default useDynamicProgressHeight;

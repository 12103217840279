import { createListenerMiddleware } from '@reduxjs/toolkit';

import { aiAggregator } from 'utils/logger';

import applyAPI from 'services/applyAPI';
import { baseAPI } from 'services/baseAPI';
import documentsAPI from 'services/documentsAPI';
import loansAPI from 'services/loansAPI';
import { signalRConnection } from 'services/signalR';
import skynetAPI from 'services/skynetAPI';
import temporaryApplyAPI from 'services/temporaryApplyAPI';
import { signOut } from 'store/slices/auth';

export const signOutMiddleware = createListenerMiddleware();

signOutMiddleware.startListening({
  actionCreator: signOut,
  effect: async (action, { dispatch }) => {
    dispatch(baseAPI.util.resetApiState());
    dispatch(applyAPI.util.resetApiState());
    dispatch(temporaryApplyAPI.util.resetApiState());
    dispatch(documentsAPI.util.resetApiState());
    dispatch(loansAPI.util.resetApiState());
    dispatch(skynetAPI.util.resetApiState());
    dispatch(loansAPI.util.resetApiState());
    await signalRConnection.stop();

    aiAggregator.clearUserContext();
  },
});

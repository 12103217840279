import { FC, memo } from 'react';
import { transparentize } from 'polished';
import { css, styled } from 'styled-components';

import { TypographyComponent } from 'enums/ui';

import { Typography } from 'components/Typography';

export const Separator = styled.div<{ $hasChildren?: boolean }>`
  display: flex;
  justify-content: center;

  border-bottom: 1px solid ${({ theme }) => theme.colors.separateLine};
  ${({ $hasChildren }) =>
    $hasChildren
      ? css`
          margin-top: -11px;
        `
      : css`
          margin: 20px 0;
          border-color: ${({ theme }) => transparentize(0.5, theme.colors.separateLine)};
        `};
`;

const SeparatorText = styled(Typography)`
  margin-bottom: -11px;
  padding: 0 15px;
  width: min-content;

  background: ${({ theme }) => theme.colors.card.background};
  color: ${({ theme }) => theme.colors.separateLine};
  white-space: nowrap;
`;

const SeparatorLine: FC<{ children?: string }> = ({ children }) => {
  return (
    <Separator $hasChildren={!!children}>
      {children && <SeparatorText component={TypographyComponent.H7}>{children}</SeparatorText>}
    </Separator>
  );
};

export default memo(SeparatorLine);

import { FC, memo } from 'react';

import { TypographyComponent } from 'enums/ui';

import { Typography } from 'components/Typography';

import { PageHeaderWrapper } from './PageHeader.styles';

interface Props {
  title: string;
  subtitle?: string;
}

const PageHeader: FC<Props> = ({ title, subtitle }) => {
  return (
    <PageHeaderWrapper>
      <Typography component={TypographyComponent.H4}>{title}</Typography>
      {subtitle && <Typography component={TypographyComponent.H6}>{subtitle}</Typography>}
    </PageHeaderWrapper>
  );
};

export default memo(PageHeader);

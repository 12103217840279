import { FC, SVGProps } from 'react';

const ExpirationTimeSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="13" viewBox="0 0 15 13" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9992 12.7713C14.9209 12.946 14.7839 13.0004 14.5908 13C12.2959 12.996 10.0014 12.995 7.70726 12.997C7.3353 12.997 7.20649 12.7837 7.39129 12.4716C8.53979 10.5328 9.6883 8.59398 10.8368 6.65515C10.8987 6.55061 10.9617 6.45287 11.1019 6.43626C11.273 6.41588 11.3736 6.49929 11.4543 6.63439C11.9724 7.51298 12.4918 8.39081 13.0126 9.26789C13.6633 10.3659 14.314 11.4637 14.9648 12.5615C14.9753 12.5759 14.9871 12.5894 15 12.6018L14.9992 12.7713ZM11.5905 10.5903V8.68028H10.7291V10.5903H11.5905ZM11.5983 11.7319C11.6011 11.6179 11.5571 11.5075 11.4758 11.4246C11.3946 11.3417 11.2827 11.2931 11.1645 11.2892C10.9331 11.2874 10.7236 11.4779 10.7205 11.6904C10.7176 11.8041 10.7613 11.9143 10.8421 11.997C10.923 12.0798 11.0345 12.1284 11.1524 12.1324C11.3873 12.1339 11.5948 11.9467 11.5983 11.7319Z"
        fill={color}
      />
      <path
        d="M6.43011 9.63728V8.79757H5.54954V9.61917C3.88708 9.52293 2.16903 8.09258 1.99753 6.20634H2.85891V5.36134H1.98657C2.08798 4.51748 2.42431 3.79438 3.00456 3.17997C3.68466 2.45914 4.53155 2.04475 5.54954 1.92134V2.76181H6.42541V1.94021C8.08983 2.03796 9.80906 3.46831 9.97781 5.35228H9.12035V6.20596H10.0142C9.96411 6.34975 9.94101 6.4758 9.87953 6.58148C9.4833 7.2608 9.07924 7.94011 8.67634 8.61604C8.6504 8.66627 8.6157 8.71184 8.57376 8.75077C7.95944 9.24818 7.25194 9.5384 6.43011 9.63728ZM5.56403 3.63927C5.55719 3.65939 5.55247 3.68013 5.54994 3.70116C5.54994 4.52012 5.54994 5.33908 5.54994 6.15803C5.55107 6.18028 5.55685 6.20208 5.56695 6.22212C5.57704 6.24216 5.59123 6.26003 5.60867 6.27465C6.03231 6.57657 6.45908 6.87396 6.89251 7.17814C7.06674 6.94755 7.23471 6.72527 7.40895 6.49354C7.11138 6.28446 6.82595 6.0784 6.53543 5.88253C6.49889 5.86085 6.46921 5.82994 6.4496 5.79314C6.43 5.75635 6.42123 5.71509 6.42423 5.67383C6.42815 5.05263 6.42423 4.43143 6.42423 3.81023V3.63927H5.56403Z"
        fill={color}
      />
      <path
        d="M7.73586 10.2053C7.48841 10.6238 7.25623 11.0209 7.01661 11.413C6.99967 11.4343 6.97854 11.4522 6.95443 11.4657C6.93031 11.4792 6.90367 11.4879 6.87605 11.4915C5.40883 11.702 3.91276 11.3806 2.67717 10.5894C1.44157 9.79825 0.554248 8.59354 0.186803 7.20828C-0.180642 5.82302 -0.00210084 4.35563 0.687896 3.08991C1.37789 1.82418 2.53032 0.850061 3.9223 0.35593C7.31026 -0.847976 11.0482 1.11715 11.8313 4.51865C11.9061 4.84321 11.9335 5.17834 11.9742 5.50932C11.9883 5.62254 11.9762 5.74105 11.9762 5.88295C11.6547 5.62103 11.3012 5.53989 10.9018 5.6082C10.7546 3.82537 9.91005 2.46786 8.2844 1.60965C7.10783 0.988452 5.85375 0.889573 4.56873 1.25414C2.02962 1.97422 0.545308 4.59187 1.25986 7.04874C1.99634 9.57883 4.85768 11.2088 7.73586 10.2053Z"
        fill={color}
      />
    </svg>
  );
};

export default ExpirationTimeSVG;

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { LoanProgramOption, PotentialLoanOption } from 'types/application';

import {
  getLongestTerm,
  getTermsFromGroupedLoanOptions,
  groupLoanOptions,
} from './useTerms.helpers';

interface UseTermsProps {
  loanOptions: PotentialLoanOption[] | LoanProgramOption[];
  activeOptionId?: string; // Option that is used currently
  isLoanProgramOptions?: boolean;
  selectedLoanOptionId: string;
  setSelectedLoanOptionId: Dispatch<SetStateAction<string>>;
}

const useTerms = ({
  loanOptions,
  isLoanProgramOptions,
  selectedLoanOptionId,
  setSelectedLoanOptionId,
  activeOptionId,
}: UseTermsProps) => {
  const [selectedTerm, setSelectedTerm] = useState<string | undefined>();

  const groupedLoanOptions = useMemo(
    () => groupLoanOptions(loanOptions, isLoanProgramOptions),
    [isLoanProgramOptions, loanOptions],
  );

  const termLoanOptions = selectedTerm ? groupedLoanOptions[selectedTerm].options : [];

  const terms = useMemo(
    () => getTermsFromGroupedLoanOptions(groupedLoanOptions),
    [groupedLoanOptions],
  );

  const visibleTermsNumber = terms.length > 2 ? 3 : terms.length;

  const changeTerm = useCallback((term: string) => {
    setSelectedTerm(term);
  }, []);

  useEffect(() => {
    if (selectedTerm || !selectedLoanOptionId) return;

    const selectedLoanOption = (loanOptions as Array<PotentialLoanOption | LoanProgramOption>).find(
      ({ loanOptionId }) => loanOptionId === selectedLoanOptionId,
    );

    if (!selectedLoanOption) return;

    const term = `${selectedLoanOption.loanPeriod / 12}`;

    setSelectedTerm(term);
  }, [selectedLoanOptionId, loanOptions, selectedTerm]);

  useEffect(() => {
    if (selectedTerm || selectedLoanOptionId) return;

    const longestTerm = getLongestTerm(groupedLoanOptions);

    if (!longestTerm) return;

    if (!isLoanProgramOptions) {
      const preselectedOptionId = groupedLoanOptions[longestTerm].options[0].id;
      setSelectedLoanOptionId(preselectedOptionId);
    }

    setSelectedTerm(longestTerm);
  }, [
    groupedLoanOptions,
    isLoanProgramOptions,
    selectedLoanOptionId,
    selectedTerm,
    setSelectedLoanOptionId,
  ]);

  // preselects term with activeOptionId
  useEffect(() => {
    if (!activeOptionId || !isLoanProgramOptions) return;

    const currentLoanOption = (loanOptions as Array<PotentialLoanOption | LoanProgramOption>).find(
      ({ loanOptionId }) => loanOptionId === activeOptionId,
    );
    const term = currentLoanOption?.loanPeriod;
    if (term) {
      setSelectedTerm(`${term / 12}`);
    }
  }, [activeOptionId, isLoanProgramOptions, loanOptions]);

  return {
    terms,
    selectedTerm,
    changeTerm,
    termLoanOptions,
    visibleTermsNumber,
  };
};

export default useTerms;

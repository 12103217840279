import { FC, lazy, memo, Suspense } from 'react';

import { DocumentsSkeleton, DocumentsWrapper } from './DocumentsData.styles';
import { DocumentsProps } from './types';

const DocumentsData = lazy(() => import('./DocumentsData'));

const Documents: FC<DocumentsProps> = props => {
  return (
    <Suspense
      fallback={
        <DocumentsWrapper>
          <DocumentsSkeleton />
        </DocumentsWrapper>
      }
    >
      <DocumentsData {...props} />
    </Suspense>
  );
};

export default memo(Documents);

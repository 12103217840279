import { darken } from 'polished';
import { styled } from 'styled-components';

import CircleSpinner from 'components/CircleSpinner';
import { Typography } from 'components/Typography';

import { ReactComponent as DeleteSVG } from 'assets/icons/delete.svg';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 5px;
`;

export const CreatedDate = styled(Typography)`
  color: ${({ theme }) => theme.colors.controls.placeholder};
`;

export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 15px;
`;

export const DeleteIcon = styled(DeleteSVG)`
  cursor: pointer;
  padding: 8px 0 8px 8px;
  &:hover {
    & > path {
      fill: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }
  }
`;

export const Loader = styled(CircleSpinner)`
  margin: 8px 0 8px 8px;
`;

import { FC, memo, useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { format } from 'date-fns';

import { Occupancy } from 'enums/application';
import { ButtonVariant } from 'enums/ui';
import { getIsPreApproval } from 'utils/common';
import { formatCurrency, getFormattedAddress } from 'utils/formatting';

import { useDownloadDocumentMutation } from 'services/documentsAPI';
import { DocumentItem as DocumentItemType } from 'types/documents';
import { PreApproval } from 'types/preApproval';

import ManagePreApproval from 'components/@modals/ManagePreApproval';
import { Tooltip } from 'components/@styled';

import { Container, EditButton, Icon, Label } from './DocumentItem.styles';

interface DocumentItemProps {
  applicationId: string;
  document: DocumentItemType | PreApproval;
  fullName: string;
  maxApprovedPrice: number;
  propertyUsage: Occupancy;
  isCanEditPreApprovals: boolean;
  blockTestName: string;
}

const DocumentItem: FC<DocumentItemProps> = ({
  propertyUsage,
  maxApprovedPrice,
  isCanEditPreApprovals,
  document,
  fullName,
  applicationId,
  blockTestName,
}) => {
  const [downloadPreApproval, { isLoading }] = useDownloadDocumentMutation();

  const isPreApproval = getIsPreApproval(document);
  const documentId = isPreApproval ? (document as PreApproval).documentId : document.id;
  const { createdDate } = document;
  const documentName = 'documentType' in document && document.documentType?.name;

  const onDownload = useCallback(() => {
    if (documentId) {
      downloadPreApproval({
        documentId,
        fullName,
        createdDate,
        ...(documentName && { name: documentName }),
      });
    }
  }, [documentId, downloadPreApproval, fullName, createdDate, documentName]);

  const onOpenModal = useCallback(() => {
    const { address, propertyValue, downPayment, propertyType, numberOfUnits } =
      document as PreApproval;

    NiceModal.show(ManagePreApproval, {
      isEdit: true,
      address,
      propertyValue,
      maxApprovedPrice,
      propertyType,
      propertyUsage,
      applicationId,
      downPayment,
      numberOfUnits,
    });
  }, [applicationId, document, maxApprovedPrice, propertyUsage]);

  const label = `${
    (document as DocumentItemType)?.documentType?.name || 'Pre-Approval Letter'
  } - ${format(new Date(createdDate), 'MM.dd.u')}`;

  return (
    <Container data-testid={`${blockTestName}__item`}>
      <Icon
        $isDownloadable={!isLoading && !!documentId}
        data-testid={`${blockTestName}__itemIcon`}
        title="Download"
        onClick={onDownload}
      />

      <Label $withTooltip={isPreApproval} data-tooltip-id={isPreApproval ? documentId : undefined}>
        {label}
      </Label>

      {isPreApproval && (
        <Tooltip id={documentId} variant="light">
          <span data-testid={`${blockTestName}__itemAmount`}>
            {formatCurrency((document as PreApproval).propertyValue)}
          </span>
          &nbsp;|&nbsp;
          <span data-testid={`${blockTestName}__itemAddress`}>
            {getFormattedAddress((document as PreApproval).address)}
          </span>
        </Tooltip>
      )}

      {isPreApproval && isCanEditPreApprovals && (
        <EditButton
          data-testid={`${blockTestName}__itemEditButton`}
          variant={ButtonVariant.Transparent}
          onClick={onOpenModal}
        >
          Edit
        </EditButton>
      )}
    </Container>
  );
};

export default memo(DocumentItem);

import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  CREDIT_SCORE_OPTIONS,
  IS_CASH_OUT_OPTIONS,
  LOAN_PURPOSE_OPTIONS,
  LOCK_TERM_OPTIONS,
  PROPERTY_USAGE_OPTIONS,
} from 'constants/application';
import { IsCashOut, LoanPurpose, LockTerm, Occupancy, PropertyType } from 'enums/application';
import { ButtonVariant, TypographyComponent } from 'enums/ui';

import { useGetLoanOptionsMutation } from 'services/applyAPI';
import { selectCompanyId } from 'store/selectors/auth';
import { PotentialPurchaseLoanInfo, PotentialRefinanceLoanInfo } from 'types/application';

import AddressFields from './components/AddressFields';
import RateComparison from './components/RateComparison';
import CurrencyField from 'components/@fields/CurrencyField';
import SelectField from 'components/@fields/SelectField';
import PropertyTypeWithUnitsFields from 'components/@formFields/PropertyTypeWithUnitsFields';
import { Tooltip } from 'components/@styled';
import Button from 'components/Button';
import Card from 'components/Card';
import CircleSpinner from 'components/CircleSpinner';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PageHeader from 'components/PageHeader';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import {
  ButtonsContainer,
  Form,
  FormInputs,
  PriceLoanContainer,
  SubHeader,
} from './PriceLoan.styles';
import { schema } from './PriceLoan.validationSchema';

const initialValues = {
  loanPurpose: LoanPurpose.Purchase,
  propertyType: '' as PropertyType,
  occupancy: '' as Occupancy,
  creditScore: '',
  // for purchase only
  purchasePrice: '',
  downPaymentValue: '',
  lockTerm: LockTerm.No,
  // for refinance only
  propertyValue: '',
  firstMortgageBalance: '',
  cashOutAmount: '',
  isCashOut: IsCashOut.No,

  zip: '',
  county: '',
  state: '',
  city: '',

  numberOfUnits: '',
};

const PriceLoan: FC = () => {
  const [loanInfo, setLoanInfo] = useState<
    PotentialPurchaseLoanInfo | PotentialRefinanceLoanInfo | null
  >(null);

  const companyId = useSelector(selectCompanyId);

  const [getLoanOptions, { data, reset: hideLoanOptions, error }] = useGetLoanOptionsMutation();
  // @ts-ignore
  const APIError = error?.data?.Message || (error?.data && Object.values(error?.data)[0]?.message) || (typeof error?.data === 'string' && error?.status === 'PARSING_ERROR' && error?.data); // prettier-ignore

  // immutable reverse
  const loanOptions = data?.conventionalLoanOptions?.slice()?.reverse();

  const onSubmit = useCallback(
    async ({
      propertyValue,
      purchasePrice,
      loanPurpose,
      numberOfUnits,
      propertyType,
      downPaymentValue,
      firstMortgageBalance,
      city,
      state,
      isCashOut,
      cashOutAmount,
      zip,
      ...values
    }: typeof initialValues) => {
      const commonData = {
        propertyType,
        loanPurpose,
        ...(loanPurpose === LoanPurpose.Purchase
          ? {
              downPaymentValue,
              propertyValue: purchasePrice,
              isCashOut: undefined,
              cashOutAmount: undefined,
            }
          : {
              firstMortgageBalance,
              propertyValue,
              lockTerm: undefined,
              isCashOut: !!isCashOut,
              cashOutAmount: isCashOut === IsCashOut.Yes ? cashOutAmount : undefined,
            }),
      };

      const data = await getLoanOptions({
        ...values,
        zip,
        state,
        city,
        companyId,
        numberOfUnits: propertyType === PropertyType.MultiUnit ? numberOfUnits : 1,
        ...commonData,
      }).unwrap();

      setLoanInfo({
        ...commonData,
        applicationId: data?.applicationId,
        zip,
        numberOfUnits,
        state,
        city,
      } as PotentialPurchaseLoanInfo | PotentialRefinanceLoanInfo);
    },
    [companyId, getLoanOptions],
  );

  return (
    <PriceLoanContainer>
      <PageHeader title="Price a Loan" />
      <SubHeader component={TypographyComponent.H6}>
        Here You Can Price a Loan for Your Client
      </SubHeader>

      <Card>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({
            handleChange,
            isSubmitting,
            values,
            setValues,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form>
              <FormInputs>
                <SelectField
                  isAbsoluteError
                  required
                  label="Type of Loan"
                  name="loanPurpose"
                  options={LOAN_PURPOSE_OPTIONS}
                />

                <PropertyTypeWithUnitsFields
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  values={values}
                />

                <SelectField
                  isAbsoluteError
                  required
                  label="Property Usage"
                  name="occupancy"
                  options={PROPERTY_USAGE_OPTIONS}
                />

                <SelectField
                  isAbsoluteError
                  required
                  label="Credit Score"
                  name="creditScore"
                  options={CREDIT_SCORE_OPTIONS}
                />

                {values.loanPurpose === LoanPurpose.Purchase && (
                  <>
                    <CurrencyField
                      isAbsoluteError
                      isDecimalScale
                      required
                      shouldRemoveLeadingZeros
                      label="Purchase Price of Home"
                      name="purchasePrice"
                      onChange={handleChange}
                    />
                    <CurrencyField
                      isAbsoluteError
                      isDecimalScale
                      required
                      shouldRemoveLeadingZeros
                      label="Down Payment"
                      name="downPaymentValue"
                      onChange={handleChange}
                    />
                    <SelectField
                      isAbsoluteError
                      required
                      label={
                        <span>
                          {`Has the borrower found a property? `}
                          <>
                            <InfoIcon data-tooltip-id="lock-term-info-id" height="13" width="13" />
                            <Tooltip
                              html="Leverage our 90-day rate lock, allowing you 45 days to explore and secure <br/> your ideal home, along with an additional 45 days to complete the closing <br/> process – all at the same fixed rate"
                              id="lock-term-info-id"
                              variant="light"
                            />
                          </>
                        </span>
                      }
                      name="lockTerm"
                      options={LOCK_TERM_OPTIONS}
                    />
                  </>
                )}

                {values.loanPurpose === LoanPurpose.Refinance && (
                  <>
                    <CurrencyField
                      isAbsoluteError
                      isDecimalScale
                      required
                      shouldRemoveLeadingZeros
                      label="Property Value"
                      name="propertyValue"
                      onChange={handleChange}
                    />
                    <CurrencyField
                      isAbsoluteError
                      isDecimalScale
                      required
                      shouldRemoveLeadingZeros
                      label="Mortgage Left"
                      name="firstMortgageBalance"
                      onChange={handleChange}
                    />
                    <SelectField
                      isAbsoluteError
                      required
                      label="Cash out?"
                      name="isCashOut"
                      options={IS_CASH_OUT_OPTIONS}
                    />
                    {values.isCashOut === IsCashOut.Yes && (
                      <CurrencyField
                        isAbsoluteError
                        isDecimalScale
                        shouldRemoveLeadingZeros
                        label="Cash Out Amount"
                        name="cashOutAmount"
                        onChange={handleChange}
                      />
                    )}
                  </>
                )}

                {/*  ZIP field with autocomplete and county  */}
                <AddressFields handleChange={handleChange} setValues={setValues} />
              </FormInputs>

              {APIError && <ErrorMessage error={APIError} marginBottom={10} />}
              <ButtonsContainer>
                <Button disabled={isSubmitting || !isValid || !dirty} type="submit">
                  {isSubmitting ? <CircleSpinner size={16} /> : 'Get Prices'}
                </Button>
                <Button type="reset" variant={ButtonVariant.Transparent} onClick={hideLoanOptions}>
                  Reset
                </Button>
              </ButtonsContainer>
            </Form>
          )}
        </Formik>

        {loanOptions && loanInfo && (
          <RateComparison loanInfo={loanInfo} loanOptions={loanOptions} />
        )}
      </Card>
    </PriceLoanContainer>
  );
};

export default PriceLoan;

import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { useField } from 'formik';

import { ONLY_LATIN_LETTERS_NUMBERS_PUNCTUATION_MARKS_REGEXP } from 'constants/regExps';
import useUniqueId from 'hooks/useUniqueId';

import FieldLabel from 'components/FieldLabel';

import { calculateTextAreaHeight } from './TextField.helpers';
import { FieldErrorMessage, Input, TextArea, TextFieldWrapper } from './TextField.styles';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  className?: string;
  isAbsoluteError?: boolean;
  RightIcon?: ReactNode;
  // below are teaxtarea only props
  isTextArea?: boolean;
  $isNarrowScrollbar?: boolean;
  rows?: number;
}

const TextField: ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (
  {
    label,
    disabled,
    required,
    className,
    placeholder,
    onBlur,
    onChange,
    isAbsoluteError,
    RightIcon,
    onClick,
    maxLength,
    isTextArea = false,
    $isNarrowScrollbar,
    rows,
    ...props
  },
  ref,
) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(props);

  const isNotValid = !!(!disabled && touched && error);

  const formattedPlaceholder = required && placeholder ? `${placeholder}*` : placeholder;
  const formattedLabel = required ? `${label}*` : label;
  const validPlaceholder = formattedPlaceholder || formattedLabel;

  const id = useUniqueId(field.name);
  const value = props.value ?? field.value ?? '';
  const textAreaProps = useRef(isTextArea ? { rows: 1, height: 48, maxLength } : null);

  const onInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (!touched) {
        setValue(e.target.value || '', true);
        setTouched(true, false);
      }

      onBlur?.(e);
    },
    [touched, setTouched, setValue, onBlur],
  );

  // auto formatting textArea height
  const getTextAreaProps = useCallback(() => {
    const textAreaElement = document.getElementById(id);

    const maxHeight = calculateTextAreaHeight(rows || 1);
    const scrollHeight = textAreaElement?.scrollHeight;
    const actualHeight = scrollHeight ? scrollHeight + 2 : calculateTextAreaHeight(1);

    const height = maxHeight > actualHeight ? actualHeight : maxHeight;

    return { rows: 1, maxLength, height };
  }, [id, maxLength, rows]);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const re = new RegExp(ONLY_LATIN_LETTERS_NUMBERS_PUNCTUATION_MARKS_REGEXP, 'g');

      if (!re.test(e.target.value)) {
        return;
      }

      if (isTextArea) {
        textAreaProps.current = getTextAreaProps();
      }

      onChange?.(e);
    },
    [getTextAreaProps, isTextArea, onChange],
  );

  // TODO: Add correct type
  const Component = (isTextArea ? TextArea : Input) as any; // eslint-disable-line

  return (
    <TextFieldWrapper className={className} disabled={disabled} onClick={onClick}>
      <FieldLabel htmlFor={id}>{label}</FieldLabel>
      <Component
        {...field}
        {...textAreaProps.current}
        {...props}
        $hasRightIcon={!!RightIcon}
        $isNarrowScrollbar={$isNarrowScrollbar}
        $isNotValid={isNotValid}
        disabled={disabled}
        id={id}
        placeholder={validPlaceholder}
        ref={ref}
        value={value}
        onBlur={onInputBlur}
        onChange={onInputChange}
      />
      {RightIcon}
      {isNotValid ? <FieldErrorMessage error={error} isAbsolute={isAbsoluteError} /> : null}
    </TextFieldWrapper>
  );
};

export default memo(forwardRef(TextField));

import { FC, HTMLAttributes, memo, ReactNode } from 'react';
import { styled } from 'styled-components';

const CardContainer = styled.div`
  border-radius: 20px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;

  background-color: ${({ theme }) => theme.colors.card.background};
  box-shadow: ${({ theme }) => `0px 10px 30px 0 ${theme.colors.shadow}`};
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

const Card: FC<Props> = ({ children, ...props }) => {
  return <CardContainer {...props}>{children}</CardContainer>;
};

export default memo(Card);

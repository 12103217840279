import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { typographyStyles, TypographyStylesProps } from 'styles/typography';

export const TitlesContainer = styled.div<{ $isFewLinesEnabled?: boolean; $minTabWidth?: number }>`
  display: flex;
  min-height: min-content; // fixes incorrect height in Safari (iOS 14)
  margin-bottom: 15px;

  & > :not(:last-child) {
    margin-right: 38px;
  }
  align-self: flex-start;

  ${({ $isFewLinesEnabled, $minTabWidth }) =>
    $isFewLinesEnabled &&
    css`
      display: grid;
      grid-row-gap: 5px;
      grid-template-columns: repeat(auto-fill, minmax(${$minTabWidth}px, 1fr));
      justify-items: left;

      width: 100%;
      margin-right: 0;
      white-space: nowrap;
    `}
`;

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${MediaQueries.Tablet}) {
    align-items: center;
  }
`;

export const TabTitle = styled.div<TypographyStylesProps & { $isActive: boolean }>`
  ${typographyStyles};

  user-select: none;
  list-style: none;
  cursor: pointer;

  padding-bottom: 5px;
  display: flex;

  border-bottom: 2px solid transparent;
  border-bottom-color: ${({ $isActive, theme }) => $isActive && theme.colors.primary};
`;

import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DETAILS_INCLUDES } from 'constants/application';
import { FeatureFlags } from 'constants/featureToggles';
import { VALID_UUID_REGEXP } from 'constants/regExps';
import { ServerMethods } from 'enums/signalR';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { useGetLoanApplicationsQuery } from 'services/loansAPI';
import { signalRConnection } from 'services/signalR';
import { useGetLoanConditionsQuery } from 'services/skynetAPI';
import { useGetTasksQuery } from 'services/tasksAPI';
import { selectIsRealtorLoanOfficer } from 'store/selectors/auth';

import Conditions from './components/Conditions';
import ConditionsTabTitle from './components/Conditions/components/ConditionsTabTitle';
import Documents from './components/Documents';
import GeneralInfo from './components/GeneralInfo';
import Tasks, { TasksTabTitle } from './components/Tasks';
import Tabs from 'components/Tabs';

export const ApplicationDetails: FC = () => {
  const { id } = useParams();

  const isViewDocumentsTab = useFeatureFlag(FeatureFlags.ViewNewDocumentsTab);
  const isRealtorLO = useSelector(selectIsRealtorLoanOfficer);

  const { data, ...applicationsQuery } = useGetLoanApplicationsQuery(
    { applicationIds: [id as string], includes: DETAILS_INCLUDES },
    { skip: !id },
  );
  const application = data?.items?.[0];

  const { isLoading: isLoadingTasks, isError: isTasksError } = useGetTasksQuery(
    { applicationIds: [id as string] },
    { skip: !id },
  );

  const {
    data: conditions,
    isError: isConditionsError,
    isLoading: isConditionsLoading,
  } = useGetLoanConditionsQuery(
    { loanId: application?.id as string },
    { skip: !isRealtorLO || !application?.id },
  );

  const tabs = useMemo(() => {
    const commonProps = { application, ...applicationsQuery };

    const tabs = [
      { title: 'General Info', component: <GeneralInfo {...commonProps} /> },
      {
        title: <TasksTabTitle {...commonProps} isLoadingTasks={isLoadingTasks} />,
        component: (
          <Tasks {...commonProps} isLoadingTasks={isLoadingTasks} isTasksError={isTasksError} />
        ),
      },
      ...(isRealtorLO
        ? [
            {
              title: (
                <ConditionsTabTitle
                  count={conditions?.length}
                  isLoading={applicationsQuery.isLoading || isConditionsLoading}
                />
              ),
              component: (
                <Conditions
                  conditions={conditions}
                  isError={applicationsQuery.isError || isConditionsError}
                  isLoading={applicationsQuery.isLoading || isConditionsLoading}
                />
              ),
            },
          ]
        : []),
    ];

    if (isRealtorLO && isViewDocumentsTab) {
      // set in array as second element
      tabs.splice(1, 0, { title: 'Documents', component: <Documents {...commonProps} /> });
    }

    return tabs;
  }, [
    application,
    applicationsQuery,
    conditions,
    isConditionsError,
    isConditionsLoading,
    isLoadingTasks,
    isRealtorLO,
    isTasksError,
    isViewDocumentsTab,
  ]);

  const isCorrectUUID = id && !!id.match(VALID_UUID_REGEXP);

  useEffect(() => {
    if (isCorrectUUID) {
      signalRConnection.tryInvoke(ServerMethods.JoinToPipeline, 5, id);
    }
    return () => {
      if (isCorrectUUID) {
        signalRConnection.tryInvoke(ServerMethods.LeavePipeline, 1, id);
      }
    };
  }, [id, isCorrectUUID]);

  return <Tabs $isFewLinesEnabled={isRealtorLO} minTabWidth={100} tabs={tabs} />;
};

export default ApplicationDetails;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_CONTAINER_ID } from 'constants/common';

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const applicationContainer = document.getElementById(APP_CONTAINER_ID);
    applicationContainer?.scrollTo(0, 0);
  }, [pathname]);
};

export default useScrollToTop;

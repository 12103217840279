export const ONLY_LATIN_LETTERS_NUMBERS_PUNCTUATION_MARKS_REGEXP =
  /^[A-Za-z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/;

export const ONLY_DIGITS_REGEXP = /^[0-9]*$/g;

export const ONLY_ALPHANUMERIC_REGEXP = /^[a-zA-Z0-9]*$/g;

export const PHONE_NUMBER_REGEXP = /^\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

// Find leading zeros in the string/number
export const LEADING_ZEROS_REGEXP = /^0+(\w+)/;

export const TASK_MIME_TYPES_REGEXP = /application\/pdf,|image\/jpeg,|image\/png,/gi;

// Regular expression to check if string is a valid UUID. Use with match method!
export const VALID_UUID_REGEXP =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const BOTH_ALPHANUMERIC_REGEXP = /[a-zA-Z].*\d|\d.*[a-zA-Z]/;

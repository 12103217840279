import { FC, memo, useCallback } from 'react';
import { OnChangeValue } from 'react-select';
import { FormikHelpers, FormikValues } from 'formik';

import { NUMBER_OF_UNITS_OPTIONS, PROPERTY_TYPE_OPTIONS } from 'constants/application';
import { PropertyType } from 'enums/application';

import SelectField from 'components/@fields/SelectField';
import { OptionType } from 'components/@fields/SelectField/SelectField';

export interface PropertyTypeWithUnitsFieldsProps<T> {
  values: FormikValues;
  setFieldValue: FormikHelpers<T>['setFieldValue'];
  setFieldTouched: FormikHelpers<T>['setFieldTouched'];
}
const PropertyTypeWithUnitsFields: FC<PropertyTypeWithUnitsFieldsProps<FormikValues>> = ({
  values,
  setFieldValue,
  setFieldTouched,
}) => {
  const onChangePropertyType = useCallback(
    (propertyTypeOption: OnChangeValue<OptionType, false>) => {
      if (propertyTypeOption?.value === PropertyType.MultiUnit && values.numberOfUnits <= 1) {
        return;
      }

      setFieldValue('numberOfUnits', 1);
      setFieldTouched('numberOfUnits', false);
    },
    [values.numberOfUnits, setFieldValue, setFieldTouched],
  );

  return (
    <>
      <SelectField
        isAbsoluteError
        required
        label="Property Type"
        name="propertyType"
        options={PROPERTY_TYPE_OPTIONS}
        onChange={onChangePropertyType}
      />
      {values.propertyType === PropertyType.MultiUnit && (
        <SelectField
          isAbsoluteError
          required
          label="# of Units"
          name="numberOfUnits"
          options={NUMBER_OF_UNITS_OPTIONS}
        />
      )}
    </>
  );
};

export default memo(PropertyTypeWithUnitsFields);

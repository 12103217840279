import { formatZipCode } from 'utils/formatting';

import { Suggestion } from './PlacesAutocomplete.types';

export const getSuggestion = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const suggestion = addressComponents?.reduce<Suggestion>(
    (acc, addressComponent) => {
      const { types, long_name: longName, short_name: shortName } = addressComponent;
      const componentType = types[0];

      switch (componentType) {
        case 'street_number':
          acc.street = acc.street ? `${longName} ${acc.street}` : longName;
          break;

        case 'route':
          acc.street = acc.street ? `${acc.street} ${shortName}` : shortName;
          break;

        case 'postal_code':
          acc.zip = formatZipCode(longName);
          break;

        case 'locality':
          acc.city = longName;
          break;

        case 'administrative_area_level_1':
          acc.state = shortName;
          break;

        case 'administrative_area_level_2':
          acc.county = longName;
          break;

        case 'administrative_area_level_3': {
          if (acc.city) {
            break;
          }

          acc.city = longName;
          break;
        }
      }

      return acc;
    },
    { city: '', street: '', zip: '', county: '', state: '' },
  );

  // problem with New York city in google api
  if (suggestion?.state === 'NY' && !suggestion?.city) {
    return { ...suggestion, city: 'New York' };
  }

  return suggestion;
};

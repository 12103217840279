import { DefaultTheme } from 'styled-components';

import { palette } from 'styles/palette';

export const lightTheme: DefaultTheme = {
  colors: {
    primary: palette.pink,
    secondary: palette.secondary,
    accent: palette.dark,
    primaryGradientEnd: palette.pinkGradientEnd,
    white: palette.light,
    black: palette.dark,
    authBackground: 'linear-gradient(262.2deg, #fff 31.46%, rgba(255, 228, 239, 0) 96.66%)',
    bodyBackground: palette.light,
    paginationBackground: palette.backgroundGrey,
    shadow: '#ebebeb',
    subGridBackground: '#ebebeb',
    subGridFoldedShadow:
      'rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
    separateLine: '#C7C7C7',
    loader: palette.darkGrey,
    text: {
      primary: palette.darkGrey,
      secondary: palette.pink,
    },
    status: {
      info: '#1a96fe',
      success: '#24D440',
      error: '#ef4141',
      warning: '#d7a148',
    },
    card: {
      icon: palette.lightGrey,
      background: palette.light,
    },
    tooltip: {
      text: palette.darkGrey,
      background: palette.light,
      border: '#ebebeb',
    },
    buttons: {
      primary: palette.pink,
      transparent: palette.transparent,
    },
    controls: {
      text: palette.darkGrey,
      placeholder: palette.lightGrey,
      label: palette.grey,
      background: palette.backgroundGrey,
      disabledBackground: palette.lightGrey,
      hoveredBackground: palette.backgroundGrey,
    },
  },
  coefficients: {
    primaryHovered: 0.1,
    primaryDisabled: 0.2,
    primaryTextHovered: 0.14,
    primaryErrorHovered: 0.15,
    secondaryHovered: 0.14,
    secondaryDisabled: 0.18,
    controlsHovered: 0.13,
    controlsDisabled: 0.09,
  },
};

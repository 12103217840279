import { FC } from 'react';
import { css, styled } from 'styled-components';

import CrossSVG from 'components/@icons/CrossSVG';
import Accordion from 'components/Accordion';
import { Typography } from 'components/Typography';

import { ReactComponent as CheckRound } from 'assets/icons/checkRound.svg';

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.controls.label};
`;

export const AccordionContent = styled.div`
  padding-top: 20px;
`;

export const TaskAccordion = styled(Accordion)`
  margin-bottom: 20px;
`;

export const TasksListContainer = styled.div<{ isNoTasks?: boolean }>`
  margin-top: 20px;
  width: 100%;

  height: ${({ isNoTasks }) => isNoTasks && 61}px;
  margin-bottom: ${({ isNoTasks }) => isNoTasks && 20}px;

  ${({ isNoTasks }) =>
    isNoTasks &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;

      height: 61px;
      margin-bottom: 20px;
    `}
`;

export const CheckRoundIcon = styled(CheckRound)<{ $isActive: boolean }>`
  position: absolute;
  left: 17px;

  ${({ $isActive }) =>
    $isActive &&
    css`
      transition: all 0.5s ease;

      & > path:last-child {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    `}
`;

export const IconBackGround = styled.div`
  height: 30px;
  width: 30px;

  position: absolute;
  left: 17px;

  display: flex;
  align-items: center;
  justify-content: center;

  // TODO: think about put in variables
  background-color: #f8f8f8;
  border-radius: 50px;
`;

export const IconBackGroundRejected = styled(IconBackGround)<{ isActive: boolean }>`
  ${({ isActive, theme }) =>
    isActive &&
    css`
      transition: all 0.5s ease;
      background-color: ${theme.colors.primary};
    `}
`;

export const PendingDot = styled.div<{ isActive: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 50px;

  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.primary : '#8d9aa6')};

  &:not(:last-child) {
    margin-right: 2px;
  }
`;

export const PendingIcon: FC<{ $isActive: boolean }> = ({ $isActive }) => {
  return (
    <IconBackGround>
      <PendingDot isActive={$isActive} />
      <PendingDot isActive={$isActive} />
      <PendingDot isActive={$isActive} />
    </IconBackGround>
  );
};

const CrossIcon = styled(CrossSVG)`
  margin: 0 0 4px;
  display: initial;
  cursor: initial;
`;

export const RejectedIcon: FC<{ $isActive: boolean }> = ({ $isActive }) => {
  return (
    <IconBackGroundRejected isActive={$isActive}>
      <CrossIcon color={$isActive ? '#ffffff' : '#8d9aa6'} height={25} width={25} />
    </IconBackGroundRejected>
  );
};

export const RejectionInfo = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 14px;
  background-color: #ffe7ee;
  color: ${({ theme }) => theme.colors.black};
`;

export const RejectionReason = styled(Typography)`
  color: ${({ theme }) => theme.colors.controls.label};
`;

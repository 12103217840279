import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { useGetApplyUiSettingsQuery } from 'services/temporaryApplyAPI';
import { selectThemePrimaryColor } from 'store/selectors/ui';

import ThemeProvider from './ThemeProvider';

const AppThemeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const themePrimaryColor = useSelector(selectThemePrimaryColor);

  const { isLoading: isThemeLoading } = useGetApplyUiSettingsQuery();

  return (
    <ThemeProvider isLoading={isThemeLoading} themePrimaryColor={themePrimaryColor}>
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;

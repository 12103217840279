import { css, styled } from 'styled-components';

import { Typography } from 'components/Typography';

import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

export const AccordionContainer = styled.div`
  width: 100%;
  padding: 15px 25px;
  border-radius: 20px;
  position: relative;

  display: flex;
  flex-direction: column;

  box-shadow: ${({ theme }) => `0px 10px 30px 0 ${theme.colors.shadow}`};
  background-color: ${({ theme }) => theme.colors.bodyBackground};
`;

export const TitleRow = styled.div<{ $isExpandClickZone?: boolean; $isClickable?: boolean }>`
  // prevent selecting on multiple click
  user-select: none;
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'initial')};

  // need to create new stacking context for bottom padding to be clickable while expanded
  isolation: isolate;

  display: flex;
  justify-content: space-between;
  align-items: center;

  // make clickable area larger by size of AccordionContainer
  ${({ $isExpandClickZone }) =>
    $isExpandClickZone &&
    css`
      padding: 15px 25px;
      margin: -15px -25px;
    `}
`;

export const ArrowIcon = styled(Arrow)<{ $isActive: boolean }>`
  & > path {
    fill: ${({ theme }) => theme.colors.text.primary};
  }

  transition: all 0.5s ease;

  ${({ $isActive, theme }) =>
    $isActive &&
    `
  transform: rotate(180deg);

  & > path {
    fill: ${theme.colors.primary};
  }
`}
`;

export const Title = styled(Typography)<{
  $isActive?: boolean;
  $isSelected?: boolean;
  $isWithIcon?: boolean;
}>`
  transition: all 0.5s ease-in-out;
  color: ${({ theme, $isActive, $isSelected }) =>
    ($isActive || $isSelected) && theme.colors.primary};

  ${({ $isWithIcon }) =>
    $isWithIcon &&
    css`
      padding-left: 35px;
    `}
`;

export const Content = styled.div<{ $isActive: boolean; $isOverflowClip?: boolean }>`
  opacity: 0;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
      max-height: 200vh; // animation working only with numeric value
      transition:
        max-height 1s ease-in-out,
        opacity 1s ease;
    `}

  ${({ $isOverflowClip }) =>
    $isOverflowClip &&
    css`
      overflow: clip;
      overflow-clip-margin: 20px;
    `}
`;

export const NestedNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  border-radius: 50px;
  min-height: 20px;
  min-width: 20px;
  max-width: 20px;
  max-height: 20px;
  margin: 0 5px 0 auto;

  // align text
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
`;

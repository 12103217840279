import { styled } from 'styled-components';

import {
  DESKTOP_LONGEST_COLUMN_HEIGHT,
  LONGEST_COLUMN_HEIGHT,
  LONGEST_COLUMN_HEIGHT_FOR_LO,
} from 'constants/ui';
import { MediaQueries } from 'enums/ui';

import CustomerCard from 'components/ApplicationCard';
import Skeleton from 'components/Skeleton';

// Masonry Layout
// https://tobiasahlin.com/blog/common-flexbox-patterns/
// https://codepen.io/collection/XPjvPP/
export const GeneralInfoDetails = styled.div<{ $isRealtorLO?: boolean }>`
  display: flex;
  flex-flow: column wrap;
  flex-shrink: 0; // fixes inability to wrap in Safari (iOS 14)
  max-width: 1060px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${MediaQueries.MobileLandscape}) {
    // must necessarily match with the length of the longest column
    height: ${({ $isRealtorLO }) =>
      `${$isRealtorLO ? LONGEST_COLUMN_HEIGHT_FOR_LO : LONGEST_COLUMN_HEIGHT}px`};
    // for center aligning
    width: 700px;

    & > * {
      margin-bottom: 20px;

      &:nth-child(odd) {
        order: 1;
        margin-right: 20px;
      }

      &:nth-child(even) {
        order: 2;
      }
    }
  }

  @media (min-width: ${MediaQueries.Desktop}) {
    // must necessarily match with the length of the longest column
    height: ${DESKTOP_LONGEST_COLUMN_HEIGHT}px;

    // force new columns
    &::before,
    &::after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 2;
    }

    & > * {
      margin-bottom: 20px;

      // each first child in row
      &:nth-child(3n + 1) {
        order: 1;
        margin-right: 20px;
      }

      // each second child in row
      &:nth-child(3n + 2) {
        order: 2;
        margin-right: 20px;
      }

      // each third child in row
      &:nth-child(3n) {
        order: 3;
      }
    }
  }
`;

export const GeneralInfoCard = styled(CustomerCard)`
  width: 340px;
  height: min-content;
`;

export const GeneralInfoSkeleton = styled(Skeleton)`
  width: 340px;
  height: 361px;
  border-radius: 20px;
`;

import { FC, lazy, Suspense } from 'react';

import AnimatedLogoSVG from 'components/@icons/AnimatedLogoSVG';

const SignInData = lazy(() => import('./SignInData'));

const SignIn: FC = () => (
  <Suspense fallback={<AnimatedLogoSVG />}>
    <SignInData />
  </Suspense>
);

export default SignIn;

import { FC, memo } from 'react';

import { TypographySize } from 'enums/ui';
import { getTooltipId } from 'utils/id';

import { FormBundleModel } from 'types/documents';

import { Tooltip } from 'components/@styled';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';

import Documents from '../Documents';

import { CrossIcon, StepContainer, StepProgress, StepText } from './Step.styles';

interface StepProps {
  title: string;
  number: number;
  isPassed: boolean;
  isProcessingStopped: boolean;
  description?: string;
  isDynamicProgress?: boolean;
  documents?: FormBundleModel[] | [];
}

const Step: FC<StepProps> = ({
  title,
  number,
  isPassed,
  isProcessingStopped,
  description,
  isDynamicProgress,
  documents,
  ...props
}) => {
  const tooltipId = description ? getTooltipId(title) : undefined;
  const processingData = isProcessingStopped ? <CrossIcon /> : number;
  let stepData = isPassed ? <CheckmarkIcon /> : processingData;

  if (isDynamicProgress && isProcessingStopped) {
    stepData = processingData;
  }

  return (
    <>
      <StepContainer {...props}>
        <StepProgress
          $isDynamicProgress={isDynamicProgress}
          $isPassed={isPassed}
          $isProcessingStopped={isProcessingStopped}
        >
          {stepData}
        </StepProgress>
        <StepText
          $isError={isProcessingStopped && !isPassed}
          data-tooltip-id={tooltipId}
          size={TypographySize.Small}
        >
          {title}
        </StepText>

        {tooltipId && <Tooltip content={description} id={tooltipId} variant="light" />}
      </StepContainer>
      {!!documents?.length && <Documents documents={documents} milestoneName={title} />}
    </>
  );
};

export default memo(Step);

import { useEffect, useMemo } from 'react';

import { SUMMARY_INCLUDES } from 'constants/application';
import { IS_PRODUCTION } from 'constants/common';
import { SortDirection } from 'enums/common';
import { getEmptyArray } from 'utils/array';

import { useLazyGetLoanApplicationsQuery } from 'services/loansAPI';
import { useGetTasksQuery } from 'services/tasksAPI';
import { LoanApplication } from 'types/application';

import { usePagination } from './usePagination';

interface UsePagedApplciationsProps {
  pageSize: number;
  isDashboard?: boolean;
  status?: string | null;

  // for table
  sortBy?: string;
  sortDirection?: string;
}

const usePagedApplications = ({
  pageSize,
  isDashboard,
  status,
  sortDirection,
  sortBy,
}: UsePagedApplciationsProps) => {
  const [getLoanApplications, { currentData, data, isError, isFetching }] =
    useLazyGetLoanApplicationsQuery();

  const items = currentData?.items;
  const isTableView = !!(sortDirection || sortBy);

  const { currentPage, setPage, maxPage } = usePagination<LoanApplication>({
    pageSize,
    data: items,
    totalSize: data?.initialSourceSize,
  });

  const applications: LoanApplication[] | undefined = useMemo(() => {
    if (isDashboard) {
      if (items?.length === 0) {
        return items;
      }

      return items && items.length < pageSize ? [...items, ...getEmptyArray(3)].slice(0, 4) : items;
    }

    return items;
  }, [isDashboard, items, pageSize]);

  const applicationIdsOnPage = useMemo(() => {
    return items?.map(app => app.applicationId);
  }, [items]);

  useGetTasksQuery(
    { applicationIds: applicationIdsOnPage },
    { skip: isTableView || isFetching || !applicationIdsOnPage?.length },
  );

  useEffect(() => {
    const query = getLoanApplications({
      count: pageSize,
      offset: (currentPage - 1) * pageSize,
      includes: SUMMARY_INCLUDES,
      status,
      orderByColumn: sortBy,
      descendingOrder: sortDirection === SortDirection.DESC,
    });

    if (IS_PRODUCTION) {
      return () => query.abort(); // React 18 in dev mode mounts components twice
    }
  }, [currentPage, getLoanApplications, pageSize, sortBy, sortDirection, status]);

  return {
    applications,
    applicationIdsOnPage,

    isError,
    isFetchingNewQuery: isFetching && !currentData,

    setPage,
    maxPage,
    currentPage,
  };
};

export default usePagedApplications;

import { LEADING_ZEROS_REGEXP } from 'constants/regExps';
import { ZIP_CODE_LENGTH } from 'constants/ui';

import { Address } from 'types/address';
import { LoanOfficer } from 'types/application';

export const formatNumber = (value: number, options: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat('en-US', options).format(value);

export const formatName = (value: string): string =>
  value.toLowerCase().replace(/(\s|^|\u2018|\u2019|'|-)([a-z])/g, i => i.toUpperCase());

export const getFormattedAddress = ({ street, city, state, zip }: Address) =>
  [
    [street, city],
    [state, zip],
  ]
    .map(arr => arr.filter(Boolean).join(' '))
    .filter(Boolean)
    .join(', ');

// ex '7:24 AM'
const getFormattedTime = (date: string, options: Intl.DateTimeFormatOptions = {}) =>
  new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', ...options });

// ex '12/01/2023'
export const getFormattedDate = (date: string, options: Intl.DateTimeFormatOptions = {}) =>
  new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    ...options,
  });

// ex '12/01/2023 8:23 PM'
export const getFormattedDateTime = (date: string, options: Intl.DateTimeFormatOptions = {}) => {
  const formattedDate = getFormattedDate(date, options);
  const formattedTime = getFormattedTime(date, options);
  return `${formattedDate} ${formattedTime}`;
};

export const formatPhoneForRequest = (phone: string) => phone.replace(/\(|\)|-|\s/g, '');

export const removePhoneCountryCode = (phone: string) => phone.replace(/^\+1/, '');

export const formatPhone = (phone: string) => {
  const withoutPrefix = removePhoneCountryCode(phone);
  const code = withoutPrefix.slice(0, 3);
  const numberGroups = [withoutPrefix.slice(3, 6), withoutPrefix.slice(6)];
  return `(${code}) ${numberGroups.join('-')}`;
};

export const formatPhoneForFormik = (phone: string) => {
  if (!phone) {
    return '';
  }
  return `+1 ${formatPhone(phone)}`;
};

interface FormatCurrencyOptions {
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}
export const formatCurrency = (value: number, options: FormatCurrencyOptions = {}): string => {
  const { maximumFractionDigits, minimumFractionDigits } = options;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits ?? 2,
    minimumFractionDigits: minimumFractionDigits ?? 0,
  }).format(value);
};

export const getValueWithoutLeadingZeros = (value: string): string => {
  return value.replace(LEADING_ZEROS_REGEXP, '$1');
};

export const removeDashes = (string: string): string => string.replace(/-/gim, '');

export const formatZipCode = (zip: string): string =>
  removeDashes(zip).substring(0, ZIP_CODE_LENGTH);

const isBlank = (value: unknown) => !value && value !== 0;
export const getBlankDefault = (
  value: unknown,
  {
    defaultValue = '—',
    processValue,
  }: { defaultValue?: string | number; processValue?: (value: unknown) => string | number },
) => {
  const processedValue = processValue ? processValue(value) : value;
  return isBlank(value) ? defaultValue : processedValue;
};

export const getLoanOfficerFormattedArray = (loanOfficer: LoanOfficer | undefined) =>
  !!loanOfficer ? [{ ...loanOfficer, loanRoleName: 'Loan Officer' }] : undefined;

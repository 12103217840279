import { useMemo } from 'react';

import { getRandomUUID } from 'utils/id';

const useUniqueId = (prefix?: string) =>
  useMemo(() => {
    return `${prefix ? prefix + '_' : ''}${getRandomUUID()}`;
  }, [prefix]);

export default useUniqueId;

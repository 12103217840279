import { FC, memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';

import { getApiError } from 'utils/api';
import { getRealtorId } from 'utils/id';

import { useSubmitTaskMutation } from 'services/tasksAPI';

import TextField from 'components/@fields/TextField';
import CircleSpinner from 'components/CircleSpinner';

import { TaskHandlerProps } from './TaskForm';
import { APIErrorMessage, FormContainer, SubmitButton } from './TaskForm.styles';

const FreeTextTaskForm: FC<TaskHandlerProps> = ({ task, isReadonly }) => {
  const { id: applicationId } = useParams();

  const [submitTask, { error }] = useSubmitTaskMutation();

  const realtorId = getRealtorId();

  const initialValues = useMemo(
    () => ({
      freeText: task.submittedText ?? '',
    }),
    [task],
  );

  const onSubmit = useCallback(
    async ({ freeText }: typeof initialValues) => {
      const { id } = task;
      const fd = new FormData();

      fd.append('taskId', id);
      fd.append('realtorId', realtorId);
      fd.append('freeText', freeText);

      await submitTask({ id, applicationId, body: fd });
    },
    [applicationId, realtorId, submitTask, task],
  );

  const APIError = getApiError(error);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleChange, isSubmitting, dirty }) => (
        <FormContainer $isReadonly={isReadonly}>
          <TextField
            isTextArea
            $isNarrowScrollbar={!isReadonly}
            autoComplete="off"
            disabled={isReadonly || isSubmitting}
            height={112}
            label=""
            name="freeText"
            onChange={handleChange}
          />
          {APIError && <APIErrorMessage error={APIError} />}
          {!isReadonly && (
            <SubmitButton
              data-testid="task_submit_button"
              disabled={!dirty}
              isSubmitting={isSubmitting}
              type="submit"
            >
              {isSubmitting ? <CircleSpinner size={17} /> : 'Submit Task'}
            </SubmitButton>
          )}
        </FormContainer>
      )}
    </Formik>
  );
};

export default memo(FreeTextTaskForm);

import { number, object } from 'yup';

import { ZIP_CODE_LENGTH } from 'constants/ui';
import { PropertyType } from 'enums/application';
import { ValidationError } from 'enums/validation';
import { getAddressFields, getCurrencyField, getZipField } from 'utils/validation';

export const getSchema = (isRequiredDownPayment: boolean) =>
  object().shape({
    propertyValue: getCurrencyField().test(
      'isEqualOrHigherThan1k',
      'Purchase Price can not be lower than $1,000',
      (value = '') => !!value && +value >= 1000,
    ),
    numberOfUnits: number().test(
      'isRequiredNumberOfUnits',
      ValidationError.RequiredField,
      (value, { parent: { propertyType } }) =>
        propertyType !== PropertyType.MultiUnit || (!!value && value > 1),
    ),
    downPaymentValue: getCurrencyField({ isRequired: isRequiredDownPayment, minValue: 1 }).test(
      'LessThanPropertyValue',
      'Your down payment exceeds the new max purchase price',
      (value = '', { parent: { propertyValue } }) => !!value && +value < +propertyValue,
    ),
    ...getAddressFields(),
    // We assume that zip is invalid if there is no county returned from BE by zip code
    zip: getZipField({
      customValidator: (zip = '', { parent }) =>
        (zip as string).length === ZIP_CODE_LENGTH && !!parent?.county,
    }),
  });

import { darken } from 'polished';
import { styled } from 'styled-components';

import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.status.error};
  text-decoration: underline;

  transition: all 0.5s ease;
`;

export const NoLongerWorkingTogetherContainer = styled.div`
  padding: 10px 0;
  max-width: 340px;

  display: flex;
  justify-content: center;

  &:hover {
    cursor: ${({ onClick }) => onClick && 'pointer'};

    & > ${Text} {
      color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.status.error)};
      // added for transition
      text-decoration-color: transparent;
    }
  }
`;

const TextSkeleton = styled(Skeleton)`
  width: 180px;
  height: 18px;
  border-radius: 7px;
`;

export const NoLongerWorkingTogetherSkeleton = () => (
  <NoLongerWorkingTogetherContainer>
    <TextSkeleton />
  </NoLongerWorkingTogetherContainer>
);

import {
  AppInsightsAggregator,
  Logger,
  LoggerSettings,
  LoggingLevel,
  LogLevel,
} from '@interfirst/utils';

import { APP_INSIGHTS_INSTRUMENTARY_KEY, IS_PRODUCTION } from 'constants/common';

export const commonLoggerSettings = {
  expand: {
    [LogLevel.INFO]: true,
    [LogLevel.LOG]: true,
    [LogLevel.WARN]: true,
    [LogLevel.ERROR]: true,
  },
  loggingLevel: IS_PRODUCTION ? LoggingLevel.ERROR : LoggingLevel.VERBOSE,
  internalLogger: window.console,
};

export const aiAggregator = new AppInsightsAggregator({
  disableTelemetry: process.env.DISABLE_AI_LOAD === 'true',
  tags: { 'ai.cloud.role': 'Realtors' },
  instrumentationKey: APP_INSIGHTS_INSTRUMENTARY_KEY as string,
  correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
  enableCorsCorrelation: true,
  // instead of passing history for react-router v6
  enableAutoRouteTracking: true,
});

export const externalLogger = new Logger(
  'GLOBAL',
  new LoggerSettings({
    ...commonLoggerSettings,
    aggregator: aiAggregator,
  }),
);

export const handleSignalRError = (error: unknown) => {
  if (error instanceof Error) {
    externalLogger.error(error.message);
  } else {
    externalLogger.error('signalR Error', JSON.stringify(error));
  }
};

import { FC, memo, MouseEvent } from 'react';
import { useTheme } from 'styled-components';

import CircleSpinner from 'components/CircleSpinner';

import { File, FileExtension, FileName, FileNameContainer } from './TaskForm.styles';

interface SubmittedDocumentProps {
  name: string;
  requestDocument(event: MouseEvent<HTMLLIElement>): void;
  isRequesting: boolean;
  isDisabled: boolean;
  documentId: string;
}

const SubmittedDocument: FC<SubmittedDocumentProps> = ({
  name,
  requestDocument,
  isRequesting,
  isDisabled,
  documentId,
}) => {
  const theme = useTheme();

  return (
    <File
      isViewing
      data-documentid={documentId}
      data-name={name}
      isDisabled={isDisabled}
      onClick={requestDocument}
    >
      <FileNameContainer>
        <FileName>{name}</FileName>
        <FileExtension>.pdf</FileExtension>
        {isRequesting && <CircleSpinner color={theme.colors.primary} size={13} />}
      </FileNameContainer>
    </File>
  );
};

export default memo(SubmittedDocument);

import { FC, SVGProps } from 'react';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

const Svg = styled.svg`
  margin-right: 20px;
  display: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    display: initial;
  }
`;

const MenuSVG: FC<SVGProps<SVGSVGElement>> = ({ color, onClick }) => {
  return (
    <Svg
      fill="none"
      height="31"
      viewBox="0 0 30 31"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect fill={color} height="2" rx="1" width="20" x="5" y="8.5" />
      <rect fill={color} height="2" rx="1" width="20" x="5" y="14.5" />
      <rect fill={color} height="2" rx="1" width="20" x="5" y="20.5" />
    </Svg>
  );
};

export default MenuSVG;

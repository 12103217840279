import { darken, transparentize } from 'polished';
import { styled } from 'styled-components';

import Button from 'components/Button';
import { Separator as SeparatorLine } from 'components/SeparatorLine';
import { Typography } from 'components/Typography';

import { ReactComponent as ArrowWithLine } from 'assets/icons/arrowWithLine.svg';

export const Separator = styled(SeparatorLine)`
  width: 100%;
  margin: 15px 0;

  transition: border-color 0.5s ease-in-out;
`;

export const Program = styled.div<{ isSelected: boolean }>`
  user-select: none;
  padding: 35px 0;

  border-radius: 20px;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#039855 !important' : '#D0D5DD')};
  background-color: ${({ theme }) => theme.colors.bodyBackground};

  cursor: pointer;
  transition:
    border-color 0.5s ease-in-out,
    background-color 0.5s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => transparentize(0.97, theme.colors.primary)};

    & > ${Separator} {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const Term = styled.span`
  margin: 15px 0;

  font-size: 38px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Years = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const ButtonWithIcon = styled(Button)`
  align-self: center;

  &:hover {
    & > svg > path {
      fill: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }
  }
`;

export const ComparisonText = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
  }
`;

export const Arrow = styled(ArrowWithLine)`
  margin-left: 10px;
  stroke: ${({ theme }) => theme.colors.primary};
`;

export const YearsLabel = styled(Typography)`
  display: inline;
  text-decoration: underline;
`;

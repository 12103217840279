import { FC, memo } from 'react';
import { styled } from 'styled-components';

import { TypographyComponent, TypographyWeight } from 'enums/ui';
import { getTime } from 'utils/date';
import { getTooltipId } from 'utils/id';

import { Tooltip } from 'components/@styled';
import { Typography } from 'components/Typography';

const PassedDaysText = styled(Typography)`
  padding: 0;
`;
interface TaskTitleProps {
  name: string;
  createdDate: string;
  id: string;
}

const TaskTitle: FC<TaskTitleProps> = ({ name, createdDate, id }) => {
  const passedDays = getTime({ date: createdDate, time: 'days', type: 'passed' });
  const tooltipId = getTooltipId(id);

  if (passedDays > 0) {
    return (
      <>
        {name}{' '}
        <PassedDaysText
          component={TypographyComponent.H7}
          data-tooltip-id={tooltipId}
          forwardedAs={TypographyComponent.SPAN}
          weight={TypographyWeight.Regular}
        >
          - <u>{passedDays} day(s)</u>
        </PassedDaysText>
        <Tooltip content={`created ${passedDays} day(s) ago`} id={tooltipId} wrapper="span" />
      </>
    );
  }

  return (
    <>
      {name}{' '}
      <PassedDaysText
        component={TypographyComponent.H7}
        forwardedAs={TypographyComponent.SPAN}
        weight={TypographyWeight.Regular}
      >
        - created today
      </PassedDaysText>
    </>
  );
};

export default memo(TaskTitle);

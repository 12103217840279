import { lighten } from 'polished';
import { styled } from 'styled-components';

import Carousel from 'components/Carousel';
import { Typography } from 'components/Typography';

import { ReactComponent as DownloadPDF } from 'assets/icons/downloadPDF.svg';

export const RateComparisonCarousel = styled(Carousel)`
  width: 100%;
  margin: 30px 0;
`;

export const RateComparisonTitle = styled(Typography)`
  margin-top: 30px;
  user-select: none;
`;

export const EmptyStateText = styled(Typography)`
  width: 100%;
  margin: 55px 0 30px;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadPDFButton = styled.button`
  background-color: ${({ theme }) => lighten(0.355, theme.colors.primary)}; // equals #fff6fb

  min-width: 48px;
  min-height: 48px;
  border-radius: 8px;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => lighten(0.33, theme.colors.primary)};
  }
`;

export const DownloadPDFIcon = styled(DownloadPDF)`
  & > path {
    stroke: ${({ theme }) => theme.colors.primary};
  }
  & > path:last-of-type {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

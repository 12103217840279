import { Form as FormikForm } from 'formik';
import { css, styled } from 'styled-components';
import { ifNotProp } from 'styled-tools';

import { MediaQueries } from 'enums/ui';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

export const Form = styled(FormikForm)<{ $isPurchaseAgreement?: boolean }>`
  ${ifNotProp(
    '$isPurchaseAgreement',
    css`
      width: 75vw;
      max-width: 1000px;
    `,
  )};

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    width: auto;
  }
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  max-height: calc(100vh - 250px);
  overflow-y: auto;

  margin: 15px 0 35px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
  }
`;

export const APIErrorMessage = styled(ErrorMessage)`
  margin-bottom: 15px;
  text-align: center;
`;

export const AddressWrapper = styled.div`
  grid-column: span 3;
`;

import { createApi } from '@reduxjs/toolkit/query/react';

import { temporaryApplyQueryWithReauth } from 'utils/api';

import { ApplyUiSettings } from './types';

// delete when realtors will have separate theme settings endpoint
const temporaryApplyAPI = createApi({
  reducerPath: 'temporaryApplyAPI',
  baseQuery: temporaryApplyQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getApplyUiSettings: build.query<ApplyUiSettings, void>({
      queryFn: async (_, __, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: '/company/info/v1',
        });

        if (response.error) {
          return {
            error: response.error,
          };
        }

        const data = response.data as ApplyUiSettings;
        const settingsData = data.applyFormUiSetting;

        const formattedData = {
          applyFormUiSetting: {
            isNoProgramsFlow: settingsData.isNoProgramsFlow,
            settings: JSON.parse(settingsData.settings).colors,
          },
        };

        return { data: formattedData };
      },
    }),
  }),
});

export const { useGetApplyUiSettingsQuery } = temporaryApplyAPI;

export default temporaryApplyAPI;

import { FC, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { ApplicationsViewType } from 'enums/application';

import GridViewSVG from 'components/@icons/GridViewSVG';
import ListSVG from 'components/@icons/ListSVG';

import { palette } from 'styles/palette';

import { SwitcherContainer } from './ViewTypeSwitcher.styles';

interface DisplayTypeFilterProps {
  viewType: ApplicationsViewType;
  onChange: (viewType: ApplicationsViewType) => void;
}

const ViewTypeSwitcher: FC<DisplayTypeFilterProps> = ({ viewType, onChange }) => {
  const theme = useTheme();

  const setTable = useCallback(() => onChange(ApplicationsViewType.Table), [onChange]);
  const setCards = useCallback(() => onChange(ApplicationsViewType.Cards), [onChange]);

  return (
    <SwitcherContainer>
      <ListSVG
        color={viewType === ApplicationsViewType.Table ? theme.colors.primary : palette.lightGrey}
        onClick={setTable}
      />
      <GridViewSVG
        color={viewType === ApplicationsViewType.Cards ? theme.colors.primary : palette.lightGrey}
        onClick={setCards}
      />
    </SwitcherContainer>
  );
};

export default ViewTypeSwitcher;

import { css, styled } from 'styled-components';

interface PartProps {
  $isActive?: boolean;
}

export const BottomPart = styled.span<PartProps>`
  color: ${({ theme }) => theme.colors.bodyBackground};
  font-family: Rubik, sans-serif;
  font-weight: 600;

  transition: -webkit-text-stroke 0.3s;

  -webkit-text-stroke: 1px ${({ theme }) => theme.colors.black};

  ${({ $isActive }) =>
    $isActive &&
    css`
      -webkit-text-stroke: initial;
    `};
`;

export const UpperPart = styled.span<PartProps>`
  position: absolute;
  top: 2px;
  right: -2px;

  color: ${({ theme }) => theme.colors.text.primary};
  font-family: Rubik, sans-serif;
  font-weight: 600;

  transition: all 0.3s ease-in-out;

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      top: -2px;
      right: 2px;
      color: ${theme.colors.white};
      -webkit-text-stroke: 1px ${theme.colors.black};
    `};
`;

export const TextContainer = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;

import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import { FeatureFlags } from 'constants/featureToggles';
import { EMPTY_ARRAY } from 'constants/frozenObjects';
import { ApplicationStatus } from 'enums/application';
import { ButtonVariant, TypographyComponent, TypographySize, TypographyWeight } from 'enums/ui';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { selectIsActivePurchaseAgreementTaskByApplicationId } from 'store/selectors/tasks';
import { LoanApplication } from 'types/application';
import { DocumentItem as DocumentItemType } from 'types/documents';
import { PreApproval } from 'types/preApproval';

import DocumentItem from './components/DocumentItem';
import ManagePreApproval from 'components/@modals/ManagePreApproval';

import {
  CreateButton,
  DocumentsListContainer,
  EmptyMessage,
  List,
  Title,
} from './DocumentsList.styles';

interface DocumentsListProps {
  application: LoanApplication;
  documents?: DocumentItemType[];
  isViewDocumentsList?: boolean;
}

const blockTestName = 'preApproval';

const DocumentsList: FC<DocumentsListProps> = ({ application, documents, isViewDocumentsList }) => {
  const isFeatureEnabled = useFeatureFlag(FeatureFlags.RealtorsEnablePreApprovalManagement);

  const isActivePurchaseAgreementTask = useSelector(
    selectIsActivePurchaseAgreementTaskByApplicationId(application.applicationId),
  );

  const isInitiatedOrSubmittedStatus =
    application?.loanStatus === ApplicationStatus.Initiated ||
    application?.loanStatus === ApplicationStatus.Submitted;
  // if initial disclosure was generated, user haven't access to create or edit pre-approvals
  const isCanCreatePreApprovals =
    (isFeatureEnabled as boolean) &&
    !isInitiatedOrSubmittedStatus &&
    application.isTbd &&
    isActivePurchaseAgreementTask &&
    !application?.loanProcessingStopReason;

  const maxApprovedPrice = application.subjectProperty.propertyValue;
  const preApprovals = application?.preApprovals || EMPTY_ARRAY;

  const allDocuments = useMemo(() => {
    const docs = documents || [];
    let allDocs: (DocumentItemType | PreApproval)[] = preApprovals;

    if (docs.length) {
      const preApprovalDocumentIds = new Set(preApprovals.map(item => item.documentId));
      const filteredDocs = docs
        .filter(doc => !preApprovalDocumentIds.has(doc.id))
        .sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate));
      allDocs = [...preApprovals, ...filteredDocs];
    }

    return allDocs;
  }, [documents, preApprovals]);

  const propertyType = preApprovals?.[0]?.propertyType || application.subjectProperty.type;
  const propertyUsage = application.subjectProperty.occupancy;
  const fullName = application.primaryBorrower.fullName;
  const { applicationId } = application;

  const onOpenModal = useCallback(() => {
    NiceModal.show(ManagePreApproval, {
      applicationId,
      maxApprovedPrice,
      propertyType,
      propertyUsage,
    });
  }, [applicationId, maxApprovedPrice, propertyType, propertyUsage]);

  return (
    <DocumentsListContainer>
      <Title component={TypographyComponent.H6}>
        {isViewDocumentsList ? 'Documents' : 'Pre-Approval List'}
      </Title>
      {isCanCreatePreApprovals && (
        <CreateButton
          data-testid={`${blockTestName}__createButton`}
          variant={ButtonVariant.Transparent}
          onClick={onOpenModal}
        >
          Create New Pre-Approval
        </CreateButton>
      )}

      <List data-testid={`${blockTestName}__list`}>
        {allDocuments.length ? (
          allDocuments.map(item => (
            <DocumentItem
              applicationId={applicationId}
              blockTestName={blockTestName}
              document={item}
              fullName={fullName}
              isCanEditPreApprovals={isCanCreatePreApprovals}
              key={(item as PreApproval).documentId || item.id}
              maxApprovedPrice={maxApprovedPrice}
              propertyUsage={propertyUsage}
            />
          ))
        ) : (
          <EmptyMessage size={TypographySize.Small} weight={TypographyWeight.Regular}>
            {`No Current ${isViewDocumentsList ? 'Documents' : 'Pre-Approvals'}`}
          </EmptyMessage>
        )}
      </List>
    </DocumentsListContainer>
  );
};

export default memo(DocumentsList);

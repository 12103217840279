import { useCallback, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ButtonVariant, TypographyComponent } from 'enums/ui';

import Button from 'components/Button';
import CircleSpinner from 'components/CircleSpinner';
import { Typography } from 'components/Typography';

import { ButtonsContainer, ConfirmationModalContainer } from './ConfirmationModal.styles';

interface Props {
  title: string;
  onSubmit(): void;
  isAsync?: boolean;
}

export default NiceModal.create<Props>(({ title, onSubmit, isAsync = false }) => {
  // cannot be pass isSubmitting as prop because the component will not be re-rendered if props changed
  const [isSubmitting, setSubmitting] = useState(false);
  const modal = useModal();

  const onSubmitAction = useCallback(() => {
    if (isAsync) {
      setSubmitting(true);
    }

    onSubmit();
  }, [isAsync, onSubmit]);

  return (
    <ConfirmationModalContainer withCloseIcon={false}>
      <Typography component={TypographyComponent.H5}>{title}</Typography>

      <ButtonsContainer>
        <Button $minWidth={70} data-testid="submit_button" onClick={onSubmitAction}>
          {isSubmitting ? <CircleSpinner size={14} /> : 'Yes'}
        </Button>
        <Button
          data-testid="cancel_button"
          variant={ButtonVariant.Transparent}
          onClick={modal.remove}
        >
          No
        </Button>
      </ButtonsContainer>
    </ConfirmationModalContainer>
  );
});

import { KeyboardEvent as ReactKeyboardEvent, RefObject } from 'react';

import { KeyCode } from 'enums/keyboard';

export const navigateByArrows = (
  e: KeyboardEvent | ReactKeyboardEvent,
  ref: RefObject<any>, // eslint-disable-line
): void => {
  e.stopPropagation();

  if (e.code === KeyCode.ArrowRight || e.code === KeyCode.ArrowDown) {
    e.preventDefault();
    ref?.current?.nextSibling?.focus();
  }

  if (e.code === KeyCode.ArrowLeft || e.code === KeyCode.ArrowUp) {
    e.preventDefault();
    ref?.current?.previousSibling?.focus();
  }
};

import { FC, lazy, Suspense } from 'react';

import AnimatedLogoSVG from 'components/@icons/AnimatedLogoSVG';

const SignUpData = lazy(() => import('./SignUpData'));

const SignUp: FC = () => (
  <Suspense fallback={<AnimatedLogoSVG />}>
    <SignUpData />
  </Suspense>
);

export default SignUp;

export enum RealTimeEvent {
  Loan = 'loan',
  TaskUpdated = 'taskupdated',
  TaskCreated = 'taskcreated',
  OnCreditReportDocumentSaved = 'onCreditReportDocumentSaved',
  LoanRoleAssignmentsChanged = 'loanRoleAssignmentsChanged',
  LoanProgressChanged = 'loanprogresschanged',
  LoanContactsChanged = 'loancontactschanged',
}

export enum ServerMethods {
  JoinToPipeline = 'JoinToPipeline',
  LeavePipeline = 'LeavePipeline',
}

import { FC, memo } from 'react';
import { styled } from 'styled-components';

interface CircleProgressBar {
  percentage: number;
  trailStrokeColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
}

const Wrapper = styled.div`
  width: 65px;
  height: 65px;
`;

const Text = styled.text<{ $isFull: boolean }>`
  fill: #fff;
  font-size: ${({ $isFull }) => ($isFull ? '10px' : '11px')};
  font-weight: 600;
`;

const Circle = styled.circle`
  stroke: ${({ theme }) => theme.colors.primary};
  opacity: 0.7;
`;

const initialOffset = 25;

const circleConfig = {
  viewBox: '0 0 38 38',
  x: '19',
  y: '19',
  radio: '15.92',
};

const CircleProgressBarBase: FC<CircleProgressBar> = ({
  trailStrokeColor,
  strokeColor = '#d9d9d9',
  percentage,
  strokeWidth = 3,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <figure>
        <svg viewBox={circleConfig.viewBox}>
          <circle
            cx={circleConfig.x}
            cy={circleConfig.y}
            fill="transparent"
            r={circleConfig.radio}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />

          <Circle
            cx={circleConfig.x}
            cy={circleConfig.y}
            fill="transparent"
            r={circleConfig.radio}
            stroke={trailStrokeColor}
            strokeDasharray={`${percentage} ${100 - percentage}`}
            strokeDashoffset={initialOffset}
            strokeWidth={strokeWidth}
          />
          <g>
            <Text
              $isFull={percentage === 100}
              dominantBaseline="middle"
              textAnchor="middle"
              x="50%"
              y="53%"
            >
              {percentage}%
            </Text>
          </g>
        </svg>
      </figure>
    </Wrapper>
  );
};

export default memo(CircleProgressBarBase);

import { toast } from 'react-toastify';
import { AuthErrors } from '@interfirst/utils';
import { SerializedError } from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';

import {
  API_URL,
  APPLY_API_CODE,
  APPLY_API_URL,
  AUTH_API_URL,
  DOCUMENTS_API_URL,
  LOANS_API_URL,
  SKYNET_API_URL,
} from 'constants/api';
import { IS_DEVELOPMENT, IS_PRODUCTION } from 'constants/common';
import { authClient, prepareHeaders } from 'utils/auth';
import { externalLogger } from 'utils/logger';

import { ServerError } from 'types/common';

export const getDomainName = () =>
  IS_DEVELOPMENT ? 'interfirst-realtor.dev-lender.com' : window.origin.split('//')[1];

const externalLogging: (query: BaseQuery) => BaseQuery =
  baseQuery => async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error && !result?.meta?.request?.signal?.aborted) {
      externalLogger.error('RequestError', JSON.stringify(result.error));
    }

    return result;
  };

export const realtorsQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders,
  }),
);

export const authQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: `${AUTH_API_URL}api/`,
  }),
);

const applyQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: APPLY_API_URL,
    prepareHeaders: headers => {
      prepareHeaders(headers);

      headers.append('x-functions-key', APPLY_API_CODE);
      return headers;
    },
  }),
);

const temporaryApplyQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: APPLY_API_URL,
    prepareHeaders: headers => {
      prepareHeaders(headers);

      headers.append('x-functions-key', APPLY_API_CODE);
      headers.append('domainname', getDomainName());
      return headers;
    },
  }),
);

const skynetQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: SKYNET_API_URL,
    prepareHeaders,
  }),
);

const loansQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: LOANS_API_URL,
    prepareHeaders,
  }),
);

const documentsQuery = externalLogging(
  fetchBaseQuery({
    baseUrl: DOCUMENTS_API_URL,
    prepareHeaders,
  }),
);

type BaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Record<string, unknown>,
  FetchBaseQueryMeta
>;

export const queryWithReauth: (query: BaseQuery) => BaseQuery =
  baseQuery => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    const toastOptions = IS_PRODUCTION
      ? { toastId: 'GETErrorToastId', autoClose: 10_000 }
      : {
          hideProgressBar: false,
          draggable: false,
          progressStyle: { background: 'grey' },
          closeOnClick: false,
          closeButton: true,
          autoClose: 10_000,
        };

    if (
      result.error &&
      result?.meta?.response?.status !== 401 &&
      result?.meta?.request?.method === 'GET' &&
      !result?.meta?.request?.signal?.aborted
    ) {
      const developmentError = !!result.error.data && (
        <>
          Status: {result.error.status} <br />
          ID: {(result?.error as ServerError)?.data?.error?.id} <br />
          Message: {getApiError(result.error) || '-'}
        </>
      );

      const errorMessage =
        IS_PRODUCTION || !developmentError ? 'Something went wrong on our end' : developmentError;

      toast(errorMessage, toastOptions);
    }

    // checks original response status as status in error object not always numeric status
    if (result.error && result.meta?.response?.status === 401) {
      const refreshResult = await authClient.refreshToken().catch((err: Error) => err);

      if (refreshResult instanceof Error) {
        const message =
          refreshResult instanceof AuthErrors.TokenExpiredError
            ? 'You are logged out as your authorization is expired'
            : 'Something went wrong on our end';
        toast(message, toastOptions);
      } else {
        result = await baseQuery(args, api, extraOptions);
      }
    }

    return result;
  };

export const baseQueryWithReauth: BaseQuery = queryWithReauth(realtorsQuery);
export const skynetQueryWithReauth: BaseQuery = queryWithReauth(skynetQuery);
export const applyQueryWithReauth: BaseQuery = queryWithReauth(applyQuery);
export const temporaryApplyQueryWithReauth: BaseQuery = queryWithReauth(temporaryApplyQuery);
export const loansQueryWithReauth: BaseQuery = queryWithReauth(loansQuery);
export const documentsQueryWithReauth: BaseQuery = queryWithReauth(documentsQuery);

export const getApiError = (error?: FetchBaseQueryError | SerializedError | ServerError) => {
  if (!error) {
    return null;
  }

  const isServerError = typeof error === 'object' && 'status' in error && 'data' in error;

  if (isServerError) {
    const isMultipleError = (error as ServerError).data?.errors;

    if (isMultipleError) {
      return (error as ServerError).data?.title;
    }

    // Errors from Apply API
    if (typeof (error as ServerError)?.data === 'string') {
      return (error as ServerError).data as unknown as string;
    }

    return (error as ServerError).data?.error?.message;
  }

  // Serialized error from RTK
  throw new Error(`Error in request function: ${JSON.stringify(error)}`);
};

import { FC, memo } from 'react';

import { FeatureFlags } from 'constants/featureToggles';
import { TaskDataType, TaskGroupType, TaskStatus } from 'enums/task';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { Task } from 'types/task';

import DocumentsTaskForm from './DocumentsTaskForm';
import FormDownload from './FormDownload';
import FreeTextTaskForm from './FreeTextTaskForm';
import SubmittedDocuments from './SubmittedDocuments';

export interface TaskHandlerProps {
  task: Task;
  isReadonly?: boolean;
}

interface TaskFormProps {
  task: Task;
  groupType: TaskGroupType;
}

const TaskForm: FC<TaskFormProps> = ({ task, groupType }) => {
  const isFeatureEnabled = useFeatureFlag(FeatureFlags.RealtorsEnableTaskCompletion);

  const isFormsTask = task.dataType === TaskDataType.Forms;
  const isSignatureRequired = isFormsTask && task.isSignatureRequired;
  const isOmitFormsDownloadButton =
    isFormsTask && !isSignatureRequired && task.status === TaskStatus.Approved && !task.documentIds;
  const isReadonly = groupType !== TaskGroupType.General;

  const renderTaskForm = (): JSX.Element | null => {
    if (!isFeatureEnabled || isSignatureRequired || isOmitFormsDownloadButton) {
      return null;
    }

    switch (task.dataType) {
      case TaskDataType.FreeText:
        return <FreeTextTaskForm isReadonly={isReadonly} task={task} />;

      case TaskDataType.Documents:
        return isReadonly ? <SubmittedDocuments task={task} /> : <DocumentsTaskForm task={task} />;

      case TaskDataType.Forms:
        return <FormDownload isReadonly={isReadonly} task={task} />;

      default:
        return null;
    }
  };

  return renderTaskForm();
};

export default memo(TaskForm);

import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Modal from 'components/Modal';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  & > :first-child {
    margin-right: 50px;
  }
`;

export const ConfirmationModalContainer = styled(Modal)`
  padding: 30px;
  text-align: center;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    width: 95%;
  }
`;

import { FC, ReactNode } from 'react';
import { css, styled } from 'styled-components';

import { MediaQueries, TypographyComponent } from 'enums/ui';

import Button from 'components/Button/Button';
import Modal from 'components/Modal';
import { Typography } from 'components/Typography';

export const ChangeProgramContainer = styled(Modal)<{ isFailure?: boolean }>`
  padding: 30px;
  min-width: 800px;
  min-height: 836px;
  max-height: 840px;

  @media (max-width: ${MediaQueries.Tablet}) {
    min-width: 100%;

    ${({ isFailure }) =>
      isFailure
        ? css`
            max-height: 180px;
          `
        : css`
            max-height: 850px;
          `}
  }

  @media (max-width: ${MediaQueries.Mobile}) {
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: initial;
    padding: 25px 10px;
  }

  // Make sure to update Programs with ProgramsSkeleton
  // case for win 14" 125% scale (120dpi)
  @media (max-height: 840px) and (min-width: ${MediaQueries.Mobile}) {
    min-height: 680px;
    max-height: 680px;
  }
  // case for win 14" 150% scale (144dpi)
  @media (max-height: 680px) and (min-width: ${MediaQueries.Mobile}) {
    min-height: 500px;
    max-height: 500px;
  }

  ${({ isFailure }) =>
    isFailure &&
    css`
      min-height: auto;
      max-height: 268px;
    `}
`;

const StyledTitle = styled(Typography)`
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: ${MediaQueries.Mobile}) {
    margin-bottom: 25px;
  }
`;

export const Title: FC<{ children?: ReactNode }> = ({ children }) => (
  <StyledTitle component={TypographyComponent.H5}>{children}</StyledTitle>
);

export const ChangeProgramButton = styled(Button)<{ isLoading?: boolean }>`
  width: 161.4px;
  height: 36px;
  margin: 15px 0 0 auto;

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}

  @media (max-width: ${MediaQueries.Mobile}) {
    width: 100%;
  }
`;

export const FailureText = styled(Typography)`
  margin: 30px 0;
  text-align: center;
`;

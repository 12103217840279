import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { KeyCode } from 'enums/keyboard';
import { MediaQueries } from 'enums/ui';
import { useClickOutside } from 'hooks/useClickOutside';

import { CloseIcon, Content, Overlay } from './Modal.styles';

interface ModalProps {
  children?: ReactNode;
  className?: string;
  // options
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOuterClick?: boolean;
  withCloseIcon?: boolean;
  fullScreenPoint?: MediaQueries;
}

const Modal: FC<ModalProps> = ({
  shouldCloseOnOuterClick = true,
  shouldCloseOnEsc = true,
  withCloseIcon = true,
  fullScreenPoint,
  className,
  children,
}) => {
  const modal = useModal();

  const ref = useClickOutside<HTMLDivElement>(modal.remove, shouldCloseOnOuterClick);

  const closeOnEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === KeyCode.Esc) {
        modal.remove();
      }
    },
    [modal],
  );

  useEffect(() => {
    if (shouldCloseOnEsc) {
      document.addEventListener('keydown', closeOnEsc);
      return () => {
        document.removeEventListener('keydown', closeOnEsc);
      };
    }
  }, [shouldCloseOnEsc, closeOnEsc]);

  return (
    <Overlay>
      <Content className={className} fullScreenPoint={fullScreenPoint} ref={ref}>
        {withCloseIcon && <CloseIcon onClick={modal.remove} />}
        {children}
      </Content>
    </Overlay>
  );
};

export default Modal;

import invert from 'lodash/invert';

import { State } from 'enums/states';

import { StatesOptions } from 'types/states';

export const STATES_MAP: StatesOptions = {
  Alabama: State.AL,
  Alaska: State.AK,
  Arizona: State.AZ,
  Arkansas: State.AR,
  California: State.CA,
  Colorado: State.CO,
  Connecticut: State.CT,
  Delaware: State.DE,
  'District of Columbia': State.DC,
  Florida: State.FL,
  Georgia: State.GA,
  Hawaii: State.HI,
  Idaho: State.ID,
  Illinois: State.IL,
  Indiana: State.IN,
  Iowa: State.IA,
  Kansas: State.KS,
  Kentucky: State.KY,
  Louisiana: State.LA,
  Maine: State.ME,
  Maryland: State.MD,
  Massachusetts: State.MA,
  Michigan: State.MI,
  Minnesota: State.MN,
  Mississippi: State.MS,
  Missouri: State.MO,
  Montana: State.MT,
  Nebraska: State.NE,
  Nevada: State.NV,
  'New Hampshire': State.NH,
  'New Jersey': State.NJ,
  'New Mexico': State.NM,
  'New York': State.NY,
  'North Carolina': State.NC,
  'North Dakota': State.ND,
  Ohio: State.OH,
  Oklahoma: State.OK,
  Oregon: State.OR,
  Pennsylvania: State.PA,
  'Rhode Island': State.RI,
  'South Carolina': State.SC,
  'South Dakota': State.SD,
  Tennessee: State.TN,
  Texas: State.TX,
  Utah: State.UT,
  Vermont: State.VT,
  Virginia: State.VA,
  Washington: State.WA,
  'West Virginia': State.WV,
  Wisconsin: State.WI,
  Wyoming: State.WY,
};

// invertes { key: value } pairs to { value: key }
export const STATE_ABBR_TO_NAME_MAP = invert(STATES_MAP);

export const STATES_MAP_WITH_MILITARY_STATES: StatesOptions = {
  ...STATES_MAP,
  'Armed Forces Europe, Africa, the Middle East, and Canada': State.AE,
  'Armed Forces Pacific': State.AP,
  'Armed Forces Americas (except Canada)': State.AA,
};

import { FC, memo, ReactNode } from 'react';

import { RecommendedIndicator } from './RecommendedMark.styles';

interface RecommendedMarkProps {
  className?: string;
  isChangeProgram?: boolean;
  children?: ReactNode;
}

const RecommendedMark: FC<RecommendedMarkProps> = ({ className, children, isChangeProgram }) => (
  <RecommendedIndicator aria-hidden="true" className={className}>
    {children || isChangeProgram ? 'Current Program' : 'Recommended'}
  </RecommendedIndicator>
);

export default memo(RecommendedMark);

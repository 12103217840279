import { ChangeEventHandler, FC, InputHTMLAttributes, memo } from 'react';

import { CheckboxInput, Label } from './Checkbox.styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  name: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const Checkbox: FC<CheckboxProps> = ({ className, name, label, onChange, checked }) => {
  return (
    <Label className={className} htmlFor={name}>
      <CheckboxInput checked={checked} id={name} name={label} type="checkbox" onChange={onChange} />
      {label}
    </Label>
  );
};

export default memo(Checkbox);

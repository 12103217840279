import { baseAPI } from 'services/baseAPI';

import { CreateNotePayload, DeleteNotePayload, Note } from './types';

const notesAPI = baseAPI.injectEndpoints({
  endpoints: build => ({
    getNotes: build.query<Note[], { applicationId?: string }>({
      query: ({ applicationId }) => `api/loanApplications/${applicationId}/notes`,
      transformResponse: (response: Note[]) =>
        response.sort((a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)),
    }),
    createNote: build.mutation<Note, CreateNotePayload>({
      query: ({ applicationId, note, loanId }) => ({
        url: `api/loanApplications/${applicationId}/notes`,
        method: 'POST',
        body: { loanId, content: note },
      }),
      async onQueryStarted({ applicationId }, { dispatch, queryFulfilled }) {
        await queryFulfilled.then(({ data }) => {
          // pessimistic update
          dispatch(
            notesAPI.util.updateQueryData('getNotes', { applicationId }, draft => {
              draft.unshift(data);
            }),
          );
        });
      },
    }),
    deleteNote: build.mutation<void, DeleteNotePayload>({
      query: ({ noteId, applicationId }) => ({
        url: `api/loanApplications/${applicationId}/notes/${noteId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ applicationId, noteId }, { dispatch, queryFulfilled }) {
        await queryFulfilled.then(() => {
          // pessimistic update
          dispatch(
            notesAPI.util.updateQueryData('getNotes', { applicationId }, draft => {
              return draft.filter(note => note.noteId !== noteId);
            }),
          );
        });
      },
    }),
  }),
});

export const { useGetNotesQuery, useCreateNoteMutation, useDeleteNoteMutation } = notesAPI;

export default notesAPI;

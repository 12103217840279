import { keyframes, styled } from 'styled-components';

import { CircleSpinnerProps } from './CircleSpinner';

const rotate = keyframes`
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Wrapper = styled.div<CircleSpinnerProps>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  border: ${({ size, color, theme }) =>
    `${Math.floor(size / 7)}px solid ${color || theme.colors.white}`};
  border-radius: 50%;
  border-right-color: transparent;

  animation: ${rotate} 0.75s linear infinite;
`;

import { FC, Fragment, memo, MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { isAfter } from 'date-fns';
import { useTheme } from 'styled-components';

import { ADDRESS_PLUG, LAST_UPDATED_PLUG, TASKS_NUMBER_PLUG } from 'constants/ui';
import { AccessStatus } from 'enums/application';
import { TaskStatus } from 'enums/task';
import { TypographyComponent, TypographyType } from 'enums/ui';
import { formatPhone, getFormattedAddress, getFormattedDate } from 'utils/formatting';

import { useRequestAccessMutation } from 'services/baseAPI';
import { useDownloadDocumentMutation } from 'services/documentsAPI';
import { selectTasksByApplicationIds } from 'store/selectors/tasks';
import { LoanApplication } from 'types/application';

import ExpirationTimeSVG from 'components/@icons/ExpirationTimeSVG';
import LocationSVG from 'components/@icons/LocationSVG';
import MailSVG from 'components/@icons/MailSVG';
import PersonFilledSVG from 'components/@icons/PersonFilledSVG';
import PhoneSVG from 'components/@icons/PhoneSVG';
import TasksSVG from 'components/@icons/TasksSVG';
import TimelapseSVG from 'components/@icons/TimelapseSVG';
import ChangeProgramModal from 'components/@modals/ChangeProgramModal';
import Chip from 'components/Chip';
import ExpirationRateDate from 'components/ExpirationRateDate';
import { TasksNumber, TasksNumberSkeleton } from 'pages/ApplicationDetails/components/Tasks';

import {
  ActionCardText,
  Address,
  ApplicationCardContainer,
  ApplicationCardRow,
  ApplicationCardWrapper,
  ChangeProgram,
  ContainerWithLabel,
  Email,
  FullName,
  Label,
  LeftBlockApplicationCardRow,
  RequestAccessButton,
  Text,
} from './ApplicationCard.styles';

interface Props extends LoanApplication {
  className?: string;
  $isClickable?: boolean;
  isRealtorLO?: boolean;
  applicationIdsOnPage?: string[];
}

const blockTestName = 'loanCard';

const ApplicationCard: FC<Props> = ({
  className,
  lastUpdated,
  lockDate,
  lockExpirationDate,
  subjectProperty: { address, propertyValue },
  $isClickable = true,
  isRealtorLO,
  loanStatus,
  accessStatus,
  preApprovals,
  id: loanId,
  programId,
  applicationId,
  primaryBorrower: { fullName, email, phone },
  loanProcessingStopReason,
  loanPurpose,
  loanAmount,
  loanPeriod,
  interestRate,
  programPoints,
  applicationIdsOnPage,
  progress,
}) => {
  const navigate = useNavigate();
  const isAppDetails = !!useMatch('pipeline/:id');
  const tasks = useSelector(selectTasksByApplicationIds(applicationIdsOnPage));

  const activeTasksNumber = useMemo(
    () => tasks?.[applicationId]?.filter(task => task.status === TaskStatus.New).length || 0,
    [applicationId, tasks],
  );

  const [downloadPreApproval, { isLoading }] = useDownloadDocumentMutation();

  const [requestAccess, { isLoading: isRequesting }] = useRequestAccessMutation();

  const {
    colors: {
      card: { icon: iconColor },
    },
  } = useTheme();

  const { documentId, createdDate } = preApprovals[0] || {};
  const isAnonymous = accessStatus !== AccessStatus.Approved;
  const isDownloadAvailable = !isAnonymous && !isAppDetails && !!documentId;
  // TODO: should be changed later to AccessStatus.None to support Requested state
  const isRequestButtonShown = isAnonymous && isAppDetails;
  // lockDate exists and lockDate <= currentDate
  const isValidLockDate = !!lockDate && !isAfter(Date.parse(lockDate), Date.now());
  const isChangeProgramShown =
    isAppDetails && !isRequestButtonShown && isRealtorLO && !!programId && isValidLockDate;

  const progressLastCompletedStateType = progress?.lastCompleted?.name;
  const isStatusEnabled = !!progressLastCompletedStateType || isAnonymous;

  const redirectToDetails = useCallback(() => {
    if ($isClickable) {
      navigate(`../pipeline/${applicationId}`);
    }
  }, [applicationId, $isClickable, navigate]);

  const onDownload = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation(); // prevent redirection

      if (isLoading || !documentId) {
        return;
      }

      downloadPreApproval({ documentId, fullName, createdDate });
    },
    [isLoading, downloadPreApproval, documentId, fullName, createdDate],
  );

  const onRequestAccess = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation(); // prevent redirection

      if (isRequesting) {
        return;
      }

      requestAccess({ applicationId });
    },
    [isRequesting, requestAccess, applicationId],
  );

  const onOpenChangeProgramModal = useCallback(() => {
    NiceModal.show(ChangeProgramModal, {
      applicationId,
      loanId,
      loanPurpose,
      loanAmount,
      purchasePrice: propertyValue,
      programId,
      loanPeriod,
      programPoints,
      interestRate,
    });
  }, [
    applicationId,
    loanAmount,
    loanId,
    loanPeriod,
    loanPurpose,
    programPoints,
    programId,
    propertyValue,
    interestRate,
  ]);

  const Wrapper = isRequestButtonShown ? ApplicationCardWrapper : Fragment;

  return (
    <Wrapper>
      <ApplicationCardContainer
        $$isClickable={$isClickable}
        $isAppDetails={isAppDetails}
        className={className}
        data-testid={blockTestName}
        onClick={redirectToDetails}
      >
        <LeftBlockApplicationCardRow $isFullWidth $isShort title={fullName}>
          <PersonFilledSVG color={iconColor} />
          <FullName component={TypographyComponent.H6} data-testid={`${blockTestName}__fullname`}>
            {fullName}
          </FullName>
        </LeftBlockApplicationCardRow>

        {isAppDetails && (
          <>
            <LeftBlockApplicationCardRow $isFullWidth $isShort title={email}>
              <MailSVG color={iconColor} />
              <Email data-testid={`${blockTestName}__email`} title={email}>
                {email}
              </Email>
            </LeftBlockApplicationCardRow>

            <LeftBlockApplicationCardRow $isFullWidth $isShort>
              <PhoneSVG color={iconColor} />
              <Text $isBlurred={false} data-testid={`${blockTestName}__phone`}>
                {formatPhone(phone)}
              </Text>
            </LeftBlockApplicationCardRow>
          </>
        )}

        {isStatusEnabled && (
          <ApplicationCardRow $isShort>
            <Chip
              data-testid={`${blockTestName}__loan-status`}
              progressLastCompletedStateType={progressLastCompletedStateType}
              status={loanProcessingStopReason || loanStatus}
            />

            {isAnonymous && !isAppDetails && (
              <ActionCardText
                $alignCenter
                $isLoading={isRequesting}
                component={TypographyComponent.H7}
                data-testid={`${blockTestName}__request-access`}
                type={TypographyType.Secondary}
                onClick={onRequestAccess}
              >
                Request Access
              </ActionCardText>
            )}
          </ApplicationCardRow>
        )}

        <LeftBlockApplicationCardRow
          $isFullWidth
          {...(!isAnonymous && { title: getFormattedAddress(address) })}
        >
          <LocationSVG color={iconColor} />
          <Address $isBlurred={isAnonymous} data-testid={`${blockTestName}__address`}>
            {isAnonymous ? ADDRESS_PLUG : getFormattedAddress(address)}
          </Address>
        </LeftBlockApplicationCardRow>

        {!isAppDetails && (
          <LeftBlockApplicationCardRow $isFullWidth $isShort>
            <TasksSVG color={iconColor} />
            <Label>Active Tasks</Label>
            {tasks ? (
              <TasksNumber
                $isBlurred={isAnonymous}
                data-testid={`${blockTestName}__number-of-active-tasks`}
              >
                {isAnonymous ? TASKS_NUMBER_PLUG : activeTasksNumber}
              </TasksNumber>
            ) : (
              <TasksNumberSkeleton />
            )}
          </LeftBlockApplicationCardRow>
        )}

        <ApplicationCardRow>
          <LeftBlockApplicationCardRow>
            <TimelapseSVG color={iconColor} />
            <ContainerWithLabel>
              <Label>Last Upd</Label>
              <Text $isBlurred={isAnonymous} data-testid={`${blockTestName}__last-updated-date`}>
                {isAnonymous ? LAST_UPDATED_PLUG : getFormattedDate(lastUpdated)}
              </Text>
            </ContainerWithLabel>
          </LeftBlockApplicationCardRow>

          {/*TODO: will be implemented after 20030*/}
          {/*{isProvidedByOportunities && (*/}
          {/*  <ProvidedByOportunitiesTag>*/}
          {/*    Provided by*/}
          {/*    <br /> Oportunities*/}
          {/*  </ProvidedByOportunitiesTag>*/}
          {/*)}*/}
        </ApplicationCardRow>

        <ApplicationCardRow>
          <LeftBlockApplicationCardRow>
            <ExpirationTimeSVG color={iconColor} />
            <ContainerWithLabel>
              <Label>Lock Exp</Label>
              <Text $isBlurred={isAnonymous} data-testid={`${blockTestName}__lock-expiration-date`}>
                <ExpirationRateDate lockExpirationDate={lockExpirationDate} />
              </Text>
            </ContainerWithLabel>
          </LeftBlockApplicationCardRow>

          {isDownloadAvailable && (
            <ActionCardText
              $isLoading={isLoading}
              component={TypographyComponent.H7}
              data-testid={`${blockTestName}__download-pre-approval`}
              type={TypographyType.Secondary}
              onClick={onDownload}
            >
              Pre-Approval
            </ActionCardText>
          )}
        </ApplicationCardRow>
        {isChangeProgramShown && (
          <ChangeProgram onClick={onOpenChangeProgramModal}>Change Program</ChangeProgram>
        )}
      </ApplicationCardContainer>
      {isRequestButtonShown && ( // TODO: Move RequestAccessButton to Details Page
        <RequestAccessButton
          data-testid={`${blockTestName}__request-access-to-personal-info`}
          disabled={isRequesting}
          onClick={onRequestAccess}
        >
          Request Access to Personal Info
        </RequestAccessButton>
      )}
    </Wrapper>
  );
};

export default memo(ApplicationCard);

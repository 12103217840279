import { FC } from 'react';
import isEmpty from 'lodash/isEmpty';

import { NO_APPLICATIONS_MSG } from 'constants/application';
import { TypographyComponent } from 'enums/ui';
import usePagedApplications from 'hooks/usePagedApplications';
import { getEmptyArray } from 'utils/array';

import CustomerCard, { ApplicationCardSkeleton } from 'components/ApplicationCard';
import Pagination, { PaginationSkeleton } from 'components/Pagination';
import { Typography } from 'components/Typography';

import { CardsContainer, EmptyStateContainer, PaginationWrapper } from './ApplicationCards.styles';

const PAGE_SIZE = 4;

const ApplicationCards: FC = () => {
  const {
    applications,
    applicationIdsOnPage,
    isError,
    isFetchingNewQuery,
    setPage,
    maxPage,
    currentPage,
  } = usePagedApplications({ pageSize: PAGE_SIZE, isDashboard: true });

  if (isFetchingNewQuery || !applications || isError) {
    return (
      <PaginationWrapper>
        <CardsContainer>
          {getEmptyArray(PAGE_SIZE).map((_, index) => (
            <ApplicationCardSkeleton isError={isError} key={index} />
          ))}
        </CardsContainer>
        {maxPage ? (
          <Pagination currentPage={currentPage} maxPage={maxPage} setPage={setPage} />
        ) : (
          <PaginationSkeleton isError={isError} />
        )}
      </PaginationWrapper>
    );
  }

  if (isEmpty(applications)) {
    return (
      <EmptyStateContainer>
        <Typography component={TypographyComponent.H5}>{NO_APPLICATIONS_MSG}</Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <PaginationWrapper>
      <CardsContainer>
        {applications?.map((props, index) =>
          typeof props === 'string' ? (
            <ApplicationCardSkeleton isPlug key={index} />
          ) : (
            <CustomerCard key={props.id} {...props} applicationIdsOnPage={applicationIdsOnPage} />
          ),
        )}
      </CardsContainer>
      <Pagination currentPage={currentPage} maxPage={maxPage} setPage={setPage} />
    </PaginationWrapper>
  );
};

export default ApplicationCards;

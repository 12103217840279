import { styled } from 'styled-components';

export const LabelText = styled.label<{ isValueExist?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
  cursor: text;

  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.controls.label};
`;

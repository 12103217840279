import maxBy from 'lodash/maxBy';

import { DocumentTypeWithDocuments } from 'services/documentsAPI/types';
import { Document, DocumentSubCategoryType } from 'services/skynetAPI/types';
import { PreApproval, PreApprovalFromServer } from 'types/preApproval';

export const findLastCreatedDate = (
  types: (Document | DocumentSubCategoryType | DocumentTypeWithDocuments)[],
) => maxBy(types, ({ createdDate }) => (createdDate ? new Date(createdDate).getTime() : undefined));

export const preApprovalMapper = (preApproval: PreApprovalFromServer): PreApproval => ({
  id: preApproval.id,
  documentId: preApproval.documentId,
  applicationId: preApproval.loanApplicationId.applicationId,
  propertyValue: +preApproval.snapshotForPreApproval.propertyValue,
  createdDate: preApproval.createdDate,
  address: {
    city: preApproval?.address?.address?.City,
    country: preApproval?.address?.address?.Country?.name,
    county: preApproval?.address?.address?.County,
    state: preApproval?.address?.address?.Country?.state?.abbreviation,
    street: preApproval?.address?.address?.Street,
    unit: preApproval?.address?.address?.Unit,
    zip: preApproval?.address?.address?.Country?.zip,
  },
  createdByUserId: preApproval.createdByUserId,
  downPayment: +preApproval.snapshotForPreApproval.downPaymentValue,
  propertyType: preApproval.snapshotForPreApproval.propertyType.type,
  numberOfUnits: preApproval.snapshotForPreApproval.numberOfUnits as number,
  snapshotForPreApproval: preApproval.snapshotForPreApproval,
});

import { FC, SVGProps } from 'react';

const PersonFilledSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="15" viewBox="0 0 11 15" width="11" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.03344 14.5C1.88367 14.4658 1.73164 14.4428 1.58445 14.3973C0.645476 14.1178 0.0499409 13.3486 0.00862465 12.3013C-0.0343055 11.2191 0.0777003 10.153 0.46504 9.13965C0.715198 8.48587 1.07833 7.91976 1.68774 7.57455C2.0559 7.36359 2.46955 7.25817 2.88785 7.26873C3.02084 7.27181 3.1606 7.34304 3.28197 7.41291C3.47758 7.52592 3.65898 7.66599 3.84878 7.78963C4.95248 8.51247 6.05597 8.51155 7.15925 7.78689C7.31224 7.6862 7.46879 7.58928 7.61114 7.47489C7.87518 7.26496 8.16568 7.23859 8.47911 7.28756C9.33513 7.42113 9.93938 7.91839 10.3471 8.7119C10.7121 9.42116 10.8777 10.1921 10.9487 10.9832C10.9866 11.3909 11.0035 11.8005 10.9994 12.2102C10.9855 13.4315 10.1953 14.3404 9.05043 14.4743C9.02194 14.4804 8.99398 14.489 8.96683 14.5H2.03344Z"
        fill={color}
      />
      <path
        d="M8.59884 3.86658C8.61079 5.71113 7.17569 7.24163 5.42717 7.24677C3.67866 7.25191 2.23969 5.72346 2.23872 3.88371C2.23632 2.9898 2.56835 2.13142 3.16192 1.49703C3.75548 0.862635 4.56207 0.504082 5.40458 0.500093C7.16246 0.486394 8.58819 1.98881 8.59884 3.86658Z"
        fill={color}
      />
    </svg>
  );
};

export default PersonFilledSVG;

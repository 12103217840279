import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { ANONYMOUS_STATUS } from 'constants/application';
import { ApplicationsViewType } from 'enums/application';

import { useLazyGetMilestoneDefinitionQuery } from 'services/loansAPI';

import ApplicationCards from './components/ApplicationCards';
import ApplicationsTable from './components/ApplicationsTable';
import ViewTypeSwitcher from './components/ViewTypeSwitcher';
import PageHeader from 'components/PageHeader';

import { useApplicationsViewTypeHandler } from './hooks/useApplicationsViewTypeHandler';
import { FiltersContainer, PipelineContainer, StatusFilter } from './Pipeline.styles';

const LOANS_VIEW_TYPE_ID = 'LOANS_VIEW_TYPE_ID';

const Pipeline: FC = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [applicationsViewType, _setViewType] = useState<ApplicationsViewType>(
    (localStorage.getItem(LOANS_VIEW_TYPE_ID) as ApplicationsViewType) ||
      ApplicationsViewType.Cards,
  );

  const [getMilestoneDefinition, { data, isFetching }] = useLazyGetMilestoneDefinitionQuery();

  useEffect(() => {
    getMilestoneDefinition({});
  }, [getMilestoneDefinition]);

  const statusFilterOptions = useMemo(() => {
    const permanentMilestones = get(data, 'permanentMilestones', []);
    const sequenceMilestones = get(data, 'sequenceMilestones', []);

    const options = [...permanentMilestones, ...sequenceMilestones].map(({ name }) => ({
      label: name,
      value: name,
    }));

    return [
      { label: 'All', value: null },
      ...sortBy([{ label: ANONYMOUS_STATUS, value: ANONYMOUS_STATUS }, ...options], 'label'),
    ];
  }, [data]);

  const setViewType = useCallback((viewType: ApplicationsViewType) => {
    _setViewType(viewType);
    localStorage.setItem(LOANS_VIEW_TYPE_ID, viewType);
  }, []);

  const isDisplayViewTypeSwitcher = useApplicationsViewTypeHandler({
    applicationsViewType,
    setViewType,
  });

  return (
    <PipelineContainer $isTableViewType={applicationsViewType === ApplicationsViewType.Table}>
      <PageHeader subtitle="Here You Can Price a Loan for Your Client" title="Pipeline" />

      <FiltersContainer>
        <StatusFilter
          disabled={isFetching}
          label="Filter by Status"
          name="status"
          options={statusFilterOptions}
          placeholder="Choose Filter"
          value={status}
          onChange={setStatus}
        />

        {isDisplayViewTypeSwitcher && (
          <ViewTypeSwitcher viewType={applicationsViewType} onChange={setViewType} />
        )}
      </FiltersContainer>

      {applicationsViewType === ApplicationsViewType.Cards && <ApplicationCards status={status} />}

      {applicationsViewType === ApplicationsViewType.Table && <ApplicationsTable status={status} />}
    </PipelineContainer>
  );
};

export default Pipeline;

import { emailRegExp, getEmailField } from '@interfirst/utils';
import { object } from 'yup';

import { getNameField } from 'utils/validation';

const schema = object().shape({
  firstName: getNameField(),
  lastName: getNameField(),
  emailAddress: getEmailField({ isRequired: true, emailValidationRegExp: emailRegExp }),
});

export default schema;

import { FC, memo } from 'react';

import { Counter, CounterSkeleton } from './ConditionsTabTitle.styles';

interface ConditionsTabTitleProps {
  count?: number;
  isLoading: boolean;
}

const ConditionsTabTitle: FC<ConditionsTabTitleProps> = ({ count = 0, isLoading }) => {
  return (
    <>
      Conditions&nbsp;
      {isLoading ? <CounterSkeleton /> : <Counter>{count}</Counter>}
    </>
  );
};

export default memo(ConditionsTabTitle);

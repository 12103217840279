import { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';

import { TypographyComponent, TypographyWeight } from 'enums/ui';
import { getRealtorId } from 'utils/id';

import { useDeleteRealtorFromApplicationMutation } from 'services/baseAPI';

import ConfirmationModal from 'components/@modals/ConfirmationModal';

import { NoLongerWorkingTogetherContainer, Text } from './NoLongerWorkingTogether.styles';

const NoLongerWorkingTogether: FC<{ applicationId: string }> = ({ applicationId }) => {
  const navigate = useNavigate();
  const [deleteRealtor] = useDeleteRealtorFromApplicationMutation();

  const confirmationModal = useModal(ConfirmationModal);

  const realtorId = getRealtorId();

  const onDelete = useCallback(async () => {
    await deleteRealtor({ realtorId, navigate, applicationId });
    confirmationModal.remove();
  }, [applicationId, confirmationModal, deleteRealtor, navigate, realtorId]);

  const onClick = useCallback(() => {
    confirmationModal.show({
      onSubmit: onDelete,
      title: 'Are you sure you want to remove this client?',
      isAsync: true,
    });
  }, [confirmationModal, onDelete]);

  return (
    <NoLongerWorkingTogetherContainer
      data-testid="loanInfo_noLongerWorkingButton"
      onClick={onClick}
    >
      <Text component={TypographyComponent.H8} weight={TypographyWeight.Regular}>
        No Longer Working Together
      </Text>
    </NoLongerWorkingTogetherContainer>
  );
};

export default memo(NoLongerWorkingTogether);

import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AccessStatus } from 'enums/application';
import { TaskStatus } from 'enums/task';

import { selectTasksByApplicationIds } from 'store/selectors/tasks';
import { LoanApplication } from 'types/application';

import { TasksNumber, TasksNumberSkeleton } from './TasksTabTitle.styles';

interface TasksProps {
  application?: LoanApplication;
  isLoading: boolean;
  isLoadingTasks: boolean;
}

const TasksTabTitle: FC<TasksProps> = ({ application, isLoading, isLoadingTasks }) => {
  const tasks = useSelector(selectTasksByApplicationIds([application?.applicationId as string]));

  const tasksNumber = useMemo(
    () =>
      tasks?.[application?.applicationId as string]?.filter(
        ({ status }) => status === TaskStatus.New,
      )?.length || 0,
    [application?.applicationId, tasks],
  );
  const withAccess = application?.accessStatus === AccessStatus.Approved;

  return (
    <>
      Tasks&nbsp;
      {isLoading || isLoadingTasks ? (
        <TasksNumberSkeleton />
      ) : (
        withAccess && <TasksNumber>{tasksNumber}</TasksNumber>
      )}
    </>
  );
};

export default memo(TasksTabTitle);

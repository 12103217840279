import { configureStore } from '@reduxjs/toolkit';

import { IS_PRODUCTION } from 'constants/common';
import { signOutMiddleware } from 'utils/middlewares';

import applyAPI from 'services/applyAPI';
import authAPI from 'services/authAPI';
import { baseAPI, unauthorizedBaseAPI } from 'services/baseAPI';
import documentsAPI from 'services/documentsAPI';
import loansAPI from 'services/loansAPI';
import skynetAPI from 'services/skynetAPI';
import temporaryApplyAPI from 'services/temporaryApplyAPI';
import { AuthReducer } from 'store/slices/auth';
import { UIReducer } from 'store/slices/ui';

const rootReducer = {
  [baseAPI.reducerPath]: baseAPI.reducer,
  [unauthorizedBaseAPI.reducerPath]: unauthorizedBaseAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [applyAPI.reducerPath]: applyAPI.reducer,
  [temporaryApplyAPI.reducerPath]: temporaryApplyAPI.reducer,
  [skynetAPI.reducerPath]: skynetAPI.reducer,
  [loansAPI.reducerPath]: loansAPI.reducer,
  [documentsAPI.reducerPath]: documentsAPI.reducer,

  authSlice: AuthReducer,
  ui: UIReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: !IS_PRODUCTION,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      signOutMiddleware.middleware,
      baseAPI.middleware,
      unauthorizedBaseAPI.middleware,
      authAPI.middleware,
      applyAPI.middleware,
      temporaryApplyAPI.middleware,
      skynetAPI.middleware,
      loansAPI.middleware,
      documentsAPI.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;

export type Store = typeof store;

export type AppDispatch = typeof store.dispatch;

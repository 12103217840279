import { createApi } from '@reduxjs/toolkit/query/react';

import { EditPreApprovalResponseStatus } from 'enums/preApproval';
import { applyQueryWithReauth } from 'utils/api';

import {
  CheckStateLicensePayload,
  EditPreApprovalPayload,
  GetLoanOptionsPayload,
  GetLoanOptionsResponse,
  GetPotentialLoanOptionsPayload,
  GetPotentialLoanOptionsResponse,
  SubmitPurchaseAgreementPayload,
  SubmitPurchaseAgreementResponse,
} from './types';

const applyAPI = createApi({
  reducerPath: 'applyAPI',
  baseQuery: applyQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getLoanOptions: build.mutation<GetLoanOptionsResponse, GetLoanOptionsPayload>({
      query: body => ({
        url: `pricing/preview/v2`,
        method: 'POST',
        body,
      }),
    }),
    getPotentialLoanOptions: build.mutation<
      GetPotentialLoanOptionsResponse,
      GetPotentialLoanOptionsPayload
    >({
      query: ({ applicationId, ...body }) => ({
        url: `applications/${applicationId}/potential-final-rates/v2`,
        method: 'POST',
        body,
      }),
    }),
    submitPurchaseAgreement: build.mutation<
      SubmitPurchaseAgreementResponse,
      SubmitPurchaseAgreementPayload
    >({
      query: ({ applicationId, taskId, fd }) => ({
        url: `applications/${applicationId}/purchase-agreement-tasks/${taskId}/v1`,
        method: 'PATCH',
        body: fd,
      }),
    }),
    editPreApproval: build.mutation<EditPreApprovalResponseStatus, EditPreApprovalPayload>({
      query: ({ applicationId, loanOptionId, downPaymentValue, ...body }) => ({
        url: `applications/${applicationId}/pre-approval-details/v3`,
        method: 'POST',
        body: {
          ...body,
          loanOptionId: loanOptionId || null,
          ...(downPaymentValue && { downPaymentValue }),
        },
      }),
    }),
    checkStateLicense: build.query<boolean, CheckStateLicensePayload>({
      query: ({ state }) => `state-license-exists/${state}/v1`,
    }),
  }),
});

export const {
  useGetLoanOptionsMutation,
  useEditPreApprovalMutation,
  useLazyCheckStateLicenseQuery,
  useGetPotentialLoanOptionsMutation,
  useSubmitPurchaseAgreementMutation,
} = applyAPI;

export default applyAPI;

import {
  ApplciationInclude,
  ApplicationStatus,
  CreditScore,
  IsCashOut,
  LoanPurpose,
  LockTerm,
  Occupancy,
  ProcessingStopReason,
  PropertyType,
} from 'enums/application';

export const ANONYMOUS_STATUS = 'Anonymous';

export const NO_APPLICATIONS_MSG = 'There are currently no clients/applications assigned to you.';

export const normalFlowApplicationStatuses: ApplicationStatus[] = [
  ApplicationStatus.Initiated,
  ApplicationStatus.Submitted,
  ApplicationStatus.ReadyToSign,
  ApplicationStatus.Signing,
  ApplicationStatus.Signed,
  ApplicationStatus.ApplicationInUnderwriting,
  ApplicationStatus.Approved,
  ApplicationStatus.ConfirmingScheduling,
  ApplicationStatus.ClosingScheduled,
  ApplicationStatus.Closing,
  ApplicationStatus.Funded,
];

export const tbdFlowApplicationStatuses: ApplicationStatus[] = [
  ApplicationStatus.Initiated,
  ApplicationStatus.Submitted,
  ApplicationStatus.PreApproved,
  ApplicationStatus.ProvidePropertyAddressToLoanOfficer,
  ApplicationStatus.ReadyToSign,
  ApplicationStatus.Signing,
  ApplicationStatus.Signed,
  ApplicationStatus.ApplicationInUnderwriting,
  ApplicationStatus.Approved,
  ApplicationStatus.ConfirmingScheduling,
  ApplicationStatus.ClosingScheduled,
  ApplicationStatus.Closing,
  ApplicationStatus.Funded,
];

// These are long (file progress) and short (status label) titles. Maybe they can be unified in future
export const applicationStatusToTitleMap = {
  [ApplicationStatus.Initiated]: 'Application Initiated',
  [ApplicationStatus.Submitted]: 'Application Submitted',
  [ApplicationStatus.PreApproved]: 'Pre-Approved',
  [ApplicationStatus.ProvidePropertyAddressToLoanOfficer]:
    'Provide Property Address To Loan Officer',
  [ApplicationStatus.ReadyToSign]: 'Documents Ready to Sign',
  [ApplicationStatus.Signing]: 'Signing in Progress',
  [ApplicationStatus.Signed]: 'Documents Signed',
  [ApplicationStatus.ApplicationInUnderwriting]: 'Application in Underwriting',
  [ApplicationStatus.Approved]: 'Approved',
  [ApplicationStatus.ConfirmingScheduling]: 'Confirming Scheduling',
  [ApplicationStatus.ClosingScheduled]: 'Closing Scheduled',
  [ApplicationStatus.Closing]: 'Closing',
  [ApplicationStatus.Funded]: 'Funded',

  [ProcessingStopReason.Cancelled]: 'Application Cancelled',
  [ProcessingStopReason.Denied]: ' Application Denied',
  [ProcessingStopReason.Withdrawn]: 'Application Withdrawn',

  [ANONYMOUS_STATUS]: ANONYMOUS_STATUS,
};

export const LOCK_TERM_TO_LABEL_MAP = {
  [LockTerm.Yes]: 'Yes',
  [LockTerm.No]: 'No',
};

export const IS_CASH_OUT_TO_LABEL_MAP = {
  [IsCashOut.Yes]: 'Yes',
  [IsCashOut.No]: 'No',
};

export const OCCUPANCY_TYPE_TO_LABEL_MAP = {
  [Occupancy.PrimaryResidence]: 'Primary Residence',
  [Occupancy.SecondHome]: 'Second Home',
  [Occupancy.InvestmentProperty]: 'Investment Property',
};

export const PROPERTY_TYPE_TO_LABEL_MAP = {
  [PropertyType.SingleFamily]: 'Single Family',
  [PropertyType.Condo]: 'Condo',
  [PropertyType.Townhouse]: 'Townhouse',
  [PropertyType.Coop]: 'Co-op',
  [PropertyType.MultiUnit]: 'Multi Unit (2-4)',
  [PropertyType.ManufacturedHome]: 'Manufactured Home',
  [PropertyType.Land]: 'Land',
};

export const PROPERTY_TYPE_TO_LABEL_MAP_FOR_PDF = Object.entries(PROPERTY_TYPE_TO_LABEL_MAP).reduce(
  (acc, [type, label]) => {
    if (type === PropertyType.SingleFamily) {
      acc[type] = `${label.toLowerCase()} house`;
    } else {
      acc[type as PropertyType] = label.toLowerCase();
    }
    return acc;
  },
  {} as typeof PROPERTY_TYPE_TO_LABEL_MAP,
);

export const PROPERTY_TYPE_OPTIONS = [
  {
    label: PROPERTY_TYPE_TO_LABEL_MAP[PropertyType.SingleFamily],
    value: PropertyType.SingleFamily,
  },
  {
    label: PROPERTY_TYPE_TO_LABEL_MAP[PropertyType.Condo],
    value: PropertyType.Condo,
  },
  {
    label: PROPERTY_TYPE_TO_LABEL_MAP[PropertyType.Townhouse],
    value: PropertyType.Townhouse,
  },
  {
    label: PROPERTY_TYPE_TO_LABEL_MAP[PropertyType.Coop],
    value: PropertyType.Coop,
  },
  {
    label: PROPERTY_TYPE_TO_LABEL_MAP[PropertyType.MultiUnit],
    value: PropertyType.MultiUnit,
  },
  {
    label: PROPERTY_TYPE_TO_LABEL_MAP[PropertyType.ManufacturedHome],
    value: PropertyType.ManufacturedHome,
  },
];

export const LOAN_PURPOSE_OPTIONS = [
  { label: LoanPurpose.Purchase, value: LoanPurpose.Purchase },
  { label: LoanPurpose.Refinance, value: LoanPurpose.Refinance },
];

export const NUMBER_OF_UNITS_OPTIONS = [
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
];

export const PROPERTY_USAGE_OPTIONS = [
  {
    label: OCCUPANCY_TYPE_TO_LABEL_MAP[Occupancy.PrimaryResidence],
    value: Occupancy.PrimaryResidence,
  },
  { label: OCCUPANCY_TYPE_TO_LABEL_MAP[Occupancy.SecondHome], value: Occupancy.SecondHome },
  {
    label: OCCUPANCY_TYPE_TO_LABEL_MAP[Occupancy.InvestmentProperty],
    value: Occupancy.InvestmentProperty,
  },
];

export const CREDIT_SCORE_TO_LABEL_MAP = {
  [CreditScore.Excellent]: 'EXCELLENT (780+)',
  [CreditScore.Good]: 'GOOD (720-779)',
  [CreditScore.Average]: 'FAIR (640-719)',
  [CreditScore.BelowAverage]: 'POOR (620-639)',
  [CreditScore.Poor]: 'BAD (<619)',
};

export const CREDIT_SCORE_OPTIONS = [
  { label: CREDIT_SCORE_TO_LABEL_MAP[CreditScore.Excellent], value: CreditScore.Excellent },
  { label: CREDIT_SCORE_TO_LABEL_MAP[CreditScore.Good], value: CreditScore.Good },
  { label: CREDIT_SCORE_TO_LABEL_MAP[CreditScore.Average], value: CreditScore.Average },
  { label: CREDIT_SCORE_TO_LABEL_MAP[CreditScore.BelowAverage], value: CreditScore.BelowAverage },
  { label: CREDIT_SCORE_TO_LABEL_MAP[CreditScore.Poor], value: CreditScore.Poor },
];

export const LOCK_TERM_OPTIONS = [
  { label: LOCK_TERM_TO_LABEL_MAP[LockTerm.Yes], value: LockTerm.Yes },
  { label: LOCK_TERM_TO_LABEL_MAP[LockTerm.No], value: LockTerm.No },
];

export const IS_CASH_OUT_OPTIONS = [
  { label: IS_CASH_OUT_TO_LABEL_MAP[IsCashOut.Yes], value: IsCashOut.Yes },
  { label: IS_CASH_OUT_TO_LABEL_MAP[IsCashOut.No], value: IsCashOut.No },
];

export const DETAILS_INCLUDES = [
  ApplciationInclude.PreApprovals,
  ApplciationInclude.LoanOfficer,
  ApplciationInclude.Progress,
];

export const SUMMARY_INCLUDES = [ApplciationInclude.PreApprovals, ApplciationInclude.Progress];

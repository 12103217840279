import { FC, memo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TaskGroupType, TaskStatus } from 'enums/task';
import { TypographyComponent, TypographyWeight } from 'enums/ui';

import { Task } from 'types/task';

import { Typography } from 'components/Typography';

import TaskForm from '../TaskForm';
import TaskTitle from '../TaskTitle';

import {
  AccordionContent,
  CheckRoundIcon,
  Description,
  PendingIcon,
  RejectedIcon,
  RejectionInfo,
  RejectionReason,
  TaskAccordion,
  TasksListContainer,
} from './TasksList.styles';

interface TasksListProps {
  tasks?: Task[];
  groupType: TaskGroupType;
}

const iconsByStatus: Record<string, FC<{ $isActive: boolean }>> = {
  [TaskStatus.Approved]: CheckRoundIcon,
  [TaskStatus.New]: CheckRoundIcon,
  [TaskStatus.Submitted]: PendingIcon,
  [TaskStatus.Rejected]: RejectedIcon,
  [TaskStatus.Canceled]: RejectedIcon,
};

const DENIED_STATUSES = [TaskStatus.Rejected, TaskStatus.Canceled];

const TasksList: FC<TasksListProps> = ({ tasks, groupType }) => {
  const isNoTasks = isEmpty(tasks);

  if (isNoTasks) {
    return (
      <TasksListContainer isNoTasks={isNoTasks}>
        <Typography>There are no tasks at the moment</Typography>
      </TasksListContainer>
    );
  }

  return (
    <TasksListContainer data-testid="tasks_list_container">
      {tasks?.map(task => (
        <TaskAccordion
          isUnmountOnCollapse
          Icon={iconsByStatus[task.status]}
          isForceActiveIcon={groupType === TaskGroupType.Done}
          key={task.id}
          title={<TaskTitle createdDate={task.createdDate} id={task.id} name={task.name} />}
          titleComponent={TypographyComponent.H7}
        >
          <AccordionContent>
            {DENIED_STATUSES.includes(task.status) && (
              <RejectionInfo>
                The task was {task.status === TaskStatus.Rejected ? 'rejected ' : 'canceled '}
                with the following reason:
                <br />
                <RejectionReason
                  component={TypographyComponent.H7}
                  weight={TypographyWeight.SemiBold}
                >
                  {task.userNote}
                </RejectionReason>
              </RejectionInfo>
            )}
            <Typography component={TypographyComponent.H7}>Note from Lender</Typography>
            <Description component={TypographyComponent.H8}>{task.description}</Description>
            <TaskForm groupType={groupType} task={task} />
          </AccordionContent>
        </TaskAccordion>
      ))}
    </TasksListContainer>
  );
};

export default memo(TasksList);

import { FC, SVGProps } from 'react';

const ThinArrowSVG: FC<SVGProps<SVGSVGElement>> = ({ className, height = 9, width = 18 }) => {
  return (
    <svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 18 9"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.92 0.949951L10.4 7.46995C9.62996 8.23995 8.36996 8.23995 7.59996 7.46995L1.07996 0.949951"
        stroke="#353A3F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ThinArrowSVG;

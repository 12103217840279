import { styled } from 'styled-components';

import { HEADER_HEIGHT } from 'constants/ui';
import { MediaQueries } from 'enums/ui';

export const AppContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT});
  // related to bug 19439, helps to avoid hiding header when focusout from any field on IOS
  height: calc((var(--vh, 1vh) * 100) - ${HEADER_HEIGHT});

  overflow-y: scroll;
  // when changing, also change in SelectField (connected#1)
  padding: 70px 0;

  @media (max-width: ${MediaQueries.Tablet}) {
    padding: 50px 0;
  }
`;

export const AppContent = styled.div`
  display: flex;
  height: min-content;
  min-height: min-content; // fixes iOS 14 reduced height

  @media (min-width: ${MediaQueries.Desktop}) {
    min-width: 1410px;
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    justify-content: center;
    width: 100%;
    padding: 0 5%;
  }
`;

import { Form as FormikForm } from 'formik';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { Typography } from 'components/Typography';

export const PriceLoanContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (min-width: ${MediaQueries.Tablet}) {
    min-width: 700px;
    max-width: 700px;
  }

  @media (min-width: ${MediaQueries.Desktop}) {
    max-width: 1060px;
  }
`;

export const SubHeader = styled(Typography)`
  margin: -20px 0 20px;
`;

export const FormInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  @media (max-width: ${MediaQueries.Desktop}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: flex;
    flex-direction: column;
  }

  margin-bottom: 30px;
`;

export const Form = styled(FormikForm)`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  user-select: none;
  display: flex;

  & > :first-child {
    margin-right: 30px;
    min-width: 120px;
  }
`;

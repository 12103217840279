import { toast } from 'react-toastify';
import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';

import { DETAILS_INCLUDES } from 'constants/application';
import { REALTOR_ID } from 'constants/common';
import { baseQueryWithReauth } from 'utils/api';
import { downloadFileFromBlob } from 'utils/browser';

import loansAPI from 'services/loansAPI';
import { LoanContactRaw } from 'types/application';
import { SignalRConnectionData } from 'types/signalR';

import {
  CreateEmailForPreviewPayload,
  CreateEmailForPreviewResponse,
  DeleteRealtorFromApplicationPayload,
  DocumentsResponse,
  GetCountiesResponse,
  GetDocumentsPayload,
  GetLoanContactsPayload,
  InvitationDataPayload,
  InvitationDataResponse,
  LoanContactsResponse,
  LoanOptionsPayloadForPDF,
  SendEmailV3,
  SendFeedbackPayload,
  SendInvitePayload,
  SendInviteServerResponse,
  SendLoanOptionsByEmail,
} from './types';

export const baseAPI = createApi({
  reducerPath: 'commonAPI',
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getSignalRConnectionData: build.query<SignalRConnectionData, void>({
      query: () => 'api/negotiate',
    }),
    downloadLoanOptions: build.mutation<File, LoanOptionsPayloadForPDF>({
      query: ({ timestamp, ...body }) => ({
        url: `api/pricing/export`,
        method: 'POST',
        body: {
          ...body,
          userDate: format(timestamp, 'MM/dd/u'),
          userTime: format(timestamp, 'hh:mm aa'),
        },
        cache: 'no-cache',
        responseHandler: async response => await response.blob(),
      }),
      async onQueryStarted({ timestamp }, { queryFulfilled }) {
        queryFulfilled.then(({ data }) =>
          downloadFileFromBlob(
            data,
            `Zero Mortgage Loan Options ${format(timestamp, 'MM.dd.u hh.mm aaa')}.pdf`,
          ),
        );
      },
    }),
    requestAccess: build.mutation<void, { applicationId: string }>({
      query({ applicationId }) {
        return {
          url: `api/loanApplications/${applicationId}/access-request`,
          method: 'POST',
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.then(() => {
          toast('Request was sent');
        });
      },
    }),
    sendInvite: build.mutation<string, SendInvitePayload>({
      query: ({ companyId, ...body }) => ({
        url: `api/loanApplications/realtors/leads`,
        method: 'POST',
        body: {
          workingWithRealtor: true,
          realtorId: localStorage.getItem(REALTOR_ID),
          companyId,
          ...body,
        },
      }),
      transformResponse: ({ leadLink }: SendInviteServerResponse): string => leadLink,
    }),
    getCounties: build.query<GetCountiesResponse, { zipCode: string }>({
      query: ({ zipCode }) => ({
        url: `api/counties/${zipCode}`,
        method: 'GET',
      }),
    }),
    sendFeedback: build.mutation<void, SendFeedbackPayload>({
      query: ({ applicationId, feedback }) => ({
        url: `/api/loanApplications/${applicationId}/feedbacks`,
        method: 'POST',
        body: {
          content: feedback,
        },
      }),
    }),
    getReusableLink: build.mutation<string, void>({
      query: () => ({
        url: `/api/realtor/link`,
        method: 'POST',
        params: {
          domainType: 'ApplyForm',
        },
      }),
    }),
    sendLoanOptionsByEmail: build.mutation<void, SendLoanOptionsByEmail>({
      query: ({ companyId, loanOptions, message, timestamp, ...body }) => ({
        url: `api/leads`,
        method: 'POST',
        body: {
          message,
          userDate: format(timestamp, 'MM/dd/u'),
          userTime: format(timestamp, 'hh:mm aa'),
          loanOptions,
          borrowerDto: { companyId, ...body },
        },
      }),
    }),
    createEmailForPreview: build.mutation<
      CreateEmailForPreviewResponse,
      CreateEmailForPreviewPayload
    >({
      query: ({
        virtualApplicationParameters,
        borrowerContactInformation,
        selectedLoanOptions,
      }) => ({
        url: `api/leads/emails`,
        method: 'POST',
        body: {
          virtualApplicationParameters,
          borrowerContactInformation,
          selectedLoanOptions,
        },
      }),
    }),
    sendEmailV3: build.mutation<void, SendEmailV3>({
      query: ({ generatedEmailId, type }) => ({
        url: `api/leads/v3`,
        method: 'POST',
        body: {
          generatedEmailId,
          type,
        },
      }),
    }),
    deleteRealtorFromApplication: build.mutation<void, DeleteRealtorFromApplicationPayload>({
      query: ({ applicationId, realtorId }) => ({
        url: `api/loanApplications/${applicationId}/realtors/${realtorId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ applicationId, navigate }, { dispatch, queryFulfilled }) {
        await queryFulfilled.then(() => {
          // TODO: use redirect instead after update react-router to v6.4 or later
          navigate('/..');
          // pessimistic update
          dispatch(
            loansAPI.util.updateQueryData(
              'getLoanApplications',
              {
                applicationIds: [applicationId],
                includes: DETAILS_INCLUDES,
              },
              draft => {
                draft.items = draft.items.filter(app => app.applicationId !== applicationId);
              },
            ),
          );
        });
      },
    }),
    getInvitationData: build.query<InvitationDataResponse, InvitationDataPayload>({
      query: ({ companyId, requestId }) => ({
        url: `api/companies/${companyId}/loans/realtors/requests/${requestId}`,
        method: 'GET',
      }),
    }),
    // TODO: update request when applicationId was in document item for updating data via signalR
    getDocuments: build.query<DocumentsResponse, GetDocumentsPayload>({
      query: ({ companyId, loanId }) => ({
        url: `api/companies/${companyId}/loans/${loanId}/documents`,
        method: 'GET',
      }),
    }),
    getLoanContacts: build.query<LoanContactsResponse, GetLoanContactsPayload>({
      queryFn: async (params, _, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/api/loanApplications/${params.applicationId}/contacts`,
        });

        if (response.error) {
          return {
            error: response.error,
          };
        }

        const data = response.data as LoanContactRaw[];
        const formattedData = data.map(contact => ({
          ...contact,
          phoneNumber: contact.phoneNumber?.phoneNumber,
          phoneExtensionNumber: contact.phoneNumber?.extensionNumber,
        }));

        return { data: formattedData };
      },
    }),
  }),
});

export const {
  useDownloadLoanOptionsMutation,
  useSendInviteMutation,
  useRequestAccessMutation,
  useLazyGetCountiesQuery,
  useSendFeedbackMutation,
  useGetReusableLinkMutation,
  useSendLoanOptionsByEmailMutation,
  useCreateEmailForPreviewMutation,
  useSendEmailV3Mutation,
  useDeleteRealtorFromApplicationMutation,
  useLazyGetInvitationDataQuery,
  useLazyGetSignalRConnectionDataQuery,
  useGetDocumentsQuery,
  useGetLoanContactsQuery,
} = baseAPI;

import { ButtonHTMLAttributes, FC, memo } from 'react';
import { darken } from 'polished';
import { css, styled } from 'styled-components';
import { switchProp } from 'styled-tools';

import { ButtonVariant } from 'enums/ui';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  $minWidth?: number;
}

const BaseButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: ${({ $minWidth }) => ($minWidth ? `${$minWidth}px` : 'initial')};

  // is caused by unequal paddings of the custom font
  padding: 12px 20px 10px 20px;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;
  line-height: 1;

  ${switchProp(
    'variant',
    {
      [ButtonVariant.Transparent]: css`
        color: ${({ theme }) => theme.colors.primary};
        background: ${({ theme }) => theme.colors.buttons.transparent};
        padding: 12px 0 10px 0;
        border-radius: 0;
        font-size: 16px;
        font-weight: 400;

        &:focus,
        &:hover {
          color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
        }

        &:disabled {
          color: ${({ theme }) => theme.colors.controls.disabledBackground};
        }
      `,
    },
    // default case for Primary variant
    css`
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.buttons.primary};

      &:focus,
      &:hover:not(:disabled) {
        background: ${({ theme }) =>
          darken(theme.coefficients.primaryHovered, theme.colors.buttons.primary)};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.controls.disabledBackground};
      }
    `,
  )}
`;

const Button: FC<ButtonProps> = props => <BaseButton {...props} />;

export default memo(Button);

import { useEffect, useRef, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { RootState } from 'store';

import { signalRConnection, signalREventsController } from 'services/signalR';
import { selectAuthData } from 'store/selectors/auth';

export const useSignalR = () => {
  const store = useStore<RootState>();

  const { isAuthenticated } = useSelector(selectAuthData);

  const [isInitiating, setIsInitiating] = useState(true);

  const storeRef = useRef(store);

  useEffect(() => {
    if (isAuthenticated) {
      const initSignalR = async () => {
        await signalRConnection.init();
        await signalRConnection.start();
        await signalREventsController.init(storeRef.current);

        setIsInitiating(false);
      };

      initSignalR();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isInitiating) {
      signalREventsController.startListeners();

      // * To avoid multiple listeners on events
      return () => signalREventsController.stopListeners();
    }
  }, [isAuthenticated, isInitiating]);
};

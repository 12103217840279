import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { ReactComponent as ArrowWithLine } from 'assets/icons/arrowWithLine.svg';

export const StyledLink = styled(Link)`
  position: absolute;
  top: 20px;
  justify-self: flex-start;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};

    & > svg > path {
      fill: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }
  }

  @media (min-width: ${MediaQueries.Tablet}) {
    top: -40px;
    z-index: 1;
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    left: 5%;
  }
`;

export const Arrow = styled(ArrowWithLine)`
  margin-right: 5px;
  stroke: ${({ theme }) => theme.colors.primary};
  transform: rotate(180deg);
`;

import { FC, ReactNode } from 'react';
import { styled } from 'styled-components';

import { REALTOR_ID } from 'constants/common';
import { useUIStoreSync } from 'hooks/useUIStoreSync';

import { useGetCompanyIdQuery, useGetUserQuery } from 'services/authAPI';
import { useGetFeatureTogglesQuery } from 'services/baseAPI';
import { useGetApplyUiSettingsQuery } from 'services/temporaryApplyAPI';

import AnimatedLogoSVG from 'components/@icons/AnimatedLogoSVG';

import { useAuthorize } from './hooks/useAuthorize';
import { useSignalR } from './hooks/useSignalR';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.bodyBackground};
`;

const InitializeApp: FC<{ children?: ReactNode }> = ({ children }) => {
  useUIStoreSync();
  useSignalR();

  const { isLoading: isFeatureTogglesLoading } = useGetFeatureTogglesQuery();
  const { data, isLoading: isCompanyIdLoading } = useGetCompanyIdQuery();
  const isAuthorizing = useAuthorize({ companyId: data?.id });
  const { isLoading: isThemeLoading } = useGetApplyUiSettingsQuery();

  const isLoading =
    isAuthorizing || isCompanyIdLoading || isFeatureTogglesLoading || isThemeLoading;
  const userId = localStorage.getItem(REALTOR_ID) || '';

  const { isLoading: isLoadingUser } = useGetUserQuery(
    { companyId: data?.id || '', userId },
    { skip: isLoading || !userId },
  );

  if (isLoading || isLoadingUser) {
    return (
      <LoaderContainer>
        <AnimatedLogoSVG />
      </LoaderContainer>
    );
  }

  return <>{children}</>;
};

export default InitializeApp;

import { StylesConfig } from 'react-select';
import { darken, lighten } from 'polished';
import { CSSProperties, DefaultTheme, styled } from 'styled-components';

import { palette } from 'styles/palette';

interface CSSPropertiesWithHover extends CSSProperties {
  '&:hover'?: {
    [key: string]: string;
  };
}
export const SelectContainer = styled.div`
  position: relative;
`;

const getSelectBorderStyles = (
  theme: DefaultTheme,
  isFocused: boolean,
  isInvalid: boolean,
  isDisabled: boolean,
): CSSPropertiesWithHover => {
  if (isInvalid) {
    return {
      borderColor: theme.colors.primary,
    };
  }

  if (isDisabled) {
    return {
      borderColor: palette.transparent,
      backgroundColor: theme.colors.controls.disabledBackground,
    };
  }

  if (isFocused) {
    return {
      borderColor: darken(theme.coefficients.primaryHovered, theme.colors.primary),
    };
  }

  return {
    borderColor: palette.transparent,
    boxShadow: 'none',
  };
};

export const getCustomStyles = (
  theme: DefaultTheme,
  isInvalid: boolean,
): StylesConfig<unknown, false> => ({
  container: provided => ({
    ...provided,
    width: '100%',
    minWidth: 0,
  }),
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    width: '100%',
    maxWidth: '100%',
    minWidth: 0,
    height: 48,
    padding: '0 8px',
    overflow: 'hidden', // fixes height change during open/close phases

    fontSize: 14,
    fontFamily: `'Axiforma', 'Arial', sans-serif`,
    fontWeight: 400,

    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    backgroundColor: isDisabled
      ? theme.colors.controls.disabledBackground
      : theme.colors.controls.background,

    cursor: 'pointer',

    boxShadow: 'none',
    '&:hover': {
      borderColor: darken(theme.coefficients.primaryHovered, theme.colors.primary),
    },

    ...getSelectBorderStyles(theme, isFocused, isInvalid, isDisabled),
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled
      ? lighten(theme.coefficients.controlsDisabled, theme.colors.controls.text)
      : theme.colors.controls.text,
    fontSize: 14,
    fontWeight: 400,
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.colors.controls.placeholder,
    fontSize: 15,
    fontWeight: 400,
    opacity: 1,
    fontFamily: 'Axiforma',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, { selectProps }) => ({
    ...provided,
    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    transition: `transform 0.2s`,
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 1000, // same as Modal
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2,
    border: 'none',
    borderRadius: 12,
    boxShadow: `0 0 12px ${theme.colors.shadow}`,
    // fix scrollbar overflow
    padding: '5px 0',
  }),
  menuList: (provided, { options }) => ({
    ...provided,
    position: 'relative',
    padding: '7px 0',
    zIndex: 1,
    maxHeight: 170,
    overflowX: 'hidden',

    backgroundColor: options.length ? theme.colors.white : undefined,
    borderRadius: 12,
  }),
  option: (_, { isSelected, isFocused }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: `7px 14px`,
    fontSize: 14,
    fontFamily: `'Axiforma', 'Arial', sans-serif`,

    backgroundColor: theme.colors.white,
    color: isSelected || isFocused ? theme.colors.primary : theme.colors.black,
    cursor: 'pointer',

    transition: `color 0.2s`,

    '&:hover': {
      color: theme.colors.primary,
    },
  }),
});

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthErrors, AuthEventsEnum } from '@interfirst/utils';

import { REALTOR_ID } from 'constants/common';
import { authClient } from 'utils/auth';

import { useGenerateSignInTokenMutation } from 'services/authAPI';
import { useLazyGetInvitationDataQuery } from 'services/baseAPI';
import { setRedirectUrl, signIn, signOut } from 'store/slices/auth';

interface UseAuthorizeParams {
  companyId?: string;
}

export const useAuthorize = ({ companyId }: UseAuthorizeParams) => {
  const [isAuthorizing, setIsAuthorizing] = useState(true);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [generateToken] = useGenerateSignInTokenMutation();
  const [getInvitationData, { isLoading: isLoadingInvite }] = useLazyGetInvitationDataQuery();

  const requestId = searchParams.get('requestId');
  const isSignup = pathname.includes('/signup');

  useEffect(() => {
    const cleanupLogout = authClient.emitter.on(AuthEventsEnum.Logout, () => {
      localStorage.removeItem(REALTOR_ID);
      dispatch(signOut());
    });

    return () => {
      cleanupLogout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // retrieves invitation data for signup
  useEffect(() => {
    if (isSignup && requestId && companyId) {
      getInvitationData({ companyId, requestId });
    }
  }, [isSignup, requestId, companyId, getInvitationData]);

  useEffect(() => {
    authClient
      .restorePreviousSession()
      .then(() => {
        dispatch(signIn());
      })
      .catch(err => {
        const code = searchParams.get('code');
        if (requestId && code) {
          return generateToken({ requestId, code }).unwrap();
        }
        return Promise.reject(err);
      })
      .catch(err => {
        if (err instanceof AuthErrors.TokenMissedError) {
          dispatch(setRedirectUrl(pathname));
        } else if (err instanceof AuthErrors.TokenExpiredError) {
          // if refresh token expired
          toast('You are logged out as your authorization is expired');
        } else {
          toast('Something went wrong. Please try again');
        }
      })
      .finally(() => setIsAuthorizing(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthorizing || isLoadingInvite;
};

import { darken } from 'polished';
import { css } from 'styled-components';

const scrollbarPartStyles = css`
  border-radius: 15px;

  background-clip: padding-box;

  &:hover {
    border: 0;
  }
`;

const verticalScrollbarPartStyles = css`
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;

  ${scrollbarPartStyles}
`;

const horizontalScrollbarPartStyles = css`
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  ${scrollbarPartStyles}
`;

export const getScrollbar = (width = 12) => css`
  ::-webkit-scrollbar {
    width: ${width}px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => darken(0.05, theme.colors.white)};
  }

  ::-webkit-scrollbar-thumb:vertical {
    ${verticalScrollbarPartStyles}
  }

  ::-webkit-scrollbar-track:vertical {
    ${verticalScrollbarPartStyles}
  }

  ::-webkit-scrollbar-thumb:horizontal {
    ${horizontalScrollbarPartStyles}
  }

  ::-webkit-scrollbar-track:horizontal {
    ${horizontalScrollbarPartStyles}
  }
`;

import { FC, SVGProps } from 'react';

const LocationSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="15" viewBox="0 0 11 15" width="11" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.79067 0C5.94646 0.0194403 6.10337 0.034479 6.25842 0.0594212C8.2908 0.387338 9.73533 1.48736 10.5641 3.33786C11.104 4.54315 11.1178 5.7954 10.7407 7.05682C10.4284 8.10182 9.95248 9.0742 9.40515 10.0136C8.41834 11.7063 7.23557 13.2612 5.94535 14.7387C5.64157 15.0867 5.34522 15.0875 5.04182 14.7387C3.58018 13.058 2.24571 11.2867 1.19903 9.31996C0.720126 8.42057 0.299223 7.49331 0.110709 6.48755C-0.261113 4.51161 0.305916 2.82434 1.77759 1.44041C2.64691 0.623556 3.70177 0.17863 4.88974 0.0333786C4.99199 0.0209075 5.09313 0.0110039 5.19687 0H5.79067ZM5.47611 8.16381C6.97492 8.19059 8.23057 6.97319 8.25883 5.46638C8.28708 3.95958 7.0597 2.7242 5.51849 2.69743C4.01522 2.67139 2.76106 3.88072 2.72983 5.38789C2.69859 6.89506 3.92412 8.1363 5.47611 8.16381Z"
        fill={color}
      />
    </svg>
  );
};

export default LocationSVG;

import {
  AuthClient,
  AuthClientsTypes,
  AuthInitiateType,
  InitSignInByEmailProps,
  InitSignInViaPhoneProps,
} from '@interfirst/utils';

import { AUTH_API_URL } from 'constants/api';
import { IS_DEVELOPMENT } from 'constants/common';
import { getTime } from 'utils/date';
import { formatPhoneForRequest } from 'utils/formatting';
import { externalLogger } from 'utils/logger';

import { SignInDetails } from 'types/auth';

export const getCreateRequestPayload = ({
  isEmail,
  companyId,
  email,
  redirectUrl,
  phoneNumber,
}: SignInDetails): InitSignInViaPhoneProps | InitSignInByEmailProps =>
  isEmail
    ? {
        type: AuthInitiateType.ViaEmail,
        companyId,
        email: email || '',
        redirectUrl: window.origin + (redirectUrl || ''),
      }
    : {
        type: AuthInitiateType.ViaPhone,
        companyId,
        phoneNumber: formatPhoneForRequest(phoneNumber || ''),
      };

export const getExpirationCodeMinutes = (expirationDate: string) =>
  getTime({
    date: expirationDate,
    time: 'minutes',
    rounding: 'ceil',
    onlyPositiveValues: true,
  });

export const getDomain = () =>
  IS_DEVELOPMENT ? 'interfirst-realtor.dev-lender.com' : window.origin;

export const authClient = new AuthClient({
  baseUrl: AUTH_API_URL + 'api/',
  domain: getDomain(),
  clientId: AuthClientsTypes.RealtorPortal,
  logger: externalLogger,
});

export const prepareHeaders = (headers: Headers): Headers => {
  const accessToken = authClient.getAccessToken();

  if (accessToken) {
    headers.append('Authorization', `Bearer ${accessToken}`);
  }

  return headers;
};

import { FC, memo } from 'react';
import { css, keyframes, styled } from 'styled-components';

// TODO: sink about add to palette
const skeletonLoading = keyframes`
  0% {
    background-color: #A3B8C2;
  }

  100% {
    background-color: #E0E7EB;
  }
`;

export const SkeletonBlock = styled.div<{ $isPlug?: boolean }>`
  ${({ $isPlug }) =>
    $isPlug
      ? css`
          background-color: #e0e7eb;
          opacity: 0.3;
        `
      : css`
          opacity: 0.7;
          animation: ${skeletonLoading} 1s linear infinite alternate;
        `};
`;

interface SkeletonProps {
  className?: string;
  isError?: boolean;
  isPlug?: boolean;
}

const Skeleton: FC<SkeletonProps> = ({ className, isError, isPlug }) => {
  return <SkeletonBlock $isPlug={isError || isPlug} className={className} />;
};

export default memo(Skeleton);

import { ChangeEvent, FC, memo, useCallback } from 'react';
import { NumberFormatValues, NumericFormat, NumericFormatProps } from 'react-number-format';
import { useField } from 'formik';

import { MAX_NUMBER } from 'constants/inputs';

import TextField from 'components/@fields/TextField';

export interface CurrencyFieldProps extends NumericFormatProps {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isDecimalScale?: boolean;
  label: string;
  maxValue?: number;
  shouldRemoveLeadingZeros?: boolean;
  isAbsoluteError?: boolean;
  className?: string;
}

const CurrencyField: FC<CurrencyFieldProps> = ({
  name,
  isDecimalScale = true,
  maxValue = MAX_NUMBER,
  shouldRemoveLeadingZeros = true,
  onChange,
  ...props
}) => {
  const [{ value: fieldValue }, , { setValue }] = useField(name);

  const onInputChange = useCallback(
    ({ floatValue }: NumberFormatValues) => setValue(floatValue),
    [setValue],
  );

  const onBlur = useCallback(
    // prevent setting value with mask from TextField
    () => setValue(typeof fieldValue === 'number' ? Math.min(fieldValue, maxValue) : ''),
    [setValue, fieldValue, maxValue],
  );

  return (
    <NumericFormat
      {...props}
      thousandSeparator
      allowLeadingZeros={false}
      allowNegative={false}
      customInput={TextField}
      data-testid={`${String(name).replace(/[^a-zA-Z]/g, '')}_input`}
      decimalScale={isDecimalScale ? 2 : 0}
      decimalSeparator="."
      name={name}
      prefix="$"
      value={fieldValue}
      onBlur={onBlur}
      onValueChange={onInputChange}
    />
  );
};

export default memo(CurrencyField);

import { useCallback, useEffect, useState } from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import get from 'lodash/get';
import set from 'lodash/set';

import { ZIP_CODE_LENGTH } from 'constants/ui';
import useDebounce from 'hooks/useDebounce';
import { getOptionsFromArray } from 'utils/array';
import { externalLogger } from 'utils/logger';

import { useLazyGetCountiesQuery } from 'services/baseAPI';

interface Props<T> {
  zip: string;
  county: string;
  namePath?: string;
  setValues: FormikHelpers<T>['setValues'];
  setZipTouched?: FormikHelpers<boolean>['setTouched'];
  isAutoRequesting?: boolean;
}

export const useCounties = <T extends FormikValues>({
  zip,
  county,
  setValues,
  setZipTouched,
  namePath,
  isAutoRequesting = true,
}: Props<T>) => {
  const [countyOptions, setCountyOptions] = useState<{ value: string; label: string }[]>([]);

  const [getCounties, { isFetching }] = useLazyGetCountiesQuery();

  const resetCountyOptions = useCallback((): void => {
    setCountyOptions([]);
  }, []);

  const getZipCounties = useCallback(
    async (zipCode: string): Promise<void> => {
      const isCurrentZipLengthValid = zipCode.length === ZIP_CODE_LENGTH;

      if (!isCurrentZipLengthValid) {
        resetCountyOptions();
        return;
      }

      try {
        const data = await getCounties({ zipCode }).unwrap();
        const counties = data?.counties || [];
        const isCurrentCountyFits = county && counties.includes(county);
        setZipTouched?.(true);

        if (!isCurrentCountyFits) {
          const pathArray = namePath?.split('.').slice(0, -1);
          const nestedState = namePath ? {} : { county: counties[0] };

          setValues(prevState => {
            if (namePath && pathArray) {
              const addressData = get(prevState, pathArray);
              set(nestedState, pathArray, { ...addressData, county: counties[0] });
            }

            return { ...prevState, ...nestedState };
          });
        }

        setCountyOptions(getOptionsFromArray(counties));
      } catch (error) {
        if (error instanceof Error) {
          externalLogger.error(error.message);
        } else {
          externalLogger.error('getCounties error', JSON.stringify(error));
        }
      }
    },
    [setZipTouched, county, getCounties, namePath, resetCountyOptions, setValues],
  );

  const debouncedGetZipCounties = useDebounce(getZipCounties, 200);

  useEffect(() => {
    if (isAutoRequesting) {
      debouncedGetZipCounties(zip);
    }
    return debouncedGetZipCounties.cancel;
    // avoid a double request
  }, [zip]); // eslint-disable-line

  return {
    isFetchingCounties: isFetching,
    countyOptions,
    resetCountyOptions,
    getCounties: getZipCounties,
  };
};

import { createSelector } from '@reduxjs/toolkit';

import { PURCHASE_AGREEMENT_TASK_NAME } from 'constants/tasks';
import { TaskStatus } from 'enums/task';

import tasksAPI from 'services/tasksAPI';
import { RootState } from 'store/index';

export const selectTasksByApplicationIds = (applicationIds?: string[]) => (state: RootState) => {
  return tasksAPI.endpoints.getTasks.select({ applicationIds })(state).data;
};

export const selectIsActivePurchaseAgreementTaskByApplicationId = (applicationId: string) =>
  createSelector(
    (state: RootState) => selectTasksByApplicationIds([applicationId])(state),
    tasks =>
      !!tasks?.[applicationId]?.find(
        task =>
          task.applicationId === applicationId &&
          task.status === TaskStatus.New &&
          task.name === PURCHASE_AGREEMENT_TASK_NAME,
      ),
  );

import { FC, memo, MouseEvent, useCallback, useState } from 'react';

import { TypographyComponent } from 'enums/ui';

import { useDownloadDocumentMutation } from 'services/documentsAPI';

import SubmittedDocument from './SubmittedDocument';
import { TaskHandlerProps } from './TaskForm';
import { Container, DocumentsTitle } from './TaskForm.styles';

const SubmittedDocuments: FC<TaskHandlerProps> = ({ task }) => {
  const [requestingDocId, setRequestingDocId] = useState<null | string>(null);

  const [downloadDocument] = useDownloadDocumentMutation();

  const { name, documentIds } = task;
  const hasDocuments = !!documentIds?.length;

  const onDownloadDocument = useCallback(
    async (event: MouseEvent<HTMLLIElement>) => {
      const { documentid: documentId, name: documentName } = event.currentTarget.dataset;

      if (!documentId) {
        return;
      }

      setRequestingDocId(documentId);
      await downloadDocument({ documentId, name: documentName });
      setRequestingDocId(null);
    },
    [downloadDocument],
  );

  return (
    <Container>
      <DocumentsTitle component={TypographyComponent.H7}>Documents</DocumentsTitle>
      {hasDocuments &&
        documentIds.map((documentId, index) => (
          <SubmittedDocument
            documentId={documentId}
            isDisabled={!!requestingDocId}
            isRequesting={requestingDocId === documentId}
            key={documentId}
            name={name + (index > 0 ? `[${index}]` : '')}
            requestDocument={onDownloadDocument}
          />
        ))}
    </Container>
  );
};

export default memo(SubmittedDocuments);

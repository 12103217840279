import { darken } from 'polished';
import { css, styled } from 'styled-components';

import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import { HIGHLIGHTED_CELL } from 'components/Table/Table.constants';

const DEFAULT_ROW_HEIGHT = 72;

export const StyledRow = styled(Card)<{
  rowHeight?: number;
  isActive?: boolean;
  isSelected?: boolean;
  isHighlighted?: boolean;
  $isClickable?: boolean;
  isSubGridShown?: boolean;
  isWithSubGrid?: boolean;
}>`
  padding: 0 12px;
  width: 100%;
  height: ${({ rowHeight }) => rowHeight || DEFAULT_ROW_HEIGHT}px;
  max-height: ${({ rowHeight }) => rowHeight || DEFAULT_ROW_HEIGHT}px;
  margin-bottom: 20px;
  margin-bottom: ${({ isSubGridShown }) => (isSubGridShown ? '0' : '20px')};
  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transition: box-shadow 0.5s ease;

  box-shadow: ${({ theme, isWithSubGrid, isSubGridShown }) => {
    if (isWithSubGrid && !isSubGridShown) {
      return theme.colors.subGridFoldedShadow;
    }
  }};

  &:hover {
    cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
    box-shadow: ${({ theme, isWithSubGrid, isSubGridShown }) =>
      isWithSubGrid && !isSubGridShown
        ? `rgba(0, 0, 0, 0.4) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset, 0px 10px 30px 0 ${darken(
            theme.coefficients.primaryHovered,
            theme.colors.shadow,
          )}`
        : `0px 10px 30px 0 ${darken(theme.coefficients.primaryHovered, theme.colors.shadow)}`};

    & > #${HIGHLIGHTED_CELL} {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #24d440;
      &:hover {
        cursor: default;
      }
    `}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.primary};
    `}

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: #24d440;
    `}

  ${({ isSubGridShown }) =>
    isSubGridShown &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const RowSkeleton = styled(Skeleton)<{ rowHeight?: number }>`
  width: 100%;
  margin-bottom: 20px;
  height: ${({ rowHeight }) => rowHeight || DEFAULT_ROW_HEIGHT}px;
  border-radius: 20px;
`;

import { useMemo } from 'react';
import type { DebounceSettings } from 'lodash';
import debounce from 'lodash/debounce';

const useDebounce = <T>(
  callback: (...args: any) => T, // eslint-disable-line
  delay: number,
  options?: DebounceSettings,
) => {
  const debouncedFunction = useMemo(
    () => debounce(callback, delay, options),
    [callback, delay, options],
  );

  return debouncedFunction;
};

export default useDebounce;

import { forwardRef, memo, useMemo } from 'react';

import {
  applicationStatusToTitleMap,
  normalFlowApplicationStatuses,
  tbdFlowApplicationStatuses,
} from 'constants/application';
import { FeatureFlags } from 'constants/featureToggles';
import { ApplicationStatus, ProcessingStopReason } from 'enums/application';
import { MilestoneState } from 'enums/milestones';
import { TypographyComponent } from 'enums/ui';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { Milestone, Progress } from 'types/milestones';

import Step from './components/Step';
import CircleProgress from 'components/CircleProgress';

import { Error, FileProgressContainer, Header, Path, Title } from './FileProgress.styles';

interface FileProgressProps {
  isTbd: boolean;
  status: ApplicationStatus;
  processingStopReason?: ProcessingStopReason;
  progress?: Progress;
}

const blockTestName = 'fileProgress';

const FileProgress = forwardRef<HTMLDivElement, FileProgressProps>(
  ({ isTbd, status, processingStopReason, progress }, ref) => {
    const isDynamicProgress = useFeatureFlag(FeatureFlags.UseDynamicLoanProgress);

    const { percentage, steps, currentStepIndex, error } = useMemo(() => {
      if (isDynamicProgress) {
        return {
          percentage: Math.round(progress?.percentage as number),
          steps: progress?.milestones,
          error: !progress?.milestones?.length && (
            <Error>Error: Unable to fetch milestone data. Please contact your loan officer.</Error>
          ),
        };
      }

      const stepsFlow = isTbd ? tbdFlowApplicationStatuses : normalFlowApplicationStatuses;
      const currentStepIndex = stepsFlow.findIndex(step => step === status);

      return {
        currentStepIndex,
        percentage: processingStopReason
          ? 100
          : Math.round(((currentStepIndex + 1) / stepsFlow.length) * 100),
        // leave passed steps and add stop reason to end
        steps: processingStopReason
          ? [...stepsFlow.slice(0, currentStepIndex + 1), processingStopReason]
          : stepsFlow,
      };
    }, [isDynamicProgress, isTbd, processingStopReason, progress, status]);

    return (
      <div ref={ref}>
        <FileProgressContainer data-testid={`${blockTestName}__container`}>
          <Header>
            <CircleProgress data-testid={`${blockTestName}__percentage`} percentage={percentage} />
            <Title component={TypographyComponent.H5}>File Progress</Title>
          </Header>
          <Path>
            {isDynamicProgress
              ? error ||
                (steps as Milestone[]).map((milestone, index) => {
                  const isPassed = milestone.state.type === MilestoneState.Completed;

                  return (
                    <Step
                      isDynamicProgress
                      data-testid={`${blockTestName}__step`}
                      description={milestone.description}
                      documents={milestone.formBundlesToAttach}
                      isPassed={isPassed}
                      isProcessingStopped={isPassed && milestone.isTermination}
                      key={milestone.definitionId}
                      number={index + 1}
                      title={milestone.name}
                    />
                  );
                })
              : (steps as ApplicationStatus[]).map((step, index) => (
                  <Step
                    data-testid={`${blockTestName}__step`}
                    isPassed={(currentStepIndex as number) >= index}
                    isProcessingStopped={!!processingStopReason}
                    key={step}
                    number={index + 1}
                    title={applicationStatusToTitleMap[step]}
                  />
                ))}
          </Path>
        </FileProgressContainer>
      </div>
    );
  },
);

export default memo(FileProgress);

import { FC, SVGProps } from 'react';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

const Svg = styled.svg`
  margin: 25px;
  display: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    display: initial;
  }
`;

const CrossSVG: FC<SVGProps<SVGSVGElement>> = ({
  color,
  onClick,
  className,
  height = 30,
  width = 30,
}) => {
  return (
    <Svg
      className={className}
      fill="none"
      height={height}
      viewBox="0 0 30 30"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        fill={color}
        height="2"
        rx="1"
        transform="rotate(45 8.63599 10)"
        width="20"
        x="8.63599"
        y="10"
      />
      <rect
        fill={color}
        height="2"
        rx="1"
        transform="rotate(-45 7.2218 24.364)"
        width="20"
        x="7.2218"
        y="24.364"
      />
    </Svg>
  );
};

export default CrossSVG;

export const getTime = ({
  date,
  time,
  type = 'left',
  rounding = 'floor',
  onlyPositiveValues = false,
}: {
  date: string;
  time: 'days' | 'hours' | 'minutes';
  rounding?: 'floor' | 'ceil' | 'round';
  onlyPositiveValues?: boolean;
  type?: 'left' | 'passed';
}) => {
  const getRounded = (ms: number) =>
    Math[rounding](
      (type === 'left' ? Date.parse(date) - Date.now() : Date.now() - Date.parse(date)) / ms,
    );

  if (onlyPositiveValues && Date.parse(date) - Date.now() <= 0) {
    return 0;
  }

  switch (time) {
    case 'days':
      return getRounded(1000 * 60 * 60 * 24); // ms s min hours

    case 'hours':
      return getRounded(1000 * 60 * 60); // ms s min

    case 'minutes':
      return getRounded(1000 * 60); // ms s
  }
};

export enum AccessStatus {
  None = 'None',
  Requested = 'Requested',
  Approved = 'Approved',
}

export enum ApplicationStatus {
  Initiated = 'Initiated',
  Submitted = 'Submitted',
  PreApproved = 'PreApproved',
  ProvidePropertyAddressToLoanOfficer = 'ProvidePropertyAddressToLoanOfficer',
  ReadyToSign = 'ReadyToSign',
  Signing = 'Signing',
  Signed = 'Signed',
  ApplicationInUnderwriting = 'ApplicationInUnderwriting',
  Approved = 'Approved',
  ConfirmingScheduling = 'ConfirmingScheduling',
  ClosingScheduled = 'ClosingScheduled',
  Closing = 'Closing',
  Funded = 'Funded',
}

export enum ProcessingStopReason {
  Cancelled = 'Cancelled',
  Withdrawn = 'Withdrawn',
  Denied = 'Denied',
}

export enum PropertyType {
  SingleFamily = 'SingleFamily',
  Condo = 'Condo',
  Townhouse = 'Townhouse',
  Coop = 'Coop',
  MultiUnit = 'MultiUnit',
  ManufacturedHome = 'ManufacturedHome',
  Land = 'Land',
}

export enum Occupancy {
  PrimaryResidence = 'PrimaryResidence',
  SecondHome = 'SecondHome',
  InvestmentProperty = 'InvestmentProperty',
}

export enum LoanPurpose {
  Purchase = 'Purchase',
  Refinance = 'Refinance',
}

export enum CreditScore {
  Excellent = 'Excellent',
  Good = 'Good',
  Average = 'Average',
  BelowAverage = 'BelowAverage',
  Poor = 'Poor',
}

export enum IsCashOut {
  Yes = 1,
  No = 0,
}

export enum LockTerm {
  Yes = 60,
  No = 90,
}

export enum ApplicationsViewType {
  Table = 'table',
  Cards = 'cards',
}

export enum PurchaseProcessType {
  SignedPurchasedContract = 'SignedPurchasedContract',
  SearchingOrShoppingAround = 'SearchingOrShoppingAround',
  MakingOfferOrSigningPurchaseContract = 'MakingOfferOrSigningPurchaseContract',
}

export enum LoanProcessError {
  Other = 'Other',
  NoCreditReport = 'NoCreditReport',
  NoFirstPartPrograms = 'NoFirstPartPrograms',
  NoSecondPartPrograms = 'NoSecondPartPrograms',
  LoanOfficerAssignmentFailed = 'LoanOfficerAssignmentFailed',
  CannotCreateSignatureRequest = 'CannotCreateSignatureRequest',
  RequestCreditReportLater = 'RequestCreditReportLater',
  FailedToLoadFees = 'FailedToLoadFees',
  AusNotEligible = 'AusNotEligible',
  AusError = 'AusError',
}

export enum LoanType {
  BPL = 'BPL', // Business Purpose Loan
  Residential = 'Residential',
}

export enum MaritalStatus {
  Married = 'Married',
  Unmarried = 'Unmarried',
  Separated = 'Separated',
}

export enum LanguagePreference {
  English = 'English',
  Chinese = 'Chinese',
  Korean = 'Korean',
  Spanish = 'Spanish',
  Tagalog = 'Tagalog',
  Vietnamese = 'Vietnamese',
  Other = 'Other',
  IdoNotWishToRespond = 'IdoNotWishToRespond',
}

export enum ApplciationInclude {
  PreApprovals = 'preapprovals',
  LoanOfficer = 'loanofficer',
  Progress = 'progress',
}

import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useModal } from '@ebay/nice-modal-react';

import { EditPreApprovalRequestType, EditPreApprovalResponseStatus } from 'enums/preApproval';

import { useEditPreApprovalMutation } from 'services/applyAPI';
import { EditPreApprovalLetterParams } from 'types/preApproval';

import { Loader, Step, SubmitButton, Text, Title } from '../@shared';

interface SubmitProgramProps {
  applicationId: string;
  preApprovalData: EditPreApprovalLetterParams;
  loanOptionId: string | null;
  setStep: (step: Step) => void;
}

const SubmitProgram: FC<SubmitProgramProps> = ({
  applicationId,
  preApprovalData,
  loanOptionId,
  setStep,
}) => {
  const modal = useModal();

  const [editPreApproval, { isLoading }] = useEditPreApprovalMutation();

  useEffect(() => {
    const tryEditPreApproval = async () => {
      const status = await editPreApproval({
        type: EditPreApprovalRequestType.Full,
        applicationId,
        loanOptionId,
        address: preApprovalData.address,
        numberOfUnits: preApprovalData.numberOfUnits,
        propertyType: preApprovalData.propertyType,
        propertyValue: preApprovalData.propertyValue,
        downPaymentValue: preApprovalData.downPaymentValue,
      }).unwrap();

      if (status !== EditPreApprovalResponseStatus.RequestSent) {
        setStep(Step.Error);
        return;
      }

      toast('Pre-Approval Letter is generating. It may take up to 1 minute');
    };

    tryEditPreApproval();
  }, []); // eslint-disable-line

  if (isLoading) {
    return (
      <>
        <Title isCentered>Processing</Title>

        <Loader />

        <Text>This takes up to a minute to process</Text>
      </>
    );
  }

  return (
    <>
      <Title isCentered>Pre-Approved!</Title>

      <Text>Latest changes are successfully pre-approved.</Text>

      <SubmitButton onClick={modal.remove}>Done</SubmitButton>
    </>
  );
};

export default SubmitProgram;

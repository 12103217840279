import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { LoanApplication } from 'types/application';

import { AsyncPaginationTable } from 'components/Table';

import { blurred } from 'styles/common';

export const BlurredText = styled.span<{ $isBlurred: boolean }>`
  ${blurred}
`;

export const TableStyled = styled(AsyncPaginationTable<LoanApplication>)`
  // fix skeleton width and changing the width of the table when changing pages
  min-width: 850px;

  @media (min-width: ${MediaQueries.MobileLandscape}) {
    min-width: 700px;
  }
`;

import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { Typography } from 'components/Typography';

import { ReactComponent as CarouselArrow } from 'assets/icons/carouselArrow.svg';

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1010px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    flex-direction: column;
  }

  // case for win 14" 150% scale (144dpi)
  @media (max-width: 1280px) and (max-height: 570px) {
    max-width: 700px;
    align-self: center;
  }
`;

export const ArrowContainer = styled.button<{
  isDisabled?: boolean;
  withReducedLeftMargin?: boolean;
  shouldArrowsBeMounted: boolean;
}>`
  display: ${({ shouldArrowsBeMounted }) => (shouldArrowsBeMounted ? 'flex' : 'none')};
  visibility: ${({ isDisabled }) => (isDisabled ? 'hidden' : 'visible')};

  align-items: center;
  justify-content: center;
  padding: 10px;

  transition: all 0.5s ease;

  color: ${({ theme }) => theme.colors.accent};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Content = styled.div<{
  currentIndex: number;
  visibleCount: number;
  totalCount: number;
}>`
  display: flex;
  width: 100%;
  height: 100%;

  transform: translateX(
    -${({ currentIndex, visibleCount }) => currentIndex * (100 / visibleCount)}%
  );
  transition: 0.5s ease-in-out;

  ${({ totalCount, visibleCount }) =>
    visibleCount > totalCount &&
    css`
      justify-content: center;
    `}

  & > * {
    flex-grow: 1;
    flex-shrink: 0;
    width: calc(100% / ${({ visibleCount }) => visibleCount} - 15px);

    &:not(:last-child) {
      margin-right: 15.1px;
    }
  }
`;

export const RightArrow = styled(CarouselArrow)`
  transform: rotate(180deg);
`;

export const MobileArrowsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoArrowsMobileHint = styled(Typography)`
  margin: 10px 0 -15px;
`;

import { FC, LabelHTMLAttributes, memo } from 'react';

import { LabelText } from './FieldLabel.styles';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
  required?: boolean;
}

const FieldLabel: FC<LabelProps> = ({ className, children, ...props }) => {
  return (
    <LabelText {...props} className={className}>
      {children}
    </LabelText>
  );
};

export default memo(FieldLabel);

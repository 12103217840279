export enum TaskStatus {
  New = 'New',
  Submitted = 'Submitted',
  Canceled = 'Canceled',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum TaskDataType {
  FreeText = 'FreeText',
  Documents = 'Documents',
  Forms = 'Forms',
}

export enum TaskGroupType {
  General = 'General', // Editable (New)
  Borrower = 'Borrower', // Not editable (New && TaskDataType.Forms && isSignatureRequired)
  Done = 'Done', // Not editable (Submitted, Approved, Rejected, Canceled)
}

export enum SubmitPurchaseAgreementStatus {
  Success = 'Success',
  NotEligibility = 'NotEligibility',
  AusFailed = 'AusFailed',
  LoanOfficerIsNotReassigned = 'LoanOfficerIsNotReassigned',
}

export enum TaskAssignmentType {
  SlaGroup = 'SlaGroup',
  Borrower = 'Borrower',
}

import { FC, memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { TypographyComponent } from 'enums/ui';

import { AccordionContainer, ArrowIcon, Content, Title, TitleRow } from './Accordion.styles';

interface AccordionProps {
  defaultState?: 'opened' | 'closed';
  title: string | JSX.Element;
  titleComponent?: TypographyComponent;
  children: ReactNode;
  className?: string;
  Icon?: FC<{ $isActive: boolean }>;
  isForceActiveIcon?: boolean;
  isUnmountOnCollapse?: boolean;
  $isOverflowClip?: boolean;
}

const Accordion: FC<AccordionProps> = ({
  title,
  titleComponent = TypographyComponent.H6,
  defaultState = 'closed',
  Icon,
  className,
  children,
  isForceActiveIcon,
  $isOverflowClip,
  isUnmountOnCollapse, // TODO: potentially can be reworked in React 18
}) => {
  // here we set transition type, useRef helps to avoid extra re-render
  const toStateRef = useRef('');

  // isActive determines whether content is shown
  // isMounted determines whether content is rendered in DOM
  const [isActive, setIsActive] = useState(defaultState === 'opened');
  const [isMounted, setIsMounted] = useState(
    isUnmountOnCollapse ? defaultState === 'opened' : true,
  );

  // switchers for isActive and isMounted
  const toggleActivity = useCallback(() => setIsActive(prev => !prev), []);
  const toggleMount = useCallback(() => setIsMounted(prev => !prev), []);

  // fn to trigger 2-step transition
  const toggleStateTransition = useCallback(() => {
    // set transition type so useEffect will know about it at next render
    toStateRef.current = isActive ? 'collapse' : 'expand';

    /* 1-st step:
      if 'isMounted: false' - at first we should mount component by calling toggleMount (expand phase)
      if 'isMounted: true' - at first call toogleActivity to perform collapsing animations (collapse phase)
    */
    if (!isMounted) {
      toggleMount();
    } else {
      toggleActivity();
    }
  }, [isActive, isMounted, toggleActivity, toggleMount]);

  useEffect(() => {
    // if unmount is not required just cancel
    if (!isUnmountOnCollapse) {
      return;
    }

    /* 2-nd step: based on type of transition:
      1) if 'expand' - trigger toggleActivity to perform expanding animations on already mounted content
      2) if 'collapse' - trigger toggleMount to unmount component after collapsing animation
      empty string - no transition will be performed in useEffect
      setTimeout is required to delay execution of 2-nd step for animation purposes
    */
    if (toStateRef.current === 'expand') {
      setTimeout(toggleActivity, 50); // gives extra 50ms to render complex components
    } else if (toStateRef.current === 'collapse') {
      setTimeout(toggleMount, 250); // waits before most animations are completed
    }

    toStateRef.current = ''; // clean up
  }, [isActive, isMounted, isUnmountOnCollapse, toggleActivity, toggleMount]);

  return (
    <AccordionContainer className={className} data-testid="accordion__item">
      <TitleRow
        $isClickable
        $isExpandClickZone
        // use toggleActivity if isUnmountOnCollapse isn't passed as content is always mounted
        onClick={isUnmountOnCollapse ? toggleStateTransition : toggleActivity}
      >
        {/*@ts-ignore*/}
        {Icon && <Icon $isActive={isForceActiveIcon || isActive} />}
        <Title $isActive={isActive} $isWithIcon={!!Icon} component={titleComponent}>
          {title}
        </Title>
        <ArrowIcon $isActive={isActive} />
      </TitleRow>

      {isMounted && (
        <Content $isActive={isActive} $isOverflowClip={$isOverflowClip}>
          {children}
        </Content>
      )}
    </AccordionContainer>
  );
};

export default memo(Accordion);

import { FC, SVGProps } from 'react';

const MailSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="11" viewBox="0 0 15 11" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9996 9.83648L14.8822 10.1874L10.3539 5.50209L14.8826 0.82042C14.9143 0.912242 14.957 1.03753 15 1.1624L14.9996 9.83648Z"
        fill={color}
      />
      <path
        d="M0.783158 0.0887042C0.972179 0.0597294 1.1612 0.007085 1.35061 0.007085C3.47354 0.0002834 5.59672 -0.00162105 7.72017 0.00137166C9.69764 0.00137166 11.6751 0.00137166 13.6522 0.00545262C13.8412 0.00545262 14.0302 0.0601375 14.2193 0.0891123L14.2216 0.141349C14.1895 0.176853 14.1586 0.213582 14.1253 0.24827C12.064 2.40438 10.0038 4.56198 7.9448 6.72108C7.68534 6.9945 7.31513 6.99736 7.05331 6.72108C4.99874 4.55246 2.93609 2.39132 0.865341 0.237659C0.837947 0.209092 0.811727 0.179301 0.785115 0.150327L0.783158 0.0887042Z"
        fill={color}
      />
      <path
        d="M9.70078 6.18158L14.2474 10.8853C14.0784 10.9261 13.9344 10.9628 13.7915 10.9959C13.7576 11.0004 13.7234 11.0012 13.6894 10.9983C9.56537 10.9983 5.44123 10.9971 1.31695 10.9947C1.13811 10.9947 0.958872 10.9367 0.746371 10.9L5.30986 6.17994C5.31495 6.18484 5.349 6.21218 5.37718 6.2432C5.74583 6.62899 6.11434 7.01477 6.48273 7.40056C7.07954 8.02209 7.91663 8.02331 8.51539 7.40505C8.91378 6.99287 9.31452 6.58151 9.70078 6.18158Z"
        fill={color}
      />
      <path
        d="M4.66571 5.51067L0.131957 10.1993C0.0428808 10.0266 -0.00238269 9.833 0.00046447 9.63693C0.00046447 8.4461 0.00046447 7.25514 0.00046447 6.06405C0.00046447 4.50145 -0.00227495 2.93966 0.00555197 1.37666C0.00555197 1.18036 0.0658193 0.984475 0.0986924 0.783284C1.63355 2.37119 3.1418 3.93215 4.66571 5.51067Z"
        fill={color}
      />
    </svg>
  );
};

export default MailSVG;

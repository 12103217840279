import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { NAVBAR_CONFIG } from 'constants/navigation';
import { TypographyComponent } from 'enums/ui';
import { useClickOutside } from 'hooks/useClickOutside';

import { selectIsMobileLandscapeAndLower } from 'store/selectors/ui';

import CrossSVG from 'components/@icons/CrossSVG';
import BackLink from 'components/BackLink';
import {
  NavigationContainer,
  NavigationLink,
  NavigationLinkText,
  Overlay,
} from 'components/Navbar/Navbar.styles';

interface Props {
  isOpenSidebar: boolean;
  closeSideBar(): void;
}

const Navbar: FC<Props> = ({ isOpenSidebar, closeSideBar }) => {
  // const isDarkMode = useDarkMode();
  const {
    colors: {
      white,
      text: { primary: iconColor },
    },
  } = useTheme();

  const isMobileView = useSelector(selectIsMobileLandscapeAndLower);
  const isDesktopOrTablet = !isMobileView;

  const ref = useClickOutside(closeSideBar);

  return (
    <>
      <NavigationContainer $isOpenSidebar={isOpenSidebar} ref={ref}>
        {isDesktopOrTablet && <BackLink />}

        <CrossSVG color={iconColor} onClick={closeSideBar} />

        {NAVBAR_CONFIG.map(({ title, Icon, path }) => (
          <NavigationLink
            $isDarkMode={false}
            end={path === '/'}
            key={title}
            to={path}
            onClick={closeSideBar}
          >
            {({ isActive }) => (
              <>
                <Icon color={isActive ? white : iconColor} />
                <NavigationLinkText $isActive={isActive} component={TypographyComponent.H7}>
                  {title}
                </NavigationLinkText>
              </>
            )}
          </NavigationLink>
        ))}
      </NavigationContainer>
      {/*prevents clicks on page elements when the sidebar is open*/}
      {isMobileView && <Overlay $isOpenSidebar={isOpenSidebar} />}
    </>
  );
};

export default memo(Navbar);

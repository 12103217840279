import { FC, memo } from 'react';
import { styled } from 'styled-components';

import { ANONYMOUS_STATUS } from 'constants/application';
import { ApplicationStatus, ProcessingStopReason } from 'enums/application';

type UnifiedLoanStatus = ApplicationStatus | ProcessingStopReason | typeof ANONYMOUS_STATUS;

export const ChipContainer = styled.div<{
  $isAnonymous?: boolean;
}>`
  padding: 0 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  min-width: 100px;
  max-width: 170px;
  min-height: 25px;
  max-height: 40px;
  border-radius: 8px;

  font-size: 12px;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, $isAnonymous }) =>
    $isAnonymous ? theme.colors.primary : theme.colors.secondary};
`;

interface Props {
  className?: string;
  status: UnifiedLoanStatus;
  progressLastCompletedStateType?: string;
}

const Chip: FC<Props> = ({ className, status, progressLastCompletedStateType, ...props }) => {
  const isAnonymous = status === ANONYMOUS_STATUS;
  const formattedStatus = isAnonymous ? ANONYMOUS_STATUS : progressLastCompletedStateType;

  if (!status && !isAnonymous) {
    return null;
  }

  return (
    <ChipContainer {...props} $isAnonymous={isAnonymous} className={className}>
      {formattedStatus}
    </ChipContainer>
  );
};

export default memo(Chip);

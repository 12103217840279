import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { NO_APPLICATIONS_MSG } from 'constants/application';
import { SortDirection } from 'enums/common';
import usePagedApplications from 'hooks/usePagedApplications';

import { LoanApplication } from 'types/application';

import { TableStyled } from './ApplicationsTable.styles';
import { getColumns, SORT_BY_MAP, SortByType } from './ApplicationsTable.util';

const PAGE_SIZE = 10;

interface ApplicationsTableProps {
  status?: string | null;
}

const ApplicationsTable: FC<ApplicationsTableProps> = ({ status }) => {
  const {
    colors: {
      card: { icon: iconColor },
    },
  } = useTheme();
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState<string>('lastUpdated');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);

  const { applications, isError, isFetchingNewQuery, currentPage, maxPage, setPage } =
    usePagedApplications({
      pageSize: PAGE_SIZE,
      status,
      sortBy: SORT_BY_MAP[sortBy as SortByType],
      sortDirection,
    });

  const columns = useMemo(() => getColumns(iconColor), [iconColor]);

  const redirectToDetails = useCallback(
    ({ applicationId }: LoanApplication) => {
      navigate(`../pipeline/${applicationId}`);
    },
    [navigate],
  );

  return (
    <TableStyled
      blockTestName="loansTable"
      columns={columns}
      currentPage={currentPage}
      data={applications}
      emptyStateText={NO_APPLICATIONS_MSG}
      isError={isError}
      isLoading={isFetchingNewQuery}
      maxPage={maxPage}
      pageSize={PAGE_SIZE}
      setPage={setPage}
      setSortBy={setSortBy}
      setSortDirection={setSortDirection}
      sortBy={sortBy}
      sortDirection={sortDirection}
      onRowClick={redirectToDetails}
    />
  );
};

export default ApplicationsTable;

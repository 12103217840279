import { styled } from 'styled-components';

import { CELL_STYLES } from 'components/Table/Table.styles';
import { Typography } from 'components/Typography';

import { getLineClampStyles } from 'styles/typography';

export const StyledCell = styled(Typography)<{
  minWidth?: string;
  maxWidth?: string;
  isReverse?: boolean;
}>`
  ${CELL_STYLES};

  // three-line limit
  ${getLineClampStyles(3)}
`;

import { FC } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { SubmitButton, Text, Title } from '../@shared';

const Error: FC = () => {
  const modal = useModal();

  return (
    <>
      <Title isCentered>We&apos;re Sorry!</Title>

      <Text>
        Unable to pre-approve latest changes. Please contact assigned loan officer for more
        information.
      </Text>

      <SubmitButton onClick={modal.remove}>Done</SubmitButton>
    </>
  );
};

export default Error;

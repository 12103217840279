import { FC, memo, MouseEvent, useCallback, useMemo } from 'react';

import { getEmptyArray } from 'utils/array';

import { PageItem, PaginationContainer, Separator } from './Pagination.styles';

interface PaginationProps {
  currentPage: number;
  maxPage: number;
  setPage(page: number): void;
}

const Pagination: FC<PaginationProps> = ({ currentPage, maxPage, setPage }) => {
  const pages = useMemo(() => getEmptyArray(maxPage).map((_, index) => index + 1), [maxPage]);

  const onClick = useCallback(
    (e: MouseEvent) => {
      setPage(parseInt((e.target as HTMLElement).innerText, 10));
    },
    [setPage],
  );

  const firstFivePages = useMemo(
    () => [...pages.slice(0, 5), 'separator2', maxPage],
    [maxPage, pages],
  );
  const lastFivePages = useMemo(
    () => [1, 'separator1', ...pages.slice(maxPage - 5, maxPage)],
    [maxPage, pages],
  );
  const shownPages = useMemo(
    () => [1, 'separator1', currentPage - 1, currentPage, currentPage + 1, 'separator2', maxPage],
    [currentPage, maxPage],
  );

  const isFirstFour = currentPage <= 4;
  const isLastFour = currentPage > maxPage - 4;
  const isLessThanEight = maxPage <= 7;

  if (maxPage === 1) {
    // adds empty space if haven't pagination
    return <PaginationContainer />;
  }

  if (isLessThanEight || isFirstFour || isLastFour) {
    const firstOrLastPages = isFirstFour ? firstFivePages : lastFivePages;
    // show pages without separators
    const items = isLessThanEight ? pages : firstOrLastPages;

    return (
      <PaginationContainer>
        {items.map(item =>
          typeof item === 'string' ? (
            <Separator key={item}>...</Separator>
          ) : (
            <PageItem $isActive={item === currentPage} key={item} onClick={onClick}>
              {item}
            </PageItem>
          ),
        )}
      </PaginationContainer>
    );
  }

  return (
    <PaginationContainer>
      {shownPages.map(item =>
        typeof item === 'string' ? (
          <Separator key={item}>...</Separator>
        ) : (
          <PageItem $isActive={item === currentPage} key={item} onClick={onClick}>
            {item}
          </PageItem>
        ),
      )}
    </PaginationContainer>
  );
};

export default memo(Pagination);

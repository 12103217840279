export enum EditPreApprovalResponseStatus {
  RequestSent = 'RequestSent',
  NotEligibility = 'NotEligibility',
  AusFailed = 'AusFailed',
}

export enum EditPreApprovalRequestType {
  // Partial needed for need more modal on apply
  Partial = 'Partial',
  Full = 'Full',
}

import { FC, memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { downloadFileFromBlob } from 'utils/browser';
import { getRealtorId } from 'utils/id';

import { useDownloadDocumentMutation } from 'services/documentsAPI';
import { useSubmitTaskMutation } from 'services/tasksAPI';

import CircleSpinner from 'components/CircleSpinner';

import { TaskHandlerProps } from './TaskForm';
import { Container, DownloadButton } from './TaskForm.styles';

const FormDownload: FC<TaskHandlerProps> = ({ task, isReadonly }) => {
  const { id: applicationId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [downloadDocument] = useDownloadDocumentMutation();

  const [submitTask] = useSubmitTaskMutation();

  const realtorId = getRealtorId();
  // Task with dataType 'Forms' can have only 1 documentId
  const documentId = task?.documentIds?.[0];
  const isDisabled = !documentId || isReadonly;

  /* Handle download in this callback to overcome Safari (iOS) issue:
    it opens downloaded document in the same tab preventing task from completion.
    So we receive document data => submit task => execute download document
  */
  const onDocumentDownload = useCallback(async () => {
    const { id, name, isSignatureRequired } = task;

    if (documentId) {
      setIsLoading(true);

      const fd = new FormData();
      fd.append('taskId', id);
      fd.append('realtorId', realtorId);

      const data = await downloadDocument({ name, documentId, skipDownload: true })
        .unwrap()
        .catch(() => setIsLoading(false));

      // prevents task from completion if no document is received
      if (!data?.size) {
        setIsLoading(false);
        return;
      }

      // If signature is required - only borrower can submit the task
      if (!isSignatureRequired && !isReadonly) {
        await submitTask({ id, applicationId, body: fd });
      }

      setIsLoading(false);

      const fileName = `${name ?? 'document'}.pdf`;
      downloadFileFromBlob(data, fileName);
    }
  }, [applicationId, documentId, downloadDocument, isReadonly, realtorId, submitTask, task]);

  return (
    <Container>
      <DownloadButton
        data-testid="task_download_button"
        disabled={isDisabled}
        isSubmitting={isLoading}
        onClick={onDocumentDownload}
      >
        {isLoading ? <CircleSpinner size={17} /> : 'Download Document'}
      </DownloadButton>
    </Container>
  );
};

export default memo(FormDownload);

import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import { HEADER_HEIGHT } from 'constants/ui';
import { MediaQueries, TypographySize, TypographyWeight } from 'enums/ui';

import LogoSVG from 'components/@icons/LogoSVG';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

export const HeaderWrapper = styled.header`
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  padding: 0 40px;

  background-color: ${({ theme }) => theme.colors.card.background};

  box-shadow: ${({ theme }) => `0px 10px 30px 0 ${theme.colors.shadow}`};

  @media (max-width: ${MediaQueries.Tablet}) {
    padding: 0 20px;
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const RightBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;

  cursor: pointer;
`;

export const ProfileImage = styled.img`
  border-radius: 50px;
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;

export const ProfileImageSkeleton = styled(Skeleton)`
  border-radius: 50px;
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;

export const FullName = styled(Typography)`
  margin-right: 10px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: none;
  }
`;

// `$` avoid passing isRotated prop to svg tag
export const ArrowIcon = styled(Arrow)<{ $isRotated: boolean }>`
  margin-left: 5px;
  transition: 0.7s;

  ${({ $isRotated }) =>
    $isRotated &&
    css`
      transform: rotate(180deg);
    `}
`;

export const RouterLink = styled(Link)`
  // removes extra paddings
  display: flex;
`;

export const LogoSVGStyled = styled(LogoSVG)`
  @media (max-width: ${MediaQueries.Tablet}) {
    height: 33px;
    width: 64px;
  }
`;

export const LogoStyled = styled.img`
  height: 50px;

  @media (max-width: ${MediaQueries.Tablet}) {
    height: 35px;
  }
`;

export const NameSkeleton = styled(Skeleton)`
  width: 90px;
  height: 18px;
  border-radius: 7px;
  margin-right: 5px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: none;
  }
`;

export const DropDownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 55px;
  // overlaps toasts
  z-index: 10000;

  border-radius: 20px;
  height: min-content;
  width: 140px;
  padding: 5px;

  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.card.background};
  box-shadow: ${({ theme }) => `0px 10px 30px 0 ${theme.colors.shadow}`};
`;

const StyledDropDownItem = styled(Typography)`
  padding: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const DropDownItem: FC<HTMLAttributes<HTMLParagraphElement>> = ({ children, ...props }) => (
  <StyledDropDownItem {...props} size={TypographySize.Small} weight={TypographyWeight.Regular}>
    {children}
  </StyledDropDownItem>
);

import { memo, SetStateAction, useCallback, useMemo } from 'react';
import Select, { OnChangeValue, Props as ReactSelectProps, StylesConfig } from 'react-select';
import { DefaultTheme, ThemeConsumer } from 'styled-components';

import useUniqueId from 'hooks/useUniqueId';
import { inputKeyHandler } from 'utils/ui';

import FieldLabel from 'components/FieldLabel';

import { getCustomStyles, SelectContainer } from './SelectField.styles';

type OptionType<T> = { label: T; value: T };

interface SelectFieldProps<T> extends Omit<ReactSelectProps, 'onChange'> {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options?: OptionType<T>[];
  onChange: (value: T) => void | SetStateAction<T>;
}

const ControlledSelectField = <T extends unknown>({
  options,
  name,
  disabled,
  label,
  placeholder,
  isSearchable = true,
  styles = {},
  required,
  onChange,
  value,
  className,
}: SelectFieldProps<T>) => {
  const id = useUniqueId();

  const formattedPlaceholder = required && placeholder ? `${placeholder}*` : placeholder;
  const formattedLabel = required ? `${label}*` : label;
  const validPlaceholder = formattedPlaceholder || formattedLabel;

  const getSelectStyles = useCallback(
    // eslint-disable-next-line
    (theme?: DefaultTheme): StylesConfig<any, false> => ({
      ...(theme ? getCustomStyles(theme, false) : {}),
      ...styles,
    }),
    [styles],
  );

  const currentValue = useMemo(
    (): OptionType<T> | undefined =>
      options?.find((option: OptionType<T>) => option.value === value),
    [value, options],
  );

  const onChangeSelect = useCallback(
    (option: OnChangeValue<OptionType<T>, false>): void => {
      const value = (option as OptionType<T>).value;

      if (value === currentValue?.value) {
        return;
      }

      onChange?.(value);
    },
    [currentValue?.value, onChange],
  );

  return (
    <SelectContainer className={className}>
      <FieldLabel htmlFor={id}>{label}</FieldLabel>
      <ThemeConsumer>
        {theme => (
          <Select
            inputId={id}
            isDisabled={disabled}
            isSearchable={isSearchable}
            name={name}
            options={options}
            placeholder={validPlaceholder}
            styles={getSelectStyles(theme)}
            value={currentValue || null}
            onChange={onChangeSelect}
            onKeyDown={inputKeyHandler}
          />
        )}
      </ThemeConsumer>
    </SelectContainer>
  );
};

export default memo(ControlledSelectField) as typeof ControlledSelectField;

import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { LoanCondition } from 'types/conditions';

import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import { Table } from 'components/Table';
import { Typography } from 'components/Typography';

export const ConditionsWrapper = styled(Card)`
  min-width: 700px;
  max-width: 700px;
  height: fit-content;

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (min-width: ${MediaQueries.Desktop}) {
    max-width: 1060px;
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 15px;
`;

export const StyledTable = styled(Table<LoanCondition>)`
  height: initial; // fixes absense of pagination
  margin-top: 20px;
`;

export const ConditionSkeleton = styled(Skeleton)`
  width: 100%;
  height: 56px;
  border-radius: 20px;
  margin-bottom: 10px;
`;

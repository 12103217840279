import { FC, SVGProps } from 'react';

const PriceSheetSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="21" viewBox="0 0 16 21" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.19529 2.22813C2.19529 1.95047 2.16796 1.70063 2.20014 1.45945C2.3149 0.596147 3.00042 0.00185523 3.8693 0.00185523C6.82428 -0.00061841 9.77784 -0.00061841 12.73 0.00185523C13.2601 0.00185523 13.7901 0.00185523 14.3202 0.00185523C15.2869 0.00742092 15.9979 0.728487 15.9985 1.71547C16.0009 6.49784 16.0009 11.2802 15.9985 16.0626C15.9985 17.0483 15.2856 17.7694 14.3202 17.7774C14.1623 17.7774 14.0045 17.7774 13.8181 17.7774C13.8181 17.9667 13.8181 18.1411 13.8181 18.3161C13.8065 19.2598 13.0992 19.9963 12.1787 19.9975C8.66507 20.0025 5.15128 20.0025 1.63728 19.9975C1.42219 19.9987 1.20901 19.9563 1.0101 19.873C0.811194 19.7896 0.630513 19.6668 0.47853 19.5118C0.326547 19.3568 0.206286 19.1726 0.124716 18.9699C0.0431461 18.7672 0.00189027 18.55 0.00333953 18.3309C-0.00111318 13.5184 -0.00111318 8.70577 0.00333953 3.89289C0.00333905 3.4518 0.175216 3.02873 0.481229 2.7166C0.787241 2.40447 1.20237 2.22879 1.63546 2.22813C1.8079 2.22257 1.98095 2.22813 2.19529 2.22813ZM1.09324 11.1128C1.09324 13.4888 1.09324 15.8647 1.09324 18.2406C1.09324 18.6785 1.29179 18.8881 1.71136 18.8887C5.18042 18.8887 8.64929 18.8887 12.1179 18.8887C12.5235 18.8887 12.7251 18.676 12.7251 18.2555C12.7251 17.924 12.7215 17.5919 12.7251 17.2604C12.7306 16.895 12.9492 16.6742 13.3105 16.6692C13.6359 16.6643 13.962 16.6692 14.2874 16.6692C14.6973 16.6662 14.9043 16.4596 14.9043 16.0434C14.9043 11.2763 14.9043 6.50897 14.9043 1.74144C14.9043 1.32587 14.6973 1.11685 14.2868 1.11685C10.8206 1.11561 7.35375 1.11499 3.8863 1.11499C3.4801 1.11499 3.27912 1.32773 3.27912 1.74825C3.27912 2.07971 3.28276 2.4118 3.27912 2.74327C3.27304 3.10875 3.05385 3.32952 2.69318 3.33509C2.39809 3.34003 2.10239 3.33509 1.80729 3.33509C1.26629 3.33632 1.09324 3.51257 1.09324 4.0543V11.1128Z"
        fill={color}
      />
      <path
        d="M13.8181 9.58073V15.2027C13.8216 15.2875 13.819 15.3724 13.8102 15.4569C13.791 15.5895 13.7252 15.7105 13.6249 15.7974C13.5247 15.8843 13.3969 15.9312 13.2653 15.9294C13.1337 15.9276 13.0072 15.8772 12.9093 15.7875C12.8114 15.6979 12.7488 15.5751 12.7331 15.442C12.7261 15.3496 12.7243 15.2569 12.7276 15.1644C12.7276 11.4704 12.7276 7.77641 12.7276 4.08244C12.7276 3.50485 12.5637 3.33602 11.999 3.33602H4.73157C4.64835 3.33913 4.56502 3.33727 4.48201 3.33046C4.35303 3.31021 4.23541 3.24362 4.15045 3.14273C4.06548 3.04183 4.01877 2.9133 4.01877 2.78038C4.01877 2.64746 4.06548 2.51893 4.15045 2.41804C4.23541 2.31714 4.35303 2.25055 4.48201 2.23031C4.55679 2.22513 4.63181 2.22451 4.70667 2.22845C7.15242 2.22845 9.59817 2.22845 12.0439 2.22845C13.1326 2.22845 13.8175 2.92416 13.8181 4.03112C13.8189 5.88016 13.8189 7.73003 13.8181 9.58073Z"
        fill={color}
      />
      <path
        d="M5.45594 8.56344C5.45594 8.23074 5.66178 7.98276 5.96051 7.96359C6.26897 7.9438 6.50091 8.14911 6.54463 8.48181C6.55374 8.54922 6.54949 8.64198 6.59017 8.67785C6.6855 8.76257 6.80511 8.87512 6.9138 8.87389C6.97753 8.86651 7.03869 8.84412 7.09244 8.80848C7.14619 8.77284 7.19105 8.72494 7.22346 8.66857C7.26779 8.57025 7.24472 8.36988 7.17246 8.29753C6.98241 8.112 6.74743 7.9809 6.53431 7.82321C6.32118 7.66551 6.07406 7.51771 5.88583 7.32291C5.19424 6.60927 5.36001 5.50602 6.21189 5.00016C6.29565 4.94312 6.36394 4.86549 6.41044 4.77444C6.5252 4.56913 6.6861 4.44607 6.92048 4.4504C7.14453 4.45473 7.29633 4.57779 7.40623 4.77383C7.46039 4.86929 7.53565 4.9506 7.62603 5.0113C8.06017 5.28711 8.31397 5.67609 8.35951 6.19555C8.39109 6.5567 8.18343 6.82695 7.87376 6.85478C7.55074 6.88384 7.31576 6.67296 7.27629 6.30501C7.25322 6.09228 7.15485 5.95561 6.94294 5.93396C6.86293 5.92155 6.7812 5.939 6.71281 5.98308C6.64442 6.02717 6.59396 6.09494 6.57074 6.17391C6.5405 6.24179 6.53469 6.31835 6.55435 6.39016C6.574 6.46197 6.61785 6.52443 6.67821 6.5666C6.87858 6.71687 7.09353 6.84797 7.2939 6.99948C7.5307 7.17635 7.78997 7.33652 7.98548 7.55605C8.6121 8.253 8.43541 9.3179 7.6406 9.80459C7.54454 9.86905 7.46435 9.95518 7.40623 10.0563C7.28965 10.2498 7.13542 10.3698 6.91198 10.3704C6.68853 10.3711 6.53431 10.2468 6.41651 10.0575C6.3579 9.95662 6.27781 9.8704 6.18214 9.80521C5.74921 9.53002 5.46019 9.04951 5.45594 8.56344Z"
        fill={color}
      />
      <path
        d="M6.91198 12.5942C5.90607 12.5942 4.89976 12.5942 3.89304 12.5942C3.57487 12.5942 3.36175 12.4409 3.29132 12.1725C3.27298 12.0954 3.27102 12.0153 3.28557 11.9374C3.30012 11.8595 3.33085 11.7856 3.37569 11.7208C3.42054 11.656 3.47846 11.6017 3.54559 11.5615C3.61272 11.5213 3.6875 11.4962 3.76492 11.4879C3.83299 11.4824 3.90131 11.481 3.96955 11.4836H9.84773C9.92349 11.4803 9.99938 11.4825 10.0748 11.4904C10.2074 11.5093 10.3285 11.5771 10.4153 11.6809C10.502 11.7848 10.5483 11.9174 10.5454 12.0537C10.5424 12.1986 10.4832 12.3363 10.3808 12.4369C10.2784 12.5375 10.1411 12.5927 9.99892 12.5905C9.33101 12.5992 8.66736 12.5936 8.00127 12.5942H6.91198Z"
        fill={color}
      />
      <path
        d="M6.16999 17.0375H3.85539C3.61252 17.0375 3.43036 16.9324 3.32593 16.7091C3.22999 16.502 3.24639 16.2837 3.41276 16.1334C3.52691 16.0301 3.69935 15.9367 3.84689 15.9355C5.40494 15.9213 6.96298 15.925 8.52224 15.9287C8.8568 15.9287 9.09542 16.1761 9.09057 16.489C9.08571 16.8019 8.84769 17.0338 8.50827 17.0357C7.72804 17.0406 6.94902 17.0381 6.16999 17.0375Z"
        fill={color}
      />
      <path
        d="M5.63931 14.8162C5.05702 14.8162 4.47473 14.8162 3.89243 14.8162C3.52144 14.8162 3.2731 14.5868 3.27553 14.2596C3.27796 13.9325 3.52508 13.7092 3.89972 13.7086C5.05621 13.7086 6.2131 13.7086 7.3704 13.7086C7.74747 13.7086 7.99398 13.9269 7.99884 14.2559C8.0037 14.5849 7.75232 14.8174 7.36312 14.8187C6.78872 14.8174 6.21371 14.8162 5.63931 14.8162Z"
        fill={color}
      />
      <path
        d="M9.62914 14.8131C9.49313 14.8131 9.35651 14.8224 9.22111 14.8131C9.08405 14.8037 8.9556 14.7417 8.86178 14.6395C8.76795 14.5373 8.71575 14.4026 8.71575 14.2627C8.71575 14.1228 8.76795 13.9881 8.86178 13.8859C8.9556 13.7837 9.08405 13.7217 9.22111 13.7123C9.49274 13.6978 9.76493 13.6978 10.0366 13.7123C10.1742 13.7203 10.3035 13.7817 10.3979 13.8841C10.4923 13.9864 10.5444 14.1217 10.5436 14.2621C10.5424 14.402 10.4895 14.5364 10.3954 14.6384C10.3014 14.7404 10.173 14.8025 10.036 14.8125C9.90177 14.8224 9.76515 14.8131 9.62914 14.8131Z"
        fill={color}
      />
    </svg>
  );
};

export default PriceSheetSVG;

import capitalize from 'lodash/capitalize';
import sum from 'lodash/sum';

import { formatCurrency } from 'utils/formatting';

import { FeeDetails, FeeDetailsFormatted } from 'types/application';

export const formatFeeDetails = (feeDetails: FeeDetails): FeeDetailsFormatted[] =>
  Object.entries(feeDetails).map(([name, value]) => ({
    name: capitalize(name),
    value: formatCurrency(value, { minimumFractionDigits: 2 }),
  }));

export const sumFees = (feeDetails: FeeDetails) =>
  formatCurrency(sum(Object.values(feeDetails)), { minimumFractionDigits: 2 });

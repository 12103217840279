import { FC, memo, useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';

import { TypographyComponent, TypographySize } from 'enums/ui';

import { useDeleteNoteMutation } from 'services/notesAPI';
import { Note } from 'services/notesAPI/types';

import ConfirmationModal from 'components/@modals/ConfirmationModal';
import { Typography } from 'components/Typography';

import { CreatedDate, DeleteIcon, Loader, NoteContainer, Row } from './NoteItem.styles';

const blockTestName = 'note';

interface NoteItemProps extends Note {
  applicationId?: string;
}

const NoteItem: FC<NoteItemProps> = ({ content, noteId, createdDate, applicationId }) => {
  const theme = useTheme();

  const [deleteNote, { isLoading }] = useDeleteNoteMutation();

  const confirmationModal = useModal(ConfirmationModal);

  const onDelete = useCallback(() => {
    deleteNote({ noteId, applicationId });
    confirmationModal.remove();
  }, [applicationId, confirmationModal, deleteNote, noteId]);

  const onClick = useCallback(() => {
    confirmationModal.show({
      onSubmit: onDelete,
      title: 'Are you sure you want to delete this note?',
    });
  }, [confirmationModal, onDelete]);

  return (
    <NoteContainer data-testid={`${blockTestName}__container`}>
      <CreatedDate size={TypographySize.Small}>
        {format(new Date(createdDate), 'MMMM dd, u - hh:mm aaa')}
      </CreatedDate>

      <Row data-testid={`${blockTestName}__item`}>
        <Typography component={TypographyComponent.H7}>{content}</Typography>
        {isLoading ? (
          <Loader color={theme.colors.primary} size={14} />
        ) : (
          <DeleteIcon data-testid={`${blockTestName}__deleteButton`} onClick={onClick} />
        )}
      </Row>
    </NoteContainer>
  );
};

export default memo(NoteItem);

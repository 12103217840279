interface CalculateDefaultLeftIndexProps {
  slideIndex: number;
  totalSlidesCount: number;
  currentSlideIndex: number;
  visibleSlidesCount: number;
  isCompact?: boolean;
}

export const calculateDefaultLeftIndex = ({
  slideIndex,
  totalSlidesCount,
  currentSlideIndex,
  visibleSlidesCount,
}: CalculateDefaultLeftIndexProps): number => {
  switch (slideIndex) {
    case 0:
      return 0;

    case totalSlidesCount - 1:
      return totalSlidesCount - 3;

    default:
      if (totalSlidesCount > 3) {
        const isForwardWithNav = slideIndex > currentSlideIndex && visibleSlidesCount < 3;
        return slideIndex - (isForwardWithNav ? 0 : 1);
      }

      return slideIndex - 1;
  }
};

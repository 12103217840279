import { Form as FormikForm } from 'formik';
import { darken } from 'polished';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Button from 'components/Button';
import Card from 'components/Card';
import ErrorMessage from 'components/ErrorMessage';
import { Separator } from 'components/SeparatorLine';

import { ReactComponent as Chain } from 'assets/icons/chain.svg';

export const InviteBuyerCardContainer = styled(Card)`
  width: 340px;
  padding-bottom: 15px;

  @media (max-width: ${MediaQueries.Desktop}) {
    width: 100%;
  }
`;

export const Form = styled(FormikForm)`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  & > ${Separator} {
    margin-bottom: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  width: 100%;
`;

export const ChainIcon = styled(Chain)`
  transition: 0.7s;

  & > path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const SendInviteButton = styled(Button)`
  width: 100%;
  height: 39px;
`;

export const ButtonWithIcon = styled(Button)`
  font-size: 14px;

  & > :first-child {
    margin-right: 5px;
  }

  &:focus,
  &:hover {
    & > svg > path {
      fill: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }
  }
`;

export const InviteCardError = styled(ErrorMessage)`
  @media (min-width: ${MediaQueries.Desktop}) {
    max-width: 290px;
  }
`;

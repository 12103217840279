import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Modal from 'components/Modal';

export const PurchaseAgreementModalContainer = styled(Modal)<{ isSelectProgramStep: boolean }>`
  padding: 25px 30px 50px;
  max-height: 100vh;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    min-width: 90%;

    ${({ isSelectProgramStep }) =>
      isSelectProgramStep &&
      css`
        padding: 25px 10px 10px;
      `}
  }
`;

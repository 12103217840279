import { FC, memo } from 'react';

import { ReactComponent as CheckMarkIcon } from 'assets/icons/checkmark.svg';

import { Indicator } from './CheckedMark.styles';

export interface CheckedMarkProps {
  isChecked: boolean;
  className?: string;
}

const CheckedMark: FC<CheckedMarkProps> = ({ isChecked, className }) => {
  return (
    <Indicator aria-hidden="true" className={className} isChecked={isChecked}>
      <CheckMarkIcon />
    </Indicator>
  );
};

export default memo(CheckedMark);

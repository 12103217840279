import { FC, ReactNode } from 'react';
import { styled } from 'styled-components';

import { MediaQueries, TypographyComponent } from 'enums/ui';

import AnimatedLogoSVG from 'components/@icons/AnimatedLogoSVG';
import Button from 'components/Button/Button';
import { Typography } from 'components/Typography';

export const Text = styled(Typography)`
  margin-top: 10px;
  text-align: center;
`;

export const Loader = styled(AnimatedLogoSVG)`
  margin: 25px 50px 15px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    margin: 25px auto 15px;
  }
`;

const StyledTitle = styled(Typography)<{ isCentered?: boolean }>`
  padding: ${({ isCentered }) => (isCentered ? 0 : '0 20px 5px 0')};
  text-align: ${({ isCentered }) => isCentered && 'center'};

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    font-size: 18px;
  }
`;

export const Title: FC<{ isCentered?: boolean; children?: ReactNode }> = ({
  children,
  isCentered,
}) => (
  <StyledTitle component={TypographyComponent.H5} isCentered={isCentered}>
    {children}
  </StyledTitle>
);

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 25px;
`;

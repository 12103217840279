import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import get from 'lodash/get';

import { SIGNAL_R_CONNECTION_URL } from 'constants/api';
import { RealTimeEvent } from 'enums/signalR';
import { authClient } from 'utils/auth';

const RETRY_PERIOD_BY_RETRY_COUNT = {
  0: 0,
  1: 2000,
  2: 10_000,
  3: 30_000,
};

class SignalRConnection {
  #connection?: HubConnection;

  init() {
    this.#connection = new HubConnectionBuilder()
      .withUrl(SIGNAL_R_CONNECTION_URL, {
        withCredentials: false,
        accessTokenFactory: () => {
          return authClient.getAccessToken() as string;
        },
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: ({ previousRetryCount }) =>
          get(RETRY_PERIOD_BY_RETRY_COUNT, previousRetryCount, null),
      })
      .configureLogging(LogLevel.Information)
      .build();
  }

  onreconnecting(...args: Parameters<HubConnection['onreconnecting']>) {
    if (!this.#connection) {
      throw new Error('Trying to reconnect without active connection!');
    }

    return this.#connection?.onreconnecting(...args);
  }

  onreconnected(...args: Parameters<HubConnection['onreconnected']>) {
    if (!this.#connection) {
      throw new Error('Trying to reconnect without active connection!');
    }

    return this.#connection.onreconnected(...args);
  }

  on(...args: Parameters<HubConnection['on']>) {
    if (!this.#connection) {
      throw new Error('Trying to invoke without active connection!');
    }

    return this.#connection.on(...args);
  }

  off(methodName: RealTimeEvent) {
    if (!this.#connection) {
      throw new Error('Trying to invoke without active connection!');
    }

    return this.#connection.off(methodName);
  }

  start(...args: Parameters<HubConnection['start']>) {
    if (!this.#connection) {
      throw new Error('Trying to start connection without active connection!');
    }

    return this.#connection.start(...args);
  }

  stop(...args: Parameters<HubConnection['stop']>) {
    if (!this.#connection) {
      throw new Error('Trying to stop connection without active connection!');
    }

    return this.#connection.stop(...args);
  }

  invoke(methodName: string, ...args: Parameters<HubConnection['invoke']>) {
    if (!this.#connection) {
      throw new Error('Trying to invoke without active connection!');
    }

    return this.#connection.invoke(methodName, ...args);
  }

  // TODO: handles case when user navigates directly to application details. Can be refactored.
  tryInvoke(methodName: string, count: number, ...args: Parameters<HubConnection['invoke']>) {
    if (this.#connection?.state === HubConnectionState.Connected) {
      return this.invoke(methodName, ...args);
    }

    let attemptCount = 0;
    const id = setInterval(() => {
      if (this.#connection?.state === HubConnectionState.Connected) {
        clearInterval(id);
        return this.invoke(methodName, ...args);
      } else if (attemptCount >= count) {
        clearInterval(id);
      }
      attemptCount++;
    }, 3000);
  }

  onclose(...args: Parameters<HubConnection['onclose']>) {
    if (!this.#connection) {
      throw new Error('Trying to close connection without active connection!');
    }

    return this.#connection?.onclose(...args);
  }
}

export default SignalRConnection;

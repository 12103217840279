import { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import { selectRealtorInfo } from 'store/selectors/auth';
import { FormBundleModel } from 'types/documents';

import AllDocumentsModal from './AllDocumentsModal';
import DocumentItem from './DocumentItem';
import { AllDocumentsButton, DocumentsContainer } from './Documents.styles';

interface DocumentItemProps {
  documents: FormBundleModel[];
  milestoneName: string;
}

const Documents: FC<DocumentItemProps> = ({ documents, milestoneName }) => {
  const { fullName } = useSelector(selectRealtorInfo) || {};

  const isShowAllDocsButton = documents.length > 3;
  const limitedDocs = useMemo(() => documents.slice(0, 3), [documents]);

  const onAllDocsClick = useCallback(() => {
    NiceModal.show(AllDocumentsModal, { documents: documents, milestoneName, fullName });
  }, [documents, fullName, milestoneName]);

  if (!fullName) {
    return null;
  }

  return (
    <DocumentsContainer>
      {limitedDocs.map(doc => (
        <DocumentItem
          blockTestName="progress__document"
          document={doc}
          fullName={fullName}
          key={doc.documentId}
        />
      ))}
      {isShowAllDocsButton && (
        <AllDocumentsButton onClick={onAllDocsClick}>View All Documents</AllDocumentsButton>
      )}
    </DocumentsContainer>
  );
};

export default memo(Documents);

import { createApi } from '@reduxjs/toolkit/query/react';

import { loansQueryWithReauth } from 'utils/api';

import { LoanApplicationsData } from 'types/application';
import { MilestoneDefinition } from 'types/milestones';

import {
  GetLoanApplicationsPayload,
  GetLoanApplicationsResponse,
  GetMilestoneDefinitionPayload,
} from './types';
import { createParamsForGetLoanApplications, loanApplicationMapper } from './utils';

const loansAPI = createApi({
  reducerPath: 'loansAPI',
  baseQuery: loansQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getLoanApplications: build.query<LoanApplicationsData, GetLoanApplicationsPayload>({
      query: payload => ({
        url: 'api/loanapplications',
        method: 'GET',
        params: createParamsForGetLoanApplications(payload),
      }),
      transformResponse: (data: GetLoanApplicationsResponse) => ({
        ...data,
        items: data.items.map(loanApplicationMapper),
      }),
    }),
    getMilestoneDefinition: build.query<MilestoneDefinition, GetMilestoneDefinitionPayload>({
      query: () => ({
        url: 'api/milestoneDefinitionCollection',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetLoanApplicationsQuery,
  useLazyGetLoanApplicationsQuery,
  useGetMilestoneDefinitionQuery,
  useLazyGetMilestoneDefinitionQuery,
} = loansAPI;

export default loansAPI;

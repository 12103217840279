import { useCallback, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ApplicationsViewType } from 'enums/application';

interface Props {
  applicationsViewType: ApplicationsViewType;
  setViewType: (viewType: ApplicationsViewType) => void;
}

const WIDTH_LIMITATION = 767;

export const useApplicationsViewTypeHandler = ({ applicationsViewType, setViewType }: Props) => {
  const [isDisplayViewTypeSwitcher, setIsDisplayViewTypeSwitcher] = useState<boolean>(
    window.innerWidth > WIDTH_LIMITATION,
  );

  const onResize = useCallback(() => {
    if (window.innerWidth < WIDTH_LIMITATION) {
      setIsDisplayViewTypeSwitcher(false);

      if (applicationsViewType === ApplicationsViewType.Table) {
        setViewType(ApplicationsViewType.Cards);

        toast('For this screen type, the display is only available in the form of cards', {
          toastId: 'viewLimitation',
        });
      }
    } else {
      setIsDisplayViewTypeSwitcher(true);
    }
  }, [applicationsViewType, setViewType]);

  useLayoutEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return isDisplayViewTypeSwitcher;
};

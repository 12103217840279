import { FC, MouseEvent, ReactNode } from 'react';
import { darken } from 'polished';
import { css, styled } from 'styled-components';

import { TypographyComponent, TypographyType } from 'enums/ui';

import Button from 'components/Button';
import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

import { blurred } from 'styles/common';
import { getLineClampStyles } from 'styles/typography';

const ellipsisText = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Label: FC<{ children?: ReactNode }> = ({ children }) => (
  <Typography component={TypographyComponent.H7} type={TypographyType.CardLabel}>
    {children}
  </Typography>
);

interface TextWithBlur {
  $isBlurred: boolean;
  children?: ReactNode;
}

const BlurredText = styled(Typography)<TextWithBlur>`
  ${blurred}
`;

export const Text: FC<TextWithBlur> = ({ children, $isBlurred, ...props }) => (
  <BlurredText {...props} $isBlurred={$isBlurred} component={TypographyComponent.H7}>
    {children}
  </BlurredText>
);

const StyledEmail = styled(Typography)`
  ${ellipsisText};
`;

export const Email: FC<{ title: string; children?: ReactNode }> = ({ children, title }) => (
  <StyledEmail component={TypographyComponent.H7} title={title}>
    {children}
  </StyledEmail>
);

const StyledTypography = styled(BlurredText)`
  height: 100%;

  // two-line limit
  ${getLineClampStyles(2)}

  // fix bug on IOS with blinking blurred address text (21996)
  ${props =>
    props.$isBlurred &&
    ` overflow: initial;
    `}
`;

export const Address: FC<TextWithBlur> = ({ children, $isBlurred, ...props }) => (
  <StyledTypography {...props} $isBlurred={$isBlurred} component={TypographyComponent.H7}>
    {children}
  </StyledTypography>
);

interface IActionCardText {
  $alignCenter?: boolean;
  $isLoading?: boolean;
  onClick?(event: MouseEvent): void;
}

export const ActionCardText = styled(Typography)<IActionCardText>`
  align-self: ${({ $alignCenter }) => ($alignCenter ? 'center' : 'flex-end')};

  // for better usability (more space to click)
  padding: 7px;
  margin: -7px;

  // pointer-events: none cannot be used here to prevent route change, checks for $isLoading in callbacks
  ${({ $isLoading, theme }) =>
    $isLoading
      ? `
        opacity: 0.5;
        cursor: default;
      `
      : `
        &:hover {
          color: ${darken(theme.coefficients.primaryHovered, theme.colors.primary)};
        }
      `}
`;

export const FullName = styled(Typography)`
  ${ellipsisText};
`;

interface IApplicationCardContainer {
  $isAppDetails: boolean;
  $$isClickable: boolean;
}

export const ApplicationCardContainer = styled(Card)<IApplicationCardContainer>`
  width: 340px;

  ${({ $$isClickable, theme }) =>
    $$isClickable &&
    `&:hover {
    cursor: pointer;

    & > div > ${FullName} {
      color: ${theme.colors.text.secondary};
    }
  }`}

  & > :not(:last-child) {
    margin-bottom: 15px;
  }

  & > :not(:first-child) > svg,
  & > :not(:first-child) > div > svg {
    margin-bottom: 22px;
  }

  // 4 child
  & > :nth-child(4) > svg {
    margin-bottom: 0;
  }

  ${({ $isAppDetails }) =>
    $isAppDetails &&
    `
  // 2 and 3 children's
  & > :not(:first-child):nth-child(-n + 3) > svg,
  & > :not(:first-child):nth-child(-n + 3) > div > svg {
    margin-bottom: 0;
  }
`}
`;

export const ApplicationCardRow = styled.div<{ $isShort?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: ${({ $isShort }) => ($isShort ? 'initial' : '42px')};
`;

export const LeftBlockApplicationCardRow = styled.div<{
  $isFullWidth?: boolean;
  $isShort?: boolean;
}>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? '260px' : '190px')};
  height: ${({ $isShort }) => ($isShort ? 'initial' : '42px')};

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-bottom: 4px;
    margin-right: 10px;
  }
`;

export const ContainerWithLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApplicationCardWrapper = styled.div``;

export const RequestAccessButton = styled(Button)`
  width: 340px;
  height: 40px;
  margin-top: 20px;
`;

export const ApplicationCardSkeleton = styled(Skeleton)`
  height: 324px;
  width: 340px;
  border-radius: 20px;
`;

export const ChangeProgram = styled(Button)`
  width: 100%;
  align-self: center;
`;

import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { MediaQueries } from 'enums/ui';

import { useGetPotentialLoanOptionsMutation } from 'services/applyAPI';
import { EditPreApprovalLetterParams } from 'types/preApproval';

import { ManageModalProps, Step } from 'components/@modals/@SelectNewProgramSteps/@shared';
import Error from 'components/@modals/@SelectNewProgramSteps/Error';
import SelectProgram from 'components/@modals/@SelectNewProgramSteps/SelectProgram';
import SubmitProgram from 'components/@modals/@SelectNewProgramSteps/SubmitProgram';

import EditData from './EditData';
import { ManagePreApprovalModalContainer } from './ManagePreApproval.styles';

export default NiceModal.create<ManageModalProps>(props => {
  const [preApprovalData, setPreapprovalData] = useState<EditPreApprovalLetterParams>();
  const [selectedProgram, setSelectedProgram] = useState<string>('');
  const [step, setStep] = useState(Step.EditData);

  const [getPotentialLoanOptions, response] = useGetPotentialLoanOptionsMutation();

  const isEditDataStep = step === Step.EditData;
  const hasFullScreenPoint = step === Step.EditData || step === Step.SelectProgram;

  const loanOptions = response?.data?.loanOptions;
  const loanOptionId = response?.data?.selectedLoanOptionId || null;

  return (
    <ManagePreApprovalModalContainer
      fullScreenPoint={hasFullScreenPoint ? MediaQueries.MobileLandscape : undefined}
      isSelectProgramStep={step === Step.SelectProgram}
      shouldCloseOnEsc={isEditDataStep}
      shouldCloseOnOuterClick={false}
      withCloseIcon={isEditDataStep}
    >
      {isEditDataStep && (
        <EditData
          {...props}
          getLoanOptions={getPotentialLoanOptions}
          setPreapprovalData={setPreapprovalData}
          setStep={setStep}
        />
      )}
      {step === Step.SelectProgram && loanOptions && (
        <SelectProgram
          loanOptions={loanOptions}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
          setStep={setStep}
        />
      )}
      {step === Step.SubmitProgram && preApprovalData && (
        <SubmitProgram
          applicationId={props.applicationId}
          loanOptionId={selectedProgram || loanOptionId}
          preApprovalData={preApprovalData}
          setStep={setStep}
        />
      )}
      {step === Step.Error && <Error />}
    </ManagePreApprovalModalContainer>
  );
});

import { lazy, Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { DevTogglesModalContaner } from './DevToggles.styles';

const DevTogglesData = lazy(() => import('components/@modals/DevToggles/DevTogglesData'));

export default NiceModal.create(() => (
  <DevTogglesModalContaner>
    <Suspense fallback={<>Loading... &nbsp; &nbsp; &nbsp;</>}>
      <DevTogglesData />
    </Suspense>
  </DevTogglesModalContaner>
));

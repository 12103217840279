import { css } from 'styled-components';

interface CommonInputProps {
  $isNotValid?: boolean;
  $hasRightIcon?: boolean;
  disabled?: boolean;
}

export const commonInput = css<CommonInputProps>`
  appearance: none;

  width: 100%;
  height: 48px;
  padding: 15px;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.controls.background};
  border: 1px solid transparent;

  color: ${({ theme }) => theme.colors.controls.text};

  // next 4 lines fix lighter text color in Safari & Chrome on iOS/MacOS
  :disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.controls.text};
  }
  opacity: 1;

  font-weight: 400;
  font-size: 14px;

  /* Hide input type number controls */
  /* stylelint-disable */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }

  -moz-appearance: textfield;
  /* stylelint-enable */

  &::placeholder {
    color: ${({ theme }) => theme.colors.controls.placeholder};
  }

  ${({ $isNotValid, theme }) =>
    $isNotValid &&
    css`
      padding-right: 38px;
      color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
    `}

  ${({ $hasRightIcon }) =>
    $hasRightIcon &&
    css`
      padding-right: 57px;
    `}


  ${({ disabled, theme }) =>
    disabled
      ? css`
          background-color: ${theme.colors.controls.disabledBackground};

          &::placeholder {
            color: ${theme.colors.controls.label};
          }
        `
      : css`
          &:hover,
          &:focus {
            border-color: ${theme.colors.primary};
          }
        `}
`;

export const blurred = css<{ $isBlurred?: boolean }>`
  filter: ${({ $isBlurred }) => $isBlurred && 'blur(4px)'};
`;

import { FC, FocusEvent, memo, useCallback } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { useField } from 'formik';

import { COMMON_PHONE_NUMBER_FORMAT } from 'constants/ui';

import TextField from 'components/@fields/TextField';

type Modify<T, R> = Omit<T, keyof R> & R;

interface PhoneFieldProps extends Modify<PatternFormatProps, { format?: string }> {
  name?: string;
  label?: string;
  isAbsoluteError?: boolean;
}

const PhoneField: FC<PhoneFieldProps> = ({
  label = 'Phone',
  name = 'phoneNumber',
  format = COMMON_PHONE_NUMBER_FORMAT,
  ...props
}) => {
  const [{ value }, , { setValue }] = useField(name);

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (e.target.value === format?.replaceAll('#', '_')) {
        // e.target.value === '' with onChange not works
        setValue('');
      }
    },
    [format, setValue],
  );

  return (
    <PatternFormat
      inputMode="tel"
      mask="_"
      value={value}
      onBlur={onBlur}
      {...props}
      customInput={TextField}
      format={format}
      label={label}
      name={name}
      type="tel"
    />
  );
};

export default memo(PhoneField);

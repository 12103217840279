import { FC, SVGProps } from 'react';
import { darken } from 'polished';
import { styled } from 'styled-components';

const Svg = styled.svg<{ color: string }>`
  &:hover {
    cursor: pointer;
    & > path {
      fill: ${({ theme, color }) => darken(theme.coefficients.primaryHovered, color)};
    }
  }
`;

interface GridViewSVGProps extends SVGProps<SVGSVGElement> {
  color: string;
}

const GridViewSVG: FC<GridViewSVGProps> = ({ color, onClick, className }) => {
  return (
    <Svg
      className={className}
      color={color}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12.6667 2C12.6667 0.895429 13.5621 0 14.6667 0H20C21.1046 0 22 0.895431 22 2V7.33333C22 8.4379 21.1046 9.33333 20 9.33333H14.6667C13.5621 9.33333 12.6667 8.4379 12.6667 7.33333V2Z"
        fill={color}
      />
      <path
        d="M12.6667 14.6667C12.6667 13.5621 13.5621 12.6667 14.6667 12.6667H20C21.1046 12.6667 22 13.5621 22 14.6667V20C22 21.1046 21.1046 22 20 22H14.6667C13.5621 22 12.6667 21.1046 12.6667 20V14.6667Z"
        fill={color}
      />
      <path
        d="M0 14.6667C0 13.5621 0.895431 12.6667 2 12.6667H7.33333C8.4379 12.6667 9.33333 13.5621 9.33333 14.6667V20C9.33333 21.1046 8.4379 22 7.33333 22H2C0.895429 22 0 21.1046 0 20V14.6667Z"
        fill={color}
      />
      <path
        d="M0 2C0 0.895429 0.895431 0 2 0H7.33333C8.4379 0 9.33333 0.895431 9.33333 2V7.33333C9.33333 8.4379 8.4379 9.33333 7.33333 9.33333H2C0.895429 9.33333 0 8.4379 0 7.33333V2Z"
        fill={color}
      />
    </Svg>
  );
};

export default GridViewSVG;

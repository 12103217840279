import { FC, memo, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';

import { CARD_LABELS, FEE_DETAILS, TOOLTIP_CONTENT_MAP } from 'constants/programCard';
import { TypographyComponent } from 'enums/ui';
import { formatCurrency } from 'utils/formatting';
import { getTooltipId } from 'utils/id';

import { selectIsDesktopView } from 'store/selectors/ui';
import { FeeDetails } from 'types/application';

import { Tooltip } from 'components/@styled';
import { Typography } from 'components/Typography';

import { Fees, FeesCellContainer, Points } from './ProgramCard.styles';
import UpfrontCosts from './UpfrontCosts';

interface FeesCellProps {
  programId: string;
  fees: string;
  points: string;
  feeDetails: FeeDetails;
  onOpenModal?: MouseEventHandler<HTMLParagraphElement>;
}

const FeesCell: FC<FeesCellProps> = ({ programId, fees, points, feeDetails, onOpenModal }) => {
  const isDesktop = useSelector(selectIsDesktopView);

  const toolTipFeesId = getTooltipId(`${FEE_DETAILS}_${programId}`);
  const toolTipPointsId = getTooltipId(CARD_LABELS.points);

  const isNegativePoints = +points < 0;
  const $isClickableFeesTooltip = Object.keys(feeDetails || {}).length > 4;
  const normalizedLenderCredits =
    isNegativePoints && formatCurrency(Math.abs(+feeDetails['Lender Credits']));

  return (
    <FeesCellContainer>
      <Typography component={TypographyComponent.H7}>Fees:</Typography>

      <Fees data-tooltip-id={toolTipFeesId} onClick={isDesktop ? undefined : onOpenModal}>
        {fees}
        {isDesktop && (
          <Tooltip clickable={$isClickableFeesTooltip} id={toolTipFeesId} variant="light">
            <UpfrontCosts feeDetails={feeDetails} />
          </Tooltip>
        )}
      </Fees>

      <Points
        data-tip
        component={TypographyComponent.H7}
        data-tooltip-id={toolTipPointsId}
        id={toolTipPointsId}
        onClick={isDesktop ? undefined : onOpenModal}
      >
        {isNegativePoints ? `Lender Credit: ${normalizedLenderCredits}` : `Points: ${points}`}
      </Points>
      {isDesktop && (
        <Tooltip
          content={TOOLTIP_CONTENT_MAP[toolTipPointsId]}
          id={toolTipPointsId}
          variant="light"
        />
      )}
    </FeesCellContainer>
  );
};

export default memo(FeesCell);

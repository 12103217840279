import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import StrokeText from 'components/StrokeText';

interface TabProps {
  $isActive?: boolean;
  $isCompact?: boolean;
}

export const Tab = styled.button<TabProps>`
  // setup width in Carousel -> Content styled component;
  flex-shrink: 0;

  padding: 12px;

  border: solid 1px ${({ theme }) => theme.colors.accent};
  border-radius: 20px;

  color: ${({ theme }) => theme.colors.text.primary};

  text-align: center;

  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    padding: 9px 0 18px;
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    ${({ $isCompact }) =>
      !$isCompact &&
      css`
        padding: 12px;
      `}
  }

  ${({ $isCompact }) =>
    $isCompact &&
    css`
      padding: 9px 0 18px;
    `}

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}
`;

export const Years = styled(StrokeText)<{ $isCompact?: boolean }>`
  font-size: 38px;
  line-height: 30px;
`;

export const Text = styled.p`
  font-size: 10px;

  @media (max-width: ${MediaQueries.Tablet}) {
    font-size: 12px;
  }
`;

const compactTextBoldStyles = css`
  margin-bottom: 0;
`;
export const SubTitle = styled.p<{ $isCompact?: boolean }>`
  margin-bottom: 10px;

  font-size: 12px;
  font-weight: 300;

  ${({ $isCompact }) => $isCompact && compactTextBoldStyles};

  @media (max-width: ${MediaQueries.Tablet}) {
    font-size: 14px;
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    ${compactTextBoldStyles};
    font-size: 12px;
  }
`;

export const ConditionValue = styled(SubTitle)`
  margin: 5px 0 0;

  font-size: 12px;
  font-weight: 600;

  @media (max-width: ${MediaQueries.Tablet}) {
    font-size: 14px;
  }
`;

export const ConditionsList = styled.ul`
  display: flex;
  justify-content: space-between;
`;

export const Condition = styled.li`
  text-align: start;

  :first-child {
    margin-right: 5px;
  }
`;

import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Formik } from 'formik';

import { FeatureFlags } from 'constants/featureToggles';
import { ButtonVariant, TypographyComponent } from 'enums/ui';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { getApiError } from 'utils/api';
import { getPDFLoanOptions, getRelevantLoanOptions } from 'utils/loanOptions';

import {
  useCreateEmailForPreviewMutation,
  useSendEmailV3Mutation,
  useSendLoanOptionsByEmailMutation,
} from 'services/baseAPI';
import { CreateEmailForPreviewResponse } from 'services/baseAPI/types';
import { selectCompanyAndRealtorId } from 'store/selectors/auth';
import {
  LoanOption,
  PotentialPurchaseLoanInfo,
  PotentialRefinanceLoanInfo,
} from 'types/application';

import EmailField from 'components/@fields/EmailField';
import NameField from 'components/@fields/NameField';
import Button from 'components/Button';
import CircleSpinner from 'components/CircleSpinner';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { Typography } from 'components/Typography';

import { ButtonsContainer, Form, SendLoanProgramContainer } from './SendLoanProgramsModal.styles';
import schema from './SendLoanProgramsModal.validationSchema';

interface Props {
  loanOptions: LoanOption[];
  loanInfo: PotentialPurchaseLoanInfo | PotentialRefinanceLoanInfo;
  selectedLoanOptions: string[];
}

const initialValues = {
  firstName: '',
  lastName: '',
  emailAddress: '',
};

export default NiceModal.create<Props>(({ loanOptions, loanInfo, selectedLoanOptions }) => {
  const modal = useModal();

  const [emailData, setEmailData] = useState<CreateEmailForPreviewResponse>({});

  const isUpdateSendingLogicEnabled = useFeatureFlag(FeatureFlags.EnableUpdateSendingLogic);

  const { companyId, realtorId } = useSelector(selectCompanyAndRealtorId);

  const [sendLoanOptions, sendLoanOptionsMeta] = useSendLoanOptionsByEmailMutation();
  const [createEmailForPreview, createEmailForPreviewMeta] = useCreateEmailForPreviewMutation();
  const [sendEmail, sendEmailMeta] = useSendEmailV3Mutation();

  const { error } = isUpdateSendingLogicEnabled
    ? createEmailForPreviewMeta || sendEmailMeta
    : sendLoanOptionsMeta;

  const APIError = getApiError(error);

  const emailBody = useMemo(() => ({ __html: emailData?.body || '' }), [emailData]);

  const onSubmit = useCallback(
    async (values: typeof initialValues) => {
      if (isUpdateSendingLogicEnabled) {
        const relevantLoanOptions = getRelevantLoanOptions(loanOptions);

        const data = await createEmailForPreview({
          virtualApplicationParameters: { ...loanInfo, companyId },
          borrowerContactInformation: { ...values },
          selectedLoanOptions: {
            applicationId: loanInfo.applicationId,
            loanOptionIds: selectedLoanOptions.length
              ? selectedLoanOptions
              : relevantLoanOptions.map(({ loanOptionId }) => loanOptionId),
          },
        }).unwrap();

        setEmailData(data);
      } else {
        await sendLoanOptions({
          ...getPDFLoanOptions(loanOptions, loanInfo),
          companyId,
          realtorId,
          ...values,
        });
      }
    },
    [
      companyId,
      loanInfo,
      loanOptions,
      realtorId,
      sendLoanOptions,
      createEmailForPreview,
      isUpdateSendingLogicEnabled,
      selectedLoanOptions,
    ],
  );

  const onSendEmail = useCallback(async () => {
    await sendEmail({ generatedEmailId: emailData?.id || '', type: 'ForBorrower' }).unwrap();

    modal.remove();
    toast('Email was sent');
  }, [emailData, modal, sendEmail]);

  return (
    <SendLoanProgramContainer withCloseIcon={false}>
      {!!emailData?.body && (
        <>
          <Typography component={TypographyComponent.H6}>
            From: {emailData?.emailAddresses?.from.email}
          </Typography>
          <Typography component={TypographyComponent.H6}>
            To: {emailData?.emailAddresses?.to.join(',')}
          </Typography>

          <div dangerouslySetInnerHTML={emailBody} />

          {APIError && <ErrorMessage isCenterAlign error={APIError} marginBottom={15} />}

          <ButtonsContainer>
            <Button $minWidth={120} disabled={sendEmailMeta.isLoading} onClick={onSendEmail}>
              {sendEmailMeta.isLoading ? <CircleSpinner size={16} /> : 'Send'}
            </Button>
            <Button type="submit" variant={ButtonVariant.Transparent} onClick={modal.remove}>
              Cancel
            </Button>
          </ButtonsContainer>
        </>
      )}

      {!emailData?.body && (
        <>
          <Typography component={TypographyComponent.H5}>Send Loan Programs to Client</Typography>
          <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {({ handleChange, isSubmitting, dirty }) => (
              <Form>
                <NameField
                  isAbsoluteError
                  required
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                />
                <NameField
                  isAbsoluteError
                  required
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                />
                <EmailField
                  isAbsoluteError
                  required
                  label="Email"
                  name="emailAddress"
                  onChange={handleChange}
                />

                {APIError && <ErrorMessage isCenterAlign error={APIError} marginBottom={15} />}
                <ButtonsContainer>
                  <Button $minWidth={120} disabled={!dirty} type="submit">
                    {isSubmitting ? <CircleSpinner size={16} /> : 'Preview'}
                  </Button>
                  <Button type="submit" variant={ButtonVariant.Transparent} onClick={modal.remove}>
                    Cancel
                  </Button>
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        </>
      )}
    </SendLoanProgramContainer>
  );
});

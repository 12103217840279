import { getEnvVariable } from 'utils/common';

export const API_URL = getEnvVariable('apiBaseUrl', process.env.REALTORS_API_BASE_URL);

export const AUTH_API_URL = getEnvVariable('accountsApiUrl', process.env.ACCOUNTS_API_BASE_URL);

export const APPLY_URL = getEnvVariable('applyUrl', process.env.APPLY_URL);
export const APPLY_API_URL = getEnvVariable('applyApiUrl', process.env.APPLY_API_BASE_URL);
export const APPLY_API_CODE = getEnvVariable('applyApiCode', process.env.APPLY_API_CODE);

export const SKYNET_API_URL = getEnvVariable('skynetApiUrl', process.env.ADMIN_API_BASE_URL);

export const LOANS_API_URL = getEnvVariable('loansApiUrl', process.env.LOANS_API_BASE_URL);
export const DOCUMENTS_API_URL = getEnvVariable(
  'documentsApiUrl',
  process.env.DOCUMENTS_API_BASE_URL,
);

export const SIGNAL_R_CONNECTION_URL = `${API_URL}api`;

import { styled } from 'styled-components';

import { Typography } from 'components/Typography';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  cursor: default;
`;

export const CrossIcon = styled(PlusIcon)`
  width: 20px;
  height: 20px;
  margin-top: 1.5px;
  color: ${({ theme }) => theme.colors.white};
  transform: rotate(45deg);
`;

export const StepProgress = styled.div<{
  $isProcessingStopped: boolean;
  $isPassed: boolean;
  $isDynamicProgress?: boolean;
}>`
  width: 25px;
  height: 25px;
  padding-top: 1px; // need for aligning
  margin-right: 16px;

  flex-shrink: 0;

  font-size: 12px;
  font-weight: 700;
  text-align: center;

  border-radius: 50%;
  color: #c7c7c7;
  background-color: ${({ $isPassed, $isProcessingStopped, $isDynamicProgress }) => {
    if ($isProcessingStopped && ($isDynamicProgress || !$isPassed)) {
      return '#d74f78';
    }
    return $isPassed ? '#00ae7f' : '#f8f8f8';
  }};
`;

export const StepText = styled(Typography)<{ $isError: boolean }>`
  ${({ $isError }) => $isError && `color: #d74f78;`}
`;

import { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NiceModal from '@ebay/nice-modal-react';
import { useTheme } from 'styled-components';

import { IS_PRODUCTION } from 'constants/common';
import { TypographyComponent, TypographyType } from 'enums/ui';
import { useClickOutside } from 'hooks/useClickOutside';
import { authClient } from 'utils/auth';

import { selectRealtorInfo } from 'store/selectors/auth';
import { selectRealtorsLogo } from 'store/selectors/ui';
import { signOut } from 'store/slices/auth';

import MenuSVG from 'components/@icons/MenuSVG';
import DevToggles from 'components/@modals/DevToggles';

import emptyAvatar from 'assets/images/emptyAvatar.png';

import {
  ArrowIcon,
  DropDownContainer,
  DropDownItem,
  FullName,
  HeaderWrapper,
  LeftBlock,
  LogoStyled,
  NameSkeleton,
  ProfileImage,
  ProfileImageSkeleton,
  RightBlock,
  RouterLink,
} from './Header.styles';

const Header: FC<{ openSideBar(): void }> = ({ openSideBar }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    colors: {
      text: { primary: textPrimary },
    },
  } = useTheme();

  const { fullName } = useSelector(selectRealtorInfo) || {};
  const isError = !fullName;

  const logoSrc = useSelector(selectRealtorsLogo);

  const onClickRightBlock = useCallback(() => setIsDropDownOpen(prev => !prev), []);

  const onLogOut = useCallback(() => {
    dispatch(signOut());
    authClient.logout();
    toast.dismiss();
  }, [dispatch]);

  const closeDropDown = useCallback(() => {
    setIsDropDownOpen(false);
  }, []);

  const onOpenDevToggles = useCallback(() => {
    NiceModal.show(DevToggles);
  }, []);

  const ref = useClickOutside<HTMLDivElement>(closeDropDown);

  return (
    <HeaderWrapper data-testid="header">
      <LeftBlock>
        <MenuSVG color={textPrimary} onClick={openSideBar} />
        <RouterLink to="/">{logoSrc && <LogoStyled alt="Logo" src={logoSrc} />}</RouterLink>
      </LeftBlock>

      <RightBlock ref={ref} onClick={onClickRightBlock}>
        {isError ? (
          <>
            <ProfileImageSkeleton isError={isError} />
            <NameSkeleton isError={isError} />
            <NameSkeleton isError={isError} />
          </>
        ) : (
          <>
            <ProfileImage alt="Profile Image" src={emptyAvatar} />
            <FullName component={TypographyComponent.H7} type={TypographyType.Secondary}>
              {fullName}
            </FullName>
          </>
        )}

        {/* `$` avoid passing isRotated prop to svg tag */}
        <ArrowIcon $isRotated={isDropDownOpen} />

        {isDropDownOpen && (
          <DropDownContainer>
            {!IS_PRODUCTION && <DropDownItem onClick={onOpenDevToggles}>DevConfig</DropDownItem>}
            <DropDownItem onClick={onLogOut}>Log out</DropDownItem>
          </DropDownContainer>
        )}
      </RightBlock>
    </HeaderWrapper>
  );
};

export default memo(Header);

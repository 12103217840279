import { FC, lazy, memo, Suspense } from 'react';

import { TypographyComponent } from 'enums/ui';

import { LoanCondition } from 'types/conditions';

import { ConditionSkeleton, ConditionsWrapper, Title } from './Conditions.styles';

const ConditionsData = lazy(() => import('./ConditionsData'));

interface ConditionsProps {
  conditions?: LoanCondition[];
  isLoading: boolean;
  isError: boolean;
}

const Conditions: FC<ConditionsProps> = props => {
  return (
    <ConditionsWrapper>
      <Title component={TypographyComponent.H6}>Conditions</Title>

      <Suspense
        fallback={
          <>
            <ConditionSkeleton />
            <ConditionSkeleton />
          </>
        }
      >
        <ConditionsData {...props} />
      </Suspense>
    </ConditionsWrapper>
  );
};

export default memo(Conditions);

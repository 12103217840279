import { ChangeEvent, useCallback } from 'react';
import { FormikHelpers, FormikValues } from 'formik';

import { Suggestion, ZipAutocomplete } from 'components/@fields/PlacesAutocomplete';

interface Props<T> {
  handleChange: (e: ChangeEvent<unknown>) => void;
  setValues: FormikHelpers<T>['setValues'];
}

const AddressFields = <T extends FormikValues>({ handleChange, setValues }: Props<T>) => {
  const onAutoComplete = useCallback(
    (suggestion: Suggestion) => {
      setValues(prevState => {
        return { ...prevState, ...suggestion };
      });
    },
    [setValues],
  );

  return (
    <ZipAutocomplete<T>
      isAbsoluteError
      required
      handleChange={handleChange}
      label="ZIP Code of Property"
      name="zip"
      placeholder="ZIP"
      setValues={setValues}
      onPlaceClick={onAutoComplete}
    />
  );
};

export default AddressFields;

import { memo } from 'react';

import { SortDirection } from 'enums/common';

import { Column } from '../../Table.types';

import { TableHeaderContainer } from './TableHeader.styles';
import TableHeaderColumn from './TableHeaderColumn';

interface TableHeaderProps<T> {
  columns: Column<T>[];
  isSubGrid?: boolean;
  onSort: (key: string) => void;
  sortBy: string;
  sortDirection: SortDirection;
  blockTestName?: string;
}

const TableHeader = <T extends unknown>({
  onSort,
  sortBy,
  columns,
  sortDirection,
  isSubGrid,
  blockTestName,
}: TableHeaderProps<T>) => {
  return (
    <TableHeaderContainer data-testod={`${blockTestName}__header`} isSubGrid={isSubGrid}>
      {columns.map(props => (
        <TableHeaderColumn
          {...props}
          columnKey={props.key}
          data-testid={`${blockTestName}__column__${props.key}`}
          key={props.key}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
        />
      ))}
    </TableHeaderContainer>
  );
};

export default memo(TableHeader) as typeof TableHeader;

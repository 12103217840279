import { IS_SAFARI } from 'constants/common';

interface CopyToClipboardProps {
  text?: string;
  request?: Promise<{ data: string } | { error: any }>; // eslint-disable-line
}

// return isWithoutError boolean status
export const copyToClipboard = async ({ text, request }: CopyToClipboardProps) => {
  if (text) {
    await navigator.clipboard.writeText(text);
    return true;
  }

  if (IS_SAFARI && request) {
    // fix bug with data from server for copying in Safari
    const clipboardItem = new ClipboardItem({
      'text/plain': request.then(response => {
        const leadLink = 'data' in response && response.data;

        if (leadLink) {
          return new Promise(async resolve => {
            resolve(new Blob([leadLink]));
          });
        }

        return new Promise(async (resolve, reject) => {
          reject(new Blob([``]));
        });
      }),
    });

    await navigator.clipboard.write([clipboardItem]);
    return true;
  }

  if (request) {
    const response = await request;
    const responseText = 'data' in response && response.data;

    if (responseText) {
      await navigator.clipboard.writeText(responseText);
      return true;
    }

    return false;
  }

  throw Error('error in copyToClipboard function, you need pass text or request prop');
};

import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { Typography } from 'components/Typography';

import { ReactComponent as IconPDF } from 'assets/icons/pdf.svg';

import { getLineClampStyles } from 'styles/typography';

export const DocumentsContainer = styled.div<{ isModal?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isModal }) =>
    isModal &&
    css`
      max-height: 60vh;
      overflow-y: auto;

      @media (max-width: ${MediaQueries.Mobile}) {
        max-height: calc(100vh - 75px);
      }
    `}

  & > * {
    width: 100%;
  }
`;
export const Icon = styled(IconPDF)<{ $isDownloadable: boolean }>`
  margin-right: 20px;
`;

export const Label = styled.p`
  // two-line limit
  ${getLineClampStyles(2)}

  flex-grow: 1;
  min-width: 150px;
  margin-right: 10px;
  line-height: 20px;
`;

export const DocumentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
  height: 45px;
  padding: 0 15px 0 20px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.controls.hoveredBackground};
  }
  &:hover > ${Label} {
    transform: translateX(2px);
  }
`;

export const AllDocumentsModal = styled(Modal)`
  padding: 30px;
  min-width: 400px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    width: 95%;
    min-width: initial;
  }

  @media (max-width: ${MediaQueries.Mobile}) {
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    padding: 25px 10px;
    border-radius: 0;
  }
`;

export const Title = styled(Typography)`
  text-align: center;
  margin-bottom: 15px;
`;

export const AllDocumentsButton = styled(Button)`
  margin-top: 5px;
`;

import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { SubmitPurchaseAgreementStatus } from 'enums/task';
import { MediaQueries } from 'enums/ui';

import { useSubmitPurchaseAgreementMutation } from 'services/applyAPI';
import { LoanOption } from 'types/application';
import { EditPreApprovalLetterParams } from 'types/preApproval';

import { Step } from 'components/@modals/@SelectNewProgramSteps/@shared';
import Error from 'components/@modals/@SelectNewProgramSteps/Error';
import SelectProgram from 'components/@modals/@SelectNewProgramSteps/SelectProgram';

import { PurchaseAgreementModalContainer } from './PurchaseAgreementModal.styles';

export interface PurchaseAgreementModalProps {
  applicationId: string;
  step: Step;
  setStep: (step: Step) => void;
  loanOptions: LoanOption[];
  purchaseAgreementData: EditPreApprovalLetterParams;
  geFormDataforPA: (data: EditPreApprovalLetterParams, loanOptionId?: string) => FormData;
  taskId: string;
}

export default NiceModal.create<PurchaseAgreementModalProps>(
  ({
    applicationId,
    step,
    setStep,
    loanOptions,
    purchaseAgreementData,
    taskId,
    geFormDataforPA,
  }) => {
    const modal = useModal();

    const [selectedProgram, setSelectedProgram] = useState<string>('');

    const [submitPurchaseAgreement, { isLoading }] = useSubmitPurchaseAgreementMutation();

    const isEditDataStep = step === Step.EditData;
    const hasFullScreenPoint = step === Step.EditData || step === Step.SelectProgram;

    useEffect(
      () => () => {
        setStep(Step.EditData);
      },
      [setStep],
    );

    const onSubmitProgram = useCallback(async () => {
      const response = await submitPurchaseAgreement({
        fd: geFormDataforPA(purchaseAgreementData, selectedProgram),
        taskId,
        applicationId,
      }).unwrap();

      if (response.status === SubmitPurchaseAgreementStatus.Success) {
        modal.remove();
        toast('Task successfully submitted');
        return;
      }

      setStep(Step.Error);
    }, [
      applicationId,
      geFormDataforPA,
      modal,
      purchaseAgreementData,
      selectedProgram,
      setStep,
      submitPurchaseAgreement,
      taskId,
    ]);

    return (
      <PurchaseAgreementModalContainer
        fullScreenPoint={hasFullScreenPoint ? MediaQueries.MobileLandscape : undefined}
        isSelectProgramStep={step === Step.SelectProgram}
        shouldCloseOnEsc={isEditDataStep}
        shouldCloseOnOuterClick={false}
        withCloseIcon={isEditDataStep}
      >
        {step === Step.SelectProgram && loanOptions && (
          <SelectProgram
            isLoadingPurchaseAgreement={isLoading}
            loanOptions={loanOptions}
            selectedProgram={selectedProgram}
            setSelectedProgram={setSelectedProgram}
            setStep={setStep}
            onSubmit={onSubmitProgram}
          />
        )}
        {step === Step.Error && <Error />}
      </PurchaseAgreementModalContainer>
    );
  },
);

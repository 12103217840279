import { memo, useCallback } from 'react';

import { TypographyComponent } from 'enums/ui';

import { Typography } from 'components/Typography';

import { Column } from '../../Table.types';

import { HeaderColumnContainer, SortArrow } from './TableHeader.styles';

interface HeaderColumnProps<T> extends Column<T> {
  onSort: (key: string) => void;
  columnKey: string;
  sortBy: string;
  sortDirection: string;
}

const TableHeaderColumn = <T extends unknown>({
  isSortable,
  columnKey,
  minWidth,
  maxWidth,
  onSort,
  icon,
  label,
  sortBy,
  sortDirection,
  isReverse,
  ...props
}: HeaderColumnProps<T>) => {
  const isActive = sortBy === columnKey;

  const handleColumnClick = useCallback(() => {
    isSortable && onSort(columnKey);
  }, [columnKey, onSort, isSortable]);

  return (
    <HeaderColumnContainer
      {...props}
      isReverse={isReverse}
      isSortable={isSortable}
      maxWidth={maxWidth}
      minWidth={minWidth}
      role="button"
      withIcon={!!icon}
      onClick={handleColumnClick}
      onKeyDown={handleColumnClick}
    >
      {label && (
        <>
          {icon}
          <Typography component={TypographyComponent.H7}>{label}</Typography>
          {isSortable && (
            <SortArrow $isActive={isActive} $sortDirection={sortDirection} height={4} width={8} />
          )}
        </>
      )}
    </HeaderColumnContainer>
  );
};

export default memo(TableHeaderColumn) as typeof TableHeaderColumn;

import { styled } from 'styled-components';

import Button from 'components/Button';
import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const DocumentsListContainer = styled(Card)`
  display: flex;
  width: 340px;
  // TODO: update (with skeleton) when Closing Date is added. it matches to info card
  height: 361px;
  padding: 20px 0;
`;

export const Title = styled(Typography)`
  margin-left: 15px;
`;

export const CreateButton = styled(Button)`
  margin-left: 15px;
  font-size: 14px;
`;

export const List = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const EmptyMessage = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.controls.placeholder};
`;

export const DocumentsSkeleton = styled(Skeleton)`
  width: 340px;
  height: 361px; // matches actual widget size
  border-radius: 20px;
`;

import { FC, memo } from 'react';
import { useTheme } from 'styled-components';

import { FeatureFlags } from 'constants/featureToggles';
import { TypographyComponent, TypographySize, TypographyWeight } from 'enums/ui';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { formatPhone } from 'utils/formatting';

import { LoanContact, LoanOfficer } from 'types/application';

import MailSVG from 'components/@icons/MailSVG';
import PersonFilledSVG from 'components/@icons/PersonFilledSVG';
import PhoneSVG from 'components/@icons/PhoneSVG';

import {
  EmptyMessage,
  KeyPeopleContainer,
  PositionedAccordion,
  String,
  Text,
  Title,
} from './KeyPeople.styles';

const blockTestName = 'keyPeople';

interface KeyPeopleProps {
  loanContacts?: (LoanOfficer & { loanRoleName: string })[] | LoanContact[];
}

const KeyPeople: FC<KeyPeopleProps> = ({ loanContacts }) => {
  const {
    colors: {
      card: { icon: iconColor },
    },
  } = useTheme();

  const isLoanContactsEnabled = useFeatureFlag(
    FeatureFlags.EnableLoanContactsOnPresentationRealtors,
  );

  const isNoContacts = !loanContacts?.length;

  if (isLoanContactsEnabled && isNoContacts) {
    return null;
  }

  if (isNoContacts) {
    return (
      <KeyPeopleContainer $isNoPeople data-testid={`${blockTestName}__container`}>
        <Title component={TypographyComponent.H6}>Key people on application</Title>

        <EmptyMessage size={TypographySize.Small} weight={TypographyWeight.Regular}>
          No assignments at the moment
        </EmptyMessage>
      </KeyPeopleContainer>
    );
  }

  return (
    <KeyPeopleContainer data-testid={`${blockTestName}__container`}>
      <Title component={TypographyComponent.H6}>Key people on application</Title>

      {loanContacts.map((contact, index) => {
        const { loanRoleName, firstName, lastName, email, phoneNumber, phoneExtensionNumber } =
          contact;

        return (
          <PositionedAccordion defaultState="closed" key={index} title={loanRoleName}>
            <String>
              <PersonFilledSVG color={iconColor} />
              <Text component={TypographyComponent.H7} data-testid={`${blockTestName}__name`}>
                {firstName} {lastName}
              </Text>
            </String>

            {!!email && (
              <String>
                <MailSVG color={iconColor} />
                {/*title prop adds native tooltip*/}
                <Text
                  component={TypographyComponent.H7}
                  data-testid={`${blockTestName}__email`}
                  title={email}
                >
                  {email}
                </Text>
              </String>
            )}

            {!!phoneNumber && (
              <String>
                <PhoneSVG color={iconColor} />
                <Text component={TypographyComponent.H7} data-testid={`${blockTestName}__phone`}>
                  {formatPhone(phoneNumber) +
                    (phoneExtensionNumber ? `, ext. ${phoneExtensionNumber}` : '')}
                </Text>
              </String>
            )}
          </PositionedAccordion>
        );
      })}
    </KeyPeopleContainer>
  );
};

export default memo(KeyPeople);

import { FC, MouseEvent, useCallback } from 'react';
import { FileWithPath } from 'react-dropzone';

import { formatBytes, openURLInNewTab, parseFileName } from 'utils/file';

import {
  File as FileWrapper,
  FileControls,
  FileExtension,
  FileName,
  FileNameContainer,
  FileSize,
} from './TaskForm.styles';

interface FileProps {
  file: FileWithPath;
  onRemoveFile: (file: FileWithPath) => (e: MouseEvent<HTMLButtonElement>) => void;
}

const File: FC<FileProps> = ({ file, onRemoveFile }) => {
  const onPreviewFile = useCallback(() => {
    const objectURL = URL.createObjectURL(file);

    openURLInNewTab(objectURL);
  }, [file]);

  const [name, extension] = parseFileName(file.path);

  return (
    <FileWrapper>
      <FileNameContainer as="button" onClick={onPreviewFile}>
        <FileName>{name}</FileName>
        {!!extension && <FileExtension>.{extension}</FileExtension>}
      </FileNameContainer>
      <FileControls>
        <FileSize>{formatBytes(file.size)}</FileSize>
        <button type="button" onClick={onRemoveFile(file)}>
          X
        </button>
      </FileControls>
    </FileWrapper>
  );
};

export default File;

import { styled } from 'styled-components';

export const RecommendedIndicator = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 0;
  padding-left: 4px;

  width: 120px;
  font-size: 11px;

  border-bottom: 22px solid ${({ theme }) => theme.colors.primary};
  border-left: 17px solid transparent;

  color: ${({ theme }) => theme.colors.white};
`;

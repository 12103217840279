import { styled } from 'styled-components';

import { SortDirection } from 'enums/common';

import ThinArrowSVG from 'components/@icons/ThinArrowSVG';
import { CELL_STYLES } from 'components/Table/Table.styles';

export const TableHeaderContainer = styled.div<{ isSubGrid?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  margin-bottom: ${({ isSubGrid }) => (isSubGrid ? '10px' : '30px')};
  padding: 0 12px;
`;

interface TableColumnContainerProps {
  isSortable?: boolean;
  withIcon: boolean;
  // for CELL_STYLES
  minWidth?: string;
  maxWidth?: string;
  isReverse?: boolean;
}

export const HeaderColumnContainer = styled.div<TableColumnContainerProps>`
  ${CELL_STYLES};

  user-select: none;

  & > :first-child {
    margin-right: ${({ withIcon }) => (withIcon ? 5 : 0)}px;
  }

  &:hover {
    cursor: ${({ isSortable }) => isSortable && 'pointer'};
  }
`;

export const SortArrow = styled(ThinArrowSVG)<{ $isActive: boolean; $sortDirection?: string }>`
  margin-left: 5px;

  & > path {
    stroke: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.colors.text.primary};
  }

  transition: transform 0.5s ease;
  transform: ${({ $sortDirection, $isActive }) =>
    $isActive && $sortDirection === SortDirection.ASC ? 'rotate(180deg)' : 'initial'};
`;

import { RefObject, useCallback, useEffect, useRef } from 'react';

export const useClickOutside = <T extends HTMLElement>(
  onClickOutside: () => void,
  shouldCloseOnOuterClick = true,
): RefObject<T> => {
  const ref = useRef<T>(null);

  const handleClick = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!shouldCloseOnOuterClick || ref.current?.contains(event.target as Node)) return;

      onClickOutside();
    },
    [onClickOutside, shouldCloseOnOuterClick],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [handleClick]);

  return ref;
};

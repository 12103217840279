export const HEADER_HEIGHT = '70px';

export const COMMON_PHONE_NUMBER_FORMAT = '+1 (###) ###-####';

export const ZIP_CODE_LENGTH = 5;

// Application Card Plugs
export const ADDRESS_PLUG = 'TBD PreApproval Somecity, XX 00000';
export const TASKS_NUMBER_PLUG = '0';
export const LAST_UPDATED_PLUG = '00/00/0000';

// Masonry Layout constants
export const LONGEST_COLUMN_HEIGHT = 1500;
export const LONGEST_COLUMN_HEIGHT_FOR_LO = 1535;
export const DESKTOP_LONGEST_COLUMN_HEIGHT = 1150;

import { FC, ReactNode } from 'react';
import { css, styled } from 'styled-components';

import { MediaQueries, TypographyComponent } from 'enums/ui';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { Typography } from 'components/Typography';

export const ManagePreApprovalModalContainer = styled(Modal)<{ isSelectProgramStep: boolean }>`
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  padding: 25px 30px 50px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    min-width: 90%;

    ${({ isSelectProgramStep }) =>
      isSelectProgramStep &&
      css`
        padding: 25px 10px 10px;
      `}
  }
`;

const StyledMaxApprovedPrice = styled(Typography)`
  margin: 5px 0 15px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const MaxApprovedPrice: FC<{ children?: ReactNode }> = ({ children }) => (
  <StyledMaxApprovedPrice component={TypographyComponent.H6}>{children}</StyledMaxApprovedPrice>
);

export const ButtonsContainer = styled.div`
  display: flex;

  & > :first-child {
    margin-right: 45px;
  }
`;

export const EditSubmitButton = styled(Button)<{ isEdit?: boolean }>`
  min-width: ${({ isEdit }) => (isEdit ? 140 : 220)}px;
`;

import { memo } from 'react';

import { TypographyComponent } from 'enums/ui';
import { getBlankDefault } from 'utils/formatting';

import { StyledCell } from 'components/Table/components/Cell/Cell.styles';
import { HIGHLIGHTED_CELL } from 'components/Table/Table.constants';
import { Column } from 'components/Table/Table.types';

interface CellProps<T> extends Column<T> {
  rowData: T;
  cellData: unknown;
}

const Cell = <T extends unknown>({
  rowData,
  cellData,
  minWidth,
  maxWidth,
  processValue,
  customRenderer,
  isHighlightOnHover,
  wrapperComponent,
  isReverse,
  ...props
}: CellProps<T>) => {
  if (customRenderer && processValue) {
    throw new Error(`you only must select one rendering prop ${[customRenderer, processValue]}`);
  }

  const renderCell = () => {
    if (customRenderer) {
      return customRenderer({ value: cellData, rowData });
    }

    return getBlankDefault(cellData, { processValue }) as string | number;
  };

  return (
    <StyledCell
      {...props}
      // TODO: maybe worth change to DIV to avoid nesting errors
      component={TypographyComponent.H7}
      forwardedAs={wrapperComponent}
      id={(isHighlightOnHover && HIGHLIGHTED_CELL) || undefined}
      isReverse={isReverse}
      maxWidth={maxWidth}
      minWidth={minWidth}
    >
      {renderCell()}
    </StyledCell>
  );
};

export default memo(Cell) as typeof Cell;

import { createApi } from '@reduxjs/toolkit/query/react';
import { format } from 'date-fns';

import { documentsQueryWithReauth } from 'utils/api';
import { downloadFileFromBlob } from 'utils/browser';

import {
  DocumentCategory,
  DocumentCategoryFormServer,
  DownloadDocumentPayload,
  GetDocumentUrlPayload,
} from './types';

const documentsAPI = createApi({
  reducerPath: 'documentsAPI',
  baseQuery: documentsQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getDocumentUrl: build.query<string, GetDocumentUrlPayload>({
      query: ({ documentId }) => ({
        url: `api/documents/${documentId}/url`,
        method: 'GET',
      }),
    }),
    getDocumentsCategories: build.query<DocumentCategory[], string>({
      queryFn: async (loanId, { dispatch }, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `api/document-categories`,
          params: { include: 'types' },
        });

        if (response.error) {
          return {
            error: response.error,
          };
        }

        const documentCategories = response.data as DocumentCategoryFormServer[];

        const mappedCategories = documentCategories?.map(({ id, name, ...category }) => ({
          ...category,
          documentCategoryId: id,
          documentCategoryName: name,
        }));

        return { data: mappedCategories };
      },
    }),
    downloadDocument: build.mutation<File, DownloadDocumentPayload>({
      query({ documentId }) {
        return {
          url: `api/documents/${documentId}/content`,
          method: 'GET',
          cache: 'no-cache',
          responseHandler: async response => await response.blob(),
        };
      },
      async onQueryStarted({ name, fullName, createdDate, skipDownload }, { queryFulfilled }) {
        if (skipDownload) {
          return;
        }

        queryFulfilled.then(({ data }) => {
          // if createdDate is passed we assume that it is a pre-approval document
          const fileName = createdDate
            ? `${fullName} ${name || 'Pre-Approval'} ${format(
                new Date(createdDate),
                'MM.dd.u hh.mm aaa',
              )}.pdf`
            : `${name ?? 'document'}.pdf`;

          downloadFileFromBlob(data, fileName);
        });
      },
    }),
  }),
});

export const {
  useLazyGetDocumentUrlQuery,
  useDownloadDocumentMutation,
  useGetDocumentsCategoriesQuery,
} = documentsAPI;

export default documentsAPI;

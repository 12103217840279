import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { REALTOR_ID, REALTOR_LOAN_OFFICER_CLAIM } from 'constants/common';

import authAPI from 'services/authAPI';

export const selectAuthData = (state: RootState) => state.authSlice;

export const selectCompanyId = (state: RootState) => {
  const companyId = authAPI.endpoints.getCompanyId.select()(state).data?.id;

  if (!companyId) {
    throw Error('companyId not found');
  }

  return companyId;
};

export const selectCompanyAndRealtorId = (state: RootState) => {
  const realtorId = localStorage.getItem(REALTOR_ID);

  if (!realtorId) {
    throw Error('companyId or realtorId not found');
  }

  const companyId = selectCompanyId(state);

  return { companyId, realtorId };
};

export const selectRealtorInfo = (state: RootState) => {
  const { companyId, realtorId } = selectCompanyAndRealtorId(state);

  return authAPI.endpoints.getUser.select({ companyId, userId: realtorId })(state).data;
};

export const selectIsRealtorLoanOfficer = createSelector(selectRealtorInfo, realtorInfo => {
  const roleAssignments = realtorInfo?.roleAssignments;

  if (roleAssignments) {
    return roleAssignments.some(
      roleAssignment =>
        roleAssignment?.role?.claims?.some(claim => claim === REALTOR_LOAN_OFFICER_CLAIM),
    );
  }

  return false;
});

import NiceModal from '@ebay/nice-modal-react';

import { TypographyComponent } from 'enums/ui';

import { FormBundleModel } from 'types/documents';

import DocumentItem from './DocumentItem';
import { AllDocumentsModal, DocumentsContainer, Title } from './Documents.styles';

interface Props {
  documents: FormBundleModel[];
  milestoneName: string;
  fullName: string;
}

export default NiceModal.create<Props>(({ documents, milestoneName, fullName }) => {
  return (
    <AllDocumentsModal>
      <Title component={TypographyComponent.H5}>{milestoneName}</Title>
      <DocumentsContainer isModal>
        {documents.map(document => (
          <DocumentItem
            blockTestName="progress__modalDocumnt"
            document={document}
            fullName={fullName}
            key={document.documentId}
          />
        ))}
      </DocumentsContainer>
    </AllDocumentsModal>
  );
});

import { FC } from 'react';

import ApplicationCards from './components/ApplicationCards';
import InviteBuyerCard from './components/InviteBuyerCard';
import PriceLoanLink from './components/PriceLoanLink';
import PageHeader from 'components/PageHeader';

import { DashboardContainer, FirstBlockContainer, PositioningContainer } from './Dashboard.styles';

const Dashboard: FC = () => {
  // const isMobileView = useSelector(selectIsMobileView);

  return (
    <DashboardContainer>
      <PageHeader title="Dashboard" />

      <FirstBlockContainer>
        <ApplicationCards />

        <PositioningContainer>
          <InviteBuyerCard />
          <PriceLoanLink />
        </PositioningContainer>
      </FirstBlockContainer>
      {/*TODO: need return after time*/}
      {/*{!isMobileView && (*/}
      {/*  <>*/}
      {/*    <SeparatorLine />*/}
      {/*    <Rejoice />*/}
      {/*  </>*/}
      {/*)}*/}
    </DashboardContainer>
  );
};

export default Dashboard;

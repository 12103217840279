import { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import mask from 'assets/images/auth-dots-mask.png';

const Container = styled.div`
  height: 100vh;
  // related to bug 19439
  height: calc((var(--vh, 1vh) * 100));

  display: flex;
  justify-content: center;
  align-items: center;

  background: url(${mask}), ${({ theme }) => theme.colors.authBackground};
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    background: none;
  }
`;

const AuthLayout: FC = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default memo(AuthLayout);

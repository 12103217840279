import { toast } from 'react-toastify';
import groupBy from 'lodash/groupBy';

import { TaskDataType, TaskStatus } from 'enums/task';

import { baseAPI } from 'services/baseAPI';
import { Task } from 'types/task';

import { GetTasksPayload, SubmitTaskPayload } from './types';

const tasksAPI = baseAPI.injectEndpoints({
  endpoints: build => ({
    getTasks: build.query<{ [applicationId: string]: Task[] }, GetTasksPayload>({
      query: ({ applicationIds }) => ({
        url: `api/tasks`,
        params: applicationIds ? { applicationIds } : undefined,
      }),
      transformResponse: (response: Task[]) =>
        groupBy(
          response.sort((a, b) => Date.parse(b.lastModified) - Date.parse(a.lastModified)),
          'applicationId',
        ),
    }),
    submitTask: build.mutation<Task, SubmitTaskPayload>({
      query: ({ applicationId, body }) => ({
        url: `api/loanApplications/${applicationId}/tasks`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id, applicationId }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(async ({ data }) => {
          const toastText =
            data.dataType === TaskDataType.Forms
              ? 'Document successfully downloaded'
              : 'Task successfully submitted';
          toast(toastText);

          // it updates task based on response, check status to make sure WS updates weren't applied earlier
          dispatch(
            tasksAPI.util.updateQueryData(
              'getTasks',
              { applicationIds: [applicationId as string] },
              draft => {
                const appId = applicationId as string;
                const index = draft?.[appId].findIndex(task => id === task.id);
                const task = draft?.[appId]?.[index];

                if (task?.status === TaskStatus.New) {
                  draft?.[appId]?.splice(index, 1);
                  draft?.[appId]?.unshift(data);
                }
              },
            ),
          );
        });
      },
    }),
  }),
});

export const { useGetTasksQuery, useSubmitTaskMutation, useLazyGetTasksQuery } = tasksAPI;

export default tasksAPI;

import { FC, memo } from 'react';

import { BottomPart, TextContainer, UpperPart } from './StrokeText.styles';

interface StrokeTextProps {
  text: string;
  isActive?: boolean;
  className?: string;
}

const StrokeText: FC<StrokeTextProps> = ({ text, isActive, ...props }) => (
  <TextContainer {...props}>
    <BottomPart aria-hidden $isActive={isActive}>
      {text}
    </BottomPart>
    <UpperPart $isActive={isActive}>{text}</UpperPart>
  </TextContainer>
);

export default memo(StrokeText);

import ApplicationDetails from 'pages/ApplicationDetails';
import Dashboard from 'pages/Dashboard';
import Pipeline from 'pages/Pipeline';
import PriceLoan from 'pages/PriceLoan';

import FolderSVG from 'components/@icons/FolderSVG';
import PriceSheetSVG from 'components/@icons/PriceSheetSVG';
import SquaresSVG from 'components/@icons/SquaresSVG';

export const NAVBAR_CONFIG = [
  { Icon: SquaresSVG, title: 'Dashboard', path: '/', Component: Dashboard },
  { Icon: FolderSVG, title: 'Pipeline', path: 'pipeline', Component: Pipeline },
  { Icon: PriceSheetSVG, title: 'Price a Loan', path: 'loan-price', Component: PriceLoan },
  // TODO:  need to return it back or delete it after a time
  // { Icon: PersonOutlinedSVG, title: 'Opportunities', path: 'opportunities' },
  // { Icon: MessagesSVG, title: 'My Pod', path: 'my-pod' },
];

export const ROUTES_CONFIG = [
  ...NAVBAR_CONFIG,
  { path: 'pipeline/:id', Component: ApplicationDetails },
];

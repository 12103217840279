import { styled } from 'styled-components';

import Accordion from 'components/Accordion';
import Card from 'components/Card';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const KeyPeopleContainer = styled(Card)<{ $isNoPeople?: boolean }>`
  display: flex;
  width: 340px;
  padding: 15px;
  height: ${({ $isNoPeople }) => $isNoPeople && '263px'};
`;
export const KeyPeopleSkeleton = styled(Skeleton)`
  width: 340px;
  height: 263px;
  border-radius: 20px;
`;

export const PositionedAccordion = styled(Accordion)`
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 20px;
`;

export const String = styled.div`
  margin-top: 20px;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: 10px;
  }
`;

export const Text = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const EmptyMessage = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.controls.placeholder};
`;

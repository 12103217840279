import { FC, SVGProps } from 'react';

const TimelapseSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49444 12.0303C5.00747 12.024 2.97191 9.98456 2.97608 7.50168C2.98026 5.01881 5.0204 2.97973 7.50404 2.98349C9.98767 2.98725 12.0257 5.02924 12.0211 7.5117C12.0166 9.99416 9.97516 12.037 7.49444 12.0303ZM6.9988 6.57692C6.80647 6.45167 6.64751 6.34437 6.4823 6.24668C6.37248 6.17873 6.24041 6.15662 6.11448 6.18512C5.98855 6.21362 5.87883 6.29044 5.80892 6.39906C5.77292 6.45213 5.74778 6.51181 5.73494 6.57466C5.7221 6.6375 5.72181 6.70227 5.73411 6.76522C5.7464 6.82817 5.77103 6.88807 5.80657 6.94145C5.84211 6.99483 5.88786 7.04065 5.94118 7.07625C6.36756 7.36516 6.79604 7.65115 7.23452 7.92419C7.58164 8.14087 7.99509 7.90624 7.99843 7.495C8.00427 6.78525 8.00719 6.07842 7.993 5.37034C7.98226 5.23739 7.92917 5.11139 7.84156 5.01087C7.70471 4.85014 7.50779 4.82133 7.31296 4.90232C7.21879 4.9364 7.13786 4.99951 7.08185 5.08258C7.02584 5.16564 6.99763 5.26436 7.0013 5.3645C6.99713 5.75862 6.9988 6.15274 6.9988 6.57692Z"
        fill={color}
      />
      <path
        d="M11.9437 1.45783C11.9437 1.30294 11.9412 1.17477 11.9437 1.04701C11.9508 0.729294 12.1624 0.504261 12.4502 0.508437C12.7381 0.512612 12.9396 0.730964 12.9417 1.04367C12.9456 1.54467 12.9456 2.0447 12.9417 2.54375C12.9417 2.80218 12.8103 2.97336 12.5562 3.04475C12.1056 3.17 11.6542 3.29316 11.2019 3.41006C10.9045 3.4873 10.6308 3.3345 10.562 3.06563C10.4894 2.78089 10.6517 2.52288 10.9562 2.4423C11.0947 2.40472 11.2328 2.36548 11.3964 2.31997C10.9682 1.98944 10.4976 1.71813 9.99706 1.51336C6.5334 0.095946 2.6905 1.78557 1.39048 5.29424C0.196849 8.51693 1.83564 12.2302 5.01226 13.5115C6.6352 14.1661 8.26231 14.1782 9.89359 13.5478C9.96017 13.5186 10.0291 13.4951 10.0997 13.4777C10.2181 13.4525 10.3415 13.4714 10.447 13.5308C10.5524 13.5902 10.6326 13.6861 10.6725 13.8004C10.756 14.0417 10.6537 14.3244 10.4076 14.4125C9.95366 14.5744 9.49682 14.7465 9.02663 14.8421C5.84959 15.4892 2.72262 14.1144 1.05797 11.3296C0.148453 9.81118 -0.179055 8.15913 0.0925468 6.40647C0.579011 3.26978 2.8336 0.844524 5.92427 0.180281C8.03701 -0.274377 10.0033 0.135608 11.794 1.35721C11.8344 1.3856 11.8762 1.41274 11.9437 1.45783Z"
        fill={color}
      />
      <path
        d="M14.9946 7.35099C14.9946 7.49127 14.9983 7.63155 14.9946 7.77183C14.9896 7.89982 14.9357 8.02102 14.8439 8.11031C14.7522 8.1996 14.6296 8.25015 14.5016 8.25149C14.3736 8.25284 14.25 8.20487 14.1564 8.11751C14.0628 8.03016 14.0063 7.91013 13.9987 7.78227C13.9833 7.44534 13.9733 7.10842 13.9657 6.77108C13.9585 6.63931 14.0037 6.51004 14.0916 6.41161C14.1794 6.31318 14.3027 6.25362 14.4343 6.24599C14.566 6.23837 14.6953 6.28329 14.7939 6.37092C14.8925 6.45855 14.9524 6.58174 14.9604 6.71346C14.9804 6.92514 14.9875 7.13806 15 7.35057L14.9946 7.35099Z"
        fill={color}
      />
      <path
        d="M13.5218 3.43395C13.6085 3.43032 13.6945 3.45153 13.7696 3.49509C13.8447 3.53865 13.9058 3.60275 13.9457 3.67986C14.1151 3.99507 14.2795 4.31362 14.4276 4.64011C14.4786 4.75898 14.4804 4.89326 14.4325 5.01343C14.3846 5.13359 14.291 5.22983 14.1723 5.28097C14.0546 5.33381 13.921 5.33888 13.7997 5.29511C13.6784 5.25134 13.5787 5.16215 13.5218 5.04634C13.3704 4.75075 13.2202 4.4539 13.0758 4.15456C13.0378 4.07858 13.0198 3.99412 13.0236 3.90922C13.0275 3.82432 13.0529 3.74182 13.0977 3.66957C13.1424 3.59732 13.2048 3.53774 13.2791 3.49649C13.3533 3.45524 13.4369 3.43371 13.5218 3.43395Z"
        fill={color}
      />
      <path
        d="M13.8026 11.1352C13.4121 11.1381 13.1684 10.7928 13.2961 10.4617C13.4175 10.1469 13.5485 9.83548 13.6833 9.52653C13.733 9.40761 13.8266 9.31244 13.9446 9.26081C14.0626 9.20918 14.196 9.20506 14.317 9.24931C14.3775 9.27039 14.4331 9.30328 14.4808 9.34608C14.5284 9.38888 14.5671 9.44072 14.5945 9.4986C14.622 9.55648 14.6376 9.61925 14.6406 9.68324C14.6436 9.74724 14.6339 9.81119 14.612 9.87139C14.4906 10.2075 14.3491 10.5394 14.2027 10.8638C14.1686 10.9423 14.1128 11.0095 14.042 11.0576C13.9712 11.1056 13.8881 11.1325 13.8026 11.1352Z"
        fill={color}
      />
      <path
        d="M13.1284 12.3476C13.0967 12.4069 13.0571 12.5439 12.9694 12.6336C12.7308 12.8787 12.4771 13.1113 12.2185 13.335C12.1694 13.378 12.1123 13.4107 12.0504 13.4314C11.9886 13.452 11.9233 13.4601 11.8583 13.4552C11.7932 13.4503 11.7299 13.4325 11.6718 13.4029C11.6137 13.3732 11.5622 13.3323 11.5201 13.2824C11.3369 13.0674 11.364 12.7639 11.5776 12.5702C11.8125 12.3572 12.0416 12.1381 12.2756 11.9251C12.4358 11.7798 12.6244 11.7381 12.8251 11.8203C13.0258 11.9026 13.1159 12.0612 13.1284 12.3476Z"
        fill={color}
      />
    </svg>
  );
};

export default TimelapseSVG;

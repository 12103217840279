import { Dispatch, memo, SetStateAction, useCallback } from 'react';

import { SortDirection } from 'enums/common';

import { PaginationSkeleton } from 'components/Pagination';
import Pagination from 'components/Pagination/Pagination';
import TableHeader from 'components/Table/components/TableHeader/TableHeader';
import TableBody from 'components/Table/TableBody';

import { TableContainer } from './Table.styles';
import { TableProps } from './Table.types';

const PAGE_SIZE = 10;

interface AsyncPaginationTableProps<T>
  extends Omit<TableProps<T>, 'defaultSortBy' | 'defaultSortDirection' | 'isSkipSort'> {
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  sortDirection: SortDirection;
  setSortDirection: Dispatch<SetStateAction<SortDirection>>;
  setPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  maxPage: number;
}

const AsyncPaginationTable = <T extends unknown>({
  data,
  columns,
  className,
  onRowClick,
  pageSize = PAGE_SIZE,
  rowHeight,
  isLoading,
  isError,
  emptyStateText,
  activeId,
  activeIdPath,
  selectedId,
  selectedIdPath,
  getIsHighlighted,
  getIsWithSubGrid,
  subGridComponent,
  isSubGrid,
  blockTestName,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  currentPage,
  maxPage,
  setPage,
}: AsyncPaginationTableProps<T>) => {
  const onSort = useCallback(
    (key: string) => {
      if (key === sortBy) {
        setSortDirection(
          sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
        );
        return;
      }

      setSortBy(key);
      setSortDirection(SortDirection.DESC);
    },
    [setSortBy, setSortDirection, sortBy, sortDirection],
  );

  const isShowSkeleton = isError || isLoading;

  return (
    <>
      <TableContainer className={className} data-testid={blockTestName} isSubGrid={isSubGrid}>
        <TableHeader<T>
          blockTestName={blockTestName}
          columns={columns}
          isSubGrid={isSubGrid}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <TableBody<T>
          activeId={activeId}
          activeIdPath={activeIdPath}
          blockTestName={blockTestName}
          columns={columns}
          data={data}
          emptyStateText={emptyStateText}
          getIsHighlighted={getIsHighlighted}
          getIsWithSubGrid={getIsWithSubGrid}
          isError={isError}
          isLoading={isLoading}
          isSubGrid={isSubGrid}
          pageSize={pageSize}
          rowHeight={rowHeight}
          selectedId={selectedId}
          selectedIdPath={selectedIdPath}
          subGridComponent={subGridComponent}
          onRowClick={onRowClick}
        />
      </TableContainer>

      {maxPage ? (
        <Pagination currentPage={currentPage} maxPage={maxPage} setPage={setPage} />
      ) : (
        isShowSkeleton && <PaginationSkeleton isError={isError} />
      )}
    </>
  );
};

export default memo(AsyncPaginationTable) as typeof AsyncPaginationTable;

import { FC, memo, useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { TypographyComponent, TypographySize, TypographyWeight } from 'enums/ui';
import { getApiError } from 'utils/api';

import { useCreateNoteMutation, useGetNotesQuery } from 'services/notesAPI';

import NoteItem from './components/NoteItem';
import TextField from 'components/@fields/TextField';
import CircleSpinner from 'components/CircleSpinner';

import {
  APIErrorMessage,
  EmptyMessage,
  Form,
  LeaveNoteButton,
  NotesContainer,
  NotesList,
  NotesSkeleton,
  Title,
} from './Notes.styles';
import schema from './Notes.validationSchema';

const initialValues = {
  note: '',
};

interface NotesProps {
  loanId?: string;
  isLoadingDetails?: boolean;
  isDetailsError?: boolean;
  applicationId?: string;
}

const Notes: FC<NotesProps> = ({ loanId, isLoadingDetails, isDetailsError, applicationId }) => {
  // conditional fetching, avoid the duplicating requests
  const { data, isLoading, isError } = useGetNotesQuery(
    { applicationId },
    { skip: isLoadingDetails || !loanId },
  );
  const [createNote, { error }] = useCreateNoteMutation();
  const APIError = getApiError(error);

  const onSubmit = useCallback(
    async ({ note }: typeof initialValues, { resetForm }: FormikHelpers<typeof initialValues>) => {
      await createNote({ note: note.trim(), loanId, applicationId }).unwrap();
      resetForm();
    },
    [applicationId, createNote, loanId],
  );

  if (isLoading || isLoadingDetails || !data || isError || isDetailsError) {
    return <NotesSkeleton isError={isError || isDetailsError} />;
  }

  return (
    <NotesContainer>
      <Title component={TypographyComponent.H6}>Notes</Title>

      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ handleChange, dirty, isSubmitting }) => (
          <Form>
            <TextField
              isAbsoluteError
              autoComplete="off"
              label="Leave Your Notes"
              name="note"
              placeholder="Your notes here"
              onChange={handleChange}
            />

            {APIError && <APIErrorMessage error={APIError} />}
            <LeaveNoteButton disabled={!dirty || isSubmitting} type="submit">
              {isSubmitting ? <CircleSpinner size={14} /> : 'Leave Notes'}
            </LeaveNoteButton>
          </Form>
        )}
      </Formik>

      <NotesList $isOverflow={data?.length > 5}>
        {data?.length ? (
          data.map(item => <NoteItem applicationId={applicationId} key={item.noteId} {...item} />)
        ) : (
          <EmptyMessage size={TypographySize.Small} weight={TypographyWeight.Regular}>
            No Current Notes
          </EmptyMessage>
        )}
      </NotesList>
    </NotesContainer>
  );
};

export default memo(Notes);

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES_CONFIG } from 'constants/navigation';

import { selectAuthData } from 'store/selectors/auth';

import ApplicationLayout from 'components/ApplicationLayout';
import AuthLayout from 'components/AuthLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import ToastContainer from 'components/ToastContainer';

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';

function App() {
  const { redirectUrl, isAuthenticated } = useSelector(selectAuthData);

  return (
    <ErrorBoundary>
      <ToastContainer />
      <Routes>
        {!isAuthenticated && (
          <Route element={<AuthLayout />}>
            <Route element={<SignIn />} path="signin" />
            <Route element={<SignUp />} path="signup" />
          </Route>
        )}

        {isAuthenticated && (
          <Route element={<ApplicationLayout />}>
            {ROUTES_CONFIG.map(({ path, Component }) => (
              <Route
                element={Component ? <Component /> : <div>{path}</div>}
                index={path === '/'}
                key={path}
                path={path}
              />
            ))}
          </Route>
        )}

        <Route
          // TODO: need to think about improving redirect logic
          // prevent multiple redirecting after toggling isAuthenticated to true in Verification step
          element={<Navigate replace to={isAuthenticated ? redirectUrl || '/' : '/signin'} />}
          path="*"
        />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;

import { FC, SVGProps } from 'react';

const SquaresSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M13.268 0C12.4377 0 11.7647 0.673036 11.7647 1.50327V6.73203C11.7647 7.56226 12.4377 8.23529 13.268 8.23529H18.4967C19.327 8.23529 20 7.56226 20 6.73203V1.50327C20 0.673036 19.327 0 18.4967 0H13.268ZM13.1373 1.50327C13.1373 1.43107 13.1958 1.37255 13.268 1.37255H18.4967C18.5689 1.37255 18.6274 1.43107 18.6274 1.50327V6.73203C18.6274 6.80422 18.5689 6.86274 18.4967 6.86274H13.268C13.1958 6.86274 13.1373 6.80422 13.1373 6.73203V1.50327Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.268 11.7647C12.4377 11.7647 11.7647 12.4377 11.7647 13.268V18.4967C11.7647 19.327 12.4377 20 13.268 20H18.4967C19.327 20 20 19.327 20 18.4967V13.268C20 12.4377 19.327 11.7647 18.4967 11.7647H13.268ZM13.1373 13.268C13.1373 13.1958 13.1958 13.1373 13.268 13.1373H18.4967C18.5689 13.1373 18.6274 13.1958 18.6274 13.268V18.4967C18.6274 18.5689 18.5689 18.6274 18.4967 18.6274H13.268C13.1958 18.6274 13.1373 18.5689 13.1373 18.4967V13.268Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.50327 11.7647C0.673036 11.7647 0 12.4377 0 13.268V18.4967C0 19.327 0.673036 20 1.50327 20H6.73203C7.56226 20 8.23529 19.327 8.23529 18.4967V13.268C8.23529 12.4377 7.56226 11.7647 6.73203 11.7647H1.50327ZM1.50327 13.1373H6.73203C6.80422 13.1373 6.86274 13.1958 6.86274 13.268V18.4967C6.86274 18.5689 6.80422 18.6274 6.73203 18.6274H1.50327C1.43107 18.6274 1.37255 18.5689 1.37255 18.4967V13.268C1.37255 13.1958 1.43107 13.1373 1.50327 13.1373Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.50327 0C0.673036 0 0 0.673036 0 1.50327V6.73203C0 7.56226 0.673036 8.23529 1.50327 8.23529H6.73203C7.56226 8.23529 8.23529 7.56226 8.23529 6.73203V1.50327C8.23529 0.673036 7.56226 0 6.73203 0H1.50327ZM1.50327 1.37255H6.73203C6.80422 1.37255 6.86274 1.43107 6.86274 1.50327V6.73203C6.86274 6.80422 6.80422 6.86274 6.73203 6.86274H1.50327C1.43107 6.86274 1.37255 6.80422 1.37255 6.73203V1.50327C1.37255 1.43107 1.43107 1.37255 1.50327 1.37255Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default SquaresSVG;

import { styled } from 'styled-components';

export const SwitcherContainer = styled.div`
  margin: 15px 0 0 25px;
  display: flex;

  & > :first-child {
    margin-right: 20px;
  }
`;

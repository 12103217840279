import {
  Children,
  FC,
  ReactNode,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { TypographyComponent } from 'enums/ui';

import { selectIsMobileView } from 'store/selectors/ui';

import { calculateDefaultLeftIndex } from 'components/Carousel/Carousel.helpers';
import {
  ArrowContainer,
  CarouselContainer,
  Content,
  ContentWrapper,
  MobileArrowsContainer,
  NoArrowsMobileHint,
  RightArrow,
} from 'components/Carousel/Carousel.styles';

import { ReactComponent as LeftArrow } from 'assets/icons/carouselArrow.svg';

const MIN_DISTANCE_FOR_TOUCH = 75;

interface CarouselProps {
  visibleSlidesCount: number;
  defaultSlideIndex?: number;
  isAlwaysWithArrows?: boolean;
  isCompact?: boolean;
  className?: string;
  children?: ReactNode;
}

const Carousel: FC<CarouselProps> = ({
  visibleSlidesCount,
  defaultSlideIndex = 0,
  isAlwaysWithArrows = false,
  children,
  className,
}) => {
  const [leftSlideIndex, setLeftSlideIndex] = useState(defaultSlideIndex);
  const [touchXStartPosition, setTouchXStartPosition] = useState<number | null>(null);
  const [totalSlidesCount, setTotalSlidesCount] = useState(Children.count(children));
  const isMobile = useSelector(selectIsMobileView);

  // Ref is used to avoid cycle in useEffect with calculateDefaultLeftIndex
  const slideIndexRef = useRef<number>(defaultSlideIndex);
  const wrapperRef = useRef<null | HTMLDivElement>(null);

  const hiddenSlidesCount = totalSlidesCount - visibleSlidesCount;
  const shouldArrowsBeMounted = totalSlidesCount > visibleSlidesCount;

  const goPrev = useCallback(() => {
    setLeftSlideIndex(index => (index === 0 ? index : index - 1));
  }, []);

  const goNext = useCallback(() => {
    setLeftSlideIndex(index => (index >= hiddenSlidesCount ? index : index + 1));
  }, [hiddenSlidesCount]);

  useEffect(() => {
    const onTouchMove = (e: TouchEvent) => {
      e.preventDefault();
    };

    let startX: number;
    const onMouseDown = (event: MouseEvent) => {
      startX = event.pageX;
    };

    const onMouseUp = (event: MouseEvent) => {
      const diffX = event.pageX - startX;

      // if its drag
      if (Math.abs(diffX) > 15) {
        if (diffX < 0) {
          goNext();
        } else if (diffX > 0) {
          goPrev();
        }
      }
    };

    const wrapper = wrapperRef.current;

    if (wrapper) {
      wrapper.addEventListener('touchmove', onTouchMove, {
        passive: false,
      });
      wrapper.addEventListener('mousedown', onMouseDown);
      wrapper.addEventListener('mouseup', onMouseUp);

      return () => {
        wrapper.removeEventListener('touchmove', onTouchMove);
        wrapper.removeEventListener('mousedown', onMouseDown);
        wrapper.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [goNext, goPrev]);

  useEffect(() => {
    setTotalSlidesCount(Children.count(children));
  }, [children]);

  useEffect(() => {
    if (defaultSlideIndex < 1) {
      return;
    }

    const timeout = setTimeout(() => {
      const index = calculateDefaultLeftIndex({
        slideIndex: defaultSlideIndex,
        totalSlidesCount,
        currentSlideIndex: slideIndexRef.current,
        visibleSlidesCount,
      });

      slideIndexRef.current = index;
      setLeftSlideIndex(index);
    }, 0);

    return () => clearTimeout(timeout);
  }, [defaultSlideIndex, totalSlidesCount, visibleSlidesCount]);

  const onTouchStart = useCallback(
    (e: ReactTouchEvent) => {
      e.stopPropagation();

      const { changedTouches } = e;

      if (changedTouches.length !== 1 || touchXStartPosition !== null) {
        return;
      }

      setTouchXStartPosition(changedTouches[0].clientX);
    },
    [touchXStartPosition],
  );

  const onTouchEnd = useCallback(
    (e: ReactTouchEvent) => {
      e.stopPropagation();

      if (touchXStartPosition === null || !e.changedTouches.length) {
        return;
      }

      const touchXEndPosition = e.changedTouches[0].clientX;
      const touchXDiff = touchXStartPosition - touchXEndPosition;

      if (Math.abs(touchXDiff) > MIN_DISTANCE_FOR_TOUCH) {
        if (touchXDiff < 0) {
          goPrev();
        } else if (touchXDiff > 0) {
          goNext();
        }
      }

      setTouchXStartPosition(null);
    },
    [goNext, goPrev, touchXStartPosition],
  );

  return (
    <CarouselContainer className={className}>
      {!isMobile && (
        <ArrowContainer
          isDisabled={leftSlideIndex === 0}
          shouldArrowsBeMounted={shouldArrowsBeMounted}
          type="button"
          onClick={goPrev}
        >
          <LeftArrow />
        </ArrowContainer>
      )}
      <ContentWrapper ref={wrapperRef} onTouchEnd={onTouchEnd} onTouchStart={onTouchStart}>
        <Content
          currentIndex={leftSlideIndex}
          totalCount={totalSlidesCount}
          visibleCount={visibleSlidesCount}
        >
          {children}
        </Content>
      </ContentWrapper>
      <MobileArrowsContainer>
        {isMobile && isAlwaysWithArrows && (
          <ArrowContainer
            isDisabled={leftSlideIndex === 0}
            shouldArrowsBeMounted={shouldArrowsBeMounted}
            type="button"
            onClick={goPrev}
          >
            <LeftArrow />
          </ArrowContainer>
        )}
        {isMobile && !isAlwaysWithArrows ? null : (
          <ArrowContainer
            withReducedLeftMargin
            isDisabled={leftSlideIndex >= hiddenSlidesCount}
            shouldArrowsBeMounted={shouldArrowsBeMounted}
            type="button"
            onClick={goNext}
          >
            <RightArrow />
          </ArrowContainer>
        )}
        {isMobile && !isAlwaysWithArrows && (
          <NoArrowsMobileHint component={TypographyComponent.H8}>
            Scroll left to view more loan terms
          </NoArrowsMobileHint>
        )}
      </MobileArrowsContainer>
    </CarouselContainer>
  );
};

export default Carousel;

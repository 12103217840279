import { getTooltipId } from 'utils/id';

export const FEE_DETAILS = 'feeDetails';

export const CARD_LABELS = {
  // rate sections
  rate: 'Rate',
  apr: 'APR',
  fees: 'Fees',
  paymentMo: 'Payment/MO',
  // years subsection
  three: '3yr cost',
  five: '5yr cost',
  eight: '8yr cost',
  // others
  points: 'Points',
};

export const TOOLTIP_CONTENT_MAP = {
  [getTooltipId(CARD_LABELS.rate)]:
    `"Rate" is a percentage that shows how much the borrower will have to pay to borrow money. Many factors come
    into play when determining the rate, such as the borrower's credit score, the loan amount, and the amount of time
    to pay off the loan. For example, someone with a high credit score who takes out a 15-year fixed-rate loan will
    probably be able to get a lower rate than someone with a lower credit score who wants a 30-year mortgage.`,
  [getTooltipId(CARD_LABELS.apr)]:
    `"APR" stands for "Annual Percentage Rate". This is the annual cost of a loan, including interest
    and fees, such as insurance on the mortgage, how much the borrower has to pay at closing, and points
    to lower the rate. Not all loans include all these costs, but it is still standard for the
    loan's APR to be a bit higher than the interest rate.`,
  [getTooltipId(CARD_LABELS.fees)]:
    `"Fees" are the extra amounts the borrower pays when obtaining a loan. No two loans are identical,
    so "fees" may or may not include mortgage insurance, closing costs,
    and points the borrower might want to pay to lower the interest rate.`,
  [getTooltipId(CARD_LABELS.paymentMo)]:
    `"Payment" is the amount of money the borrower is required to pay, typically every month,
    to the loan servicer. This will be made up of money paid against the principal and interest payment.
    What the borrower pays against the principal lowers the amount of money still owed,
    and you can think of the interest payment as a bill for borrowing money.
    The interest amount is determined by the interest rate and the amount of principal owed.
    "Payment" can also include a portion of the property taxes and housing insurance,
    should the borrower choose to spread these costs out monthly.`,
  [getTooltipId(CARD_LABELS.points)]:
    `"Points" are money the borrower can arrange to pay at closing to lower the interest rate.
    Not all loans include points and not all lenders offer points, so if the borrower is interested in
    paying points it's best to ask the mortgage loan officer if points are available for the loan. 
    Negative points means that the lender is giving you a credit.`,
  [getTooltipId(CARD_LABELS.three)]:
    `"3-year cost" is an estimate of how much money the borrower will have paid in the first 3 years of the loan.
    This amount includes, but may not be limited to, interest payments and upfront costs,
    such as discount points and origination fees paid at closing.`,
  [getTooltipId(CARD_LABELS.five)]:
    `"5-year cost" is an estimate of how much money the borrower will have paid in the first 5 years of the loan.
    This amount includes, but may not be limited to, interest payments and upfront costs,
    such as discount points and origination fees paid at closing.`,
  [getTooltipId(CARD_LABELS.eight)]:
    `"8-year cost" is an estimate of how much money the borrower will have paid in the first 8 years of the loan.
    This amount includes, but may not be limited to, interest payments and upfront costs,
    such as discount points and origination fees paid at closing.`,
} as { [key: string]: string };

export const YEARS_DATA = [
  {
    id: getTooltipId(CARD_LABELS.three),
    label: CARD_LABELS.three,
  },
  {
    id: getTooltipId(CARD_LABELS.five),
    label: CARD_LABELS.five,
  },
  {
    id: getTooltipId(CARD_LABELS.eight),
    label: CARD_LABELS.eight,
  },
];

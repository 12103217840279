import { FC, useCallback, useMemo } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { ButtonVariant } from 'enums/ui';
import { formatCurrency } from 'utils/formatting';

import { Title } from 'components/@modals/@SelectNewProgramSteps/@shared';
import EditDataForm, {
  EditDataFormProps,
} from 'components/@modals/@SelectNewProgramSteps/EditDataForm';
import Button from 'components/Button/Button';
import CircleSpinner from 'components/CircleSpinner';

import { ButtonsContainer, EditSubmitButton, MaxApprovedPrice } from './ManagePreApproval.styles';

const EditData: FC<EditDataFormProps> = ({ isEdit, ...props }) => {
  const modal = useModal();

  const title = `${isEdit ? 'Edit' : 'Create'} Your Pre-Approval Letter`;
  const buttonText = isEdit ? 'Save changes' : 'Issue Pre-Approval Letter';

  const topContent = useMemo(
    () => (
      <>
        <Title>{title}</Title>
        <MaxApprovedPrice>
          Max Approved Purchase Price: &nbsp;
          {formatCurrency(props.maxApprovedPrice)}
        </MaxApprovedPrice>
      </>
    ),
    [props.maxApprovedPrice, title],
  );

  const bottomContent = useCallback(
    ({ isSubmitting, dirty }: { isSubmitting: boolean; dirty: boolean }) => (
      <ButtonsContainer>
        <EditSubmitButton disabled={isSubmitting || !dirty} isEdit={isEdit} type="submit">
          {isSubmitting ? <CircleSpinner size={16} /> : buttonText}
        </EditSubmitButton>
        <Button
          disabled={isSubmitting}
          type="button"
          variant={ButtonVariant.Transparent}
          onClick={modal.remove}
        >
          Cancel
        </Button>
      </ButtonsContainer>
    ),
    [buttonText, isEdit, modal.remove],
  );

  return (
    <EditDataForm bottomContent={bottomContent} modal={modal} topContent={topContent} {...props} />
  );
};

export default EditData;

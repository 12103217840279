import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

export const AddressFieldset = styled.fieldset`
  padding: 0;
  border: 0;
`;

export const UpperBlock = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 30px;
  grid-template-columns: 3fr 0.8fr 1.9fr;
  margin-bottom: 30px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 0;

    // matches last 2 elements (Unit and City)
    & > :nth-child(n + 2) {
      width: calc((100% - 15px) / 2);
      margin-top: 15px;
    }
  }
`;

export const LowerBlock = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 0;

    // matches first 2 elements (State and ZipCode)
    & > :nth-child(-n + 2) {
      width: calc((100% - 15px) / 2);
      margin: 0 0 30px;
    }

    // dynamic 3-rd element (County)
    & > :nth-child(3) {
      width: calc((100% - 15px) / 2);
      margin-right: 0;
    }
  }
`;

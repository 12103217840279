import { createApi } from '@reduxjs/toolkit/query/react';
import camelCase from 'lodash/camelCase';

import { realtorsQuery } from 'utils/api';

import { FeatureToggleFromServer, FeatureToggleMap } from './types';

export const unauthorizedBaseAPI = createApi({
  reducerPath: 'unauthorizedCommonAPI',
  baseQuery: realtorsQuery,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getFeatureToggles: build.query<FeatureToggleMap, void>({
      query: () => `featuretoggles`,
      transformResponse: (response: FeatureToggleFromServer[]): FeatureToggleMap => {
        return response.reduce((acc: FeatureToggleMap, { key, value }) => {
          acc[camelCase(key)] = value;
          return acc;
        }, {});
      },
    }),
  }),
});

export const { useGetFeatureTogglesQuery } = unauthorizedBaseAPI;

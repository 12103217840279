import { ChangeEvent, memo, useCallback } from 'react';
import { PatternFormat } from 'react-number-format';
import { FormikHandlers, FormikHelpers, FormikValues, useField } from 'formik';
import set from 'lodash/set';

import { ONLY_ALPHANUMERIC_REGEXP, ONLY_DIGITS_REGEXP } from 'constants/regExps';
import { STATES_MAP } from 'constants/states';
import { State } from 'enums/states';
import { useCounties } from 'hooks/useCounties';

import { PlacesAutocomplete, Suggestion } from 'components/@fields/PlacesAutocomplete';
import SelectField from 'components/@fields/SelectField';
import TextField from 'components/@fields/TextField';
import { CountiesCircleSpinner } from 'components/@styled';

import { AddressFieldset, LowerBlock, UpperBlock } from './FormikAddress.styles';

interface OptionType<T> {
  value: T;
  label: string;
}

interface AddressProps<T> {
  namePath?: string;
  setValues: FormikHelpers<T>['setValues'];
  isDisabled?: boolean;
  handleChange: FormikHandlers['handleChange'];
  className?: string;
  blockTestName?: string;
  isAbsoluteError?: boolean;
}

const stateOptions: OptionType<State>[] = Object.entries(STATES_MAP).map(([label, value]) => ({
  value,
  label,
}));

const FormikAddress = <T extends FormikValues>({
  namePath = '',
  isDisabled = false,
  handleChange,
  setValues,
  className,
  blockTestName = 'address',
  isAbsoluteError,
}: AddressProps<T>) => {
  const [{ value: zip }, , { setTouched: setZipTouched }] = useField(`${namePath}zip`);
  const [{ value: county }] = useField(`${namePath}county`);

  const { isFetchingCounties, countyOptions, resetCountyOptions } = useCounties<T>({
    zip,
    county,
    namePath,
    setValues,
    setZipTouched: setZipTouched as FormikHelpers<boolean>['setTouched'],
  });

  const onAutoComplete = useCallback(
    (values: Suggestion) => {
      resetCountyOptions();

      const formattedValues = { ...values, unit: '' };

      const pathArray = namePath.split('.').slice(0, -1);
      const nestedState = namePath ? {} : formattedValues;

      if (namePath) {
        set(nestedState, pathArray, formattedValues);
      }

      setValues(prevState => ({
        ...prevState,
        ...nestedState,
      }));
    },
    [namePath, resetCountyOptions, setValues],
  );

  const onChange = useCallback(
    (filterPattern?: RegExp) =>
      (e: ChangeEvent<HTMLInputElement>): void => {
        if (filterPattern) {
          const re = new RegExp(filterPattern, 'g');

          if (!e.target.value.match(re)) {
            return;
          }
        }

        handleChange(e);
      },
    [handleChange],
  );

  const onChangeZipCode = onChange(ONLY_DIGITS_REGEXP);
  const onChangeUnit = onChange(ONLY_ALPHANUMERIC_REGEXP);

  const isMoreThanOneCounty = countyOptions?.length > 1;

  return (
    <AddressFieldset
      className={className}
      data-testid={`${blockTestName}__fieldset`}
      disabled={isDisabled}
    >
      <UpperBlock>
        <PlacesAutocomplete
          required
          data-testid={`${blockTestName}__streetAddressInput`}
          disabled={isDisabled}
          isAbsoluteError={isAbsoluteError}
          label="Street Address"
          name={`${namePath}street`}
          placeholder="Street Address"
          onBlur={handleChange}
          onPlaceClick={onAutoComplete}
        />
        <TextField
          data-testid={`${blockTestName}__unitInput`}
          disabled={isDisabled}
          label="Unit #"
          name={`${namePath}unit`}
          onChange={onChangeUnit}
        />
        <TextField
          required
          data-testid={`${blockTestName}__cityInput`}
          disabled={isDisabled}
          isAbsoluteError={isAbsoluteError}
          label="City"
          name={`${namePath}city`}
          onChange={handleChange}
        />
      </UpperBlock>
      <LowerBlock>
        <SelectField
          required
          data-testid={`${blockTestName}__stateSelect`}
          disabled={isDisabled}
          isAbsoluteError={isAbsoluteError}
          label="State"
          name={`${namePath}state`}
          options={stateOptions}
        />
        <PatternFormat
          required
          RightIcon={isFetchingCounties && <CountiesCircleSpinner size={14} />}
          customInput={TextField}
          data-testid={`${blockTestName}__zipCodeInput`}
          disabled={isDisabled || isFetchingCounties}
          format="#####"
          inputMode="tel"
          isAbsoluteError={isAbsoluteError}
          label="Zip Code"
          // * empty mask provided to prevent spaces in input value
          mask=""
          name={`${namePath}zip`}
          value={zip}
          onChange={onChangeZipCode}
        />
        {isMoreThanOneCounty && (
          <SelectField
            data-testid={`${blockTestName}__countyInput`}
            disabled={isDisabled}
            label="County"
            name={`${namePath}county`}
            options={countyOptions}
          />
        )}
      </LowerBlock>
    </AddressFieldset>
  );
};

export default memo(FormikAddress) as typeof FormikAddress;

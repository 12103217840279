import { FC, MouseEventHandler, ReactNode } from 'react';
import { lighten, transparentize } from 'polished';
import { css, styled } from 'styled-components';

import { MediaQueries, TypographyComponent, TypographyWeight } from 'enums/ui';

import { Typography } from 'components/Typography';

import { getLineClampStyles } from 'styles/typography';

import RecommendedMark from './RecommendedMark';

export interface CardProps {
  isSelected?: boolean;
  isCurrentProgram?: boolean;
}

const selectedStyles = css`
  border-color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => transparentize(0.97, theme.colors.primary)};
`;

// TODO: adapt ui for slim screen (mobile)
export const ProgramCardContainer = styled.div<CardProps>`
  position: relative;

  min-width: 286px;
  height: fit-content;
  min-height: 160px;
  padding: 12px;
  margin: 10px 5px;
  border-radius: 20px;

  cursor: pointer;

  border: 1px solid ${({ theme }) => theme.colors.separateLine};
  background-color: ${({ theme }) => theme.colors.bodyBackground};

  transition:
    border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  &:hover {
    ${selectedStyles};
  }

  ${({ isSelected }) => isSelected && selectedStyles};

  ${({ isCurrentProgram }) =>
    isCurrentProgram &&
    css`
      cursor: default;
    `};

  @media (max-width: ${MediaQueries.Mobile}) {
    margin: 10px 0;
  }
`;

export const UpperCells = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  & > div:first-child {
    width: 40%;
  }

  & > div:nth-child(2) {
    width: 45%;
  }

  & > div:nth-child(3) {
    width: 60%;
  }
`;

export const Cell = styled.div<{ isCompact?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: min-content;
`;

export const FeesCellContainer = styled(Cell)`
  & > button:not(:first-child) {
    text-decoration: underline;
  }
`;

export const CostValueWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :last-child {
    text-decoration: underline;
  }
`;

export const LowerCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-bottom: 16px;
  max-height: 40px;
  background-color: ${({ theme }) => lighten(0.35, theme.colors.primary)};

  & > * {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const LowerCellLabel = styled(Typography)`
  padding-right: 7px;
`;

export const StyledNumber = styled(Typography)`
  margin-right: 5px;

  @media (max-width: ${MediaQueries.Mobile}) {
    font-size: 14px;
  }
`;

interface ClickableTextProps {
  onClick?: MouseEventHandler<HTMLParagraphElement>;
  children?: ReactNode;
}

export const Number: FC<ClickableTextProps> = ({ children, ...props }) => (
  <StyledNumber {...props} component={TypographyComponent.H6} weight={TypographyWeight.SemiBold}>
    {children}
  </StyledNumber>
);

export const Fees = styled(Number)`
  text-decoration: underline;
`;

export const Points = styled(Typography)<ClickableTextProps>`
  text-decoration: underline;
`;

export const CustomRecommendedMark = styled(RecommendedMark)`
  border-bottom-right-radius: 19px;
`;

export const ProgramName = styled(Typography)`
  // three-line limit
  ${getLineClampStyles(3)}

  @media (max-width: ${MediaQueries.Mobile}) {
    font-size: 12px;
  }
`;

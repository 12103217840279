import { ChangeEvent, forwardRef, ForwardRefRenderFunction, memo, useCallback } from 'react';

import { formatName } from 'utils/formatting';

import TextField, { TextFieldProps } from 'components/@fields/TextField';

const NameField: ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (
  { onChange, ...props },
  ref,
) => {
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.target.value = formatName(e.target.value);

      onChange?.(e);
    },
    [onChange],
  );

  return <TextField {...props} ref={ref} onChange={onInputChange} />;
};

export default memo(forwardRef(NameField));

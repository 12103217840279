import { Form as FormikForm } from 'formik';
import { styled } from 'styled-components';

import Button from 'components/Button/Button';
import Card from 'components/Card';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const FeedbackContainer = styled(Card)`
  display: flex;
  width: 340px;
  padding: 20px 25px;
`;

export const FeedbackSkeleton = styled(Skeleton)`
  width: 340px;
  height: 215px;
  border-radius: 20px;
`;

export const Title = styled(Typography)`
  margin-bottom: 20px;
`;

export const SendFeedbackButton = styled(Button)`
  min-width: 150px;
`;

export const APIErrorMessage = styled(ErrorMessage)`
  margin-bottom: 15px;
  text-align: center;
`;

export const Form = styled(FormikForm)`
  width: 100%;

  & > :first-child {
    margin-bottom: 20px;
  }
`;

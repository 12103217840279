import { FC } from 'react';
import { darken } from 'polished';
import { css, styled } from 'styled-components';

import { getEmptyArray } from 'utils/array';

import Skeleton from 'components/Skeleton';

export const PaginationContainer = styled.div`
  display: flex;
  margin: 20px 0;
  min-height: 35px;
`;

export const PageItem = styled.div<{ $isActive: boolean }>`
  user-select: none;

  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary : theme.colors.paginationBackground};
  color: ${({ theme, $isActive }) => ($isActive ? theme.colors.white : theme.colors.text.primary)};
  height: 35px;
  width: 35px;
  border-radius: 50px;
  margin-right: 10px;
  font-size: 14px;
  // aligning text
  padding-top: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $isActive, theme }) =>
    !$isActive &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${darken(
          theme.coefficients.primaryHovered,
          theme.colors.paginationBackground,
        )};
      }
    `}
`;

export const Separator = styled.div`
  height: 35px;
  width: 35px;
  text-align: center;
  margin-right: 10px;
`;

const PageItemSkeleton = styled(Skeleton)`
  height: 35px;
  width: 35px;
  border-radius: 50px;
  margin-right: 10px;
`;

export const PaginationSkeleton: FC<{ isError: boolean }> = ({ isError }) => (
  <PaginationContainer>
    {getEmptyArray(7).map((_, index) => (
      <PageItemSkeleton isError={isError} key={index} />
    ))}
  </PaginationContainer>
);

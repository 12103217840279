import { css, styled } from 'styled-components';

export interface IndicatorProps {
  isChecked: boolean;
  className?: string;
}

export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;

  border-top-left-radius: 19px;

  background: ${({ theme }) =>
    `linear-gradient(to right bottom, ${theme.colors.primary} 0% 50%, rgba(0, 0, 0, 0) 51% 100%)`};

  transition: opacity 0.3s;

  & svg {
    position: absolute;
    top: 6px;
    left: 5px;
    width: 10px;
    height: 7px;
  }

  ${({ isChecked }) =>
    isChecked
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

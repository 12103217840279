import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  z-index: 1000;
  backdrop-filter: blur(1px) brightness(0.5);
`;

export const Content = styled.div<{ fullScreenPoint?: MediaQueries }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: hidden;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.card.background};

  ${({ fullScreenPoint }) =>
    fullScreenPoint &&
    css`
      @media (max-width: ${fullScreenPoint}) {
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        padding: 25px 30px 50px;
        border-radius: 0;
      }
    `};
`;

export const CloseIcon = styled(PlusIcon)`
  position: absolute;
  right: 25px;
  top: 25px;

  width: 25px;
  height: 25px;

  transform: rotate(45deg);
  opacity: 70%;
  cursor: pointer;

  &:hover {
    opacity: 100%;
  }
`;

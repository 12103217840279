import { lazy, Suspense } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { styled } from 'styled-components';

import AnimatedLogoSVG from 'components/@icons/AnimatedLogoSVG';

import { ChangeProgramContainer } from './ChangeProgramData.styles';
import { ChangeProgramModalProps } from './types';

const ChangeProgramData = lazy(() => import('./ChangeProgramData'));

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.bodyBackground};
`;

export default NiceModal.create<ChangeProgramModalProps>(props => (
  <ChangeProgramContainer>
    <Suspense
      fallback={
        <LoaderContainer>
          <AnimatedLogoSVG />
        </LoaderContainer>
      }
    >
      <ChangeProgramData {...props} />
    </Suspense>
  </ChangeProgramContainer>
));

export enum ValidationError {
  Address = 'Address must contain numbers and letters',
  CurrencyAmount = 'Field value should be a valid amount',
  Email = 'Field value should be a valid email',
  MaxLength = 'Max character length is',
  MinLength = 'Min character length is',
  PhoneNumber = 'Field value should be a valid phone number',
  RequiredField = 'Required field should not be empty',
  MinValue = 'Field must not be less than',
  Zip = 'Must be a valid zip code',
}

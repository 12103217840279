import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { store } from 'store';

import HeadConnector from 'components/HeadConnector/HeadConnector';
import InitializeApp from 'components/InitializeApp';
import AppThemeProvider from 'components/ThemeProvider/AppThemeProvider';

import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <HeadConnector />
      <AppThemeProvider>
        <NiceModal.Provider>
          <InitializeApp>
            <App />
          </InitializeApp>
        </NiceModal.Provider>
      </AppThemeProvider>
    </Provider>
  </BrowserRouter>,
);

reportWebVitals();

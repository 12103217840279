import { darken, lighten } from 'polished';
import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import AnimatedLogoSVG from 'components/@icons/AnimatedLogoSVG';
import TreeAccordion from 'components/Accordion/TreeAccordion';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import Checkbox from 'components/Checkbox';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

import { commonInput } from 'styles/common';

export const DocumentsContainer = styled(Card)`
  height: fit-content;

  @media (min-width: ${MediaQueries.Tablet}) {
    min-width: 700px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    flex-direction: column;
  }
`;

export const TreeContainer = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.separateLine};
  background-color: ${({ theme }) => theme.colors.bodyBackground};

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-items: left;

    width: 100%;
    margin-bottom: 10px;
  }
`;

export const TreeFilters = styled(Card)`
  margin-bottom: 10px;

  border: 1px solid ${({ theme }) => theme.colors.separateLine};
  background-color: ${({ theme }) => theme.colors.bodyBackground};
`;

export const SearchField = styled.input`
  ${commonInput};

  margin-bottom: 10px;
`;

export const FilterButton = styled(Button)`
  width: 100%;
  padding: 12px 18px 10px 18px;
`;

export const FilterCheckbox = styled(Checkbox)`
  font-size: 15px;
  margin-bottom: 10px;
`;

export const NestedTreeItem = styled(TreeAccordion)<{ isFirstLevel?: boolean }>`
  padding: 0 0 0 5px;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;

  ${({ isFirstLevel }) =>
    isFirstLevel &&
    css`
      margin: 5px 0 5px;
    `}
`;

export const DocumentsSkeleton = styled(Skeleton)`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  min-height: 672px;

  @media (min-width: ${MediaQueries.Tablet}) {
    min-width: 700px;
  }
`;

export const DocumentsWrapper = styled.div`
  display: flex;
  max-width: 1060px;
  width: 100%;
`;

export const Title = styled(Typography)`
  margin-bottom: 15px;
`;

export const PDF = styled.object`
  min-height: 65vh;
  border-radius: 20px;
  width: 100%;
  margin-left: 10px;
  text-align: center;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    margin: 0;
  }
`;

export const DocumentContainer = styled.div<{ $isNeedHighlight: boolean; isSelected: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
  transition: all 0.3s cubic-bezier(0.4, 0.6, 0.6, 1);

  border-color: ${({ isSelected, theme }) => isSelected && theme.colors.primary};
  background-color: ${({ theme, isSelected }) => isSelected && lighten(0.33, theme.colors.primary)};

  padding: 3px 15px;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 5px;

  ${({ $isNeedHighlight, theme }) =>
    $isNeedHighlight &&
    css`
      color: ${theme.colors.status.error};
      border-color: ${theme.colors.status.error};
    `}

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.card.background)};
    border-color: ${({ theme, $isNeedHighlight }) => !$isNeedHighlight && theme.colors.primary};
    cursor: pointer;
  }
`;

export const LeftBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 250px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    max-width: 100%;
  }
`;

export const Loader = styled(AnimatedLogoSVG)`
  margin: auto;
`;

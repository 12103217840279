import { AccessStatus } from 'enums/application';
import { TypographyComponent } from 'enums/ui';
import { getFormattedAddress, getFormattedDate } from 'utils/formatting';

import { Address } from 'types/address';
import { LoanApplication } from 'types/application';

import ExpirationTimeSVG from 'components/@icons/ExpirationTimeSVG';
import LocationSVG from 'components/@icons/LocationSVG';
import PersonFilledSVG from 'components/@icons/PersonFilledSVG';
import TimelapseSVG from 'components/@icons/TimelapseSVG';
import Chip from 'components/Chip/Chip';
import ExpirationRateDate from 'components/ExpirationRateDate';
import { Column } from 'components/Table/Table.types';

import { BlurredText } from './ApplicationsTable.styles';

const fullNameKey = 'primaryBorrower.fullName';
const lastUpdatedKey = 'lastUpdated';
const lockExpKey = 'lockExpirationDate';

export type SortByType = typeof fullNameKey | typeof lastUpdatedKey | typeof lockExpKey;

export const SORT_BY_MAP = {
  [fullNameKey]: 'BorrowerName',
  [lastUpdatedKey]: 'LastModified',
  [lockExpKey]: 'LockExpiration',
};

interface CustomRendererType<T> {
  value: T;
  rowData: LoanApplication;
}

export const getColumns = (color: string): Column<LoanApplication>[] => [
  {
    key: fullNameKey,
    label: 'Name',
    isSortable: true,
    icon: <PersonFilledSVG color={color} />,
    isHighlightOnHover: true,
  },
  {
    key: 'subjectProperty.address',
    label: 'Address',
    icon: <LocationSVG color={color} />,
    customRenderer: ({ value, rowData: { accessStatus } }: CustomRendererType<Address>) => {
      const isBlurred = accessStatus !== AccessStatus.Approved;
      return (
        <BlurredText $isBlurred={isBlurred}>
          {isBlurred ? 'Blurred Address' : getFormattedAddress(value)}
        </BlurredText>
      );
    },
    minWidth: '180px',
  },
  {
    key: lastUpdatedKey,
    label: 'Last Upd',
    isSortable: true,
    icon: <TimelapseSVG color={color} />,
    customRenderer: ({ value, rowData: { accessStatus } }: CustomRendererType<string>) => (
      <BlurredText $isBlurred={accessStatus !== AccessStatus.Approved}>
        {getFormattedDate(value)}
      </BlurredText>
    ),
  },
  {
    key: lockExpKey,
    label: 'Lock Exp',
    isSortable: true,
    icon: <ExpirationTimeSVG color={color} />,
    customRenderer: ({ value, rowData: { accessStatus } }: CustomRendererType<string>) => (
      <BlurredText $isBlurred={accessStatus !== AccessStatus.Approved}>
        <ExpirationRateDate lockExpirationDate={value} />
      </BlurredText>
    ),
  },
  {
    key: 'accessStatus',
    label: 'Status',
    wrapperComponent: TypographyComponent.DIV,
    customRenderer: ({
      rowData: { progress, loanProcessingStopReason, loanStatus },
    }: CustomRendererType<unknown>) => (
      <Chip
        progressLastCompletedStateType={progress?.lastCompleted?.name}
        status={loanProcessingStopReason || loanStatus}
      />
    ),
  },
];

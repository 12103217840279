import { useEffect, useMemo, useState } from 'react';

interface UsePaginationProps<T> {
  pageSize: number;
  initPage?: number;
  data?: T[];

  // Pass it to rely on external collection size/server paging, with only initial skeleton (should be stable over time)
  totalSize?: number;
}

export const usePagination = <T extends unknown>({
  pageSize,
  totalSize,
  initPage = 1,
  data,
}: UsePaginationProps<T>) => {
  const [currentPage, setPage] = useState(initPage);

  const total = totalSize || data?.length || 0;
  const maxPage = Math.ceil(total / pageSize);

  useEffect(() => {
    if (total && currentPage > maxPage) {
      setPage(maxPage);
    }
  }, [currentPage, maxPage, pageSize, total]);

  const paginationState = useMemo(() => {
    const from = pageSize * (currentPage - 1) + 1;
    const to = Math.min(pageSize * currentPage, total);
    const shownData = totalSize ? data : data?.slice(from - 1, to);

    return { from, to, total, shownData };
  }, [currentPage, data, pageSize, total, totalSize]);

  return { currentPage, setPage, ...paginationState, maxPage, pageSize };
};

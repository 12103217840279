import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import { ControlledSelectField } from 'components/@fields/SelectField';

export const PipelineContainer = styled.div<{ $isTableViewType: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: min-content; // fixes overlap on iOS 13.9 and lower
  width: ${({ $isTableViewType }) => $isTableViewType && '100%'};

  @media (min-width: ${MediaQueries.Tablet}) {
    max-width: 700px;
  }

  @media (min-width: ${MediaQueries.Desktop}) {
    max-width: initial;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StatusFilter = styled(ControlledSelectField<string | null>)`
  margin-bottom: 20px;

  @media (min-width: ${MediaQueries.MobileLandscape}) {
    width: 275px;
  }
`;

import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { ButtonVariant } from 'enums/ui';
import useTerms from 'hooks/useTerms';

import { LoanOption } from 'types/application';

import Button from 'components/Button';
import CircleSpinner from 'components/CircleSpinner';
import ProgramCard from 'components/ProgramCard';

import { Step, Title } from '../@shared';

import {
  ButtonsContainer,
  ContinueButton,
  CustomTermTab,
  ScrollContainer,
  TermTabsCarousel,
} from './SelectProgram.styles';

interface ProgramApprovalProps {
  loanOptions: LoanOption[];
  setStep: (step: Step) => void;
  setSelectedProgram: Dispatch<SetStateAction<string>>;
  selectedProgram: string;
  // for purchase agreement
  onSubmit?: () => void;
  isLoadingPurchaseAgreement?: boolean;
}

const SelectProgram: FC<ProgramApprovalProps> = ({
  loanOptions,
  setStep,
  selectedProgram,
  setSelectedProgram,
  isLoadingPurchaseAgreement,
  onSubmit,
}) => {
  const modal = useModal();

  const { terms, selectedTerm, changeTerm, termLoanOptions, visibleTermsNumber } = useTerms({
    loanOptions: loanOptions,
    selectedLoanOptionId: selectedProgram,
    setSelectedLoanOptionId: setSelectedProgram,
  });

  const onSubmitProgram = useCallback(async () => {
    if (onSubmit) {
      onSubmit();
      return;
    }

    setStep(Step.SubmitProgram);
  }, [onSubmit, setStep]);

  const activeTermIndex = useMemo(
    () =>
      Math.max(
        terms.findIndex(termInfo => termInfo.years === selectedTerm),
        0,
      ),
    [selectedTerm, terms],
  );

  // scroll to top of options list when switching terms
  const firstOptionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    firstOptionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedTerm]);

  return (
    <>
      <Title isCentered>Please select a new Loan Program</Title>
      <TermTabsCarousel defaultSlideIndex={activeTermIndex} visibleSlidesCount={visibleTermsNumber}>
        {terms.map((termInfo, index) => (
          <CustomTermTab
            changeTerm={changeTerm}
            index={index}
            info={termInfo}
            isActive={termInfo.years === selectedTerm}
            key={termInfo.years}
          />
        ))}
      </TermTabsCarousel>
      <ScrollContainer>
        {termLoanOptions.map((option, index) => (
          <ProgramCard
            isRecommended={index === 0}
            key={option.id}
            option={option}
            ref={index === 0 ? firstOptionRef : undefined}
            value={selectedProgram}
            onSelect={setSelectedProgram}
          />
        ))}
      </ScrollContainer>

      <ButtonsContainer>
        <Button
          disabled={isLoadingPurchaseAgreement}
          variant={ButtonVariant.Transparent}
          onClick={modal.remove}
        >
          Cancel
        </Button>
        <ContinueButton
          disabled={isLoadingPurchaseAgreement || !selectedProgram}
          onClick={onSubmitProgram}
        >
          {isLoadingPurchaseAgreement ? <CircleSpinner size={16} /> : 'Continue'}
        </ContinueButton>
      </ButtonsContainer>
    </>
  );
};

export default SelectProgram;

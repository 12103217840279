import { css, styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

export const CELL_STYLES = css<{ minWidth?: string; maxWidth?: string; isReverse?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1 0;
  justify-content: ${({ isReverse }) => isReverse && 'flex-end'};
  padding: 0 8px;

  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const TableContainer = styled.div<{ isSubGrid?: boolean }>`
  width: 100%;
  height: 100%;

  @media (max-width: ${MediaQueries.Tablet}) {
    height: initial;
  }

  @media (max-width: ${MediaQueries.Mobile}) {
    width: max-content;
  }

  ${({ isSubGrid, theme }) =>
    isSubGrid &&
    css`
      padding: 10px 20px 0.1px;
      margin-bottom: 20px;
      background-color: ${theme.colors.subGridBackground};
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 4px 0px inset;
    `};
`;

export const EmptyStateContainer = styled.div`
  padding: 70px 0;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { FC, ReactNode, useCallback, useMemo } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import _ from 'lodash';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { StyleSheetManager } from 'styled-components';

import { StyledGlobal } from 'styles/globalStyles';
import { palette } from 'styles/palette';
import { lightTheme } from 'styles/themes/light';

interface ThemeProviderProps {
  isLoading?: boolean;
  themePrimaryColor?: string;
  children?: ReactNode;
}

const ThemeProvider: FC<ThemeProviderProps> = ({
  isLoading,
  themePrimaryColor = palette.pink,
  children,
}) => {
  // const isDarkMode = useDarkMode();

  const appTheme = useMemo(() => {
    if (isLoading || !themePrimaryColor) {
      return lightTheme;
    }

    const theme = _.cloneDeep(lightTheme);
    theme.colors.primary = themePrimaryColor;
    theme.colors.text.secondary = themePrimaryColor;
    theme.colors.buttons.primary = themePrimaryColor;
    theme.colors.loader = themePrimaryColor;

    return theme;
  }, [isLoading, themePrimaryColor]);

  const shouldForwardProps = useCallback((propName: string, elementToBeRendered: unknown) => {
    return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true;
  }, []);

  return (
    <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProps}>
      {/* <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}> */}
      <StyledThemeProvider theme={appTheme}>
        <>
          <StyledGlobal />
          {children}
        </>
      </StyledThemeProvider>
    </StyleSheetManager>
  );
};

export default ThemeProvider;

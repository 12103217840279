import { Tooltip as ReactTooltip } from 'react-tooltip';
import { styled } from 'styled-components';

import CircleSpinner from 'components/CircleSpinner';

import 'react-tooltip/dist/react-tooltip.css';

export const CountiesCircleSpinner = styled(CircleSpinner)`
  position: absolute;
  right: 15px;
  top: 42px;
`;

export const Tooltip = styled(ReactTooltip)`
  max-width: 500px;
  max-height: min-content;

  z-index: 1000;

  font-size: 12px !important;
  color: ${({ theme }) => theme.colors.tooltip.text};

  // TODO: investigate problem with box-shadow
  border: 2px solid ${({ theme }) => theme.colors.tooltip.border};
  border-radius: 8px;

  background: ${({ theme }) => theme.colors.tooltip.background};
`;

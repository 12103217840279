import { toast } from 'react-toastify';
import { createApi } from '@reduxjs/toolkit/query/react';
import get from 'lodash/get';

import { DETAILS_INCLUDES } from 'constants/application';
import { skynetQueryWithReauth } from 'utils/api';

import { DocumentsData, UpdatedDocument } from 'services/documentsAPI/types';
import loansAPI from 'services/loansAPI';
import { LoanCondition, TaskSlaGroup } from 'types/conditions';

import {
  ChangeLoanProgramPartialResponse,
  ChangeLoanProgramPayload,
  LoanOptionsPayload,
  LoanOptionsResponse,
} from './types';

const skynetAPI = createApi({
  reducerPath: 'skynetAPI',
  baseQuery: skynetQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    loanOptions: build.mutation<LoanOptionsResponse, LoanOptionsPayload>({
      query: ({ loanId, ...body }) => ({
        url: `loan/${loanId}/loan-options`,
        method: 'POST',
        body,
      }),
    }),
    getLoanConditions: build.query<LoanCondition[], { loanId: string }>({
      query: ({ loanId }) => ({
        url: 'loanCondition',
        params: { loanId },
      }),
    }),
    getTaskSlaGroup: build.query<TaskSlaGroup, { slaGroupId: string }>({
      query: ({ slaGroupId }) => `TaskSlaGroup/${slaGroupId}`,
    }),
    changeLoanProgram: build.mutation<ChangeLoanProgramPartialResponse, ChangeLoanProgramPayload>({
      query: ({ applicationId, loanOptionId }) => ({
        url: `application/${applicationId}/choose-loan-option/save/v2`,
        method: 'PUT',
        params: { withLoanOptionId: loanOptionId },
      }),
      async onQueryStarted({ applicationId }, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            dispatch(
              loansAPI.util.updateQueryData(
                'getLoanApplications',
                {
                  applicationIds: [applicationId],
                  includes: DETAILS_INCLUDES,
                },
                draft => {
                  const application = draft.items?.find(app => app.applicationId === applicationId);
                  if (application) {
                    application.programId = get(data, 'loan.programId');
                    application.loanPeriod = get(data, 'loan.loanTerm');
                    application.interestRate = get(data, 'loan.interestRate');
                    application.programPoints = get(data, 'loan.programPoints');
                  }
                },
              ),
            );
            toast('Application program successfully changed');
          })
          .catch(() => {
            toast.warning(`Application program wasn't changed`);
          });
      },
    }),
    getDocuments: build.query<DocumentsData[], string>({
      queryFn: async (loanId, { dispatch }, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `/Document/filtered-initial-disclosures/${loanId}/v2`,
          params: { includeDeleted: true },
        });

        if (response.error) {
          return {
            error: response.error,
          };
        }

        const documents = response.data as UpdatedDocument[];

        const mappedDocuments = documents?.map(document => ({
          ...document,
          createdDate: document.lifeTimeInfo.createdDate,
        }));

        return { data: mappedDocuments };
      },
    }),
  }),
});

export const {
  useLoanOptionsMutation,
  useChangeLoanProgramMutation,
  useGetLoanConditionsQuery,
  useGetTaskSlaGroupQuery,
  useGetDocumentsQuery,
} = skynetAPI;

export default skynetAPI;

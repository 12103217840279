import { createSelector, Selector } from '@reduxjs/toolkit';
import pick from 'lodash/pick';

import { IS_PRODUCTION } from 'constants/common';
import { DEV_FLAGS, FeatureFlags } from 'constants/featureToggles';

import { unauthorizedBaseAPI } from 'services/baseAPI';
import { RootState } from 'store/index';

type FeatureTogglesType = {
  [key: string]: boolean;
};

const selectFeatureToggles = (state: RootState) =>
  unauthorizedBaseAPI.endpoints.getFeatureToggles.select()(state).data;

export const selectRealtorsFeatureToggles = createSelector(selectFeatureToggles, featureToggles =>
  pick(featureToggles, Object.values(FeatureFlags)),
);

const selectServerFeatureTogglesWithOverrides = createSelector(
  [selectRealtorsFeatureToggles],
  flags =>
    Object.entries(flags).reduce((acc, [key, value]) => {
      const valueFromLS = localStorage.getItem(key);
      acc[key] = valueFromLS ? valueFromLS === 'true' : (value as boolean);
      return acc;
    }, {} as FeatureTogglesType),
);

// selects server and dev toggles with overrides from LS
const selectAllFeatureToggles = createSelector(
  [selectServerFeatureTogglesWithOverrides],
  serverFlags => ({
    ...serverFlags,
    ...DEV_FLAGS,
  }),
);

export const selectIsFeatureEnabled =
  (key: string): Selector =>
  (state: RootState): boolean => {
    const featureToggles = IS_PRODUCTION
      ? selectFeatureToggles(state)
      : selectAllFeatureToggles(state);
    return !!featureToggles?.[key];
  };

import initial from 'lodash/initial';
import last from 'lodash/last';

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const kiloByte = 1024;
  const validatedDecimals = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const index = Math.floor(Math.log(bytes) / Math.log(kiloByte));

  return `${parseFloat((bytes / Math.pow(kiloByte, index)).toFixed(validatedDecimals))} ${
    sizes[index]
  }`;
};

export const parseFileName = (fileName?: string): [string?, string?] => {
  if (!fileName) {
    return [];
  }

  const splittedFileName = fileName.split('.');

  if (splittedFileName.length === 1) {
    return [fileName];
  }

  const name = initial(splittedFileName).join('.');
  const ext = last(splittedFileName);

  return [name, ext];
};

export const openURLInNewTab = (objectURL: string): void => {
  const tab = window.open(objectURL, '_blank');
  tab?.focus();
};

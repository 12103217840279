import { FC, SVGProps } from 'react';
import { darken } from 'polished';
import { styled } from 'styled-components';

const Svg = styled.svg<{ color: string }>`
  &:hover {
    cursor: pointer;
    & > rect {
      fill: ${({ theme, color }) => darken(theme.coefficients.primaryHovered, color)};
    }
  }
`;

interface ListSVGProps extends SVGProps<SVGSVGElement> {
  color: string;
}

const ListSVG: FC<ListSVGProps> = ({ color, onClick, className }) => {
  return (
    <Svg
      className={className}
      color={color}
      fill="none"
      height="22"
      viewBox="0 0 26 22"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect fill={color} height="3.9" rx="1.95" width="19.5" x="6.5" />
      <rect fill={color} height="3.9" rx="1.95" width="3.9" />
      <rect fill={color} height="3.9" rx="1.95" width="19.5" x="6.5" y="8.90002" />
      <rect fill={color} height="3.9" rx="1.95" width="3.9" y="8.90002" />
      <rect fill={color} height="3.9" rx="1.95" width="19.5" x="6.5" y="17.8" />
      <rect fill={color} height="3.9" rx="1.95" width="3.9" y="17.8" />
    </Svg>
  );
};

export default ListSVG;

import { ReactChild } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { styled } from 'styled-components';

import { MediaQueries, TypographyComponent } from 'enums/ui';

import Modal from 'components/Modal';
import { Typography } from 'components/Typography';

const ModalContainer = styled(Modal)`
  padding: 50px;

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    width: 95%;
    padding: 45px 30px;
  }
`;

interface Props {
  text?: string;
  Component?: ReactChild;
}

export default NiceModal.create<Props>(({ text, Component }) => (
  <ModalContainer>
    {Component || <Typography component={TypographyComponent.H7}>{text}</Typography>}
  </ModalContainer>
));

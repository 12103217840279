import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  height: min-content;

  @media (min-width: ${MediaQueries.Tablet}) {
    margin: 0;
  }

  @media (min-width: ${MediaQueries.Desktop}) {
    margin: 0 20px 0 0;
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    grid-template-columns: 1fr;
    margin: 0;
  }
`;

export const EmptyStateContainer = styled.div`
  min-width: 700px;
  padding: 70px 0;
  margin: 0 20px 0 0;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${MediaQueries.Desktop}) {
    min-height: 594px;
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    min-width: 340px;
    max-width: 340px;
    margin: 0;
  }
`;

export const PaginationWrapper = styled.div`
  @media (max-width: ${MediaQueries.MobileLandscape}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

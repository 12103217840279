import { createGlobalStyle } from 'styled-components';
import normalize from 'styled-normalize';

import { HEADER_HEIGHT } from 'constants/ui';
import { MediaQueries } from 'enums/ui';

import { getScrollbar } from 'styles/scrollbar';

export const StyledGlobal = createGlobalStyle`
  ${normalize}
  
  *, *::after, *::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    -webkit-font-smoothing: antialiased;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }


  body {
    position: relative;
    overflow: hidden;

    background-color: ${({ theme }) => theme.colors.bodyBackground};

    color: ${({ theme }) => theme.colors.text.primary};

    font-family: 'Axiforma', 'Arial', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }


  button {
    appearance: none;

    border: 0;
    outline: none;

    background-color: transparent;

    cursor: pointer;

    font-family: inherit;

    &:disabled {
      cursor: not-allowed;
    }
  }

  input,
  textarea {
    appearance: none;
    border: 0;
    outline: none;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    appearance: none;
  }

  input::-webkit-input-placeholder {
    line-height: normal;
  }

  input::-ms-clear {
    display: none;
  }


  a {
    text-decoration: none;
  }

  ul, nav {
    list-style-type: none;
  }

  /**
   * By default icon can be shrunk and crop part of image (ugly)
   */
  svg {
    flex-shrink: 0;
  }

  /* fixes zoom on mobile */
  div[contenteditable="true"] {
    font-size: 16px;
  }
  
  ${getScrollbar()}

  .toastContainer {
    z-index: 100;
    margin-top: ${HEADER_HEIGHT}
  }

  .clipboardToastContainer {
    z-index: 100;
    width: 200px;
    // override styles for mobile
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }

  .customToast {
    background: ${({ theme }) => theme.colors.primary};
    box-shadow: ${({ theme }) => `0px 10px 30px 0 ${theme.colors.shadow}`};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    padding: 2px;
    min-height: min-content;

    @media (max-width: ${MediaQueries.MobileLandscape}) {
      margin: 5px 15px 0 15px
    }
  }

  .clipboardToast {
    text-align: center;
    margin-bottom: 0;
  }

  /**
    * styles for places autocomplete
  */
  .pac-container {
    z-index: 10000;
  }

  .pac-item {
    height: 40px;
    padding: 4px 12px 0;

    color: ${({ theme }) => theme.colors.controls.label};

    font-family: 'Axiforma', 'Arial', sans-serif;
    font-size: 12px;

    cursor: pointer;
  }

  .pac-item-query {
    color: ${({ theme }) => theme.colors.controls.text};
    font-size: 14px;
  }
`;

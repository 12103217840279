import { css, styled } from 'styled-components';

import Button from 'components/Button';

import { ReactComponent as IconPDF } from 'assets/icons/pdf.svg';

import { getLineClampStyles } from 'styles/typography';

export const Icon = styled(IconPDF)<{ $isDownloadable: boolean }>`
  margin-right: 20px;

  ${({ $isDownloadable }) =>
    $isDownloadable
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}
`;

export const Label = styled.p<{ $withTooltip?: boolean }>`
  // two-line limit
  ${getLineClampStyles(2)}

  flex-grow: 1;
  min-width: 150px;
  margin-right: 10px;
  line-height: 20px;
  cursor: default;

  ${({ $withTooltip }) =>
    $withTooltip &&
    css`
      text-decoration: underline;
    `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 15px 0 20px;
  font-size: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.controls.hoveredBackground};
  }
  &:hover > ${Label} {
    transform: translateX(2px);
  }
`;

export const EditButton = styled(Button)`
  width: 30px;
  padding: 5px 0;
  font-size: inherit;
`;

import { useSelector } from 'react-redux';

import { selectIsFeatureEnabled } from 'store/selectors/featureToggle';

const useFeatureFlag = (featureFlag: string): boolean => {
  const isFeatureEnabled = useSelector(selectIsFeatureEnabled(featureFlag));
  return isFeatureEnabled as boolean;
};

export default useFeatureFlag;

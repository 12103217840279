import { FC, memo, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { CARD_LABELS, FEE_DETAILS, TOOLTIP_CONTENT_MAP } from 'constants/programCard';
import { TypographyComponent } from 'enums/ui';
import { getRandomUUID, getTooltipId } from 'utils/id';

import { selectIsDesktopView } from 'store/selectors/ui';
import { FeeDetails } from 'types/application';

import { Tooltip } from 'components/@styled';
import { UpfrontCosts } from 'components/ProgramCard';
import { Typography } from 'components/Typography';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import {
  RateSectionContainer,
  TitleContainer,
  UnderlinedText,
  YearsCost,
} from './RateSection.styles';

interface RateSectionProps {
  label: string;
  value: string;
  onOpenModal?: (event: MouseEvent) => void;
  extraInfo?: string[] | string | JSX.Element[];
  feeDetails?: FeeDetails;
}

const RateSection: FC<RateSectionProps> = ({
  label,
  value,
  extraInfo,
  feeDetails,
  onOpenModal,
}) => {
  const theme = useTheme();

  const isDesktop = useSelector(selectIsDesktopView);

  const sectionTooltipId = getTooltipId(label);
  const pointsTooltipId = getTooltipId(CARD_LABELS.points);
  const feeDetailsId = getTooltipId(`${FEE_DETAILS}_${getRandomUUID()}`); // should be uniq as content differs

  const $isClickableFeesTooltip = Object.keys(feeDetails || {}).length > 4;

  return (
    <RateSectionContainer data-testid="program_card_rate_container">
      <TitleContainer>
        <Typography component={TypographyComponent.H7}>{label}</Typography>
        <InfoIcon
          color={theme.colors.primary}
          data-tooltip-id={sectionTooltipId}
          height={14}
          width={14}
          onClick={isDesktop ? undefined : onOpenModal}
        />
        {isDesktop && (
          <Tooltip
            content={TOOLTIP_CONTENT_MAP[sectionTooltipId]}
            id={sectionTooltipId}
            variant="light"
          />
        )}
      </TitleContainer>

      {!!feeDetails ? (
        <UnderlinedText
          component={TypographyComponent.H5}
          data-tooltip-id={feeDetailsId}
          onClick={isDesktop ? undefined : onOpenModal}
        >
          {value}
          {isDesktop && (
            <Tooltip clickable={$isClickableFeesTooltip} id={feeDetailsId} variant="light">
              <UpfrontCosts feeDetails={feeDetails} />
            </Tooltip>
          )}
        </UnderlinedText>
      ) : (
        <Typography component={TypographyComponent.H5} data-testid={`program_card_${label}_value`}>
          {value}
        </Typography>
      )}

      {typeof extraInfo === 'string' && (
        <>
          <UnderlinedText
            component={TypographyComponent.H7}
            data-tooltip-id={pointsTooltipId}
            onClick={isDesktop ? undefined : onOpenModal}
          >
            {extraInfo}
          </UnderlinedText>
          {isDesktop && (
            <Tooltip
              content={TOOLTIP_CONTENT_MAP[pointsTooltipId]}
              id={pointsTooltipId}
              variant="light"
            />
          )}
        </>
      )}

      {Array.isArray(extraInfo) &&
        extraInfo.map((info, index) => (
          <YearsCost
            data-testid="program_card_years_cost_container"
            key={typeof info === 'string' ? info : index}
          >
            {info}
          </YearsCost>
        ))}
    </RateSectionContainer>
  );
};

export default memo(RateSection);

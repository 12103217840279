import { ChangeEvent, FocusEvent, KeyboardEvent, memo, useCallback, useMemo } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { FormikHelpers, FormikValues, useField } from 'formik';

import { ONLY_DIGITS_REGEXP } from 'constants/regExps';
import { ZIP_CODE_LENGTH } from 'constants/ui';
import { KeyCode } from 'enums/keyboard';
import { useCounties } from 'hooks/useCounties';

import SelectField from 'components/@fields/SelectField/SelectField';
import TextField from 'components/@fields/TextField';
import { CountiesCircleSpinner } from 'components/@styled';

import { PlacesAutocompleteProps } from './PlacesAutocomplete.types';
import { getSuggestion } from './PlacesAutocomplete.utils';

interface ZipAutocompleteProps<T> extends PlacesAutocompleteProps {
  handleChange: (e: ChangeEvent<unknown>) => void;
  setValues: FormikHelpers<T>['setValues'];
}

const defaultOptions: google.maps.places.AutocompleteOptions = {
  types: ['(regions)'],
  componentRestrictions: { country: 'us' },
};

// TODO: move the react-google-autocomplete library
const ZipAutocomplete = <T extends FormikValues>({
  name,
  disabled,
  onPlaceClick,
  onBlur,
  options,
  handleChange,
  setValues,
  ...props
}: ZipAutocompleteProps<T>) => {
  const [{ value: zip }, { touched }, { setValue, setTouched }] = useField({ name });
  const [{ value: county }] = useField(`county`);

  const autocompleteOptions: google.maps.places.AutocompleteOptions = useMemo(
    () => ({ ...defaultOptions, ...options }),
    [options],
  );

  const { isFetchingCounties, countyOptions, getCounties, resetCountyOptions } = useCounties<T>({
    zip,
    county,
    setValues,
    isAutoRequesting: false,
  });

  const { placePredictions, getPlacePredictions, placesService } = usePlacesAutocompleteService({
    language: 'en',
    options: {
      types: ['(regions)'],
      componentRestrictions: { country: 'us' },
      input: '',
    },
  });

  const setPlaceDetails = useCallback(
    (placeDetails: google.maps.places.PlaceResult | null) => {
      if (!placeDetails || !placeDetails?.address_components) {
        return;
      }

      const { address_components: addressComponents } = placeDetails;
      const suggestion = getSuggestion(addressComponents);

      onPlaceClick(suggestion);
    },
    [onPlaceClick],
  );

  const onChangeZipField = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (e.target.value.length > ZIP_CODE_LENGTH) {
        return;
      }

      resetCountyOptions();
      setValues(prevState => ({ ...prevState, state: '', county: '', city: '' }));

      handleChange(e);

      const value = e.target.value;
      if (value.length !== ZIP_CODE_LENGTH) {
        return;
      }

      getPlacePredictions({ input: value });
    },
    [getPlacePredictions, handleChange, resetCountyOptions, setValues],
  );

  const onBlurAutoComplete = useCallback(
    (e: FocusEvent<HTMLInputElement>): void => {
      if (!touched) {
        setValue(e.target.value || '', true);
        setTouched(true);
      }

      handleChange(e);

      if (
        !placePredictions.length ||
        placePredictions.length > 1 ||
        !placesService ||
        zip.length !== ZIP_CODE_LENGTH ||
        placePredictions[0].structured_formatting.main_text !== zip // fix setting prev value with current invalid zip
      ) {
        return;
      }

      placesService.getDetails({ placeId: placePredictions[0].place_id }, setPlaceDetails);

      onBlur?.(e);
    },
    [
      touched,
      handleChange,
      placePredictions,
      placesService,
      zip,
      setPlaceDetails,
      onBlur,
      setValue,
      setTouched,
    ],
  );

  const onPlaceSelected = useCallback(
    ({ address_components: addressComponents }: google.maps.places.PlaceResult) => {
      if (!addressComponents) {
        return;
      }

      const suggestion = getSuggestion(addressComponents);
      const { zip, county } = suggestion;

      if (!county) {
        getCounties(zip);
      }

      setValue(zip);
      onPlaceClick(suggestion);
    },
    [getCounties, onPlaceClick, setValue],
  );

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const isKeyMatch =
      e.key.match(ONLY_DIGITS_REGEXP) ||
      e.code === KeyCode.ArrowLeft ||
      e.code === KeyCode.ArrowRight ||
      e.code === KeyCode.Backspace ||
      e.key === KeyCode.Backspace; // this line fixes bug in old devices where e.code is empty

    if (!isKeyMatch) {
      e.preventDefault();
    }
  }, []);

  const { ref } = usePlacesWidget<HTMLInputElement>({
    onPlaceSelected,
    inputAutocompleteValue: 'off',
    options: autocompleteOptions,
    language: 'en',
  });

  const isMoreThanOneCounty = countyOptions?.length > 1;

  return (
    <>
      <TextField
        {...props}
        RightIcon={isFetchingCounties && <CountiesCircleSpinner size={14} />}
        data-testid={`${String(name).replace(/[^a-zA-Z]/g, '')}_input`}
        disabled={isFetchingCounties || disabled}
        inputMode="tel"
        name={name}
        ref={ref}
        value={zip}
        onBlur={onBlurAutoComplete}
        onChange={onChangeZipField}
        onKeyDown={onKeyDown}
      />
      {isMoreThanOneCounty && <SelectField label="County" name="county" options={countyOptions} />}
    </>
  );
};

export default memo(ZipAutocomplete) as typeof ZipAutocomplete;

import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Card from 'components/Card/Card';
import Skeleton from 'components/Skeleton';
import { Typography } from 'components/Typography';

export const TasksContainer = styled(Card)`
  height: fit-content;

  @media (min-width: ${MediaQueries.Tablet}) {
    min-width: 700px;
  }
`;

export const TasksSkeleton = styled(Skeleton)`
  height: 100%;
  width: 100%;
  border-radius: 20px;
  min-height: 700px;

  @media (min-width: ${MediaQueries.Tablet}) {
    min-width: 700px;
  }
`;

export const TasksWrapper = styled.div`
  display: flex;
  max-width: 1060px;
  width: 100%;

  @media (min-width: ${MediaQueries.Tablet}) {
    max-width: 700px;
  }
`;

export const TaskSidebar = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin-bottom: 20px;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 15px;
`;

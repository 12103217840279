import { FC, Fragment, memo, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';

import { TypographyComponent } from 'enums/ui';
import { getTooltipId } from 'utils/id';

import { selectIsDesktopView } from 'store/selectors/ui';
import { OptionCriterion } from 'types/application';

import { Tooltip } from 'components/@styled';
import { Typography } from 'components/Typography';

import { CostValueWrapper, Number } from './ProgramCard.styles';

interface CostCellProps {
  costs: OptionCriterion[];
  id: string;
  onOpenModal?: MouseEventHandler<HTMLParagraphElement>;
}

const CostCell: FC<CostCellProps> = ({ costs, id, onOpenModal }) => {
  const isDesktop = useSelector(selectIsDesktopView);

  return (
    <>
      {costs.map(({ label, value, tooltip }) => (
        <Fragment key={getTooltipId(label + id)}>
          <CostValueWrapper>
            <Number>{value}</Number>
            <Typography
              component={TypographyComponent.H7}
              data-tooltip-id={getTooltipId(label)}
              onClick={isDesktop ? undefined : onOpenModal}
            >
              {label}
            </Typography>
          </CostValueWrapper>
          {isDesktop && (
            <Tooltip id={getTooltipId(label)} variant="light">
              {tooltip}
            </Tooltip>
          )}
        </Fragment>
      ))}
    </>
  );
};

export default memo(CostCell);

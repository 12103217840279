import { TaskStatus } from 'enums/task';

export const PURCHASE_AGREEMENT_TASK_NAME = 'Purchase Agreement';

export const TASK_STATUS_TO_LABEL_MAP = {
  [TaskStatus.Submitted]: 'Ops To Review',
  [TaskStatus.New]: 'Pending',
  [TaskStatus.Rejected]: 'Rejected',
  [TaskStatus.Approved]: 'Completed',
  [TaskStatus.Canceled]: 'Canceled',
};

export const PURCHASE_AGREEMENT_STREET_ERROR_TEXT =
  'Please provide the property street address from the purchase agreement.';

export const TBD_VALUES = ['tbd', 'preapprov'];

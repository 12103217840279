import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ScreenType } from 'enums/ui';

export interface UIState {
  screenType: ScreenType;
}

export const initialState: UIState = {
  screenType: ScreenType.Desktop,
};

const UISlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setUIMode(state, action: PayloadAction<UIState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setUIMode } = UISlice.actions;
export const UIReducer = UISlice.reducer;

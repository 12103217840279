import { FC, memo, useCallback, useMemo, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { toast } from 'react-toastify';
import NiceModal from '@ebay/nice-modal-react';

import { ApplicationStatus, PropertyType } from 'enums/application';
import { SubmitPurchaseAgreementStatus } from 'enums/task';

import {
  SubmitPurchaseAgreementPayload,
  SubmitPurchaseAgreementResponse,
} from 'services/applyAPI/types';
import { LoanApplication, LoanOption, SubjectProperty } from 'types/application';
import { ApplyMutationType } from 'types/common';
import { EditPreApprovalLetterParams } from 'types/preApproval';

import { Step } from 'components/@modals/@SelectNewProgramSteps/@shared';
import EditDataForm from 'components/@modals/@SelectNewProgramSteps/EditDataForm';
import PurchaseAgreementModal from 'components/@modals/PurchaseAgreementModal';

interface PurchaseAgreementFormProps {
  formId: string;
  submitPurchaseAgreement: ApplyMutationType<
    SubmitPurchaseAgreementPayload,
    SubmitPurchaseAgreementResponse
  >;
  taskId: string;
  files: FileWithPath[];
  application?: LoanApplication;
}

const PurchaseAgreementForm: FC<PurchaseAgreementFormProps> = ({
  formId,
  submitPurchaseAgreement,
  taskId,
  files,
  application,
}) => {
  const [, setStep] = useState(Step.EditData);

  const preApproval = application?.preApprovals?.[0];
  const applicationId = application?.applicationId;

  const getFormDataForPA = useCallback(
    (data: EditPreApprovalLetterParams, loanOptionId?: string) => {
      const fd = new FormData();

      const model = {
        address: data.address,
        purchasePrice: data.propertyValue,
        downPayment: data.downPaymentValue,
        propertyType: data.propertyType,
        numberOfUnits: `${data.numberOfUnits || 1}`,
        loanOptionId: loanOptionId || null,
      };

      fd.append('model', JSON.stringify(model));

      files.forEach(file => {
        fd.append('file', file, file.name);
      });

      return fd;
    },
    [files],
  );

  const setStepWithOpenModal = useCallback(
    (
      step: Step,
      loanOptions?: LoanOption[],
      purchaseAgreementData?: EditPreApprovalLetterParams,
    ) => {
      setStep(step);

      NiceModal.show(PurchaseAgreementModal, {
        applicationId,
        step,
        setStep,
        loanOptions,
        purchaseAgreementData,
        geFormDataforPA: getFormDataForPA,
        taskId,
      });
    },
    [applicationId, getFormDataForPA, taskId],
  );

  const onSubmitPurchaseAgreement = useCallback(
    async (fd: FormData, data: EditPreApprovalLetterParams) => {
      if (!applicationId) {
        return;
      }

      const purchaseAgreementResponse = await submitPurchaseAgreement({
        applicationId,
        taskId,
        fd,
      }).unwrap();

      switch (purchaseAgreementResponse.status) {
        case SubmitPurchaseAgreementStatus.AusFailed:
        case SubmitPurchaseAgreementStatus.Success: {
          toast('Task successfully submitted');
          break;
        }

        case SubmitPurchaseAgreementStatus.NotEligibility: {
          const loanOptions = purchaseAgreementResponse?.rates?.loanOptions;
          if (!loanOptions?.length) {
            setStepWithOpenModal(Step.Error);
            break;
          }

          setStepWithOpenModal(Step.SelectProgram, loanOptions, data);
          break;
        }

        default: {
          setStepWithOpenModal(Step.Error);
        }
      }
    },
    [applicationId, setStepWithOpenModal, submitPurchaseAgreement, taskId],
  );

  const initialData = useMemo(() => {
    if (!application) {
      return;
    }

    const isPreApproved = application.loanStatus === ApplicationStatus.PreApproved;
    const source = isPreApproved ? preApproval : application.subjectProperty;

    return {
      address: source?.address,
      propertyType: (isPreApproved
        ? preApproval?.propertyType
        : (source as SubjectProperty)?.type) as PropertyType,
      propertyValue: source?.propertyValue,
      downPayment: isPreApproved ? preApproval?.downPayment : application.downPayment,
      numberOfUnits:
        (isPreApproved
          ? (preApproval?.numberOfUnits as number)
          : application.subjectProperty.numberOfUnits) || 1,
    };
  }, [application, preApproval]);

  if (!applicationId || !initialData) {
    toast('Something went wrong...');
    return null;
  }

  const propertyUsage = application.subjectProperty.occupancy;
  const maxApprovedPrice = application.subjectProperty.propertyValue;

  return (
    <EditDataForm
      {...initialData}
      applicationId={applicationId}
      formId={formId}
      getFormDataForPA={getFormDataForPA}
      isTbd={application.isTbd}
      maxApprovedPrice={maxApprovedPrice}
      propertyUsage={propertyUsage}
      setStep={setStep}
      onSubmitPurchaseAgreement={onSubmitPurchaseAgreement}
    />
  );
};

export default memo(PurchaseAgreementForm);

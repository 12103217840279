import { forwardRef, memo, MouseEventHandler, useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { FEE_DETAILS, TOOLTIP_CONTENT_MAP } from 'constants/programCard';
import { TypographyComponent, TypographyWeight } from 'enums/ui';
import { getTooltipId } from 'utils/id';

import { FormattedChangeProgramOption, FormattedPotentialOption } from 'types/application';

import RateComparisonInfo from 'components/@modals/RateComparisonInfo/RateComparisonInfo';
import CheckedMark from 'components/CheckedMark';
import CostCell from 'components/ProgramCard/CostCell';
import FeesCell from 'components/ProgramCard/FeesCell';
import { Typography } from 'components/Typography';

import {
  Cell,
  CustomRecommendedMark,
  LowerCell,
  LowerCellLabel,
  Number,
  ProgramCardContainer,
  ProgramName,
  UpperCells,
} from './ProgramCard.styles';
import { ProgramCardProps } from './ProgramCard.types';
import UpfrontCosts from './UpfrontCosts';

const ProgramCard = forwardRef<HTMLDivElement, ProgramCardProps>(
  ({ option, value, isRecommended, onSelect, isChangeProgram, currentProgramId }, ref) => {
    const rateComparisonModal = useModal(RateComparisonInfo);

    const { id, loanPeriod, rate, apr, points, paymentMonth, feeDetails, fees } = option;
    const isChecked = value === id;
    const isCurrentProgram = currentProgramId === id;

    const onClick = useCallback(() => {
      if (!isCurrentProgram) {
        onSelect(isChecked ? '' : id);
      }
    }, [id, isChecked, isCurrentProgram, onSelect]);

    const onOpenModal: MouseEventHandler<HTMLParagraphElement> = useCallback(
      event => {
        event.stopPropagation();
        const { tooltipId } = (event.currentTarget as HTMLElement)?.dataset || {};

        if (tooltipId) {
          rateComparisonModal.show({
            ...(tooltipId.includes(getTooltipId(FEE_DETAILS))
              ? { Component: <UpfrontCosts isModal feeDetails={feeDetails} /> }
              : { text: TOOLTIP_CONTENT_MAP[tooltipId] }),
          });
        }
      },
      [feeDetails, rateComparisonModal],
    );

    return (
      <ProgramCardContainer
        data-testid={`rate_${loanPeriod}`}
        isCurrentProgram={isCurrentProgram}
        isSelected={isChecked}
        ref={ref}
        onClick={onClick}
      >
        <CheckedMark isChecked={isChecked} />

        <UpperCells>
          <Cell>
            <Typography component={TypographyComponent.H7}>Rate / APR:</Typography>
            <Number>{rate}</Number>
            <Number>{apr}</Number>
          </Cell>
          <FeesCell
            feeDetails={feeDetails}
            fees={fees}
            points={points}
            programId={id}
            onOpenModal={onOpenModal}
          />
          {isChangeProgram ? (
            <Cell>
              <Typography component={TypographyComponent.H7}>Program Name:</Typography>
              <ProgramName weight={TypographyWeight.SemiBold}>
                {(option as FormattedChangeProgramOption).name}
              </ProgramName>
            </Cell>
          ) : (
            <Cell>
              <Typography component={TypographyComponent.H7}>Cost</Typography>
              <CostCell
                costs={(option as FormattedPotentialOption).costs}
                id={id}
                onOpenModal={onOpenModal}
              />
            </Cell>
          )}
        </UpperCells>

        <LowerCell>
          <LowerCellLabel component={TypographyComponent.H7}>Payment:</LowerCellLabel>
          <Number>{paymentMonth}</Number>
          <Typography component={TypographyComponent.H7}>/MO</Typography>
        </LowerCell>

        {(isRecommended || isCurrentProgram) && (
          <CustomRecommendedMark isChangeProgram={isChangeProgram} />
        )}
      </ProgramCardContainer>
    );
  },
);

export default memo(ProgramCard);

import { matchPath } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DETAILS_INCLUDES } from 'constants/application';
import { DevFlags, FeatureFlags } from 'constants/featureToggles';
import { VALID_UUID_REGEXP } from 'constants/regExps';
import { RealTimeEvent, ServerMethods } from 'enums/signalR';
import { getIsPreApproval } from 'utils/common';
import { preApprovalMapper } from 'utils/documents';
import { getRandomUUID } from 'utils/id';
import { handleSignalRError } from 'utils/logger';

import { baseAPI } from 'services/baseAPI';
import documentsAPI from 'services/documentsAPI';
import loansAPI from 'services/loansAPI';
import { signalRConnection } from 'services/signalR';
import tasksAPI from 'services/tasksAPI';
import { Store } from 'store/index';
import { selectCompanyId } from 'store/selectors/auth';
import { selectIsFeatureEnabled } from 'store/selectors/featureToggle';
import { LoanApplicationFromServer, LoanContactRaw } from 'types/application';
import { DocumentItem, DocumentItemSignalRResponse } from 'types/documents';
import { PreApproval, PreApprovalFromServer } from 'types/preApproval';
import { Task } from 'types/task';

import CircleSpinner from 'components/CircleSpinner';

const LOST_SIGNAL_R_CONNECTION_TOAST_ID = 'LOST_SIGNAL_R_CONNECTION_TOAST_ID';

const getIdFromPath = () => {
  const pathData = matchPath('pipeline/:id', location.pathname);
  return pathData?.params?.id;
};

const reinvokeIfNeeded = (connection: typeof signalRConnection) => {
  const id = getIdFromPath();
  if (id && !!id.match(VALID_UUID_REGEXP)) {
    connection.invoke(ServerMethods.JoinToPipeline, id);
  }
};

class SignalREventsController {
  #connection: typeof signalRConnection;
  #store?: Store;
  #isReconnecting: boolean;

  constructor(connection: typeof signalRConnection) {
    this.#connection = connection;
    this.#store = undefined;
    this.#isReconnecting = false;
  }

  set store(store: Store) {
    this.#store = store;
  }

  get store() {
    if (!this.#store) {
      throw new Error('No store was injected');
    }

    return this.#store;
  }

  get connection() {
    if (!this.#connection) {
      throw new Error('Trying to use connection without active connection');
    }

    return this.#connection;
  }

  init(store: Store) {
    if (!store) {
      throw new Error('Store is required for initializing signalR events controller');
    }

    this.store = store;

    this.#isReconnecting = false;
  }

  startListeners() {
    this.#connection.on(RealTimeEvent.Loan, this.#onLoanChanged.bind(this));
    this.#connection.on(RealTimeEvent.OnCreditReportDocumentSaved, this.#onLoanChanged.bind(this));
    this.#connection.on(RealTimeEvent.LoanProgressChanged, this.#onLoanProgressChanged.bind(this));
    this.#connection.on(RealTimeEvent.TaskUpdated, this.#onTaskChanged.bind(this));
    this.#connection.on(RealTimeEvent.TaskCreated, this.#onTaskChanged.bind(this));
    this.#connection.on(
      RealTimeEvent.LoanRoleAssignmentsChanged,
      this.#onLoanRoleAssignmentsChange.bind(this),
    );
    this.#connection.on(RealTimeEvent.LoanContactsChanged, this.#onLoanContactsChange.bind(this));

    this.#connection.onreconnecting(this.#onReconnecting.bind(this));

    this.#connection.onreconnected(this.#onReconnected.bind(this));

    this.#connection.onclose(this.#onClose.bind(this));
  }

  stopListeners() {
    this.connection.off(RealTimeEvent.Loan);
    this.connection.off(RealTimeEvent.TaskUpdated);
    this.connection.off(RealTimeEvent.TaskCreated);
    this.connection.off(RealTimeEvent.OnCreditReportDocumentSaved);
    this.connection.off(RealTimeEvent.LoanRoleAssignmentsChanged);
    this.connection.off(RealTimeEvent.LoanProgressChanged);
  }

  #onLoanRoleAssignmentsChange(stringifiedData: string) {
    try {
      const { dispatch } = this.store;
      const data: Partial<LoanApplicationFromServer> = JSON.parse(stringifiedData);

      const applicationId = data?.id?.applicationId;
      const loanOfficerId = data.loanOfficerId;
      const loanOfficer = data.loanOfficer;

      if (applicationId && loanOfficerId && loanOfficer) {
        dispatch(
          loansAPI.util.updateQueryData(
            'getLoanApplications',
            {
              applicationIds: [applicationId],
              includes: DETAILS_INCLUDES,
            },
            draft => {
              const application = draft.items?.[0];

              if (application && application.applicationId === applicationId) {
                application.loanOfficer = loanOfficer;
                application.loanOfficerId = loanOfficerId;
              }
            },
          ),
        );
      } else {
        const replacer = (key: string, value: unknown) =>
          typeof value === 'undefined' ? null : value;

        throw new Error(
          `Missing data: ${JSON.stringify(
            { applicationId, loanOfficer, loanOfficerId },
            replacer,
          )}`,
        );
      }
    } catch (err) {
      handleSignalRError(err);
    }
  }

  // 2-nd argument is maxApprovedPurchasePrice string like 'maxPurchasePrice:100000'. Helps to handle 'Need more' flow
  #onLoanChanged(stringifiedData: string, metaData: string) {
    try {
      const { dispatch, getState } = this.store;
      const state = getState();
      const data: PreApprovalFromServer | PreApproval | DocumentItemSignalRResponse | DocumentItem =
        JSON.parse(stringifiedData);

      const preApprovalFromServer = data as PreApprovalFromServer | PreApproval;
      const document = (data as DocumentItemSignalRResponse)?.document || (data as DocumentItem);

      const isPreApproval = getIsPreApproval(document || preApprovalFromServer);
      const isOtherDocument = !isPreApproval;

      const companyId = selectCompanyId(state);

      if (isPreApproval) {
        const [metaDataName, metaDataValue] = metaData.split(':');
        const preApproval = preApprovalMapper(preApprovalFromServer as PreApprovalFromServer);
        const isPreApprovalManagementEnabled = selectIsFeatureEnabled(
          FeatureFlags.RealtorsEnablePreApprovalManagement,
        )(state);
        const isAutoDownloadDisabled = selectIsFeatureEnabled(
          DevFlags.DisablePreApprovalAutoDownload,
        )(state);

        const id = getIdFromPath();

        let fullName = '';
        dispatch(
          loansAPI.util.updateQueryData(
            'getLoanApplications',
            {
              applicationIds: [id as string],
              includes: DETAILS_INCLUDES,
            },
            draft => {
              (
                draft.items?.find(application => {
                  if (application.applicationId === preApproval.applicationId) {
                    if (metaDataName.includes('maxPurchasePrice')) {
                      application.subjectProperty.propertyValue = +metaDataValue;
                    }
                    // looks bad but this line added to avoid additional selector call and array search
                    fullName = application.primaryBorrower.fullName;
                    return true;
                  }
                })?.preApprovals as PreApproval[]
              )?.unshift(preApproval);
            },
          ),
        );

        if (
          !isAutoDownloadDisabled &&
          isPreApprovalManagementEnabled &&
          preApproval.documentId &&
          fullName &&
          preApproval.createdDate
        ) {
          /*
            tabId helps to avoid multiple downloads of the same document while multiple tabs are open.
            Delays tabId comparison and download to provide time for other tabs to write their tabIds to LS.
            So only the 1 instance of document will be downloaded (the tab that wrote tabId the last).
          */

          const tabId = getRandomUUID();
          localStorage.setItem('tabId', tabId);

          setTimeout(() => {
            const tabIdFromLS = localStorage.getItem('tabId');
            const isMatch = tabIdFromLS === tabId;

            if (isMatch) {
              dispatch(
                documentsAPI.endpoints.downloadDocument.initiate({
                  fullName,
                  documentId: preApproval.documentId as string,
                  createdDate: preApproval.createdDate,
                }),
              ).reset(); // reset to avoid non-serializable data in store

              localStorage.removeItem('tabId');
            }
          }, 1000);
        }
      }

      // TODO: should be deleted when Documents widget finally becomes Pre-Approvals widget
      if (isOtherDocument) {
        dispatch(
          baseAPI.util.updateQueryData(
            'getDocuments',
            { companyId, loanId: document.loanId },
            draft => {
              draft.unshift(document);
            },
          ),
        );
      }
    } catch (err) {
      handleSignalRError(err);
    }
  }

  #onLoanProgressChanged(stringifiedData: string) {
    try {
      const { dispatch } = this.store;
      const applicationId = getIdFromPath();

      if (!applicationId) {
        return;
      }

      // message can be empty, so we just refetch current application
      if (!stringifiedData) {
        dispatch(
          loansAPI.endpoints.getLoanApplications.initiate({
            applicationIds: [applicationId],
            includes: DETAILS_INCLUDES,
          }),
        );
      } else {
        const data: Pick<LoanApplicationFromServer, 'progress'> = JSON.parse(stringifiedData);
        const progress = data?.progress;

        if (progress) {
          dispatch(
            loansAPI.util.updateQueryData(
              'getLoanApplications',
              {
                applicationIds: [applicationId],
                includes: DETAILS_INCLUDES,
              },
              draft => {
                const app = draft?.items?.[0];
                if (app) {
                  app.progress = progress;
                }
              },
            ),
          );
        }
      }
    } catch (err) {
      handleSignalRError(err);
    }
  }

  #onTaskChanged(stringifiedData: string) {
    try {
      const { dispatch } = this.store;
      const data: Task = JSON.parse(stringifiedData);

      dispatch(
        tasksAPI.util.updateQueryData(
          'getTasks',
          {
            applicationIds: [data.applicationId],
          },
          draft => {
            const tasks = draft?.[data.applicationId];
            const index = tasks?.findIndex(task => task.id === data.id);
            // splice and unshift are used separately to retain correct order
            if (index !== -1) {
              tasks?.splice(index, 1);
            }
            tasks?.unshift(data);
          },
        ),
      );
    } catch (err) {
      handleSignalRError(err);
    }
  }

  #onLoanContactsChange(stringifiedData: string) {
    try {
      const { dispatch } = this.store;
      const data: { applicationId: string; contacts: LoanContactRaw[] } =
        JSON.parse(stringifiedData);

      const applicationId = data?.applicationId;
      const loanContactsData = data?.contacts;

      if (applicationId && loanContactsData) {
        dispatch(
          baseAPI.util.updateQueryData('getLoanContacts', { applicationId }, () =>
            loanContactsData.map(contact => ({
              ...contact,
              phoneNumber: contact.phoneNumber?.phoneNumber,
              phoneExtensionNumber: contact.phoneNumber?.extensionNumber,
            })),
          ),
        );
      } else {
        const replacer = (key: string, value: unknown) =>
          typeof value === 'undefined' ? null : value;

        throw new Error(
          `Missing data: ${JSON.stringify({ applicationId, loanContactsData }, replacer)}`,
        );
      }
    } catch (err) {
      handleSignalRError(err);
    }
  }

  #onReconnecting() {
    this.#isReconnecting = true;

    toast('Your internet connection is unstable', {
      toastId: LOST_SIGNAL_R_CONNECTION_TOAST_ID,
      autoClose: false,
      icon: () => <CircleSpinner />,
    });
  }

  #onReconnected() {
    toast.dismiss(LOST_SIGNAL_R_CONNECTION_TOAST_ID);

    // re-invokes JoinToPipeline after connection lost on application details page
    reinvokeIfNeeded(this.#connection);

    this.#isReconnecting = false;
  }

  #onClose() {
    if (!this.#isReconnecting) {
      return;
    }

    throw new Error('Failed to reconnect');
  }
}

export default SignalREventsController;

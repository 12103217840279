import { FC, Fragment, useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { TypographyComponent, TypographySize, TypographyWeight } from 'enums/ui';

import { FeeDetails } from 'types/application';

import RateComparisonInfo from 'components/@modals/RateComparisonInfo';

import {
  Block,
  FeesBlock,
  Row,
  ShowMore,
  Text,
  Title,
  UpfrontCostSeparator,
  Wrapper,
} from './UpfrontCosts.styles';
import { formatFeeDetails, sumFees } from './UpfrontCosts.utils';

interface UpfrontCostsProps {
  feeDetails: FeeDetails;
  isModal?: boolean;
}

const UpfrontCosts: FC<UpfrontCostsProps> = ({ feeDetails, isModal }) => {
  const rateComparisonModal = useModal(RateComparisonInfo);

  const onOpenModal = useCallback(() => {
    rateComparisonModal.show({
      Component: <UpfrontCosts isModal feeDetails={feeDetails} />,
    });
  }, [feeDetails, rateComparisonModal]);

  const fees = formatFeeDetails(feeDetails);
  const totalFees = sumFees(feeDetails);
  const hasShowMore = !isModal && fees.length > 4;

  return (
    <Wrapper>
      <Block>
        <Title component={TypographyComponent.H6} weight={TypographyWeight.SemiBold}>
          Lender origination fees breakdown
        </Title>
        <FeesBlock data-testid="program_card_lender_fees">
          {fees.map((fee, i, array) => {
            const isShown = isModal || i < 4;
            const hasSeparator = isModal ? i !== array.length - 1 : i < 3;

            return isShown ? (
              <Fragment key={fee.name}>
                <Row>
                  <Text size={TypographySize.Small}>{fee.name}</Text>
                  <Text size={TypographySize.Small}>{fee.value}</Text>
                </Row>
                {hasSeparator && <UpfrontCostSeparator />}
              </Fragment>
            ) : null;
          })}
        </FeesBlock>
      </Block>

      {hasShowMore && <ShowMore onClick={onOpenModal}>Show More</ShowMore>}

      <UpfrontCostSeparator isTotal />

      <Row isTotal>
        <Text weight={TypographyWeight.SemiBold}>Total upfront costs:</Text>
        <Text weight={TypographyWeight.SemiBold}>{totalFees}</Text>
      </Row>
    </Wrapper>
  );
};

export default UpfrontCosts;

import groupBy from 'lodash/groupBy';
import max from 'lodash/max';

import { formatCurrency, formatNumber } from 'utils/formatting';

import {
  FormattedChangeProgramOption,
  FormattedPotentialOption,
  LoanOptionGroups,
  LoanProgramOption,
  OptionCriterion,
  PotentialLoanOption,
} from 'types/application';

export const getCostOptions = (costs: { [key: string]: number }): OptionCriterion[] => {
  const costNames = ['cost3Year', 'cost8Year'];

  return costNames.reduce((result: OptionCriterion[], costYears: string) => {
    const numberMatch = costYears.match(/\d+/);

    if (costs[costYears] && numberMatch) {
      const number = numberMatch[0];

      result.push({
        label: `${number}yr cost`,
        value: formatCurrency(costs[costYears], { maximumFractionDigits: 0 }),
        tooltip: `"${number} year cost" is an estimate of how much money you will have paid on the first ${number} years of your loan. This amount includes, but may not be limited to, interest payments and upfront costs, such as discount points and origination fees you pay at closing.`,
      });
    }

    return result;
  }, []);
};

interface PaymentMonthOption {
  paymentMonth: number;
}

export const sortLoanOptions = <T extends PaymentMonthOption>(data: T[]): T[] =>
  data
    .slice()
    .sort((firstProgram, secondProgram) => firstProgram.paymentMonth - secondProgram.paymentMonth);

export const getLongestTerm = (loanOptions: LoanOptionGroups) => max(Object.keys(loanOptions));

export const getFormattedOptions = (data: PotentialLoanOption[]): FormattedPotentialOption[] =>
  data.map(
    ({
      loanOptionId,
      loanPeriod,
      rate,
      apr,
      fees,
      feeDetails,
      pmi,
      paymentMonth,
      points,
      cost3Year,
      cost8Year,
      additionalInfo,
    }: PotentialLoanOption): FormattedPotentialOption => ({
      id: loanOptionId,
      loanPeriod: `${loanPeriod / 12}`,
      rate: `${formatNumber(rate, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}%`,
      apr: `${formatNumber(apr, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}%`,
      fees: formatCurrency(fees),
      points: `${formatNumber(points, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`,
      paymentMonth: formatCurrency(paymentMonth),
      pmi: formatCurrency(pmi),
      pmiNumericValue: pmi,
      costs: getCostOptions({ cost3Year, cost8Year }),
      feeDetails,
      additionalInfo,
    }),
  );

export const getFormattedChangeProgramOptions = (
  data: LoanProgramOption[],
): FormattedChangeProgramOption[] =>
  data.map(
    ({
      loanOptionId,
      loanPeriod,
      rate,
      apr,
      fees,
      feeDetails,
      pmi,
      paymentMonth,
      points,
      internalName,
    }: LoanProgramOption): FormattedChangeProgramOption => ({
      id: loanOptionId,
      loanPeriod: `${loanPeriod / 12}`,
      rate: `${formatNumber(rate, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}%`,
      apr: `${formatNumber(apr, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}%`,
      fees: formatCurrency(fees),
      points: `${formatNumber(points, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`,
      paymentMonth: formatCurrency(paymentMonth),
      pmi: formatCurrency(pmi),
      pmiNumericValue: pmi,
      feeDetails,
      name: internalName,
    }),
  );

export const getTermsFromGroupedLoanOptions = (options: LoanOptionGroups) =>
  Object.values(options)
    .map(({ years, minPaymentMonth, minRate }) => ({
      years,
      paymentMonth: minPaymentMonth,
      rate: minRate,
    }))
    .sort((a, b) => +b.years - +a.years);

export const groupLoanOptions = (
  data: Array<PotentialLoanOption | LoanProgramOption>,
  isLoanProgramOptions?: boolean,
): LoanOptionGroups =>
  Object.entries(
    groupBy(sortLoanOptions(data), ({ loanPeriod }) => loanPeriod / 12),
  ).reduce<LoanOptionGroups>((acc, [term, options]) => {
    // @ts-ignore
    acc[term] = {
      years: term,
      minRate: formatNumber(options[0].rate, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }),
      minPaymentMonth: formatCurrency(options[0].paymentMonth),
      options: isLoanProgramOptions
        ? getFormattedChangeProgramOptions(options as LoanProgramOption[])
        : getFormattedOptions(options as PotentialLoanOption[]),
    };

    return acc;
  }, {});

import get from 'lodash/get';

import { TBD_VALUES } from 'constants/tasks';

import { DocumentItem } from 'types/documents';
import { PreApproval, PreApprovalFromServer } from 'types/preApproval';

// eslint-disable-next-line
export const getEnvVariable = (key: string, fallbackValue: any) => {
  const value: string = get(window, key, '');
  const isEnvVarReplaced = !value.startsWith?.('{{');

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  if (isEnvVarReplaced) {
    return value;
  }

  return fallbackValue;
};

export const isTokenExpired = (token: string) => {
  const expiry = JSON.parse(window.atob(token.split('.')[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};

export const getIsPreApproval = (data: PreApprovalFromServer | PreApproval | DocumentItem) =>
  'documentId' in data;

export const checkForTbdValue = (value: string): boolean => {
  const valueInLowerCase = value.toLowerCase();

  return TBD_VALUES.some(tbdValue => valueInLowerCase.includes(tbdValue));
};

import { FC, memo, MouseEvent, useCallback, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FeatureFlags } from 'constants/featureToggles';
import { TASK_MIME_TYPES_REGEXP } from 'constants/regExps';
import { PURCHASE_AGREEMENT_TASK_NAME } from 'constants/tasks';
import { PurchaseProcessType } from 'enums/application';
import { TaskAssignmentType, TaskStatus } from 'enums/task';
import useUniqueId from 'hooks/useUniqueId';
import { getApiError } from 'utils/api';
import { getRealtorId } from 'utils/id';

import { useSubmitPurchaseAgreementMutation } from 'services/applyAPI';
import { useSubmitTaskMutation } from 'services/tasksAPI';
import { selectCurrentApplicationByApplicationId } from 'store/selectors/applications';
import { selectIsRealtorLoanOfficer } from 'store/selectors/auth';
import { selectIsFeatureEnabled } from 'store/selectors/featureToggle';

import CircleSpinner from 'components/CircleSpinner';
import ErrorMessage from 'components/ErrorMessage';

import FileZone from './FileZone';
import PurchaseAgreementForm from './PurchaseAgreementForm';
import { TaskHandlerProps } from './TaskForm';
import { APIErrorMessage, Container, Errors, SubmitButton } from './TaskForm.styles';

// removes mime-types and leaves extensions, adds whitespaces
const transformMimeTypeToExtension = (text: string): string =>
  text.replace(TASK_MIME_TYPES_REGEXP, ' ').replaceAll('.jpg,.jpeg', '.jpg, .jpeg');

const DocumentsTaskForm: FC<TaskHandlerProps> = ({ task }) => {
  const { id: applicationId } = useParams();

  const [myFiles, setMyFiles] = useState<FileWithPath[]>([]);

  const [submitTask, { isLoading: isLoadingSubmitTask, error, reset: resetSubmitState }] =
    useSubmitTaskMutation();
  const [submitPurchaseAgreement, { isLoading: isLoadingSubmitPA, error: submitPAError }] =
    useSubmitPurchaseAgreementMutation();

  const isEnableSubmitTaskWithAddress = useSelector(
    selectIsFeatureEnabled(FeatureFlags.EnableSubmitTaskWithAddressFt),
  );

  const isRealtorLoanOfficer = useSelector(selectIsRealtorLoanOfficer);

  const realtorId = getRealtorId();
  const formId = useUniqueId();

  const APIError = getApiError(error || submitPAError);
  const currentApplication = useSelector(
    selectCurrentApplicationByApplicationId(applicationId as string),
  );

  const isTaskForView =
    task.taskAssignmentType === TaskAssignmentType.SlaGroup && isRealtorLoanOfficer;

  const isSubmitDisabled = !myFiles.length || isTaskForView;
  const isLoading = isLoadingSubmitTask || isLoadingSubmitPA;
  const isPurchaseAgreement = task.name === PURCHASE_AGREEMENT_TASK_NAME;
  const isNewSignedPurchasedContractTask =
    currentApplication?.purchaseProcessType === PurchaseProcessType.SignedPurchasedContract &&
    task.status === TaskStatus.New;
  const isShowPurchaseAgreementForm =
    isEnableSubmitTaskWithAddress && isPurchaseAgreement && !isNewSignedPurchasedContractTask;

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      if (myFiles.length) {
        return;
      }

      setMyFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    },
    [myFiles.length],
  );

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    noClick: true,
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
  });

  const onRemoveFile = useCallback(
    () => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setMyFiles([]);
      error && resetSubmitState();
    },
    [error, resetSubmitState],
  );

  const onSubmit = useCallback(async () => {
    const { id } = task;
    const fd = new FormData();

    fd.append('taskId', id);
    fd.append('realtorId', realtorId);
    myFiles.forEach(file => {
      fd.append('file', file, file.name);
    });

    await submitTask({ id, applicationId, body: fd });
  }, [applicationId, myFiles, realtorId, submitTask, task]);

  return (
    <Container>
      {isShowPurchaseAgreementForm ? (
        <PurchaseAgreementForm
          application={currentApplication}
          files={myFiles}
          formId={formId}
          submitPurchaseAgreement={submitPurchaseAgreement}
          taskId={task.id}
        />
      ) : null}

      {!isTaskForView && (
        <>
          <FileZone
            dropzoneProps={getRootProps()}
            files={myFiles}
            inputProps={getInputProps()}
            isLoading={isLoading}
            onRemoveFile={onRemoveFile}
            onSelectFile={open}
          />

          {fileRejections.map(({ file, errors }) => (
            <Errors key={file.name}>
              <ErrorMessage error={`${file.name}:`} />
              {errors.map(({ message, code }) => (
                <ErrorMessage error={transformMimeTypeToExtension(message)} key={code} />
              ))}
            </Errors>
          ))}

          {APIError && <APIErrorMessage error={APIError} />}
        </>
      )}
      <SubmitButton
        data-testid="task_submit_button"
        disabled={isSubmitDisabled}
        form={formId}
        isSubmitting={isLoading}
        type="submit"
        onClick={!isShowPurchaseAgreementForm ? onSubmit : undefined}
      >
        {isLoading ? <CircleSpinner size={17} /> : 'Submit Task'}
      </SubmitButton>
    </Container>
  );
};

export default memo(DocumentsTaskForm);

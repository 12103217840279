import { FC } from 'react';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { styled } from 'styled-components';

import { TypographyComponent } from 'enums/ui';

import { Typography } from 'components/Typography';

import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Calculate } from 'assets/icons/calculate.svg';

const PriceLoanCard = styled(Link)`
  border-radius: 20px;
  width: 100%;
  height: 67px;

  margin-top: 20px;
  padding: 25px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  transition: all 0.5s ease;
  background-color: ${({ theme }) => theme.colors.card.background};
  box-shadow: ${({ theme }) => `0px 10px 30px 0 ${theme.colors.shadow}`};

  &:focus,
  &:hover {
    box-shadow: ${({ theme }) =>
      `0px 10px 30px 0 ${darken(theme.coefficients.primaryHovered, theme.colors.shadow)}`};

    & > svg > path,
    & > div > svg > path {
      fill: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }

    & > div > h5 {
      color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }
  }
`;

const LinkText = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 10px;
`;

export const ArrowIcon = styled(Arrow)`
  margin-left: 5px;
  transition: 0.7s;

  transform: rotate(-90deg);

  & > path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const CalculateIcon = styled(Calculate)`
  & > path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const PositioningContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PriceLoanLink: FC = () => {
  return (
    <PriceLoanCard to="../loan-price">
      <PositioningContainer>
        <CalculateIcon />
        <LinkText component={TypographyComponent.H5}>Price a Loan</LinkText>
      </PositioningContainer>

      <ArrowIcon />
    </PriceLoanCard>
  );
};

export default PriceLoanLink;

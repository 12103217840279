import { FC, SVGProps } from 'react';

const PhoneSVG: FC<SVGProps<SVGSVGElement>> = ({ color }) => {
  return (
    <svg fill="none" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3.22451C0.0391397 3.00509 0.0676714 2.78312 0.119614 2.56626C0.195278 2.26167 0.354971 1.98443 0.580511 1.76612C1.02422 1.324 1.46755 0.885903 1.90577 0.44049C2.52396 -0.188501 3.46331 -0.104758 4.0237 0.441953C4.70554 1.10642 5.37713 1.78295 6.0447 2.45984C6.69544 3.11809 6.62484 4.14788 5.89473 4.63571C5.7506 4.73189 5.58197 4.79186 5.42249 4.86317C4.9016 5.09649 4.55154 5.61394 4.60897 6.18003C4.64369 6.4849 4.73355 6.78093 4.87417 7.05367C5.60977 8.49157 6.70678 9.56415 8.19153 10.2191C8.70144 10.4444 9.20587 10.4173 9.67774 10.0944C9.93379 9.9196 10.0871 9.6724 10.1983 9.38898C10.5491 8.49706 11.6614 8.17342 12.3663 8.81338C13.171 9.54477 13.929 10.3288 14.6741 11.1213C15.0629 11.5345 15.0721 12.0629 14.8639 12.5738C14.7798 12.7797 14.6353 12.972 14.4809 13.1355C14.0786 13.5615 13.6528 13.9642 13.2435 14.3832C12.9232 14.7161 12.4958 14.9254 12.0364 14.9742C12.0083 14.9803 11.981 14.9892 11.9548 15.0009H11.2803C11.0374 14.9537 10.7916 14.9172 10.5516 14.8583C9.42534 14.5822 8.41283 14.0538 7.44495 13.4321C6.30222 12.7007 5.263 11.8377 4.29914 10.8825C2.92084 9.51368 1.71922 8.01179 0.844246 6.26378C0.512108 5.60004 0.241422 4.91291 0.107177 4.17896C0.0632819 3.94017 0.0340186 3.69881 0 3.45855V3.22451Z"
        fill={color}
      />
    </svg>
  );
};

export default PhoneSVG;

import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { Arrow, StyledLink } from './BackLink.styles';

const BackLink = () => {
  const { pathname } = useLocation();

  const linkData = useMemo(() => {
    if (!!matchPath('pipeline/:id', pathname)) {
      return { route: '../pipeline', text: 'to Pipeline' };
    }
  }, [pathname]);

  if (!linkData) {
    return null;
  }

  return (
    <StyledLink to={linkData.route}>
      <Arrow /> {linkData.text}
    </StyledLink>
  );
};

export default BackLink;

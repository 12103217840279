import { styled } from 'styled-components';

import ErrorMessage from 'components/ErrorMessage';

import { commonInput } from 'styles/common';
import { getScrollbar } from 'styles/scrollbar';

import { calculateTextAreaHeight } from './TextField.helpers';

interface InputProps {
  $isNotValid?: boolean;
  $hasRightIcon: boolean;
}

export const Input = styled.input<InputProps>`
  ${commonInput}
`;

export const TextArea = styled.textarea<
  InputProps & {
    height: number;
    $isNarrowScrollbar?: boolean;
  }
>`
  ${commonInput};

  ${({ $isNarrowScrollbar }) => ($isNarrowScrollbar ? getScrollbar(8) : getScrollbar())}

  resize: none;
  line-height: 16px;
  height: ${({ height }) => height}px;
  // fix 22915 bug - issue 1, occurred when change zoom in the browser settings
  overflow: ${({ height }) => (height === calculateTextAreaHeight(1) ? 'hidden' : 'auto')};
`;

export const TextFieldWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldErrorMessage = styled(ErrorMessage)`
  margin-top: 5px;
`;

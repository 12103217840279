import { FC, memo, useCallback, useState } from 'react';

import { TypographyComponent } from 'enums/ui';

import { TabsContainer, TabTitle, TitlesContainer } from 'components/Tabs/Tabs.styles';

interface Props {
  tabs: Array<{ title: string | JSX.Element; component: JSX.Element }>;
  $isFewLinesEnabled?: boolean;
  minTabWidth?: number;
}

const blockTestName = 'tab';

const Tabs: FC<Props> = ({ tabs, $isFewLinesEnabled, minTabWidth }) => {
  const [visibleTab, setVisibleTab] = useState<number>(0);

  const setTabActive = useCallback((index: number) => {
    setVisibleTab(index);
  }, []);

  return (
    <TabsContainer>
      <TitlesContainer
        $isFewLinesEnabled={$isFewLinesEnabled}
        $minTabWidth={minTabWidth}
        data-testid={`${blockTestName}__container`}
      >
        {tabs.map(({ title }, index) => (
          <TabTitle
            $component={TypographyComponent.H7}
            $isActive={visibleTab === index}
            data-testid={`${blockTestName}__item`}
            key={typeof title === 'string' ? title : index}
            // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
            onClick={setTabActive.bind(null, index)}
          >
            {title}
          </TabTitle>
        ))}
      </TitlesContainer>
      {tabs[visibleTab].component}
    </TabsContainer>
  );
};

export default memo(Tabs);

import { FC, KeyboardEvent, memo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { KeyCode } from 'enums/keyboard';
import { navigateByArrows } from 'utils/keyboard';

import { selectIsMobileView } from 'store/selectors/ui';
import { TermInfo } from 'types/application';

import {
  Condition,
  ConditionsList,
  ConditionValue,
  SubTitle,
  Tab,
  Text,
  Years,
} from './TermTab.styles';

interface TermTabProps {
  info: TermInfo;
  index: number;
  changeTerm: (term: string) => void;
  isActive?: boolean;
  className?: string;
  isCompact?: boolean;
}

const TermTab: FC<TermTabProps> = ({ isActive, info, changeTerm, className, isCompact }) => {
  const isMobile = useSelector(selectIsMobileView);

  const { years, rate, paymentMonth } = info;

  const tabRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLButtonElement>) => {
    if (e.code === KeyCode.Tab) {
      e.preventDefault();
    }

    navigateByArrows(e, tabRef);
  }, []);

  const onSelect = useCallback(() => {
    changeTerm(years);
  }, [years, changeTerm]);

  return (
    <Tab
      $isActive={isActive}
      $isCompact={isCompact}
      className={className}
      ref={tabRef}
      type="button"
      onClick={onSelect}
      onFocus={onSelect}
      onKeyDown={handleKeyDown}
    >
      <Years $isCompact={isCompact} isActive={isActive} text={years} />
      <SubTitle $isCompact={isCompact}>Years / Fixed</SubTitle>

      {!isMobile && !isCompact && (
        <ConditionsList>
          <Condition>
            <Text>
              Rate as <br /> low as
            </Text>
            <ConditionValue>{rate}%</ConditionValue>
          </Condition>
          <Condition>
            <Text>
              Payment <br /> per mo:
            </Text>
            <ConditionValue>{paymentMonth}</ConditionValue>
          </Condition>
        </ConditionsList>
      )}
    </Tab>
  );
};

export default memo(TermTab);

import { number, object, string } from 'yup';

import { LoanPurpose, PropertyType } from 'enums/application';
import { ValidationError } from 'enums/validation';
import { getCurrencyField, getNameField, getZipField } from 'utils/validation';

const getPurchaseCurrencyFieldValidation = (isDownPayment: boolean, minValue: number) =>
  string().when('loanPurpose', (loanPurpose: LoanPurpose) => {
    // disable validation for refinance
    if (loanPurpose === LoanPurpose.Refinance) {
      return string();
    }

    if (isDownPayment) {
      return getCurrencyField({ isRequired: loanPurpose === LoanPurpose.Purchase, minValue }).test(
        'downPayment validation',
        'Down Payment should be less than Purchase Price',
        (downPayment, { parent: { purchasePrice } }) =>
          !purchasePrice ? true : !!(downPayment && +downPayment < +purchasePrice),
      );
    }

    return getCurrencyField({ isRequired: loanPurpose === LoanPurpose.Purchase, minValue });
  });

const getRefinanceCurrencyFieldValidation = (isMortgageLeft: boolean, minValue: number) =>
  string().when('loanPurpose', (loanPurpose: LoanPurpose) => {
    // disable validation for purchase
    if (loanPurpose === LoanPurpose.Purchase) {
      return string();
    }

    if (isMortgageLeft) {
      return getCurrencyField({ isRequired: loanPurpose === LoanPurpose.Refinance, minValue }).test(
        'firstMortgageBalance validation',
        'Mortgage Left should be less than Property Value',
        (firstMortgageBalance, { parent: { propertyValue } }) =>
          !propertyValue
            ? true
            : !!(firstMortgageBalance && +firstMortgageBalance < +propertyValue),
      );
    }

    return getCurrencyField({ isRequired: loanPurpose === LoanPurpose.Refinance, minValue });
  });

export const schema = object().shape({
  loanPurpose: getNameField(),
  propertyType: getNameField(),
  occupancy: getNameField(), // property usage
  creditScore: getNameField(),

  // for LoanPurpose.Purchase
  purchasePrice: getPurchaseCurrencyFieldValidation(false, 1000),
  downPaymentValue: getPurchaseCurrencyFieldValidation(true, 1),

  // for LoanPurpose.Refinance
  propertyValue: getRefinanceCurrencyFieldValidation(false, 1000),
  firstMortgageBalance: getRefinanceCurrencyFieldValidation(true, 0),

  zip: getZipField({
    customValidator: (_, { parent: { city, county, state } }) => !!(city && county && state),
  }),
  county: getNameField(),

  // for multi unit property type
  numberOfUnits: number().test(
    'isRequiredNumberOfUnits',
    ValidationError.RequiredField,
    (value, { parent: { propertyType } }) =>
      propertyType !== PropertyType.MultiUnit || (!!value && value > 1),
  ),
});

import { styled } from 'styled-components';

import Skeleton from 'components/Skeleton';

import { blurred } from 'styles/common';

export const TasksNumber = styled.div<{ $isBlurred?: boolean }>`
  ${blurred};

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin-left: 5px;

  // align text
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
`;

export const TasksNumberSkeleton = styled(Skeleton)`
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin-left: 5px;
`;

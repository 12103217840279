export enum KeyCode {
  Tab = 'Tab',
  Enter = 'Enter',
  Esc = 'Escape',
  Backspace = 'Backspace',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Space = 'Space',
}

import { styled } from 'styled-components';

import { MediaQueries } from 'enums/ui';

import Button from 'components/Button';
import Carousel from 'components/Carousel';
import TermTab from 'components/TermTab';

export const TermTabsCarousel = styled(Carousel)`
  max-width: 680px;
  margin: 20px auto 30px;
`;

export const CustomTermTab = styled(TermTab)`
  padding: 10px;

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media (max-width: ${MediaQueries.Mobile}) {
    padding: 5px;
  }
`;

export const ScrollContainer = styled.div`
  max-height: 510px;
  overflow-y: scroll;

  & > :first-child {
    margin-top: 0;
  }

  @media (max-width: ${MediaQueries.Mobile}) {
    max-height: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 10px;

  & > :first-child {
    margin-right: 20px;
  }

  @media (max-width: ${MediaQueries.MobileLandscape}) {
    margin-top: 0;
  }
`;

export const ContinueButton = styled(Button)`
  min-width: 110px;
`;
